import { errorsConstants } from './constants';

export function errors(state = {}, action) {
  switch (action.type) {
    case errorsConstants.ERROR_404:
    case errorsConstants.ERROR_500:
    case errorsConstants.ERROR_OTHER:
      return {
        error: action.type,
      };
    case errorsConstants.ERROR_RESET:
      return {};
    default:
      return state;
  }
}
