import { blocIConstants } from './constants';
import { blocAPI } from '../../../common/api/blocs';
import {
  getDateString,
  getDateFromString,
  getStringFromBoolean,
  getBooleanFromString,
} from '../../../common/utils';
import { toastActions } from '../../toast/actions';
import { TOAST_MESSAGE } from '../../../common/components/Toasts/Toast';
import { foldersActions } from '../../folders/actions';
import { errorsActions } from '../../errors/actions';

export const transformApiPayloadToFormValues = ({
  closureCause,
  closureDocumentsSentAt,
  ...blocI
}) => ({
  ...blocI,
  closureCause: getStringFromBoolean(closureCause),
  closureDocumentsSentAt: getDateFromString(closureDocumentsSentAt),
  state: blocI.folder?.state || 'Actif',
});

function getBlocI(folderId) {
  function request() {
    return { type: blocIConstants.GET_BLOCI_REQUEST };
  }
  function success(blocI) {
    return { type: blocIConstants.GET_BLOCI_SUCCESS, blocI };
  }
  function failure(error) {
    return { type: blocIConstants.GET_BLOCI_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return blocAPI
      .getBloc(folderId, 'bloc_i_synthesis_closure')
      .then(({ blocISynthesisClosure: blocI }) => {
        dispatch(success(transformApiPayloadToFormValues(blocI)));
        dispatch(foldersActions.getFolderFromBloc(blocI.folder || {}));
      }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

function updateBlocI(folderId, updatedBlocI) {
  function request(blocI) {
    return { type: blocIConstants.UPDATE_BLOCI_REQUEST, blocI };
  }
  function success(blocI) {
    return { type: blocIConstants.UPDATE_BLOCI_SUCCESS, blocI };
  }
  function failure(error) {
    return { type: blocIConstants.UPDATE_BLOCI_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedBlocI));

    return blocAPI
      .updateBloc(folderId, 'bloc_i_synthesis_closure', {
        ...updatedBlocI,
        closureCause: getBooleanFromString(updatedBlocI.closureCause),
        closureDocumentsSentAt: getDateString(updatedBlocI.closureDocumentsSentAt),
        folderAttributes: {
          id: folderId,
          state: updatedBlocI.state,
        },
      })
      .then(({ blocISynthesisClosure: blocI }) => {
        dispatch(foldersActions.getFolderFromBloc(blocI.folder || {}));
        dispatch(success(transformApiPayloadToFormValues(blocI)));
        dispatch(toastActions.success(TOAST_MESSAGE.success.update));
      }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

function updateBlocIComments(blocIId, folder) {
  return { type: blocIConstants.UPDATE_BLOCI_COMMENTS, blocIId, folder };
}

// Selectors

const getBlocIById = (state, props) => state.blocs.blocI[props.match.params.folderId];

export const blocISelectors = {
  getBlocIById,
};

export const blocIActions = {
  getBlocI,
  updateBlocI,
  updateBlocIComments,
};
