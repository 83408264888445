import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Stack, Box, Spinner } from '@chakra-ui/core';

import { TabForm } from '../../../../common/components/Tabs';
import {
  FormSection,
  FormSubmitButton,
  FormCTAButton,
} from '../../../../common/components/Form/Form';
import { RadioGroup, RadioInput } from '../../../../common/components/Form/FormInputs';
import { Notice, NoticeText } from '../../../../common/components/Notice';
import { history } from '../../../../common/helpers/history';
import { getFolderPath } from '../../../../common/routes';
import MultiSelectCompanies from '../../../../common/components/Form/MultiSelectCompanies';

export const BlocDTab2 = ({ blocD: { error, pending, ...initialValues }, onSubmit }) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  const areQ5FioIncludedAndHouseAdaptable =
    initialValues.fio?.q5Answer.includes('A') &&
    (initialValues.fio?.q5Answer.includes('C') || initialValues.fio?.q5Answer.includes('D')) &&
    initialValues.fio?.q5Answer.includes('E') &&
    initialValues.fio?.q5Answer.includes('H');

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        return onSubmit({ ...initialValues, ...values }).then(() => {
          setTouched({});
          setSubmitting(false);
        });
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit}>
          <Stack spacing="3.5rem">
            {initialValues.fio ? (
              <Box>
                {areQ5FioIncludedAndHouseAdaptable &&
                  initialValues.recommandationKind !== 'other_case' && (
                    <Notice variant="warning" mb={8} spacing={3}>
                      {initialValues.recommandationKind === 'only_works' && (
                        <NoticeText variant="warning">
                          Une seule et unique entreprise travaux sera obligatoirement désignée par
                          le bénéficiaire
                        </NoticeText>
                      )}
                      {initialValues.recommandationKind ===
                        'works_and_other_except_human_help_or_service' && (
                        <NoticeText variant="warning">
                          Une seule et unique entreprise travaux sera obligatoirement désignée par
                          le bénéficiaire Une ou plusieurs entreprises hors travaux pourrait etre
                          désignée(s) par le bénéficiaire
                        </NoticeText>
                      )}
                    </Notice>
                  )}

                <FormSection title="Mise en concurrence">
                  <RadioGroup legend="Choix du bénéficiaire" name="isBiddingCompetitive">
                    <RadioInput
                      value="yes"
                      disabled={initialValues.recommandationKind !== 'other_case'}
                    >
                      Oui
                    </RadioInput>
                    <RadioInput
                      value="yes_without_major_work"
                      disabled={
                        initialValues.recommandationKind !==
                        'works_and_other_except_human_help_or_service'
                      }
                    >
                      Oui (Hors travaux de gros ou second oeuvre)
                    </RadioInput>
                    {initialValues.recommandationKind === 'only_works' ? (
                      <RadioInput value="no_only_works">Non</RadioInput>
                    ) : (
                      <RadioInput value="no">Non</RadioInput>
                    )}
                  </RadioGroup>

                  {formik.values?.companies && (
                    <MultiSelectCompanies
                      mt="2.8rem"
                      fieldName="companies"
                      fieldErrorName="blocDCompanies"
                      isInline
                      label="Entreprise(s) imposée(s) par le bénéficiaire"
                      noResultText="Non présente dans la base de données"
                      disabled={
                        formik.values?.companies?.length >= 18 ||
                        (initialValues.recommandationKind === 'only_works' &&
                          formik.values?.companies?.length >= 1)
                      }
                    />
                  )}

                  <Box textAlign="center" mt="6.375rem">
                    <FormCTAButton
                      disabled={
                        pending ||
                        !formik.values.isBiddingCompetitive ||
                        !initialValues.isRecipient_2dpeValidationReceived ||
                        (initialValues.recommandationKind === 'only_works' &&
                          formik.values?.companies?.length !== 1) ||
                        (initialValues.recommandationKind ===
                          'works_and_other_except_human_help_or_service' &&
                          formik.values?.companies?.length < 1)
                      }
                      onClick={() => {
                        formik
                          .submitForm()
                          .then(() =>
                            history.push(getFolderPath('bloc_e', initialValues.folderId)),
                          );
                      }}
                    >
                      Demander les devis
                    </FormCTAButton>
                  </Box>
                </FormSection>
              </Box>
            ) : (
              <Spinner label="Chargement..." size="xl" color="coral.900" mx="auto" />
            )}
          </Stack>

          <FormSubmitButton
            type="submit"
            disabled={pending}
            isLoading={formik.isSubmitting}
            loadingText="Enregistrer"
          >
            Enregistrer
          </FormSubmitButton>
        </TabForm>
      )}
    </Formik>
  );
};
