import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFolderPath } from '../../../../common/routes';
import { BlocITabs } from './BlocITabs';
import { blocIActions, blocISelectors } from '../../../../redux/blocs/blocI/actions';
import { PageError404 } from '../../../../errors/components';

const BlocI = ({
  match: {
    params: { folderId },
  },
  getBlocI,
  updateBlocI,
  ...props
}) => {
  useEffect(() => {
    getBlocI(folderId);
  }, [getBlocI, folderId]);

  const onSubmit = values => updateBlocI(folderId, values);

  return (
    <Switch>
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', '')}`}
        exact
        render={() => <Redirect to={getFolderPath(props.blocId, folderId)} />}
      />
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', ':tabIndex')}`}
        exact
        render={routerProps => <BlocITabs {...props} {...routerProps} onSubmit={onSubmit} />}
      />
      <Route component={PageError404} />
    </Switch>
  );
};

const mapStateToProps = (state, props) => {
  const blocI = blocISelectors.getBlocIById(state, props) || {};

  return {
    blocI,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBlocI: blocIActions.getBlocI,
      updateBlocI: blocIActions.updateBlocI,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BlocI);
