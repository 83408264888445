import { dashboardConstants } from './constants';

export const initialState = {
  data: [],
  pending: false,
};

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case dashboardConstants.GET_DASHBOARD_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case dashboardConstants.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: action.dashboard,
        pending: false,
      };
    case dashboardConstants.GET_DASHBOARD_FAILURE:
    default:
      return state;
  }
}
