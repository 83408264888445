import { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Stack, Box } from '@chakra-ui/core';
/** @jsx jsx */
import { jsx } from '@emotion/core';

import { TabForm } from '../../../../common/components/Tabs';
import { FormSection, FormSubmitButton } from '../../../../common/components/Form/Form';
import {
  RadioGroup,
  RadioInput,
  CheckboxGroup,
  CheckboxGroupInput,
  CheckBoxInput,
  Label,
} from '../../../../common/components/Form/FormInputs';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import {
  QuoteUploadFilename,
  QuoteUploadWrapper,
} from '../../../../common/components/QuoteUpload/QuoteUpload';

const TAB_SECTIONS = [
  {
    title: 'Informations générales',
    fields: ['adaptableHousing', 'hasTechnicalSheet', 'affectedRooms', 'affectedHabits'],
  },
  {
    title: 'Préconisations',
    fields: [
      'works',
      'isTechnicalAssistanceRequired',
      'isWheelchairOrOtherEquipmentsRequired',
      'isSpecificVehicleArrangementsRequired',
      'hasLifestyleAdviceWithEquipmentIntegration',
      'humanHelpOrService',
    ],
  },
  {
    title: 'Prise en compte de la LPPR',
    fields: ['hasLpprTechnicalRecommandation'],
  },
];

export const BlocATab2 = ({
  blocA: { error, pending, diagnosticSummary, ...initialValues },
  onSubmit,
}) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        return onSubmit({ ...initialValues, diagnosticSummary, ...values }).then(() => {
          setTouched({});
          setSubmitting(false);
        });
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit}>
          {diagnosticSummary && (
            <Stack
              spacing="3.5rem"
              css={{
                '[role=group], [role=radiogroup]': {
                  paddingLeft: '1.5rem',
                },
                'fieldset:not(:first-of-type)': {
                  marginTop: '2.5rem',
                },
              }}
            >
              {TAB_SECTIONS.map(section => (
                <FormSection key={section.title} title={section.title}>
                  {section.fields.map(fieldName => (
                    <DiagnosticSummaryInput
                      key={fieldName}
                      name={fieldName}
                      content={diagnosticSummary[fieldName]}
                    />
                  ))}
                </FormSection>
              ))}
            </Stack>
          )}

          <FormSection title="Réception des devis de travaux" mt="2.5rem">
            <RadioGroup
              name="worksQuoteReceived"
              legend="Un devis travaux a-t-il été reçu ?"
              disabled={
                (!initialValues.typeOfVisit?.match(/(joint|existing_quote)/) ||
                  !initialValues.visitReminderSentAt) &&
                !initialValues.firstQuoteVersion?.downloadLink
              }
            >
              <RadioInput value="true" disabled={pending}>
                Oui
              </RadioInput>
              {formik.values?.worksQuoteReceived === 'true' ? (
                <Box py={4} ml="1.75rem" pl="1.75rem">
                  {formik.values.typeOfVisit?.match(/(joint|existing_quote)/) &&
                  formik.values.positionedCompany ? (
                    <Box mb={6}>
                      <Label>Devis</Label>
                      <QuoteUploadWrapper>
                        <QuoteUploadFilename
                          quoteVersion={initialValues.firstQuoteVersion}
                          placeholder="Aucun devis disponible"
                        />
                      </QuoteUploadWrapper>
                    </Box>
                  ) : null}
                  <DateInput
                    label="1ère date de reception"
                    name="worksQuoteReceivedAt"
                    id="worksQuoteReceivedAt"
                    disabled={!initialValues.typeOfVisit.match(/(joint|existing_quote)/)}
                  />
                  <RadioGroup
                    name="worksQuoteStatus"
                    legend="Statut de l’analyse du devis"
                    mb={2}
                    disabled={!initialValues.typeOfVisit.match(/(joint|existing_quote)/)}
                  >
                    <RadioInput value="valid">Totalement valide</RadioInput>
                    <RadioInput value="almost_valid">Légers détails à modifier</RadioInput>
                    <RadioInput value="additional_request">Demandes complémentaires</RadioInput>
                  </RadioGroup>

                  <DateInput
                    label="Date de demandes complémentaires"
                    name="worksQuoteAdditionalRequestsSentAt"
                    id="worksQuoteAdditionalRequestsSentAt"
                    style={{ mb: 0 }}
                    disabled={
                      formik.values?.worksQuoteStatus?.match(/valid/) ||
                      !formik.values?.worksQuoteStatus ||
                      !initialValues.typeOfVisit.match(/(joint|existing_quote)/)
                    }
                  />
                </Box>
              ) : null}
              <RadioInput value="false" disabled={pending}>
                Non
              </RadioInput>
            </RadioGroup>
          </FormSection>

          <FormSubmitButton
            type="submit"
            disabled={pending}
            isLoading={formik.isSubmitting}
            loadingText="Enregistrer"
          >
            Enregistrer
          </FormSubmitButton>
        </TabForm>
      )}
    </Formik>
  );
};

const DiagnosticSummaryInput = ({ name, content }) => {
  switch (content.type) {
    case 'simple_choice_radio':
      return (
        <RadioGroup isInline name={name} legend={content.title}>
          {Object.entries(content.availableAnswers).map(([value, label]) => (
            <RadioInput key={label} value={value}>
              {label}
            </RadioInput>
          ))}
        </RadioGroup>
      );
    case 'multiple_choice':
      return (
        <CheckboxGroup name={name} legend={content.title}>
          {Object.entries(content.availableAnswers).map(([value, label]) => (
            <CheckboxGroupInput key={value} value={value.toUpperCase()}>
              {label}
            </CheckboxGroupInput>
          ))}
        </CheckboxGroup>
      );
    case 'simple_choice_checkbox':
      return (
        <Box
          mb={5}
          mt={name === 'isTechnicalAssistanceRequired' ? 8 : 0}
          css={{ span: { fontWeight: 600 } }}
        >
          <CheckBoxInput name={name}>{content.title}</CheckBoxInput>
        </Box>
      );
    default:
      return null;
  }
};
