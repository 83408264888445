import { apiV1 } from './api';

const getProjects = (page = 1) => apiV1.get(`/projects?page=${page}`).then(({ data }) => data);

const updateProject = (projectId, project) =>
  apiV1.put(`/projects/${projectId}`, { project }).then(({ data }) => data);

export const projectsAPI = {
  getProjects,
  updateProject,
};
