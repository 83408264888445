import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import { Box, Flex } from '@chakra-ui/core';
import { NavLink } from 'react-router-dom';

import { PageIndex } from '../common/components/layouts/Page/PageIndex';
import { SearchInput } from '../common/components/Form/FormInputs';
import { SecondaryButton, PrimaryButton } from '../common/components/Buttons';
import {
  Table,
  Thead,
  TH,
  TD,
  THeadRow,
  TBodyRow,
  TablePlaceholder,
  TableEditLink,
  TableResults,
} from '../common/components/Table';
import { foldersActions } from '../redux/folders/actions';
import { Pagination } from '../common/components/Pagination';
import { SelectInput } from '../common/components/Form/FormSelectInput';
import { getFolderPath } from '../common/routes';
import Comments from '../common/components/Comments/Comments';
import Can, { checkPermissions } from '../roleBasedAccess/Can';
import { Funders } from '../common/components/Funders/Funders';
import { is } from '../roleBasedAccess/Is';
import { usersAPI } from '../common/api/users';
import { foldersAPI } from '../common/api/folders';
import { useFoldersFilters } from './useFoldersFilters.hook';
import { THFilter } from '../tasks/components';

const PHASES = ['Phase 1', 'Phase 2', 'Phase 3', 'Phase 4'];
const STATES = ['Actif', 'En instance', 'Cloturé'];

const ConnectedFoldersIndex = ({ match, getFolders, updateFolders, pending, folders, meta }) => {
  const { user } = useSelector(state => state.authentication);
  const [currentPage, setCurrentPage] = useState(1);
  const [
    {
      currentStates,
      currentPhases,
      currentTags,
      setCurrentStates,
      setCurrentPhases,
      setCurrentTags,
    },
  ] = useFoldersFilters();

  const [search, setSearch] = useState({
    keywords: '',
    folderType: { label: "Filtrer par type d'AMO", value: '' },
    department: { label: 'Filtrer par département', value: '' },
    tags: currentTags,
    phases: currentPhases,
    status: currentStates,
  });
  const [tags, setTags] = useState([]);

  const calculateCurrentBloc = currentBloc => {
    console.log(currentBloc);
    const blocs = [
      'bloc_0',
      'bloc_fio',
      'bloc_a',
      'bloc_c',
      'bloc_d',
      'bloc_e',
      'bloc_f',
      'bloc_g',
      'bloc_h',
      'bloc_i',
    ];
    if (currentBloc.match('bloc_0')) return 'bloc_0';
    if (
      checkPermissions(user?.role, `folder:${currentBloc}:read`, { user }) ||
      checkPermissions(user?.role, `folder:read`, { user })
    )
      return currentBloc;
    let bloc = currentBloc;
    let indexOfBloc = blocs.indexOf(bloc);
    while (indexOfBloc !== 0 && !checkPermissions(user?.role, `folder:${bloc}:read`, { user })) {
      indexOfBloc -= 1;
      bloc = blocs[indexOfBloc];
    }
    return bloc;
  };

  useEffect(() => {
    getFolders(currentPage, search);
  }, [getFolders, currentPage, search]);

  useEffect(() => {
    setCurrentPage(1);
    setSearch({ ...search, tags: currentTags, status: currentStates, phases: currentPhases });
  }, [currentPhases, currentStates, currentTags]);

  useEffect(() => {
    if (is(user, 'pilote externe') || is(user, 'référent externe'))
      usersAPI
        .getUser(-1)
        .then(u => {
          setTags(u.tags);
        })
        .catch(console.log);
    else
      foldersAPI
        .getTags({ search: '' })
        .then(availableTags => {
          setTags(availableTags);
        })
        .catch(console.log);
  }, [setTags, user]);

  return (
    <PageIndex
      pageAction={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Header
          match={match}
          meta={meta}
          pending={pending}
          onSearch={searchValues => setSearch(prevSearch => ({ ...prevSearch, ...searchValues }))}
        />
      }
    >
      <Table>
        <Thead>
          <THeadRow>
            <TH size="sm" width="10%">
              N° de dossier
            </TH>
            <TH size="sm" width="5%">
              Civilité
            </TH>
            <TH size="sm" width="12%">
              Nom
            </TH>
            <TH size="sm" width="12%">
              Prénom
            </TH>
            <TH size="sm" width="15%">
              Ville
            </TH>
            <TH size="sm" width="10%">
              Code Postal
            </TH>
            <TH size="sm">
              <THFilter
                options={PHASES}
                label="Phase"
                menuOptionGroupOptions={{ onChange: setCurrentPhases, value: currentPhases }}
                // placement="bottom-center"
              />
            </TH>
            <TH size="sm">
              <THFilter
                options={STATES}
                label="Statut"
                menuOptionGroupOptions={{ onChange: setCurrentStates, value: currentStates }}
                // placement="bottom-center"
              />
            </TH>
            <TH size="sm">Type d'AMO</TH>
            <TH size="sm">
              <THFilter
                options={tags}
                label="Tag"
                menuOptionGroupOptions={{ onChange: setCurrentTags, value: currentTags }}
                isUpppercase
                placement="bottom-center"
              />
            </TH>
            <TH size="sm" textAlign="center">
              Actions
            </TH>
          </THeadRow>
        </Thead>
        <tbody>
          {!folders || pending ? (
            <TablePlaceholder colSpan={10} />
          ) : (
            folders.map(folder => {
              const {
                id,
                formattedId,
                recipientTitle,
                recipientFirstName,
                recipientLastName,
                housingPostalData,
                phase,
                state,
                folderType,
                tag,
                currentBloc,
                comments,
              } = folder;
              const bloc = calculateCurrentBloc(currentBloc); // to fix

              const editPath = getFolderPath(bloc, id);
              return (
                <TBodyRow key={id} hasScaleOnHover={false}>
                  <TD>
                    <TableEditLink size="sm" to={editPath}>
                      {formattedId}
                    </TableEditLink>
                  </TD>
                  <TD textTransform="capitalize">
                    <TableEditLink size="sm" to={editPath}>
                      {recipientTitle}.
                    </TableEditLink>
                  </TD>
                  <TD textTransform="uppercase">
                    <TableEditLink size="sm" to={editPath}>
                      {recipientLastName}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink size="sm" to={editPath}>
                      {recipientFirstName}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink size="sm" to={editPath}>
                      {housingPostalData?.city}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink size="sm" to={editPath}>
                      {housingPostalData?.postalCode}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink size="sm" to={editPath}>
                      {phase}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink size="sm" to={editPath}>
                      {state}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink size="sm" to={editPath}>
                      {folderType}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink size="sm" textTransform="uppercase" to={editPath}>
                      {tag}
                    </TableEditLink>
                  </TD>

                  <TD textAlign="center">
                    <Box py={1}>
                      <Funders quote={folder} />
                      <Can
                        perform="comments:read"
                        yes={() => (
                          <Comments
                            folder={{
                              formattedId,
                              id,
                              state,
                              recipientFirstName,
                              recipientLastName,
                            }}
                            comments={comments}
                            updateFallback={updateFolders}
                          />
                        )}
                      />
                    </Box>
                  </TD>
                </TBodyRow>
              );
            })
          )}
        </tbody>
      </Table>
      {meta && (
        <Pagination
          current={currentPage}
          total={meta.total}
          defaultPageSize={meta.perPage}
          onChange={page => setCurrentPage(page)}
        />
      )}
    </PageIndex>
  );
};

const mapStateToProps = ({ folders: state }) => {
  return {
    pending: state.pending,
    folders: state.data,
    meta: state.meta,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFolders: foldersActions.getFolders,
      updateFolders: foldersActions.updateFolders,
    },
    dispatch,
  );

export const FoldersIndex = connect(mapStateToProps, mapDispatchToProps)(ConnectedFoldersIndex);

const SearchBar = ({ onSearch, departments, match }) => {
  return (
    <Flex isInline justifyContent="space-between" w="100%">
      <Formik
        initialValues={{
          keywords: '',
          folderType: { label: "Filtrer par type d'AMO", value: '' },
          department: { label: 'Filtrer par département', value: '' },
        }}
        onSubmit={values => onSearch(values)}
      >
        {formik => (
          <Box
            as="form"
            onSubmit={formik.handleSubmit}
            d="flex"
            alignItems="start"
            flexGrow="1"
            minW={0}
            mr={3}
          >
            <Box d="flex" flexDir="column" width="100%" flexGrow={1} minW={0}>
              <SearchInput name="keywords" placeholder="Nom, Prénom, n° de dossier, tag" />
              <Box d="flex" alignItems="start" justifyContent="start" flexWrap="wrap" mt={2}>
                <SelectInput
                  name="folderType"
                  placeholder="Filtrer par type d'AMO"
                  styles={{ flexGrow: 1, maxW: '30%', mr: 3 }}
                  size="sm"
                  options={[
                    { label: "Filtrer par type d'AMO", value: '' },
                    { label: 'AMOc', value: 'AMOc' },
                    { label: 'AMOe', value: 'AMOe' },
                    { label: 'AMOad', value: 'AMOad' },
                  ]}
                />
                <SelectInput
                  name="department"
                  placeholder="Filtrer par département"
                  styles={{ flexGrow: 1, maxW: '30%', mr: 3 }}
                  css={{
                    '[class*=-indicatorContainer]:first-of-type': {
                      position: 'static',
                    },
                  }}
                  size="sm"
                  options={[
                    { label: 'Filtrer par département', value: '' },
                    ...departments.map(dep => ({ label: dep, value: dep })),
                  ]}
                />
              </Box>
            </Box>
            <SecondaryButton type="submit" size="sm" ml={3}>
              Lancer la recherche
            </SecondaryButton>
          </Box>
        )}
      </Formik>
      <Box>
        <Can
          perform="folder:edit"
          yes={() => (
            <PrimaryButton as={NavLink} to={`${match.url}/new`} size="sm" rightIcon="plus">
              Créer un dossier
            </PrimaryButton>
          )}
        />
      </Box>
    </Flex>
  );
};

const Header = ({ onSearch, match, meta, pending }) => {
  return (
    <>
      <SearchBar onSearch={onSearch} departments={meta?.presentDepartments || []} match={match} />
      {(meta || pending) && (
        <TableResults ressource="bénéficiaire" total={meta?.total || 0} style={{ mt: -3 }} />
      )}
    </>
  );
};
