import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import routes from '../common/routes';
import { checkPermissions } from '../roleBasedAccess/Can';
import { is, isExtern } from '../roleBasedAccess/Is';

export const Home = () => {
  const { profile, user } = useSelector(state => state.authentication);

  const currentUser = Object.keys(profile || {}).length === 0 ? user : profile;

  if (checkPermissions(currentUser.role, 'dashboard:visit', { user: currentUser })) {
    return <Redirect to={routes.dashboard} />;
  }
  if (is(currentUser, 'ergothérapeute') && isExtern({ user: currentUser })) {
    return <Redirect to={routes.projectPlace} />;
  }
  if (checkPermissions(currentUser.role, 'folder:edit', { user: currentUser })) {
    return <Redirect to={routes.tasks.index} />;
  }
  if (is(currentUser, 'coordinateur entreprises')) {
    return <Redirect to={routes.folders.index} />;
  }
  if (is(currentUser, 'référent entreprise')) {
    return <Redirect to={routes.quotes} />;
  }

  return <Redirect to={routes.preFolders} />;
};
