import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import { Box } from '@chakra-ui/core';

import { PageIndex } from '../common/components/layouts/Page/PageIndex';
import { SearchInput } from '../common/components/Form/FormInputs';
import { SecondaryButton, PrimaryButton } from '../common/components/Buttons';
import {
  Table,
  Thead,
  TH,
  TD,
  THeadRow,
  TBodyRow,
  TablePlaceholder,
  TableEditLink,
  TableResults,
} from '../common/components/Table';
import { ergoResourcesActions } from '../redux/ergoResources/actions';
import { Pagination } from '../common/components/Pagination';

import Can from '../roleBasedAccess/Can';

const ConnectedErgoResourcesIndex = ({ match, getErgoResources, pending, ergoResources, meta }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getErgoResources(currentPage, search);
  }, [getErgoResources, currentPage, search]);

  return (
    <PageIndex pageAction={<Header match={match} meta={meta} onSearch={name => setSearch(name)} />}>
      <Table>
        <Thead>
          <THeadRow>
            <TH>Nom de la ressource</TH>
            <TH>Type</TH>
            <TH>Les professionnels habilités</TH>
            <TH>Lien</TH>
          </THeadRow>
        </Thead>
        <tbody>
          {!ergoResources || pending ? (
            <TablePlaceholder />
          ) : (
            ergoResources.map(({ id, name, resourceType, professionals = [], link }) => (
              <TBodyRow key={id}>
                <TD>
                  <Can
                    perform="ergo-resources:edit"
                    yes={() => (
                      <TableEditLink to={`${match.url}/${id}/edit`}>{name}</TableEditLink>
                    )}
                    no={() => (
                      name
                    )}
                  />
                </TD>
                <TD>
                  <Can
                    perform="ergo-resources:edit"
                    yes={() => (
                      <TableEditLink to={`${match.url}/${id}/edit`}>{resourceType}</TableEditLink>
                    )}
                    no={() => (
                      resourceType
                    )}
                  />
                </TD>
                <TD>
                  <Can
                    perform="ergo-resources:edit"
                    yes={() => (
                      <TableEditLink to={`${match.url}/${id}/edit`}>{professionals}</TableEditLink>
                    )}
                    no={() => (
                      professionals
                    )}
                  />
                </TD>
                <TD>
                  <TableEditLink target="_blank" to={{ pathname: link }}>
                    <Box>
                      <PrimaryButton type="button" size="sm">
                        Consulter
                      </PrimaryButton>
                    </Box>
                  </TableEditLink>
                </TD>
              </TBodyRow>
            ))
          )}
        </tbody>
      </Table>
      {meta && (
        <Pagination
          current={currentPage}
          total={meta.total}
          defaultPageSize={meta.perPage}
          onChange={page => setCurrentPage(page)}
        />
      )}
    </PageIndex>
  );
};

const mapStateToProps = ({ ergoResources: state }) => ({
  pending: state.pending,
  ergoResources: state.data,
  meta: state.meta,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getErgoResources: ergoResourcesActions.getErgoResources,
    },
    dispatch,
  );

export const ErgoResourcesIndex = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedErgoResourcesIndex);

const SearchBar = ({ onSearch }) => {
  return (
    <Formik initialValues={{ search: '' }} onSubmit={values => onSearch(values.search)}>
      {formik => (
        <Box as="form" onSubmit={formik.handleSubmit} d="flex" alignItems="start" flexWrap="wrap">
          <SearchInput name="search" />
          <SecondaryButton type="submit" size="sm" ml={3}>
            Lancer la recherche
          </SecondaryButton>
        </Box>
      )}
    </Formik>
  );
};

const Header = ({ onSearch, match, meta }) => {
  return (
    <>
      <SearchBar onSearch={onSearch} />
      <Can
        perform="ergo-resources:edit"
        yes={() => (
          <PrimaryButton as={Link} to={`${match.url}/new`} rightIcon="plus">
            Créer une ressource
          </PrimaryButton>
        )}
      />
      {meta && <TableResults ressource="bénéficiaire" total={meta.total} />}
    </>
  );
};
