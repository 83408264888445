import { bloc0Constants } from './constants';

export const initialState = {
  pending: false,
};

export function bloc0(state = initialState, action) {
  switch (action.type) {
    case bloc0Constants.GET_BLOC0_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case bloc0Constants.GET_BLOC0_SUCCESS:
      return {
        ...initialState,
        ...action.bloc0,
        pending: false,
      };
    case bloc0Constants.UPDATE_BLOC0_REQUEST:
      return {
        ...state,
        ...action.bloc0,
        error: null,
        pending: true,
      };
    case bloc0Constants.UPDATE_BLOC0_SUCCESS:
      return {
        ...state,
        ...action.bloc0,
        error: null,
        pending: false,
      };
    case bloc0Constants.UPDATE_BLOC0_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case bloc0Constants.GET_BLOC0_FAILURE:
    default:
      return state;
  }
}
