import React from 'react';
import { Text, Box } from '@chakra-ui/core';

import { TabForm } from '../../../../common/components/Tabs';
import {
  FormSection,
  FormSubmitButton,
  FormCTAButton,
} from '../../../../common/components/Form/Form';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import { getFolderPath } from '../../../../common/routes';
import { history } from '../../../../common/helpers/history';

export const BlocCTab2 = ({ formik }) => {
  return (
    <TabForm onSubmit={formik.handleSubmit}>
      <FormSection title="Liste des financeurs">
        {formik.values.isCnavEligible ? (
          <DateInput
            label="Date de déclaration à la CNAV"
            name="cnavDeclarationDate"
            id="cnavDeclarationDate"
          />
        ) : (
          <Text color="semiDarkGray.600" mb={5} fontWeight={600}>
            Aucune déclaration du contrat d'AMO n'est nécessaire.
          </Text>
        )}

        <Box textAlign="center" mt="106px">
          <FormCTAButton
            onClick={() =>
              formik
                .submitForm()
                .then(() => history.push(getFolderPath('bloc_0', formik.values.folderId, 2)))
            }
            disabled={
              (formik.values.isCnavEligible && !formik.values.cnavDeclarationDate) ||
              (formik.values.requiredLaunchDocuments &&
                Object.values(formik.values.requiredLaunchDocuments).some(doc => !doc.isReceived))
            }
          >
            Finaliser l'ouverture du dossier
          </FormCTAButton>
        </Box>
      </FormSection>

      <FormSubmitButton type="submit" isLoading={formik.isSubmitting} loadingText="Enregistrer">
        Enregistrer
      </FormSubmitButton>
    </TabForm>
  );
};
