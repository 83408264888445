import { history } from '../../common/helpers/history';
import { recipientsConstants } from './constants';
import { recipientsAPI } from '../../common/api/recipients';
import { getDateString } from '../../common/utils';
import routes from '../../common/routes';
import { toastActions } from '../toast/actions';
import { TOAST_MESSAGE } from '../../common/components/Toasts/Toast';
import { errorsActions } from '../errors/actions';

function getRecipients(page, name) {
  function request() {
    return { type: recipientsConstants.GET_RECIPIENTS_REQUEST };
  }
  function success(data) {
    return { type: recipientsConstants.GET_RECIPIENTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: recipientsConstants.GET_RECIPIENTS_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return recipientsAPI.getRecipients(page, name).then(({ recipients, meta }) => {
      dispatch(success({ recipients, meta }));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function getRecipient(recipientId) {
  function request() {
    return { type: recipientsConstants.GET_RECIPIENT_REQUEST };
  }
  function success(recipient) {
    return { type: recipientsConstants.GET_RECIPIENT_SUCCESS, recipient };
  }
  function failure(error) {
    return { type: recipientsConstants.GET_RECIPIENT_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return recipientsAPI.getRecipient(recipientId).then(({ recipient }) => {
      let birthDate;
      if (recipient.birthDate) {
        const [day, month, year] = recipient.birthDate.split('/');
        birthDate = new Date(year, month - 1, day);
      }
      dispatch(
        success({
          ...recipient,
          recipientIsTheContact: recipient.recipientIsTheContact ? 'true' : 'false',
          birthDate: recipient.birthDate && birthDate,
        }),
      );
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function createRecipient(newRecipient) {
  function request() {
    return { type: recipientsConstants.CREATE_RECIPIENT_REQUEST };
  }
  function success() {
    return { type: recipientsConstants.CREATE_RECIPIENT_SUCCESS };
  }
  function failure(error) {
    return { type: recipientsConstants.CREATE_RECIPIENT_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return recipientsAPI
      .createRecipient({
        ...newRecipient,
        birthDate: newRecipient.birthDate && getDateString(newRecipient.birthDate),
        recipientIsTheContact: newRecipient.recipientIsTheContact === 'true' || false,
      })
      .then(() => {
        dispatch(success());
        history.push(routes.recipients);
        dispatch(toastActions.success(TOAST_MESSAGE.success.add));
      }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.add))));
  };
}

function updateRecipient(recipientId, updatedRecipient) {
  function request(recipient) {
    return { type: recipientsConstants.UPDATE_RECIPIENT_REQUEST, recipient };
  }
  function success() {
    return { type: recipientsConstants.UPDATE_RECIPIENT_SUCCESS };
  }
  function failure(error) {
    return { type: recipientsConstants.UPDATE_RECIPIENT_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedRecipient));

    return recipientsAPI
      .updateRecipient(recipientId, {
        ...updatedRecipient,
        birthDate: updatedRecipient.birthDate && getDateString(updatedRecipient.birthDate),
        recipientIsTheContact: updatedRecipient.recipientIsTheContact === 'true' || false,
      })
      .then(() => {
        dispatch(success());
        history.push(routes.recipients);
        dispatch(toastActions.success(TOAST_MESSAGE.success.update));
      }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

function deleteRecipient(recipientId) {
  function request() {
    return { type: recipientsConstants.DELETE_RECIPIENT_REQUEST };
  }
  function success() {
    return { type: recipientsConstants.DELETE_RECIPIENT_SUCCESS };
  }
  function failure(error) {
    return { type: recipientsConstants.DELETE_RECIPIENT_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return recipientsAPI.deleteRecipient(recipientId).then(() => {
      dispatch(success());
      history.push(routes.recipients);
      dispatch(toastActions.success(TOAST_MESSAGE.success.delete));
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.delete))));
  };
}

export const recipientsActions = {
  getRecipients,
  getRecipient,
  createRecipient,
  updateRecipient,
  deleteRecipient,
};
