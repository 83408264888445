import { blocCConstants } from './constants';

export const initialState = {
  pending: false,
};

export function blocC(state = initialState, action) {
  switch (action.type) {
    case blocCConstants.GET_BLOCC_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case blocCConstants.GET_BLOCC_SUCCESS:
      return {
        ...initialState,
        ...action.blocC,
        pending: false,
      };
    case blocCConstants.UPDATE_BLOCC_REQUEST:
      return {
        ...state,
        ...action.blocC,
        error: null,
        pending: true,
      };
    case blocCConstants.UPDATE_BLOCC_SUCCESS:
      return {
        ...state,
        ...action.blocC,
        error: null,
        pending: false,
      };
    case blocCConstants.UPDATE_BLOCC_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case blocCConstants.GET_BLOCC_FAILURE:
    default:
      return state;
  }
}
