const getInterventionTypes = () =>
  // TODO : a backend endpoint should be available to get these Magic values
  // apiV1.get(`/users/search_intervention_types?search=${search}`).then(({ data }) => data);
  new Promise((resolve, reject) => {
    resolve(200);
  })
    .then(() => [
      'AMOe - AMD',
      'AMOe - visite diagnostic',
      'AMOc - visite conjointe',
      'AMOc - ergo seul',
      'AMOc - devis existant',
      'AMOc - facture existante',
    ]) // RESOLVE
    .catch(() => []); // REJECT

export const interventionTypesAPI = {
  getInterventionTypes,
};
