import { dashboardConstants } from './constants';
import { dashboardAPI } from '../../common/api/dashboard';
import { errorsActions } from '../errors/actions';

function getDashboard() {
  function request() {
    return { type: dashboardConstants.GET_DASHBOARD_REQUEST };
  }
  function success(dashboard) {
    return { type: dashboardConstants.GET_DASHBOARD_SUCCESS, dashboard };
  }
  function failure(error) {
    return { type: dashboardConstants.GET_DASHBOARD_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return dashboardAPI.getDashboard().then(dashboard => {
      dispatch(success(dashboard));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

export const dashboardActions = {
  getDashboard,
};
