import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Stack } from '@chakra-ui/core';
import { TextInput } from '../Form/FormInputs';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { Form, FormActions, FormSection } from '../Form/Form';
import { history } from '../../helpers/history';

export const ErgoOfficeForm = ({ initialValues, onSubmit, pending, error, mode }) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={mode === 'edit'}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, setTouched }) => {
        setSubmitting(false);
        onSubmit(values);
        setTouched({});
      }}
    >
      {formik => (
        <Form onSubmit={formik.handleSubmit}>
          <Stack spacing={16}>
            <FormSection title="Informations du Cabinet">
              <TextInput
                label="Nom du Cabinet"
                type="text"
                id="name"
                name="name"
                placeholder="Mon Cabinet"
                disabled={pending}
                requiredInput
              />
            </FormSection>
          </Stack>

          <FormActions mt={20}>
            <SecondaryButton type="button" onClick={() => history.goBack()}>
              Annuler
            </SecondaryButton>
            <PrimaryButton type="submit" disabled={pending}>
              Enregistrer
            </PrimaryButton>
          </FormActions>
        </Form>
      )}
    </Formik>
  );
};
