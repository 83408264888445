import React from 'react';
import { Tabs, Tab, TabPanels, TabPanel, TabList } from '../../../../common/components/Tabs';
import { BlocETab1 } from './BlocETab1';

export const BlocETabs = ({
  match: {
    params: { folderId, tabIndex },
  },
  blocId,
  ...props
}) => {
  return (
    <Tabs folderId={folderId} tabIndex={tabIndex} blocId={blocId}>
      <TabList>
        <Tab>Demande de devis</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <BlocETab1 {...props} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
