import { blocCConstants } from './constants';
import { blocAPI } from '../../../common/api/blocs';
import { getDateString, getDateFromString } from '../../../common/utils';
import { toastActions } from '../../toast/actions';
import { TOAST_MESSAGE } from '../../../common/components/Toasts/Toast';
import { foldersActions } from '../../folders/actions';
import { errorsActions } from '../../errors/actions';

export const transformApiPayloadToFormValues = ({
  cnavDeclarationDate,
  launchDocumentsReceivedAt,
  fundingDocumentsReceivedAt,
  ...blocC
}) => ({
  ...blocC,
  cnavDeclarationDate: getDateFromString(cnavDeclarationDate),
  launchDocumentsReceivedAt: getDateFromString(launchDocumentsReceivedAt),
  fundingDocumentsReceivedAt: getDateFromString(fundingDocumentsReceivedAt),
});

function getBlocC(folderId) {
  function request() {
    return { type: blocCConstants.GET_BLOCC_REQUEST };
  }
  function success(blocC) {
    return { type: blocCConstants.GET_BLOCC_SUCCESS, blocC };
  }
  function failure(error) {
    return { type: blocCConstants.GET_BLOCC_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return blocAPI
      .getBloc(folderId, 'bloc_c_folder_validation')
      .then(({ blocCFolderValidation: blocC }) => {
        dispatch(success(transformApiPayloadToFormValues(blocC)));
        dispatch(foldersActions.getFolderFromBloc(blocC.folder || {}));
      }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function updateBlocC(folderId, updatedBlocC) {
  function request(blocC) {
    return { type: blocCConstants.UPDATE_BLOCC_REQUEST, blocC };
  }
  function success(blocC) {
    return { type: blocCConstants.UPDATE_BLOCC_SUCCESS, blocC };
  }
  function failure(error) {
    return { type: blocCConstants.UPDATE_BLOCC_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedBlocC));

    return blocAPI
      .updateBloc(folderId, 'bloc_c_folder_validation', {
        ...updatedBlocC,
        cnavDeclarationDate: getDateString(updatedBlocC.cnavDeclarationDate),
        launchDocumentsReceivedAt: getDateString(updatedBlocC.launchDocumentsReceivedAt),
        fundingDocumentsReceivedAt: getDateString(updatedBlocC.fundingDocumentsReceivedAt),
      })
      .then(({ blocCFolderValidation: blocC }) => {
        dispatch(foldersActions.getFolderFromBloc(blocC.folder || {}));
        dispatch(success(transformApiPayloadToFormValues(blocC)));
        dispatch(toastActions.success(TOAST_MESSAGE.success.update));
      }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

export const blocCActions = {
  getBlocC,
  updateBlocC,
};
