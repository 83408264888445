import React from 'react';
import { useField } from 'formik';
import { Icon, FormControl, InputGroup, InputRightElement } from '@chakra-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import DatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import 'react-datepicker/dist/react-datepicker.css';
import { BaseInput, Label, ErrorMessage } from './FormInputs';
import { useFormSubmitDisabled } from './FormSubmitDisabled.context';

// Datepicker locale
registerLocale('fr', fr);

const DatePickerCustomInput = React.forwardRef(
  ({ value, onClick, onChange, width, ...props }, ref) => {
    return (
      <InputGroup
        mb={0}
        css={{
          width: width || '16ch',
        }}
        onClick={onClick}
      >
        <BaseInput ref={ref} value={value} height="2rem" onChange={onChange} {...props} />
        <InputRightElement height="2rem">
          <Icon
            name="calendar"
            // eslint-disable-next-line no-nested-ternary
            color={props.isLocked ? 'gray' : props.disabled ? 'lightGray2' : 'coral.900'}
            opacity={props.disabled && !props.isLocked ? '0.3' : '1'}
          />
        </InputRightElement>
      </InputGroup>
    );
  },
);

const dateInputStyle = theme => css`
  .react-datepicker-wrapper {
    display: block;
  }
  .react-datepicker {
    border: 0;
    border-radius: ${theme.radii.lg};
    box-shadow: ${theme.shadows.layout};
    overflow: hidden;
    font-family: ${theme.fonts.body};
    &-popper {
      z-index: 999;
    }
    &__header {
      display: flex;
      flex-flow: wrap column-reverse;
      font-family: ${theme.fonts.heading};
      border-radius: 0;
      color: ${theme.colors.white};
      border-bottom: 0;
      padding-top: 0;
      &__dropdown {
        background-color: ${theme.colors.red};
      }
    }
    &__day-names {
      background-color: ${theme.colors.white};
      order: -1;
    }

    &__current-month,
    &-time__header,
    &-year-header {
      color: ${theme.colors.white};
      font-weight: 600;
      font-size: ${theme.sizes[5]};
    }

    &__current-month {
      color: ${theme.colors.red};
      background-color: ${theme.colors.white};
      font-size: ${theme.fontSizes['2xl']};
      padding: ${theme.sizes[2]} 0;
      text-transform: capitalize;
    }

    &__navigation {
      top: 65px;
      &--next,
      &--previous {
        border: 0;
        width: 20px;
        height: 20px;
        &::before {
          display: block;
          width: 15px;
          height: 15px;
          border-top: 3px solid ${theme.colors.coral[900]};
          border-right: 3px solid ${theme.colors.coral[900]};
          border-radius: 2px;
          content: "''";
          position: absolute;
          top: 2px;
          transform: rotate(45deg);
        }
      }
      &--previous {
        &::before {
          transform: rotate(225deg);
          left: 2px;
        }
      }
    }

    &__day-name {
      color: ${theme.colors.red};
      font-weight: bold;
      font-size: 17px;
      margin: 0.3rem 0.3rem 0;
      text-transform: capitalize;
    }
    &__year-read-view {
      padding: ${theme.sizes[2]} 0;
      &:hover {
        .react-datepicker__year-read-view--down-arrow {
          border-color: currentColor;
        }
      }
    }
    &__year-read-view--selected-year {
      font-size: 28px;
      font-weight: 600;
    }
    &__year-read-view--down-arrow {
      top: 9px;
      margin-left: 9px;
      border: 0;
      border-top: 2px solid;
      border-right: 2px solid;
      width: 10px;
      height: 10px;
      transform: rotate(-225deg);
      &:before {
        content: none;
      }
    }
    &__year-dropdown {
      border: 0;
      box-shadow: ${theme.shadows.layout};
      background-color: ${theme.colors.white};
    }
    &__year-option {
      color: ${theme.colors.red};
      &--selected_year {
        background-color: ${theme.colors.red};
        color: ${theme.colors.white};
      }
    }
    &__day {
      color: ${theme.colors.semiDarkGray[200]};
      font-size: ${theme.sizes[4]};
      &:hover {
        border-radius: 50%;
        background-color: ${theme.colors.coral[400]};
        color: ${theme.colors.white};
      }
      &:focus {
        box-shadow: 0 0 0px 4px ${theme.colors.coral[400]};
        outline: none;
      }
      &--outside-month {
        color: ${theme.colors.lightGray2};
      }
    }
    &__month {
      margin-top: 0;
    }
    &__day {
      margin: 0.3rem;
    }
    &__day--keyboard-selected {
      background-color: transparent;
      color: ${theme.colors.black};
    }
    &__day--disabled {
      color: #ccc;
      &:hover {
        cursor: not-allowed;
        background-color: transparent;
        color: #ccc;
      }
      &:focus {
        box-shadow: none;
      }
    }
    &__month-text--keyboard-selected,
    &__quarter-text--keyboard-selected,
    &__day--selected {
      border-radius: 50%;
      background-color: ${theme.colors.coral[900]};
      color: ${theme.colors.white};
    }
  }
`;

export const DateInput = ({
  label,
  width,
  enabledFutureDates,
  requiredInput,
  style,
  readOnly,
  ...props
}) => {
  const { id } = props;
  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  const [field, meta, helpers] = useField(props);
  const currentYear = new Date().getFullYear();

  return (
    <FormControl mb={6} {...style} css={dateInputStyle}>
      {label && (
        <Label htmlFor={id} d="inline-block">
          {label}
          {requiredInput && ' *'}
        </Label>
      )}
      <DatePicker
        locale="fr"
        showYearDropdown
        scrollableYearDropdown
        showFullMonthYearPicker
        dateFormat="P"
        dateFormatCalendar="MMMM"
        maxDate={new Date(currentYear + 5, 12, 31)}
        minDate={new Date(currentYear - 110, 1, 1)}
        yearDropdownItemNumber={100}
        customInput={<DatePickerCustomInput width={width} />}
        placeholderText="XX/XX/XXXX"
        {...field}
        selected={meta.value}
        onChange={date => helpers.setValue(date)}
        filterDate={
          enabledFutureDates
            ? null
            : date => {
                return new Date() > date;
              }
        }
        readOnly={readOnly || isFormSubmitDisabled}
        {...props}
      />

      {!meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </FormControl>
  );
};
