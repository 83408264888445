import React from 'react';
import {
  Divider,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import { Section } from '../Sections';
import { TabTableWrap, TabTBodyRow, TabTBodyTH, TabTD } from '../Tabs';
import { Table } from '../Table';

export const Funders = ({ quote }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const folderFormattedId = quote?.folderFormattedId || quote?.formattedId;
  const recipientFullName = quote?.recipientLastName ? (
    <Text mt={2} as="small">
      <Text as="span" fontWeight="semibold" textTransform="uppercase">
        {quote?.recipientLastName || '-'}
      </Text>{' '}
      {quote?.recipientFirstName || '-'}
    </Text>
  ) : (
    <Text mt={2} as="small">
      {quote?.recipientFullName || '-'}
    </Text>
  );

  const recipientHousingCity = quote?.recipientCity || quote?.recipientHousingCity;
  const folderTag = quote?.tag || quote?.folderTag;

  return (
    <>
      <IconButton
        icon="euro"
        variant="ghost"
        variantColor={null}
        onClick={onOpen}
        color="coral.900"
        height={8}
        minW={8}
        rounded="full"
        _hover={{ color: 'white', backgroundColor: 'coral.900' }}
        _focus={{ shadow: 'none' }}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent rounded="lg" maxWidth="60rem">
          <ModalHeader as="div" bg="darkGray" color="white" fontSize="md" roundedTop="lg">
            <Heading as="h2" fontSize="xl" fontWeight="bold">
              <Text
                as="span"
                fontFamily="heading"
                opacity="0.5"
                textTransform="uppercase"
                fontWeight="600"
              >
                Dossier n°{' '}
              </Text>
              {folderFormattedId}{' '}
              <Text as="span" d="inline-block" mx={2} opacity="0.65">
                -
              </Text>{' '}
              {recipientFullName}
            </Heading>
            <Text mt={2}>
              <Text as="span">{recipientHousingCity || '-'}</Text>
              <Text as="span" d="inline-block" opacity="0.65">
                &nbsp;/&nbsp;
              </Text>
              <Text as="span" textTransform="uppercase">
                {folderTag || '-'}
              </Text>
            </Text>
          </ModalHeader>
          <ModalCloseButton color="white" _focus={{ shadow: 'none' }} />
          <ModalBody pb={5}>
            <Section mt={5}>
              <TabTableWrap textAlign="left" px="0.5rem">
                {quote.fundersResponse ? (
                  <Text px={3} py={1}>
                    {quote.fundersResponse}
                  </Text>
                ) : null}
                {quote.worksTotalPrice ? (
                  <>
                    <Divider borderColor="lightGray3" />
                    <Text px={3} py={1}>
                      {quote.worksTotalPrice}
                    </Text>
                  </>
                ) : null}
                {quote.projectPriceWithoutWorks ? (
                  <>
                    <Divider borderColor="lightGray3" />
                    <Text px={3} py={1}>
                      {quote.projectPriceWithoutWorks}
                    </Text>
                  </>
                ) : null}
                {quote.totalToPayByRecipient ? (
                  <>
                    <Divider borderColor="lightGray3" />
                    <Text px={3} pt={1} pb={3}>
                      {quote.totalToPayByRecipient}
                    </Text>
                  </>
                ) : null}
              </TabTableWrap>
            </Section>
            <Section mt={5}>
              <TabTableWrap px="0.5rem">
                {Object.keys(quote.eligibleFundersMonitoring || {}).length ? (
                  <Table>
                    <tbody>
                      {Object.values(quote.eligibleFundersMonitoring).map(funder => (
                        <TabTBodyRow key={funder.label}>
                          <TabTBodyTH
                            w="300px"
                            borderRight="0"
                            textAlign="left"
                            fontWeight="normal"
                          >
                            {funder.label}
                          </TabTBodyTH>
                          <TabTD color="inherit" textAlign="left" fontWeight="normal">
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {funder.isGrantedAmountKnown && typeof funder.grantedAmount === 'number'
                              ? `${funder.grantedAmount}€`
                              : funder.fundingResponse === 'not_requested'
                              ? 'Non-sollicité après accord du bénéficiaire'
                              : 'Montant non connu'}
                          </TabTD>
                        </TabTBodyRow>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <Text textAlign="left" pb={2} pl={4}>
                    Aucun financeur disponible
                  </Text>
                )}
              </TabTableWrap>
            </Section>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
