import { quotesConstants } from './constants';

export const initialState = {
  data: [],
  pending: false,
};

export function quotes(state = initialState, action) {
  switch (action.type) {
    case quotesConstants.GET_QUOTES_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case quotesConstants.GET_QUOTES_SUCCESS:
      return {
        ...state,
        data: action.data.quotes,
        meta: action.data.meta,
        pending: false,
      };
    case quotesConstants.UPDATE_QUOTES_COMMENTS:
      return {
        ...state,
        data: state.data.map(quote => {
          if (quote.id === action.quote) {
            return {
              ...quote,
              comments: action.folder.comments,
            };
          }
          return quote;
        }),
      };
    case quotesConstants.GET_QUOTES_FAILURE:
    default:
      return state;
  }
}
