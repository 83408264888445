import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Flex, Tabs, TabPanels, TabPanel } from '@chakra-ui/core';

import { SecondaryTab, SecondaryTabList } from '../../../../common/components/Tabs';
import { getFolderPath } from '../../../../common/routes';
import { history } from '../../../../common/helpers/history';
import { BlocGTabForm } from './BlocGTabForm';

export const BlocGTab2 = ({
  match: {
    params: { folderId, tabIndex, funderIndex },
  },
  blocId,
  blocG: { error, pending, needOwnerAutorisation, hasWorks, ...initialValues },
  onSubmit,
}) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        // Relocked fields with lock
        document.querySelectorAll('[data-locked]').forEach(button => {
          if (
            button.getAttribute('data-locked') === 'false' &&
            button.getAttribute('data-default-locked') === 'true'
          )
            button.click();
        });

        return onSubmit({ ...initialValues, ...values }).then(() => {
          setTouched({});
          setSubmitting(false);
        });
      }}
    >
      {formik =>
        formik.values ? (
          <Tabs
            orientation="vertical"
            defaultIndex={0}
            index={Number(funderIndex) - 1}
            onChange={index =>
              history.push(getFolderPath(blocId, folderId, `${tabIndex}/${index + 1}`))
            }
          >
            <Flex alignItems="start" position="relative">
              <SecondaryTabList title="FINANCEURS">
                {formik.values.eligibleFundersMonitoring &&
                  Object.entries(formik.values.eligibleFundersMonitoring).map(
                    ([key, eligibleFunder]) => (
                      <SecondaryTab w="200px" d="block" key={key}>
                        {eligibleFunder.label}
                      </SecondaryTab>
                    ),
                  )}
              </SecondaryTabList>

              <TabPanels>
                {formik.values.eligibleFundersMonitoring &&
                  Object.keys(formik.values.eligibleFundersMonitoring).map(key => (
                    <TabPanel key={key}>
                      <BlocGTabForm
                        pending={pending}
                        fieldPrefix={`eligibleFundersMonitoring.${key}`}
                        funderName={key}
                        needOwnerAutorisation={needOwnerAutorisation}
                        hasWorks={hasWorks}
                        hasOwnerAuthorizedWorks={formik.values.hasOwnerAuthorizedWorks}
                      />
                    </TabPanel>
                  ))}
              </TabPanels>
            </Flex>
          </Tabs>
        ) : null
      }
    </Formik>
  );
};
