import { history } from '../../common/helpers/history';
import { ergoOfficesConstants } from './constants';
import { ergoOfficesAPI } from '../../common/api/ergoOffices';
import routes from '../../common/routes';
import { toastActions } from '../toast/actions';
import { TOAST_MESSAGE } from '../../common/components/Toasts/Toast';
import { errorsActions } from '../errors/actions';

function getErgoOffices(page, name, params) {
  function request() {
    return { type: ergoOfficesConstants.GET_ERGO_OFFICES_REQUEST };
  }
  function success(data) {
    return { type: ergoOfficesConstants.GET_ERGO_OFFICES_SUCCESS, data };
  }
  function failure(error) {
    return { type: ergoOfficesConstants.GET_ERGO_OFFICES_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return ergoOfficesAPI.getErgoOffices(page, name, params).then(({ ergoOffices, meta }) => {
      dispatch(success({ ergoOffices, meta }));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function getErgoOffice(ergoOfficeId) {
  function request() {
    return { type: ergoOfficesConstants.GET_ERGO_OFFICE_REQUEST };
  }
  function success(ergoOffice) {
    return { type: ergoOfficesConstants.GET_ERGO_OFFICE_SUCCESS, ergoOffice };
  }
  function failure(error) {
    return { type: ergoOfficesConstants.GET_ERGO_OFFICE_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return ergoOfficesAPI.getErgoOffice(ergoOfficeId).then(({ ergoOffice }) => {
      dispatch(success(ergoOffice));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function createErgoOffice(newErgoOffice) {
  function request() {
    return { type: ergoOfficesConstants.CREATE_ERGO_OFFICE_REQUEST };
  }
  function success() {
    return { type: ergoOfficesConstants.CREATE_ERGO_OFFICE_SUCCESS };
  }
  function failure(error) {
    return { type: ergoOfficesConstants.CREATE_ERGO_OFFICE_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return ergoOfficesAPI.createErgoOffice(newErgoOffice).then(() => {
      dispatch(success());
      history.push(routes.ergoOffices);
      dispatch(toastActions.success(TOAST_MESSAGE.success.add));
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.add))));
  };
}

function updateErgoOffice(ergoOfficeId, updatedErgoOffice) {
  function request(ergoOffice) {
    return { type: ergoOfficesConstants.UPDATE_ERGO_OFFICE_REQUEST, ergoOffice };
  }
  function success() {
    return { type: ergoOfficesConstants.UPDATE_ERGO_OFFICE_SUCCESS };
  }
  function failure(error) {
    return { type: ergoOfficesConstants.UPDATE_ERGO_OFFICE_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedErgoOffice));

    return ergoOfficesAPI.updateErgoOffice(ergoOfficeId, updatedErgoOffice).then(() => {
      dispatch(success());
      history.push(routes.ergoOffices);
      dispatch(toastActions.success(TOAST_MESSAGE.success.update));
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

function deleteErgoOffice(recipientId) {
  function request() {
    return { type: ergoOfficesConstants.DELETE_ERGO_OFFICE_REQUEST };
  }
  function success() {
    return { type: ergoOfficesConstants.DELETE_ERGO_OFFICE_SUCCESS };
  }
  function failure(error) {
    return { type: ergoOfficesConstants.DELETE_ERGO_OFFICE_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return ergoOfficesAPI.deleteErgoOffice(recipientId).then(() => {
      dispatch(success());
      history.push(routes.ergoOffices);
      dispatch(toastActions.success(TOAST_MESSAGE.success.delete));
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.delete))));
  };
}

export const ergoOfficesActions = {
  getErgoOffices,
  getErgoOffice,
  createErgoOffice,
  updateErgoOffice,
  deleteErgoOffice,
};
