import { apiV1 } from './api';

const getErgoOffices = (page = 1, name = '', params) =>
  apiV1.get(`/ergo_offices?page=${page}&by_name=${name}`, { params }).then(({ data }) => data);

const getErgoOffice = ergoOfficeId =>
  apiV1.get(`/ergo_offices/${ergoOfficeId}`).then(({ data }) => data);

const createErgoOffice = ergoOffice =>
  apiV1
    .post(`/ergo_offices`, {
      ergoOffice,
    })
    .then(({ data }) => data);

const updateErgoOffice = (ergoOfficeId, ergoOffice) =>
  apiV1
    .put(`/ergo_offices/${ergoOfficeId}`, {
      ergoOffice,
    })
    .then(({ data }) => data);

const deleteErgoOffice = ergoOfficeId => apiV1.delete(`/ergo_offices/${ergoOfficeId}`);

export const ergoOfficesAPI = {
  getErgoOffices,
  getErgoOffice,
  createErgoOffice,
  updateErgoOffice,
  deleteErgoOffice,
};
