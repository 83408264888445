import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Stack } from '@chakra-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  TextInput,
  PasswordInput,
  RadioGroup,
  RadioInput,
  CheckboxGroupInput,
} from '../Form/FormInputs';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { Form, FormActions, FormSection } from '../Form/Form';
import { history } from '../../helpers/history';
import { Notice, NoticeText, NoticeList } from '../Notice';
import { SelectInput } from '../Form/FormSelectInput';
import Can from '../../../roleBasedAccess/Can';
import { qualificationsAPI } from '../../api/qualifications';
import { interventionTypesAPI } from '../../api/interventionTypes';
import { ergoOfficesAPI } from '../../api/ergoOffices';
import { tagsAPI } from '../../api/tags';
import { companiesAPI } from '../../api/companies';
import { configActions } from '../../../redux/config/actions';

const roles = [
  { label: 'Administrateur', value: 'administrateur' },
  { label: 'Ergothérapeute', value: 'ergothérapeute' },
  { label: 'Conseiller social', value: 'conseiller social' },
  { label: 'Référent externe', value: 'référent externe' },
  { label: 'Pilote externe', value: 'pilote externe' },
  { label: 'Coordinateur entreprises', value: 'coordinateur entreprises' },
  { label: 'Référent entreprise', value: 'référent entreprise' },
];

const UserFormComponent = ({
  initialValues = {},
  onSubmit,
  pending,
  error,
  mode,
  validDepartments = [],
  getConfig,
}) => {
  const submitValidationSchema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required(),
    phoneNumber: yup.string().required(),
  });

  const formikRef = useRef();

  useEffect(() => {
    getConfig();
    if (formikRef.current) formikRef.current.setErrors(error && error.errors);
  }, [error, getConfig]);

  const multiValuesinFormFormat = values => values.map(value => ({ label: value, value }));

  const getTags = inputValue =>
    tagsAPI.getTags({ search: inputValue || '' }).then(tags => multiValuesinFormFormat(tags));

  const getQualifications = () =>
    qualificationsAPI
      .getQualifications()
      .then(qualifications => multiValuesinFormFormat(qualifications));

  const getInterventionTypes = () =>
    interventionTypesAPI
      .getInterventionTypes()
      .then(interventionTypes => multiValuesinFormFormat(interventionTypes));

  const getCompanies = inputValue =>
    companiesAPI
      .getCompanies(1, inputValue || '')
      .then(({ companies }) =>
        companies.map(company => ({ label: company.name, value: company.id })),
      );

  const getErgoOffices = inputValue =>
    ergoOfficesAPI
      .getErgoOffices(1, inputValue || '')
      .then(({ ergoOffices }) =>
        ergoOffices.map(ergoOffice => ({ label: ergoOffice.name, value: ergoOffice.id })),
      );

  const filterDepartments = inputValue => {
    if (!inputValue) return [];
    return multiValuesinFormFormat(validDepartments)
      .filter(i => i.label.toLowerCase().includes(inputValue.toLowerCase()))
      .sort(
        (a, b) =>
          a.label.toLowerCase().indexOf(inputValue.toLowerCase()) -
          b.label.toLowerCase().indexOf(inputValue.toLowerCase()),
      )
      .slice(0, 20);
  };

  const loadDepartments = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterDepartments(inputValue));
    }, 1000);
  };

  const [allDepartmentsChecked, setAllDepartmentsChecked] = useState(false);

  const handleAllDepartmentChecked = (event, formik) => {
    setAllDepartmentsChecked(!allDepartmentsChecked);
    if (event.target?.checked)
      formik.setFieldValue('operatingDepartments', multiValuesinFormFormat(validDepartments));
    else formik.setFieldValue('operatingDepartments', []);
  };

  const handleDepartmentsChange = departments => {
    if (departments?.toString() !== multiValuesinFormFormat(validDepartments)?.toString())
      setAllDepartmentsChecked(false);
    else setAllDepartmentsChecked(true);
  };

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={mode === 'edit'}
      initialValues={{
        ...initialValues,
        role: initialValues.role && roles.find(role => role.value === initialValues.role),
        tags: initialValues.tags && initialValues.tags.map(tag => ({ label: tag, value: tag })),
        companies:
          initialValues.companies &&
          initialValues.companies.map(company => ({ label: company.name, value: company.id })),
        ergoQualification: {
          label: initialValues.ergoQualification,
          value: initialValues.ergoQualification,
        },
        interventionTypes: initialValues.interventionTypes?.map(intevention => ({
          label: intevention,
          value: intevention,
        })),
        ergoOffices: initialValues.ergoOffices?.map(ergoOffice => ({
          label: ergoOffice.name,
          value: ergoOffice.id,
        })),
        operatingDepartments: initialValues.operatingDepartments?.map(departments => ({
          label: departments,
          value: departments,
        })),
      }}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, setTouched }) => {
        setSubmitting(false);
        onSubmit({
          ...values,
          pending: undefined,
          role: values.role && values.role.value,
          tags: (values.tags?.length && values.tags.map(tag => tag.value)) || [],
          companies: undefined,
          companyIds: values.companies?.map(company => company.value) || [],
          password: values.password || undefined,
          passwordConfirmation: values.password ? values.passwordConfirmation : undefined,
          error: undefined,
          ergoQualification: values.ergoQualification?.value,
          interventionTypes: values.interventionTypes?.map(intevention => intevention.value) || [],
          ergoOfficeIds: values.ergoOffices?.map(ergoOffice => ergoOffice.value) || [],
          ergoOffices:
            values.ergoOffices?.map(ergoOffice => ({
              name: ergoOffice.label,
              id: ergoOffice.value,
            })) || [],
          operatingDepartments:
            values.operatingDepartments?.map(departments => departments.value) || [],
        });
        setTouched({});
      }}
    >
      {formik => {
        handleDepartmentsChange(formik.values.operatingDepartments);
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Stack spacing={16}>
              <FormSection title="Informations de contact">
                <Can
                  perform="dashboard:visit"
                  yes={() => (
                    <SelectInput
                      placeholder="Intitulé du rôle..."
                      label="Rôle"
                      name="role"
                      id="role"
                      options={roles}
                      disabled={pending}
                      requiredInput
                    />
                  )}
                />
                <Can
                  perform="dashboard:visit"
                  yes={() => {
                    return (
                      <>
                        {formik.values?.role?.value?.match(/(référent externe|pilote externe)/) && (
                          <SelectInput
                            mt="2.8rem"
                            css={{
                              '[class*="-option"], [class*="-ValueContainer"]': {
                                textTransform: 'uppercase',
                              },
                              '[class*="-placeholder"]': { textTransform: 'none' },
                            }}
                            placeholder="Choisir un tag"
                            label="Tags"
                            name="tags"
                            id="tags"
                            defaultOptions
                            cacheOptions
                            isCreatable
                            isAsync
                            isMulti
                            isUpperCase
                            noOptionsMessage={() => 'Tapez pour chercher'}
                            loadingMessage={() => 'Recherche en cours...'}
                            loadOptions={getTags}
                            disabled={pending}
                          />
                        )}
                      </>
                    );
                  }}
                />
                <Can
                  perform="dashboard:visit"
                  yes={() => {
                    return (
                      <>
                        {formik.values?.role?.value?.match(/ergothérapeute/) && (
                          <SelectInput
                            mt="2.8rem"
                            css={{ '*:first-letter ': { textTransform: 'uppercase' } }}
                            placeholder="Choisir une qualification"
                            label="Qualification"
                            name="ergoQualification"
                            id="ergoQualification"
                            requiredInput
                            isAsync
                            defaultOptions
                            noOptionsMessage={() => "Aucune qualification n'est disponible"}
                            loadingMessage={() => 'Recherche en cours...'}
                            loadOptions={getQualifications}
                            disabled={pending}
                          />
                        )}
                      </>
                    );
                  }}
                />
                <Can
                  perform="dashboard:visit"
                  yes={() => {
                    return (
                      <>
                        {formik.values?.role?.value?.match(/ergothérapeute/) && (
                          <SelectInput
                            mt="2.8rem"
                            css={{ '*:first-letter ': { textTransform: 'uppercase' } }}
                            placeholder="Choisir un type d'intervention"
                            label="Types d'interventions"
                            name="interventionTypes"
                            id="interventionTypes"
                            isAsync
                            isMulti
                            defaultOptions
                            noOptionsMessage={() => "Aucun type d'intervention n'est disponible"}
                            loadingMessage={() => 'Recherche en cours...'}
                            loadOptions={getInterventionTypes}
                            disabled={pending}
                          />
                        )}
                      </>
                    );
                  }}
                />
                <Can
                  perform="dashboard:visit"
                  yes={() => {
                    return (
                      <>
                        {formik.values?.role?.value?.match(/ergothérapeute/) && (
                          <>
                            <SelectInput
                              mt="2.8rem"
                              css={{ '*:first-letter ': { textTransform: 'uppercase' } }}
                              placeholder="Choisir les départements d'intervention"
                              label="Départements d’intervention"
                              name="operatingDepartments"
                              id="operatingDepartments"
                              isAsync
                              isMulti
                              defaultOptions
                              noOptionsMessage={() =>
                                "Aucun département d'intervention n'est disponible"
                              }
                              loadingMessage={() => 'Recherche en cours...'}
                              loadOptions={loadDepartments}
                              disabled={pending}
                              postOnChange={handleDepartmentsChange}
                            />
                            <CheckboxGroupInput
                              isChecked={allDepartmentsChecked}
                              onChange={event => handleAllDepartmentChecked(event, formik)}
                              mb="2rem"
                            >
                              Tous les départements
                            </CheckboxGroupInput>
                          </>
                        )}
                      </>
                    );
                  }}
                />
                <Can
                  perform="dashboard:visit"
                  yes={() => {
                    return (
                      <>
                        {formik.values?.role?.value?.match(/ergothérapeute/) &&
                          formik.values?.ergoQualification?.value?.match(/cabinet/) && (
                            <SelectInput
                              mt="2.8rem"
                              css={{ '*:first-letter ': { textTransform: 'uppercase' } }}
                              placeholder="Choisir un Cabinet"
                              label="Cabinet"
                              name="ergoOffices"
                              id="ergoOffices"
                              defaultOptions
                              cacheOptions
                              isAsync
                              isMulti
                              noOptionsMessage={() => 'Tapez pour chercher'}
                              loadingMessage={() => 'Recherche en cours...'}
                              loadOptions={getErgoOffices}
                              disabled={pending}
                              requiredInput
                            />
                          )}
                      </>
                    );
                  }}
                />
                <Can
                  perform="dashboard:visit"
                  yes={() => {
                    return (
                      <>
                        {formik.values?.role?.value?.match(/référent entreprise/) && (
                          <SelectInput
                            mt="2.8rem"
                            placeholder="Choisir une entreprise"
                            label="Entreprises"
                            name="companies"
                            id="companies"
                            defaultOptions
                            cacheOptions
                            isAsync
                            isMulti
                            noOptionsMessage={() => 'Tapez pour chercher'}
                            loadingMessage={() => 'Recherche en cours...'}
                            loadOptions={getCompanies}
                            disabled={pending}
                            requiredInput
                          />
                        )}
                      </>
                    );
                  }}
                />
                <RadioGroup isInline name="title" requiredInput legend="Civilité">
                  <RadioInput id="title-Mme" value="Mme">
                    Madame
                  </RadioInput>
                  <RadioInput id="title-M" value="M">
                    Monsieur
                  </RadioInput>
                </RadioGroup>
                <TextInput
                  label="Nom"
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Dupond"
                  disabled={pending}
                  requiredInput
                />
                <TextInput
                  label="Prénom"
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Jean"
                  disabled={pending}
                  requiredInput
                />
                <TextInput
                  label="Mail"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="contact.operateur@mail.com"
                  disabled={pending}
                  requiredInput
                />
                <TextInput
                  label="Téléphone"
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  w="15ch"
                  placeholder="0602050842"
                  pattern="0(6|7)[0-9]{8}"
                  disabled={pending}
                  requiredInput
                />
              </FormSection>
              <FormSection title="Authentification">
                <Notice mb={8} variant="info">
                  <NoticeText variant="info">
                    Votre mot de passe doit suivre les règles de sécurité suivantes :
                  </NoticeText>
                  <NoticeList variant="info">
                    <li>8 caractères minimum</li>
                    <li>Au minimum 1 caractère en majuscule</li>
                    <li>Au minimum 1 caractère en minuscule</li>
                    <li>Au minimum 1 caractère spécial</li>
                    <li>Au minimum 1 chiffre</li>
                  </NoticeList>
                  {mode === 'edit' && (
                    <NoticeText variant="info">
                      Si vous ne souhaitez pas changer de mot de passe laissez les champs vides
                    </NoticeText>
                  )}
                </Notice>
                <PasswordInput
                  label="Mot de passe"
                  id="password"
                  name="password"
                  disabled={pending}
                  requiredInput
                />
                <PasswordInput
                  label="Confirmation de mot de passe"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  disabled={pending}
                  requiredInput
                />
              </FormSection>
            </Stack>

            <FormActions mt={20}>
              <SecondaryButton type="button" onClick={() => history.goBack()}>
                Annuler
              </SecondaryButton>
              <PrimaryButton
                type="submit"
                disabled={!submitValidationSchema.isValidSync(formik.values) || pending}
              >
                Enregistrer
              </PrimaryButton>
            </FormActions>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = ({ config: { validDepartments } }) => ({
  validDepartments,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getConfig: configActions.getConfig,
    },
    dispatch,
  );

export const UserForm = connect(mapStateToProps, mapDispatchToProps)(UserFormComponent);
