import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Stack, Box, Grid, Button, Icon, Flex, Text } from '@chakra-ui/core';
import { components } from 'react-select';

import { TabForm } from '../../../../common/components/Tabs';
import {
  FormSection,
  FormSubmitButton,
  FormCTAButton,
} from '../../../../common/components/Form/Form';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import {
  Notice,
  NoticeText,
  NoticeThinText,
  NoticeList,
} from '../../../../common/components/Notice';
import { history } from '../../../../common/helpers/history';
import { getFolderPath } from '../../../../common/routes';
import { SelectInput } from '../../../../common/components/Form/FormSelectInput';
import { LockedInput } from '../../../../common/components/Form/FormLockedInput';
import { QUOTE_GROUP_TYPES } from '../../../../redux/blocs/blocE/actions';
import MultiSelectCompanies from '../../../../common/components/Form/MultiSelectCompanies';
import { useFormSubmitDisabled } from '../../../../common/components/Form/FormSubmitDisabled.context';
import { SwitchInput } from '../../../../common/components/Form/FormInputs';

const QuoteGroupNameOption = props => {
  return (
    <components.Option {...props}>
      <Flex alignItems="center">
        <Icon
          size={6}
          name={QUOTE_GROUP_TYPES[props.value].icon}
          mr={2}
          transition="none"
          color={props.isFocused || props.isSelected ? 'inherit' : 'lightGray2'}
        />{' '}
        {props.label}
      </Flex>
    </components.Option>
  );
};

export const BlocETab1 = ({ folder, blocE: { error, pending, ...initialValues }, onSubmit }) => {
  const formikRef = useRef();
  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  const onDeleteQuoteGroup = (formik, index) => {
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(`Voulez-vous supprimer ce groupe de devis ?`);

    if (confirm) {
      const quoteGroupToDelete = formik.values.quoteGroups[index];
      let updatedQuoteGroups;

      if (quoteGroupToDelete.id) {
        updatedQuoteGroups = formik.values.quoteGroups;
        updatedQuoteGroups[index] = {
          ...quoteGroupToDelete,
          _destroy: true,
        };
      } else {
        formik.values.quoteGroups.splice(index, 1);
        updatedQuoteGroups = formik.values.quoteGroups;
      }

      formik.setFieldValue('quoteGroups', updatedQuoteGroups);
    }
  };

  const onAddQuoteGroup = formik => {
    const newQuoteGroup =
      formik.values.quoteGroups.find(quoteGroup => quoteGroup.name.value === 'works') &&
      initialValues.isBiddingCompetitive === 'yes_without_major_work'
        ? {
            name: {
              value: 'technical_assistances',
              label: QUOTE_GROUP_TYPES.technical_assistances.label,
            },
            companies: [],
            quoteRequestSentAt: null,
          }
        : {
            name: { value: 'works', label: QUOTE_GROUP_TYPES.works.label },
            companies: [],
            quoteRequestSentAt: null,
          };

    const updatedQuoteGroups = [...formik.values.quoteGroups, newQuoteGroup];
    formik.setFieldValue('quoteGroups', updatedQuoteGroups);
  };

  const isBlocValid = formik => {
    return (
      formik?.values?.quoteGroups?.length > 0 &&
      formik?.values?.quoteGroups?.every(quoteGroup => {
        return quoteGroup.companies.length > 0 && !!quoteGroup.quoteRequestSentAt;
      })
    );
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        // Relocked fields with lock
        document.querySelectorAll('[data-locked]').forEach(button => {
          if (button.getAttribute('data-locked') === 'false') button.click();
        });

        return onSubmit({ ...initialValues, ...values }).then(() => {
          setTouched({});
          setSubmitting(false);
        });
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit}>
          <Stack spacing="3.5rem">
            <Notice variant="info">
              <Box>
                <NoticeThinText mb={2}>
                  <NoticeText variant="info" as="span">
                    Mise en concurrence{' '}
                  </NoticeText>{' '}
                  : {initialValues.isBiddingCompetitive?.match(/^yes/) ? 'oui' : 'non'}
                </NoticeThinText>

                <Grid>
                  <NoticeText variant="info">
                    Entreprise(s) selectionné(e) par le bénéficiaire :
                  </NoticeText>
                  <NoticeList variant="info" mx="auto" mt={1} fontWeight="thin">
                    {initialValues.companiesImposedByRecipient?.map(company => (
                      <li key={company.id}>{company.name}</li>
                    ))}
                  </NoticeList>
                </Grid>
              </Box>

              <NoticeText variant="info" d="grid">
                Documents à envoyer à l'entreprise :
                <NoticeThinText mt={2} as="span">
                  Si nécessaire, consulter les fiches-procédures ou l'administrateur
                </NoticeThinText>
              </NoticeText>
            </Notice>

            <FormSection title="Demande de devis">
              {formik.values.quoteGroups && (
                <Stack spacing={6}>
                  {formik.values.quoteGroups.map((quoteGroup, index) => (
                    <Grid
                      key={quoteGroup.id}
                      templateColumns="1fr 80px"
                      bg="lightGray4"
                      rounded="15px"
                      p={4}
                      mx={-5}
                      border={
                        formik.errors?.folderQuoteGroupsCompanies &&
                        formik.values?.quoteGroups[index]?.companies?.length < 1
                          ? '1px solid'
                          : 0
                      }
                      borderColor="errorBorderColor"
                      // eslint-disable-next-line no-underscore-dangle
                      d={quoteGroup._destroy ? 'none' : 'grid'}
                    >
                      <Box>
                        <LockedInput
                          defaultIsLocked={!!quoteGroup.name && !!quoteGroup.id}
                          top="1.75rem"
                          disabled={quoteGroup.isAutomatic}
                        >
                          <SelectInput
                            label="Nom du groupe"
                            placeholder="Travaux"
                            name={`quoteGroups[${index}].name`}
                            id={`quoteGroups[${index}].name`}
                            components={{ Option: QuoteGroupNameOption }}
                            css={{ '[class*="-container"]': { background: 'white' } }}
                            options={Object.entries(QUOTE_GROUP_TYPES).map(
                              ([groupName, groupMeta]) => ({
                                value: groupName,
                                label: groupMeta.label,
                              }),
                            )}
                            // "works" option is disabled when isBiddingCompetitive is at yes_without_major_works
                            // and there is already a "works" quote group
                            isOptionDisabled={option =>
                              initialValues.isBiddingCompetitive === 'yes_without_major_work' &&
                              quoteGroup.name.value !== 'works' &&
                              option.value === 'works' &&
                              !!formik.values.quoteGroups.find(
                                // eslint-disable-next-line no-underscore-dangle
                                qGroup => qGroup.name.value === 'works' && !qGroup._destroy,
                              )
                            }
                            readOnly={quoteGroup.isAutomatic}
                          />
                        </LockedInput>

                        {quoteGroup.isAutomatic &&
                          quoteGroup.name?.value === 'works' &&
                          (initialValues.typeOfVisit === 'solo' || !initialValues.typeOfVisit) && (
                            <Box ml={12} mb={5}>
                              <SwitchInput
                                label="Mise à disposition du marché travaux"
                                name={`quoteGroups[${index}].isPublishedInProjectPlace`}
                                id={`quoteGroups[${index}].isPublishedInProjectPlace`}
                                disabled={quoteGroup.companies.length > 0}
                                yesLabel="Oui"
                                noLabel="Non"
                              />
                              {quoteGroup.companies.length === 0 &&
                              quoteGroup.isPublishedInProjectPlace ? (
                                <Text color="lightGray2">Attente de positionnement</Text>
                              ) : null}
                            </Box>
                          )}

                        <LockedInput
                          defaultIsLocked={
                            (!!quoteGroup.companies.length > 0 && !!quoteGroup.id) ||
                            (quoteGroup.isAutomatic &&
                              quoteGroup.name?.value === 'works' &&
                              initialValues.typeOfVisit?.match(/joint|existing_quote/) &&
                              quoteGroup.companies.length > 0)
                          }
                          ml={12}
                          mb={8}
                          top="-0.4rem"
                          left="6rem"
                          disabled={
                            quoteGroup.isAutomatic &&
                            quoteGroup.name?.value === 'works' &&
                            initialValues.typeOfVisit !== 'solo'
                          }
                        >
                          <MultiSelectCompanies
                            ml={12}
                            label="Entreprise(s)"
                            fieldName={`quoteGroups.${index}.companies`}
                            fieldErrorName="folderQuoteGroupsCompanies"
                            noResultText="Non présente dans la base de données"
                            filter={
                              quoteGroup.isAutomatic &&
                              quoteGroup.name?.value === 'works' &&
                              (initialValues.typeOfVisit.match(/joint|existing_quote/) ||
                                quoteGroup.isPublishedInProjectPlace)
                                ? {
                                    byDept: folder?.housingToAdapt?.postalCode?.slice(0, 2),
                                    excluded: quoteGroup.ignoredFromProjectCompanyIds?.map(id =>
                                      Number(id),
                                    ),
                                  }
                                : {}
                            }
                            disabled={
                              quoteGroup.companies.length >= 3 ||
                              (quoteGroup.isAutomatic &&
                                quoteGroup.name?.value === 'works' &&
                                (initialValues.typeOfVisit !== 'solo' ||
                                  (initialValues.typeOfVisit === 'solo' &&
                                    quoteGroup.isPublishedInProjectPlace &&
                                    quoteGroup.companies.length > 0)))
                            }
                            readOnly={
                              quoteGroup.isAutomatic &&
                              quoteGroup.name?.value === 'works' &&
                              initialValues.typeOfVisit?.match(/joint|existing_quote/)
                            }
                          />
                        </LockedInput>

                        <DateInput
                          label="Date de demande"
                          style={{ mb: 0 }}
                          name={`quoteGroups[${index}].quoteRequestSentAt`}
                          id={`quoteGroups[${index}].quoteRequestSentAt`}
                          readOnly={
                            quoteGroup.isAutomatic &&
                            quoteGroup.name?.value === 'works' &&
                            initialValues.typeOfVisit?.match(/joint|existing_quote/)
                          }
                        />
                      </Box>

                      <Box ml="auto">
                        {formik.values.quoteGroups.length > 1 && !quoteGroup.isAutomatic ? (
                          <Button
                            variantColor={null}
                            p={1}
                            size={0}
                            color="gray"
                            _hover={{ color: 'red' }}
                            aria-label={`Supprimer le groupe de devis n°${index}`}
                            onClick={() => onDeleteQuoteGroup(formik, index)}
                            disabled={isFormSubmitDisabled}
                          >
                            <Icon name="trash" />
                          </Button>
                        ) : null}
                      </Box>
                    </Grid>
                  ))}
                </Stack>
              )}
              <Button
                color="coral.900"
                variantColor={null}
                rightIcon="plus"
                ml={-5}
                mt={3}
                _hover={{ bg: 'select.bg.isSelected' }}
                _focus={{ bg: 'select.bg.isSelected' }}
                disabled={
                  initialValues.isBiddingCompetitive === 'no_only_works' || isFormSubmitDisabled
                }
                onClick={() => onAddQuoteGroup(formik)}
              >
                Ajouter un nouveau groupe de devis
              </Button>
            </FormSection>

            <Box textAlign="center">
              <FormCTAButton
                disabled={formik.isSubmitting || pending || !isBlocValid(formik)}
                isLoading={formik.isSubmitting}
                loadingText="Analyse des devis"
                onClick={() => {
                  formik.submitForm().then(() => {
                    history.push(getFolderPath('bloc_f', initialValues.folderId));
                  });
                }}
              >
                Analyse des devis
              </FormCTAButton>
            </Box>
          </Stack>

          <FormSubmitButton
            type="submit"
            disabled={formik.isSubmitting || pending}
            isLoading={formik.isSubmitting}
            loadingText="Enregistrer"
          >
            Enregistrer
          </FormSubmitButton>
        </TabForm>
      )}
    </Formik>
  );
};
