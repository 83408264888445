import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageDefault } from '../common/components/layouts/Page/PageDefault';
import { preFoldersActions } from '../redux/preFolders/actions';
import { PreFolderForm } from './components/PreFolderForm';
import { PrimaryButton } from '../common/components/Buttons';

export const PreFolderEditComponent = ({
  match: {
    params: { preFolderId },
  },
  error,
  pending,
  deletePending,
  getPreFolder,
  updatePreFolder,
  transformPreFolderIntoFolder,
  deletePreFolder,
  preFolder,
}) => {
  useEffect(() => {
    getPreFolder(preFolderId);
  }, [preFolderId, getPreFolder]);

  const displayUserNameIfExists = () => {
    if (preFolder.firstName && preFolder.lastName)
      return ` - ${preFolder.firstName} ${preFolder.lastName}`;

    return '';
  };

  return (
    <PageDefault
      title={`édition du pré-dossier n°${preFolder?.formattedId}`}
      pageAction={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <PrimaryButton
          disabled={pending || preFolder?.status !== 'En cours de rédaction'}
          isLoading={deletePending}
          loadingText="En cours de supression..."
          onClick={() => {
            // eslint-disable-next-line no-alert
            const confirm = window.confirm(
              `Voulez-vous supprimer le pré-dossier n°${
                preFolder.id
              } ${displayUserNameIfExists()} ?`,
            );
            if (confirm) deletePreFolder(preFolderId);
          }}
        >
          Supprimer
        </PrimaryButton>
      }
    >
      <PreFolderForm
        initialValues={preFolder}
        pending={pending}
        onSubmit={updatedPreFolder => updatePreFolder(preFolderId, updatedPreFolder, false)}
        onTransform={updatedPreFolder => {
          updatePreFolder(preFolderId, updatedPreFolder, false).then(() =>
            transformPreFolderIntoFolder(preFolderId),
          );
        }}
        error={error}
        mode="edit"
      />
    </PageDefault>
  );
};

const mapStateToProps = ({ preFolders: state }) => ({
  preFolder: state.preFolder,
  error: state.error,
  pending: state.pending,
  deletePending: state.deletePending,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPreFolder: preFoldersActions.getPreFolder,
      updatePreFolder: preFoldersActions.updatePreFolder,
      transformPreFolderIntoFolder: preFoldersActions.transformPreFolderIntoFolder,
      deletePreFolder: preFoldersActions.deletePreFolder,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PreFolderEditComponent);
