import { blocFConstants } from './constants';
import { blocAPI } from '../../../common/api/blocs';
import { getDateFromString, getStringFromSelectValue, getDateString } from '../../../common/utils';
import { toastActions } from '../../toast/actions';
import { TOAST_MESSAGE } from '../../../common/components/Toasts/Toast';
import { foldersActions } from '../../folders/actions';
import { errorsActions } from '../../errors/actions';

export const transformApiPayloadToFormValues = ({
  analysisFinishedAt,
  sentToRecipientAt,
  confirmationFromRecipientReceivedAt,
  quoteGroups,
  ...blocF
}) => ({
  ...blocF,
  analysisFinishedAt: getDateFromString(analysisFinishedAt),
  sentToRecipientAt: getDateFromString(sentToRecipientAt),
  confirmationFromRecipientReceivedAt: getDateFromString(confirmationFromRecipientReceivedAt),
  quoteGroups: quoteGroups.map(quoteGroup => ({
    ...quoteGroup,
    quoteRequestSentAt: getDateFromString(quoteGroup.quoteRequestSentAt),
    chosenCompany:
      quoteGroup.chosenCompany && quoteGroup.chosenCompany.name
        ? {
            label: quoteGroup.chosenCompany.name,
            value: quoteGroup?.chosenCompany.id,
          }
        : null,
    chosenCompanyNotifiedAt: getDateFromString(quoteGroup.chosenCompanyNotifiedAt),
    companies: quoteGroup.companies.map(({ quoteVersions, ...company }) => ({
      ...company,
      quoteVersions: quoteVersions.map(quoteVersion => ({
        ...quoteVersion,
        quoteReceivedAt: getDateFromString(quoteVersion.quoteReceivedAt),
        additionalRequestsSentAt: getDateFromString(quoteVersion.additionalRequestsSentAt),
      })),
    })),
  })),
});

function getBlocF(folderId) {
  function request() {
    return { type: blocFConstants.GET_BLOCF_REQUEST };
  }
  function success(blocF) {
    return { type: blocFConstants.GET_BLOCF_SUCCESS, blocF };
  }
  function failure(error) {
    return { type: blocFConstants.GET_BLOCF_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return blocAPI
      .getBloc(folderId, 'bloc_f_quote_analysis')
      .then(({ blocFQuoteAnalysis: blocF }) => {
        dispatch(success(transformApiPayloadToFormValues(blocF)));
        dispatch(foldersActions.getFolderFromBloc(blocF.folder || {}));
      }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function updateBlocF(folderId, updatedBlocF) {
  function request(blocF) {
    return { type: blocFConstants.UPDATE_BLOCF_REQUEST, blocF };
  }
  function success(blocF) {
    return { type: blocFConstants.UPDATE_BLOCF_SUCCESS, blocF };
  }
  function failure(error) {
    return { type: blocFConstants.UPDATE_BLOCF_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedBlocF));

    return blocAPI
      .updateBloc(folderId, 'bloc_f_quote_analysis', {
        ...updatedBlocF,
        analysisFinishedAt: getDateString(updatedBlocF.analysisFinishedAt),
        sentToRecipientAt: getDateString(updatedBlocF.sentToRecipientAt),
        confirmationFromRecipientReceivedAt: getDateString(
          updatedBlocF.confirmationFromRecipientReceivedAt,
        ),
        folderAttributes: {
          id: folderId,
          quoteGroupsAttributes: updatedBlocF.quoteGroups.map(({ companies, ...quoteGroup }) => ({
            ...quoteGroup,
            quoteRequestSentAt: getDateString(quoteGroup.quoteRequestSentAt),
            chosenCompanyId: getStringFromSelectValue(quoteGroup.chosenCompany),
            chosenCompanyNotifiedAt: getDateString(quoteGroup.chosenCompanyNotifiedAt),
            companiesQuoteGroupsAttributes: companies.map(({ quoteVersions, ...company }) => ({
              ...company,
              quoteVersionsAttributes: quoteVersions.map(quoteVersion => ({
                ...quoteVersion,
                quoteReceivedAt: getDateString(quoteVersion.quoteReceivedAt),
                additionalRequestsSentAt: getDateString(quoteVersion.additionalRequestsSentAt),
              })),
            })),
          })),
        },
      })
      .then(({ blocFQuoteAnalysis: blocF }) => {
        dispatch(foldersActions.getFolderFromBloc(blocF.folder || {}));
        dispatch(success(transformApiPayloadToFormValues(blocF)));
        dispatch(toastActions.success(TOAST_MESSAGE.success.update));
      }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

// Selectors

const getBlocFById = (state, props) => state.blocs.blocF[props.match.params.folderId];

export const blocFSelectors = {
  getBlocFById,
};

export const blocFActions = {
  getBlocF,
  updateBlocF,
};
