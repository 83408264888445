import React from 'react';
import { Route, Switch } from 'react-router';
import { ErgoOfficesIndex } from './ErgoOfficeIndex';
import ErgoOfficesCreate from './ErgoOfficeCreate';
import ErgoOfficesEdit from './ErgoOfficeEdit';
import { PageError404 } from '../errors/components';

export const ErgoOffices = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/new`} exact render={props => <ErgoOfficesCreate {...props} />} />
      <Route
        path={`${match.url}/:ergoOfficeId/edit`}
        exact
        render={props => <ErgoOfficesEdit {...props} />}
      />
      <Route path={match.url} exact component={ErgoOfficesIndex} />
      <Route component={PageError404} />
    </Switch>
  );
};
