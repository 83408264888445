import React from 'react';
import {
  Button,
  Box,
  useDisclosure,
  Slide,
  Icon,
  CloseButton,
  Heading,
  Text,
  Tag,
  Stack,
} from '@chakra-ui/core';
import { parse, differenceInYears } from 'date-fns';
import { CopyToClipboard } from '../CopyToClipboard';
import Can from '../../../roleBasedAccess/Can';

export const FolderInfos = ({ children, ...props }) => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  return (
    <Box {...props}>
      <Button
        variantColor={null}
        onClick={onToggle}
        bg="semiDarkGray.900"
        _focus={{ outline: 'none', bg: 'semiDarkGray.600' }}
        color="white"
        rounded="40px 0 0 40px"
        position="fixed"
        right="0"
        top="90px"
        h="auto"
        py={2}
        pr={2}
      >
        Info dossier
        <Icon name="chevron-down" transform="rotate(-90deg)" strokeWidth="3px" ml={2} />
      </Button>
      <Slide
        timeout={250}
        in={isOpen}
        placement="right"
        from="right"
        transition="transform 250ms cubic-bezier(0.16, 1, 0.3, 1)"
      >
        {styles => (
          <Box
            style={{ ...styles, top: '90px', position: 'fixed', height: 'auto' }}
            zIndex={50}
            maxH="calc(100vh - 110px)"
          >
            <CloseButton
              onClick={onClose}
              pos="absolute"
              top="8px"
              right="8px"
              zIndex={5}
              color="white"
              size="1rem"
              padding={1}
            >
              Fermer
            </CloseButton>
            {children}
          </Box>
        )}
      </Slide>
    </Box>
  );
};

export const FolderInfosContent = ({ folder }) => {
  return (
    <Box bg="white" rounded="lg" boxShadow="0px 4px 20px rgba(46, 73, 112, 0.2)" width="375px">
      <Box
        style={{
          filter: 'drop-shadow(0 3px 6px rgba(0,0,0,0.3))',
        }}
      >
        <Box
          pl={5}
          pr={2}
          py={3}
          bg="semiDarkGray.900"
          color="white"
          borderTopLeftRadius="lg"
          minH="151px"
          fontWeight="600"
          style={{
            clipPath: 'ellipse(150% 58% at 88% 41%)',
          }}
        >
          <Heading as="h2" fontSize="xl" fontWeight="bold">
            <Text
              as="span"
              fontFamily="heading"
              opacity="0.5"
              textTransform="uppercase"
              fontWeight="600"
            >
              Dossier n°{' '}
            </Text>
            {folder.formattedId}
          </Heading>
          {folder.idexPositionedCompanyInExistingQuoteProject ? (
            <Text mb={1}>{folder.idexPositionedCompanyInExistingQuoteProject}</Text>
          ) : null}
          <Text fontFamily="heading">
            <Icon
              name="dot"
              size={3}
              verticalAlign="baseline"
              color={
                // eslint-disable-next-line no-nested-ternary
                folder.state === 'Actif'
                  ? 'green.900'
                  : folder.state === 'Cloturé'
                  ? 'red'
                  : 'orange.900'
              }
            />{' '}
            {folder.state}
          </Text>
          <Text mt={5}>
            {folder.recipient?.title || 'M.'}
            {'. '}
            <Text as="span" fontWeight="semibold" textTransform="uppercase">
              {folder.recipient?.lastName || '-'}
            </Text>{' '}
            {folder.recipient?.firstName || '-'}
            <Text as="span" opacity="0.5">
              {' '}
              |{' '}
            </Text>
            {folder.recipient?.birthDate}
            <Text as="span" opacity="0.5">
              {' '}
              |{' '}
            </Text>
            {differenceInYears(
              new Date(),
              parse(folder.recipient?.birthDate, 'dd/MM/yyyy', new Date()),
            ) || '-'}{' '}
            ans
          </Text>
          <Text>
            <Can perform="data:medical:read" yes={() => <>{folder.recipient?.gir}</>} />
          </Text>
          <Can
            perform="data:medical:read"
            yes={() => (
              <CopyToClipboard mt={1} value={folder.recipient?.socialSecurityNumber}>
                <Text>
                  <Text as="span" fontWeight="light">
                    Num. sécu&nbsp;:&nbsp;
                  </Text>
                  {folder.recipient?.socialSecurityNumber || '-'}
                </Text>
              </CopyToClipboard>
            )}
          />

          <Box textAlign="right">
            <Tag
              as="span"
              variantColor={null}
              bg="semiDarkGray.600"
              textTransform="uppercase"
              fontSize="sm"
              fontWeight="600"
              py={1}
              minH={6}
            >
              {folder.tag || '-'}
            </Tag>
          </Box>
        </Box>
      </Box>

      <Stack
        px={4}
        pt={4}
        pb={8}
        spacing={5}
        color="semiDarkGray.900"
        fontWeight="600"
        overflowY="auto"
        maxH="calc(100vh - 261px)"
      >
        <Section title="Logement à adapter">
          <CopyToClipboard
            pb={2}
            value={`${folder.housingToAdapt?.streetAddress}, ${folder.housingToAdapt?.postalCode} ${folder.housingToAdapt?.city}`}
          >
            <Text>
              {folder.housingToAdapt?.streetAddress || '-'}
              <br />
              {folder.housingToAdapt?.postalCode || '-'} {folder.housingToAdapt?.city || '-'}
            </Text>
          </CopyToClipboard>

          <AttributeLine title="Statut">{folder.housingToAdapt?.ownership || '-'}</AttributeLine>

          <AttributeLine title="Exclusion des travaux">
            {folder.housingToAdapt?.opposedToWorks || '-'}
          </AttributeLine>

          <AttributeLine title="Devis initial">
            {folder.housingToAdapt?.alreadyHasQuote || '-'}
          </AttributeLine>

          <AttributeLine title="Rapport ergothérapique initial">
            {folder.housingToAdapt?.alreadyHasErgoReport || '-'}
          </AttributeLine>
        </Section>

        {folder.recipientIsTheContact ? (
          <Section title="Correspondance">
            <AttributeLine iconName="user">
              {`${folder.recipient?.firstName} ${folder.recipient?.lastName}` || '-'}
            </AttributeLine>

            <CopyToClipboard value={folder.recipient?.phoneNumber}>
              <AttributeLine iconName="phone">
                {' '}
                {folder.recipient?.phoneNumber || '-'}
              </AttributeLine>
            </CopyToClipboard>

            <CopyToClipboard value={folder.recipient?.email}>
              <AttributeLine iconName="at"> {folder.recipient?.email || '-'}</AttributeLine>
            </CopyToClipboard>

            <CopyToClipboard
              value={`${folder.recipient?.streetAddress}, ${folder.recipient?.postalCode} ${folder.contact?.city}`}
            >
              <AttributeLine iconName="envelope" d="flex" alignItems="baseline">
                <Text as="span" d="inline-block" ml="0.5ch" mt="-0.5px">
                  {folder.recipient?.streetAddress || '-'}
                  <br />
                  {folder.recipient?.postalCode || '-'} {folder.recipient?.city || '-'}
                </Text>
              </AttributeLine>
            </CopyToClipboard>
          </Section>
        ) : (
          <Section title="Correspondance">
            <AttributeLine iconName="user">
              {`${folder.contact?.firstName} ${folder.contact?.lastName}` || '-'}
            </AttributeLine>

            <CopyToClipboard value={folder.contact?.phoneNumber}>
              <AttributeLine iconName="phone">{folder.contact?.phoneNumber || '-'}</AttributeLine>
            </CopyToClipboard>

            <CopyToClipboard value={folder.recipient?.phoneNumber}>
              <AttributeLine iconName="phone">
                <Text as="span" fontSize="sm" fontWeight="thin">
                  (bénéficiaire)
                </Text>{' '}
                {folder.recipient?.phoneNumber || '-'}
              </AttributeLine>
            </CopyToClipboard>

            <CopyToClipboard value={folder.contact?.email}>
              <AttributeLine iconName="at">{folder.contact?.email || '-'}</AttributeLine>
            </CopyToClipboard>

            <CopyToClipboard
              value={`${folder.contact?.streetAddress}, ${folder.contact?.postalCode} ${folder.contact?.city}`}
            >
              <AttributeLine iconName="envelope" d="flex" alignItems="baseline">
                <Text as="span" d="inline-block" mt="-0.5px">
                  {folder.contact?.streetAddress || '-'}
                  <br />
                  {folder.contact?.postalCode || '-'} {folder.contact?.city || '-'}
                </Text>
              </AttributeLine>
            </CopyToClipboard>
          </Section>
        )}

        <Section title="Équipe">
          <AttributeLine title="Ergothérapeute">{folder.operators?.ergo || '-'}</AttributeLine>
          <AttributeLine title="Traitement ergothérapeute">
            {(folder.operators?.ergo && folder.operators?.ergoUserKind) || '-'}
          </AttributeLine>
          <AttributeLine title="Conseiller(e) social(e)">
            {folder.operators?.conseillerSocial || '-'}
          </AttributeLine>
        </Section>
      </Stack>
    </Box>
  );
};

const Section = ({ title, children, ...props }) => (
  <Box as="section" {...props}>
    <Text as="h3" color="lightGray2" fontWeight="600" textTransform="uppercase" mb={3}>
      {title}
    </Text>
    <Stack pl={6} spacing="0.3rem">
      {children}
    </Stack>
  </Box>
);

const AttributeLine = ({ title, children, iconName, ...props }) => (
  <Text {...props}>
    {iconName ? (
      <Icon name={iconName} color="lightGray2" strokeWidth="0.6" height="auto" mr={2} />
    ) : (
      <Text as="span" fontWeight="300" fontSize="sm">
        {title} :{'  '}
      </Text>
    )}
    {children}
  </Text>
);
