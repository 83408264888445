import { errorsConstants } from './constants';

const handleErrors = (callback, ...otherCallbacks) => dispatch => (...reject) => {
  const [error] = reject;
  const { response } = error;

  function error404() {
    return { type: errorsConstants.ERROR_404 };
  }

  function error500() {
    return {
      type: errorsConstants.ERROR_500,
    };
  }

  function otherError() {
    return {
      type: errorsConstants.ERROR_OTHER,
    };
  }

  switch (response.status) {
    case 404:
      dispatch(error404());
      dispatch(callback(response.data));
      return null;
    case 500:
      dispatch(error500());
      dispatch(callback(response.data));
      return null;
    case 422:
      dispatch(callback(response.data));
      otherCallbacks.map(otherCallback => dispatch(otherCallback));
      return null;
    default:
      dispatch(otherError());
      dispatch(callback(response.data));
      otherCallbacks.map(otherCallback => dispatch(otherCallback));
      return null;
  }
};

function resetErrors() {
  return { type: errorsConstants.ERROR_RESET };
}

export const errorsActions = {
  handleErrors,
  resetErrors,
};
