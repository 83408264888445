import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Stack, Box } from '@chakra-ui/core';

import { TabForm } from '../../../../common/components/Tabs';
import {
  FormSection,
  FormSubmitButton,
  FormCTAButton,
} from '../../../../common/components/Form/Form';
import { RadioGroup, RadioInput } from '../../../../common/components/Form/FormInputs';
import { LockedInput } from '../../../../common/components/Form/FormLockedInput';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import { Notice, NoticeText, NoticeThinText } from '../../../../common/components/Notice';
import { getFolderPath } from '../../../../common/routes';
import { history } from '../../../../common/helpers/history';
import Exclude from '../../../../roleBasedAccess/Exclude';

export const BlocHTab1 = ({ blocH: { error, pending, ...initialValues }, onSubmit }) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        // Relocked fields with lock
        document.querySelectorAll('[data-locked]').forEach(button => {
          if (button.getAttribute('data-locked') === 'false') button.click();
        });

        return onSubmit({ ...initialValues, ...values }).then(() => {
          setTouched({});
          setSubmitting(false);
        });
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit}>
          <Stack spacing="3.5rem">
            <Exclude
              exclude="folder:bloc_h:tab_1:form_section_1:read"
              no={() => (
                <FormSection title="Suivi">
                  <DateInput
                    label="Date de réception d'information de lancement de projet"
                    name="projectLaunchInfosReceivedAt"
                    id="projectLaunchInfosReceivedAt"
                  />

                  <LockedInput
                    defaultIsLocked={!!formik.values.projectEndFeedbackRequestSentAt}
                    left="37%"
                  >
                    <DateInput
                      label="Dernière date d'envoi de demande de retour pour fin de projet"
                      name="projectEndFeedbackRequestSentAt"
                      id="projectEndFeedbackRequestSentAt"
                      disabled={!formik.values.projectLaunchInfosReceivedAt}
                    />
                  </LockedInput>

                  <Notice variant="info" mb="2rem" spacing="1rem">
                    <NoticeText variant="info">
                      Documents à envoyer à la personne à contacter :
                    </NoticeText>
                    <NoticeThinText>
                      Si nécessaire, consulter les fiches-procédures ou l'administrateur
                    </NoticeThinText>
                  </Notice>

                  <DateInput
                    label="Date de fin de projet"
                    name="projectEndReceivedAt"
                    id="projectEndReceivedAt"
                    disabled={!formik.values.projectEndFeedbackRequestSentAt}
                  />

                  <DateInput
                    label="Dernière date de réception des pièces de déblocage des fonds"
                    name="paidInvoicesReceivedAt"
                    id="paidInvoicesReceivedAt"
                    disabled={!formik.values.projectEndFeedbackRequestSentAt}
                  />

                  <Notice variant="info" mb="2rem">
                    <NoticeThinText>
                      Envoyer si nécessaire aux financeurs concernés le dossier pour déblocage des
                      fonds
                    </NoticeThinText>
                  </Notice>
                </FormSection>
              )}
            />
            <FormSection title="Bilan de fin de projet">
              <LockedInput defaultIsLocked={!!formik.values.projectEndReviewPlannedAt} left="37%">
                <DateInput
                  label="Dernière date de contact pour la programmation de bilan de fin de projet"
                  name="projectEndReviewPlannedAt"
                  id="projectEndReviewPlannedAt"
                  enabledFutureDates
                  disabled={!formik.values.projectEndReceivedAt}
                />
              </LockedInput>

              <RadioGroup name="isRecipientContacted" legend="Bénéficiaire contacté">
                <RadioInput value="true" disabled={!formik.values.projectEndReceivedAt}>
                  Oui
                </RadioInput>
                <RadioInput value="false" disabled={!formik.values.projectEndReceivedAt}>
                  Non
                </RadioInput>
              </RadioGroup>

              <DateInput
                label="Date de bilan de fin de projet"
                name="projectEndReviewDate"
                id="projectEndReviewDate"
                enabledFutureDates
                disabled={
                  !formik.values.isRecipientContacted ||
                  formik.values.isRecipientContacted === 'false'
                }
              />

              <RadioGroup name="bfpTransmissionType" legend="Modalité de passation du BFP">
                <RadioInput value="visit" disabled={!formik.values.projectEndReviewDate}>
                  Visite à domicile
                </RadioInput>
                <RadioInput value="phone" disabled={!formik.values.projectEndReviewDate}>
                  Téléphonique
                </RadioInput>
              </RadioGroup>

              <RadioGroup name="touchUp" legend="Résultat du BFP">
                <RadioInput value="true">Réserves</RadioInput>
                <RadioInput value="false">Absence de réserves</RadioInput>
              </RadioGroup>
            </FormSection>

            <Box textAlign="center">
              <FormCTAButton
                disabled={
                  !formik.values.projectLaunchInfosReceivedAt ||
                  !formik.values.projectEndFeedbackRequestSentAt ||
                  !formik.values.projectEndReceivedAt ||
                  !formik.values.paidInvoicesReceivedAt ||
                  !formik.values.projectEndReviewPlannedAt ||
                  !formik.values.isRecipientContacted ||
                  !formik.values.projectEndReviewDate ||
                  !formik.values.bfpTransmissionType
                }
                onClick={() =>
                  formik
                    .submitForm()
                    .then(() => history.push(getFolderPath('bloc_i', initialValues.folderId)))
                }
              >
                Clôture pour aboutissement
              </FormCTAButton>
            </Box>
          </Stack>

          <FormSubmitButton
            type="submit"
            disabled={pending}
            isLoading={formik.isSubmitting}
            loadingText="Enregistrer"
          >
            Enregistrer
          </FormSubmitButton>
        </TabForm>
      )}
    </Formik>
  );
};
