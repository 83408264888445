import { apiV1 } from './api';

const getErgoResources = (page = 1, name = '', params) =>
  apiV1.get(`/ergo_resources?page=${page}&by_name=${name}`, { params }).then(({ data }) => data);
const getErgoResource = ergoResourceId =>
  apiV1.get(`/ergo_resources/${ergoResourceId}`).then(({ data }) => data);

const createErgoResource = ergoResource =>
  apiV1
    .post(`/ergo_resources`, {
      ergoResource,
    })
    .then(({ data }) => data);

const updateErgoResource = (ergoResourceId, ergoResource) =>
  apiV1
    .put(`/ergo_resources/${ergoResourceId}`, {
      ergoResource,
    })
    .then(({ data }) => data);

const deleteErgoResource = ergoResourceId => apiV1.delete(`/ergo_resources/${ergoResourceId}`);

export const ergoResourcesAPI = {
  getErgoResources,
  getErgoResource,
  createErgoResource,
  updateErgoResource,
  deleteErgoResource,
};
