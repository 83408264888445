import React from 'react';
import { keyframes } from '@emotion/core';
import { Heading, Text, Box, Icon } from '@chakra-ui/core';

const TOAST_STYLE = {
  'toast-success': {
    icon: 'toast-success',
    color: 'toast.success',
  },
  'toast-error': {
    icon: 'toast-error',
    color: 'toast.error',
  },
};

export const TOAST_MESSAGE = {
  success: {
    update: 'Les modifications ont bien été enregistrées',
    add: 'La création de la fiche a bien été effectuée',
    delete: 'La suppression de la fiche a bien été effectuée',
    quoteUpload: 'Votre devis a bien été envoyé',
  },
  error: {
    update:
      'Tous les champs du formulaire doivent être complétés pour valider la modification de la fiche',
    add:
      'Tous les champs du formulaire doivent être complétés pour valider la création de la fiche',
    delete: 'Une erreur est survenue à la suppression',
    folderClosed: "Le dossier est clôturé, aucune modification n'est possible.",
    projectPosition:
      'Une erreur est survenue. Impossible de positionner cette entreprise sur ce projet.',
    ergoProjectPosition:
      'Une erreur est survenue. Impossible de positionner cet ergothérapeute sur ce projet.',
    quoteUpload: "Une erreur est survenue lors de l'envoi du devis.",
  },
};

const Toast = props => {
  const toastAnimateIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
  `;

  const toastAnimateOut = keyframes`
    0% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(100%);
      opacity: 0;
    }
  `;

  return (
    <Box
      w={365}
      bg="white"
      p="15px 25px 15px 35px"
      borderRadius="10px 0 0 10px"
      shadow="0px 4px 10px rgba(46, 73, 112, 0.25)"
      pos="fixed"
      top="70px"
      right="0"
      transform="translateX(100%)"
      zIndex={1500}
      animation={`0.3s ease-in forwards ${toastAnimateIn}, 0.3s ease-out 3s forwards ${toastAnimateOut}`}
    >
      <Icon
        name={TOAST_STYLE[props.type].icon}
        size="38px"
        color={TOAST_STYLE[props.type].color}
        pos="absolute"
        left="-23px"
        top="15px"
      />

      <Heading fontSize="1.125rem" color={TOAST_STYLE[props.type].color}>
        {props.title}
      </Heading>
      <Text fontSize="0.875rem">{props.message}</Text>
    </Box>
  );
};

export default Toast;
