import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFolderPath } from '../../../../common/routes';
import { BlocHTabs } from './BlocHTabs';
import { blocHActions, blocHSelectors } from '../../../../redux/blocs/blocH/actions';
import { PageError404 } from '../../../../errors/components';

const BlocH = ({
  match: {
    params: { folderId },
  },
  getBlocH,
  updateBlocH,
  ...props
}) => {
  useEffect(() => {
    getBlocH(folderId);
  }, [getBlocH, folderId]);

  const onSubmit = values => updateBlocH(folderId, values);

  return (
    <Switch>
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', '')}`}
        exact
        render={() => <Redirect to={getFolderPath(props.blocId, folderId)} />}
      />
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', ':tabIndex')}`}
        exact
        render={routerProps => <BlocHTabs {...props} {...routerProps} onSubmit={onSubmit} />}
      />
      <Route component={PageError404} />
    </Switch>
  );
};

const mapStateToProps = (state, props) => {
  const blocH = blocHSelectors.getBlocHById(state, props) || {};

  return {
    blocH,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBlocH: blocHActions.getBlocH,
      updateBlocH: blocHActions.updateBlocH,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BlocH);
