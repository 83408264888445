import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { PageError404 } from '../errors/components';
import { ProjectsTabs } from './ProjectsTabs';

export const ProjectPlace = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.url}/:tabIndex`} component={ProjectsTabs} />
      <Redirect exact path={match.url} to={`${match.url}/1`} />
      <Route component={PageError404} />
    </Switch>
  );
};
