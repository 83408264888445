import { recipientsConstants } from './constants';

export const initialState = {
  data: [],
  pending: false,
  recipient: {},
};

export function recipients(state = initialState, action) {
  switch (action.type) {
    case recipientsConstants.GET_RECIPIENTS_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case recipientsConstants.GET_RECIPIENTS_SUCCESS:
      return {
        ...state,
        data: action.data.recipients,
        meta: action.data.meta,
        pending: false,
      };
    case recipientsConstants.CREATE_RECIPIENT_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case recipientsConstants.CREATE_RECIPIENT_SUCCESS:
      return initialState;
    case recipientsConstants.CREATE_RECIPIENT_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case recipientsConstants.GET_RECIPIENT_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case recipientsConstants.GET_RECIPIENT_SUCCESS:
      return {
        ...initialState,
        pending: false,
        recipient: action.recipient,
      };
    case recipientsConstants.UPDATE_RECIPIENT_REQUEST:
      return {
        ...initialState,
        recipient: action.recipient,
        pending: true,
      };
    case recipientsConstants.UPDATE_RECIPIENT_SUCCESS:
      return {
        ...initialState,
        pending: false,
      };
    case recipientsConstants.UPDATE_RECIPIENT_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case recipientsConstants.DELETE_RECIPIENT_REQUEST:
      return {
        ...state,
        deletePending: true,
      };
    case recipientsConstants.DELETE_RECIPIENT_SUCCESS:
      return {
        ...state,
        deletePending: false,
      };
    case recipientsConstants.DELETE_RECIPIENT_FAILURE:
      return {
        ...state,
        error: action.error,
        deletePending: false,
      };
    case recipientsConstants.GET_RECIPIENTS_FAILURE:
    case recipientsConstants.GET_RECIPIENT_FAILURE:
    default:
      return state;
  }
}
