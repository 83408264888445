import { blocEConstants } from './constants';

export const initialState = {
  pending: false,
};

export function blocE(state = initialState, action) {
  switch (action.type) {
    case blocEConstants.GET_BLOCE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case blocEConstants.GET_BLOCE_SUCCESS:
      return {
        ...state,
        [action.blocE.folderId]: action.blocE,
        pending: false,
      };
    case blocEConstants.UPDATE_BLOCE_REQUEST:
      return {
        ...state,
        [action.blocE.folderId]: action.blocE,
        error: null,
        pending: true,
      };
    case blocEConstants.UPDATE_BLOCE_SUCCESS:
      return {
        ...state,
        [action.blocE.folderId]: action.blocE,
        error: null,
        pending: false,
      };
    case blocEConstants.UPDATE_BLOCE_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case blocEConstants.GET_BLOCE_FAILURE:
    default:
      return state;
  }
}
