import { preFoldersConstants } from './constants';

export const initialState = {
  data: [],
  pending: false,
  deletePending: false,
  preFolder: {},
};

export function preFolders(state = initialState, action) {
  switch (action.type) {
    case preFoldersConstants.GET_PRE_FOLDERS_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case preFoldersConstants.GET_PRE_FOLDERS_SUCCESS:
      return {
        ...state,
        data: action.data.preFolders,
        meta: action.data.meta,
        pending: false,
      };
    case preFoldersConstants.GET_PRE_FOLDER_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case preFoldersConstants.GET_PRE_FOLDER_SUCCESS:
      return {
        ...initialState,
        pending: false,
        preFolder: action.preFolder,
      };
    case preFoldersConstants.GET_PRE_FOLDER_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case preFoldersConstants.CREATE_PRE_FOLDER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case preFoldersConstants.CREATE_PRE_FOLDER_SUCCESS:
      return { ...initialState, preFolder: action.preFolder };
    case preFoldersConstants.CREATE_PRE_FOLDER_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case preFoldersConstants.UPDATE_PRE_FOLDER_REQUEST:
      return {
        ...initialState,
        preFolder: action.preFolder,
        pending: true,
      };
    case preFoldersConstants.UPDATE_PRE_FOLDER_SUCCESS:
      return {
        ...state,
        pending: false,
      };
    case preFoldersConstants.UPDATE_PRE_FOLDER_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case preFoldersConstants.TRANSFORM_PRE_FOLDER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case preFoldersConstants.TRANSFORM_PRE_FOLDER_SUCCESS:
      return initialState;
    case preFoldersConstants.TRANSFORM_PRE_FOLDER_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case preFoldersConstants.DELETE_PRE_FOLDER_REQUEST:
      return {
        ...state,
        deletePending: true,
      };
    case preFoldersConstants.DELETE_PRE_FOLDER_SUCCESS:
      return initialState;
    case preFoldersConstants.DELETE_PRE_FOLDER_FAILURE:
      return {
        ...state,
        deletePending: false,
      };
    case preFoldersConstants.GET_PRE_FOLDERS_FAILURE:
    default:
      return state;
  }
}
