import React, { useRef, useEffect, useState } from 'react';
import { Formik, useFormikContext } from 'formik';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  usePrevious,
} from '@chakra-ui/core';

import { TabForm } from '../../../../common/components/Tabs';
import { FormSection, FormSubmitButton } from '../../../../common/components/Form/Form';
import {
  Label,
  RadioGroup,
  RadioInput,
  TextInput,
} from '../../../../common/components/Form/FormInputs';
import { SelectInput } from '../../../../common/components/Form/FormSelectInput';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import { LockedInput } from '../../../../common/components/Form/FormLockedInput';
import {
  Notice,
  NoticeList,
  NoticeText,
  NoticeThinText,
} from '../../../../common/components/Notice';
import { PrimaryButton, SecondaryButton } from '../../../../common/components/Buttons';
import {
  QuoteUploadFilename,
  QuoteUploadWrapper,
} from '../../../../common/components/QuoteUpload/QuoteUpload';
import Exclude from '../../../../roleBasedAccess/Exclude';
import Can from '../../../../roleBasedAccess/Can';

export const BlocATab1 = ({
  blocA: { error, pending, folder = {}, ...initialValues },
  operators,
  companies = [],
  onSubmit,
}) => {
  const formikRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPublishErgoProjectPlaceModalOpen,
    onOpen: onPublishErgoProjectPlaceModalOpen,
    onClose: onPublishErgoProjectPlaceClose,
  } = useDisclosure();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  const onModalClose = () => {
    onClose();
    setSuccess(false);
  };

  const onPublishErgoProjectPlaceModalClose = () => {
    onPublishErgoProjectPlaceClose();
    setSuccess(false);
  };

  const isTypeOfDisabled = !initialValues.bloc_0QuoteRequestPerimeter?.includes('works');
  const isPositionnedCompanyDisabled = values =>
    isTypeOfDisabled || !values.typeOfVisit || values.typeOfVisit === 'solo';
  const isRecipientContactedAtDisabled = values =>
    !values.ergoUserId ||
    !values.ergoUserKind ||
    (['existing_quote', 'joint'].includes(values.typeOfVisit) && !values.positionedCompany) ||
    (initialValues.bloc_0QuoteRequestPerimeter?.includes('works') && !values.typeOfVisit);
  const isIsRecipientContactedDisabled = values =>
    !values.ergoUserId ||
    !values.ergoUserKind ||
    (['existing_quote', 'joint'].includes(values.typeOfVisit) && !values.positionedCompany) ||
    (initialValues.bloc_0QuoteRequestPerimeter?.includes('works') && !values.typeOfVisit);
  const isHousingVisitedAtDisabled = values =>
    values.isRecipientContacted !== 'true' || !values.ergoUserId || !values.ergoUserKind;
  const isVisitReminderSentAtDisabled = values =>
    !values.housingVisitedAt || !values.ergoUserId || !values.ergoUserKind;
  const isHousingVisitDatePassed = values =>
    values.housingVisitedAt ? new Date() >= values.housingVisitedAt : false;
  const getErgoQualification = values => {
    if (values?.ergoQualification?.includes('interne')) return 'interne';
    if (values?.ergoQualification?.includes('cabinet')) return 'externe';
    return null;
  };
  const isProjectHavingPositionableErgo = values =>
    values.ergoProjectHasEligibleUsers ||
    !values.isPublishedOnErgoProjectPlace ||
    values.ergoUserId;
  const getPositionnableErgoUsers = values => {
    return values.positionnableErgoUsers;
  };
  const isPublishOnErgoProjectPlaceDisabled = values =>
    values.isPublishedOnErgoProjectPlace ||
    (!isTypeOfDisabled && !values.typeOfVisit) ||
    (values.typeOfVisit === 'existing_quote' && !values.positionedCompany);

  const isPositionableErgoDisabled = values =>
    values.typeOfVisit === 'existing_quote' && !values.positionedCompany;

  const isPublishErgoProjectPlaceModalNeeded =
    folder.folderType === 'AMOc' || folder.folderType === 'AMOe';
  const filterCompanies = (typeOfVisit, excludedCompanies = []) => {
    if (typeOfVisit === 'existing_quote') {
      return companies?.map(company => ({ label: company.name, value: company.id }));
    }

    return companies
      ?.filter(company => {
        return company.departments
          .map(dept => dept.slice(0, 2))
          .includes(folder?.housingToAdapt?.postalCode?.slice(0, 2));
      })
      .filter(company => !excludedCompanies.includes(company.id))
      .map(company => ({ label: company.name, value: company.id }));
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        return onSubmit({ ...initialValues, folder, ...values }).then(() => {
          setTouched({});
          setSubmitting(false);
        });
      }}
    >
      {formik => {
        const filteredCompanies = filterCompanies(
          formik.values.typeOfVisit,
          initialValues?.ignoredFromProjectCompanyIds?.map(id => Number(id)),
        );
        return (
          <TabForm onSubmit={formik.handleSubmit}>
            <Stack spacing="3.5rem">
              <Exclude
                exclude="folder:bloc_a:tab_1:type_of_visit:read"
                no={() => (
                  <FormSection title="Modalité de visite">
                    <LockedInput
                      defaultIsLocked={
                        (!!formik.values.typeOfVisit && formik.values.typeOfVisit !== 'joint') ||
                        isHousingVisitDatePassed(formik.values) ||
                        (formik.values.typeOfVisit === 'joint' && initialValues.isPublished)
                      }
                      disabled={isTypeOfDisabled || isHousingVisitDatePassed(formik.values)}
                      bottom="calc(100% + 18px)"
                      top="auto"
                      left="18ch"
                    >
                      <RadioGroup name="typeOfVisit" disabled={isTypeOfDisabled}>
                        <RadioInput value="joint">Visite conjointe ergo-entreprise</RadioInput>
                        {formik.values.typeOfVisit === 'joint' &&
                        !formik.values.positionedCompany ? (
                          <PrimaryButton
                            ml={8}
                            onClick={onOpen}
                            disabled={initialValues.isPublished}
                          >
                            Publier le marché
                          </PrimaryButton>
                        ) : null}
                        <RadioInput value="solo">Visite ergothérapeute seule</RadioInput>
                        <RadioInput value="existing_quote">
                          Devis/facture existant à l’ouverture du dossier
                        </RadioInput>
                      </RadioGroup>
                    </LockedInput>

                    <CasSwitch companies={filteredCompanies} />

                    {!isTypeOfDisabled &&
                    (initialValues.fioQ1BisAnswer?.includes('A') ||
                      initialValues.fioQ6Answer?.match('B')) ? (
                      // eslint-disable-next-line react/jsx-indent
                      <Notice variant="warning" mt="2rem">
                        <NoticeText variant="warning">
                          Mise à disposition du marché non recommandée :
                        </NoticeText>
                        <NoticeList variant="warning" mx="auto" mt={-3} fontWeight="thin">
                          {initialValues.fioQ1BisAnswer?.includes('A') ? (
                            <li>type de travaux non-défini</li>
                          ) : null}
                          {initialValues.fioQ6Answer?.match('B') ? <li>parc public</li> : null}
                        </NoticeList>
                      </Notice>
                    ) : null}
                  </FormSection>
                )}
              />

              <Exclude
                exclude="folder:bloc_a:tab_1:company_name:read"
                no={() => (
                  <FormSection
                    mt="2rem"
                    title={`Nom de l'entreprise${
                      formik.values?.typeOfVisit === 'existing_quote' ? ' (devis existant)' : ''
                    }`}
                  >
                    <LockedInput
                      defaultIsLocked={
                        !!formik.values.positionedCompany || isHousingVisitDatePassed(formik.values)
                      }
                      disabled={
                        isPositionnedCompanyDisabled(formik.values) ||
                        isHousingVisitDatePassed(formik.values)
                      }
                      bottom="calc(100% + 18px)"
                      top="auto"
                      left={formik.values?.typeOfVisit === 'existing_quote' ? '37ch' : '20ch'}
                    >
                      <SelectInput
                        placeholder="Sélectionner une entreprise"
                        name="positionedCompany"
                        id="positionedCompany"
                        options={filteredCompanies}
                        isDisabled={isPositionnedCompanyDisabled(formik.values)}
                      />
                    </LockedInput>
                    {formik.values.typeOfVisit === 'existing_quote' &&
                    formik.values.positionedCompany ? (
                      <>
                        <Label>Devis</Label>
                        <QuoteUploadWrapper>
                          <QuoteUploadFilename
                            quoteVersion={initialValues.firstQuoteVersion}
                            placeholder="Aucun devis disponible"
                          />
                        </QuoteUploadWrapper>
                      </>
                    ) : null}
                  </FormSection>
                )}
              />

              {isPositionableErgoDisabled(formik.values) ? (
                <Notice variant="warning" mt="2rem">
                  <NoticeText variant="warning">
                    Action requise pour désigner un ergothérapeute
                  </NoticeText>
                  <NoticeThinText mt="-1rem">
                    Veuillez désigner l’entreprise ayant produit le devis ou la facture avant de
                    désigner un ergothérapeute ou mettre la demande d’intervention sur la project
                    place.
                  </NoticeThinText>
                </Notice>
              ) : null}
              <Exclude
                exclude="folder:bloc_a:tab_1:operators:read"
                no={() => (
                  <FormSection
                    title="Opérateurs"
                    mt={isPositionableErgoDisabled(formik.values) ? '0' : '2rem'}
                  >
                    {!isProjectHavingPositionableErgo(formik.values) && (
                      <Notice variant="warning" my="2rem">
                        <NoticeText variant="warning">
                          Attention
                          <br />
                          Aucun ergothérapeute n'ayant les accréditations nécessaires sur ce
                          territoire n'est trouvé.
                        </NoticeText>
                        <NoticeList variant="warning" mx="auto" mt={-3} fontWeight="thin">
                          <li>Aucun ergothérapeute ne pourra se positionner.</li>
                          <li>
                            Veuillez avertir le responsable de recrutement des ergothérapeutes.
                          </li>
                        </NoticeList>
                      </Notice>
                    )}
                    <PrimaryButton
                      mb={8}
                      onClick={onPublishErgoProjectPlaceModalOpen}
                      disabled={isPublishOnErgoProjectPlaceDisabled(formik.values)}
                    >
                      Publier sur la project place ergo
                    </PrimaryButton>
                    <SelectInput
                      label="Ergothérapeute référent"
                      name="ergoUserId"
                      id="ergoUserId"
                      isDisabled={isPositionableErgoDisabled(formik.values)}
                      isClearable
                      options={getPositionnableErgoUsers(formik.values)?.map(operator => ({
                        label: `${operator.firstName} ${operator.lastName}`,
                        value: operator.id,
                        ergoQualification: operator.ergoQualification,
                      }))}
                      placeholder="Sélectionner un opérateur"
                      noOptionsMessage={() => 'Aucun ergothérapeute disponible'}
                      postOnChange={ergo => {
                        formik.setFieldValue('ergoUserKind', getErgoQualification(ergo));
                      }}
                    />
                    <RadioGroup
                      isInline
                      name="ergoUserKind"
                      legend="Ergothérapeute interne/externe"
                      disabled
                    >
                      <RadioInput value="interne">Interne</RadioInput>
                      <RadioInput value="externe">Externe</RadioInput>
                    </RadioGroup>
                    <Can
                      perform="dashboard:visit"
                      yes={() => (
                        <TextInput
                          label="Date de positionnement"
                          name="ergoPositionedAt"
                          id="ergoPositionedAt"
                          disabled
                        />
                      )}
                    />
                  </FormSection>
                )}
              />

              <FormSection title="Préparation de la visite" mt="2rem">
                <DateInput
                  label="Date de prise de contact pour programmation"
                  name="recipientContactedAt"
                  id="recipientContactedAt"
                  disabled={isRecipientContactedAtDisabled(formik.values)}
                />

                <RadioGroup
                  isInline
                  name="isRecipientContacted"
                  legend="Bénéficiaire contacté"
                  disabled={isIsRecipientContactedDisabled(formik.values)}
                >
                  <RadioInput value="true">Oui</RadioInput>
                  <RadioInput value="false">Non</RadioInput>
                </RadioGroup>

                <DateInput
                  label="Date de visite à domicile"
                  name="housingVisitedAt"
                  id="housingVisitedAt"
                  enabledFutureDates
                  disabled={isHousingVisitedAtDisabled(formik.values)}
                />

                <Exclude
                  exclude="folder:bloc_a:tab_1:visit_preparation:visit_reminder_sent_at:read"
                  no={() => (
                    <DateInput
                      label="Date d'envoi de la lettre de rappel de visite"
                      name="visitReminderSentAt"
                      id="visitReminderSentAt"
                      disabled={isVisitReminderSentAtDisabled(formik.values)}
                    />
                  )}
                />
              </FormSection>
            </Stack>
            <ConfirmationModal
              isOpen={isOpen}
              onClose={onModalClose}
              success={success}
              onSubmit={() => {
                formik.setFieldValue('isPublishedInProjectPlace', true);
                formik.submitForm().then(() => setSuccess(true));
              }}
              pending={pending || formik.isSubmitting}
            />
            <ConfirmationPublishErgoModal
              isOpen={isPublishErgoProjectPlaceModalOpen}
              onClose={onPublishErgoProjectPlaceModalClose}
              folderType={folder.folderType}
              typeOfVisit={formik.values.typeOfVisit}
              success={success}
              isInterventionChoiceNeeded={isPublishErgoProjectPlaceModalNeeded}
              onSubmit={() => {
                if (!isPublishErgoProjectPlaceModalNeeded)
                  formik.setFieldValue('ergoInterventionType', 'AMOc - ergo seul');
                formik.setFieldValue('isPublishedOnErgoProjectPlace', true);
                formik.submitForm().then(() => setSuccess(true));
              }}
              pending={pending || formik.isSubmitting}
            />

            <FormSubmitButton
              type="submit"
              disabled={pending}
              isLoading={formik.isSubmitting}
              loadingText="Enregistrer"
            >
              Enregistrer
            </FormSubmitButton>
          </TabForm>
        );
      }}
    </Formik>
  );
};

const CasSwitch = ({ companies }) => {
  const { values, setValues } = useFormikContext();

  const typeOfVisit = usePrevious(values.typeOfVisit);

  React.useEffect(() => {
    if (
      !!typeOfVisit &&
      typeOfVisit !== values.typeOfVisit &&
      !companies.find(company => company.value === values.positionedCompany?.value)
    ) {
      setValues({ ...values, positionedCompany: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.typeOfVisit, setValues]);
  return null;
};

const ConfirmationModal = ({ isOpen, onSubmit, pending, success, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>Confirmation de prise de marché</ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: 'none' }} />
        <ModalBody>
          {success ? (
            <p>Le marché a bien été publié. Vous pouvez le retrouver dans la Project place.</p>
          ) : (
            <p>Êtes-vous certain de vouloir de publier ce marché ?</p>
          )}
        </ModalBody>

        <ModalFooter>
          {success ? (
            <PrimaryButton onClick={onClose}>OK</PrimaryButton>
          ) : (
            <>
              <SecondaryButton mr={3} onClick={onClose}>
                Non
              </SecondaryButton>
              <PrimaryButton
                onClick={onSubmit}
                isLoading={pending}
                loadingText="Publication en cours..."
              >
                Oui
              </PrimaryButton>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const OptionalRadioInput = ({ fieldName, legend, values, setObserver }) => (
  <RadioGroup name={fieldName} legend={legend}>
    {values.map(option => (
      <RadioInput
        key={option.value}
        value={option.value}
        postOnChange={newValue => (setObserver ? setObserver(newValue) : null)}
      >
        {option.label}
      </RadioInput>
    ))}
  </RadioGroup>
);

const ConfirmationPublishErgoModal = ({
  isOpen,
  onSubmit,
  pending,
  success,
  onClose,
  folderType,
  typeOfVisit,
  isInterventionChoiceNeeded,
}) => {
  const [interventionType, setInterventionType] = useState(null);

  const InterventionSubForm = () => {
    if (folderType === 'AMOe')
      return (
        <OptionalRadioInput
          fieldName="ergoInterventionType"
          legend="S’agit-il d’une visite aide-ménagère ou d’une visite diagnostic ?"
          values={[
            { label: 'AMOe - AMD', value: 'AMOe - AMD' },
            { label: 'AMOe - visite diagnostic', value: 'AMOe - visite diagnostic' },
          ]}
          setObserver={setInterventionType}
        />
      );
    if (typeOfVisit === 'joint')
      return (
        <OptionalRadioInput
          fieldName="ergoInterventionType"
          legend="S’agit-il d’une intervention conjointe d’un ergothérapeute ou d’un diagnostic autonomie conjoint ?"
          values={[
            {
              label: 'Intervention conjointe d’un ergothérapeute',
              value: 'AMOc - visite conjointe',
            },
            { label: 'Diagnostic autonomie conjoint', value: 'AMOc - diagnostic conjoint' },
          ]}
          setObserver={setInterventionType}
        />
      );
    if (typeOfVisit === 'solo')
      return (
        <OptionalRadioInput
          fieldName="ergoInterventionType"
          legend="S’agit-il d’une visite seule d’un ergothérapeute ou d’un diagnostic autonomie seul ?"
          values={[
            { label: 'Visite seule d’un ergothérapeute', value: 'AMOc - ergo seul' },
            { label: 'Diagnostic autonomie seul', value: 'AMOc - diagnostic ergo seul' },
          ]}
          setObserver={setInterventionType}
        />
      );
    if (typeOfVisit === 'existing_quote')
      return (
        <OptionalRadioInput
          fieldName="ergoInterventionType"
          legend="S’agit-il d’une visite sur devis existant, d’une visite sur travaux réalisés ou d'un diagnostic autonomie sur devis ?"
          values={[
            { label: 'AMOc - devis existant', value: 'AMOc - devis existant' },
            { label: 'AMOc - facture existante', value: 'AMOc - facture existante' },
            { label: 'Diagnostic autonomie sur devis', value: 'AMOc - diagnostic devis existant' },
          ]}
          setObserver={setInterventionType}
        />
      );
    return null;
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>Confirmation de la publication</ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: 'none' }} onClick={onClose} />
        <ModalBody>
          {success && (
            <p>Le projet a été publié. Vous pouvez le retrouver dans la project place ergo.</p>
          )}
          {!success && isInterventionChoiceNeeded && <InterventionSubForm />}
          {!success && !isInterventionChoiceNeeded && (
            <p>Êtes-vous certain de vouloir de publier sur la project place ergo ?</p>
          )}
        </ModalBody>

        <ModalFooter>
          {success ? (
            <PrimaryButton onClick={onClose}>OK</PrimaryButton>
          ) : (
            <>
              <SecondaryButton mr={3} onClick={onClose}>
                Annuler
              </SecondaryButton>
              <PrimaryButton
                onClick={onSubmit}
                isLoading={pending}
                loadingText="Publication en cours..."
                disabled={isInterventionChoiceNeeded && !interventionType}
              >
                Confirmer
              </PrimaryButton>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
