import { bloc0Constants } from './constants';
import { blocAPI } from '../../../common/api/blocs';
import {
  getDateString,
  getBooleanFromString,
  getDateFromString,
  getStringFromBoolean,
  getStringFromSelectValue,
} from '../../../common/utils';
import { toastActions } from '../../toast/actions';
import { TOAST_MESSAGE } from '../../../common/components/Toasts/Toast';
import { foldersActions } from '../../folders/actions';
import { folderTypes } from '../../../folders/FolderEdit/blocs/Bloc0/Bloc0Tab2';
import { errorsActions } from '../../errors/actions';
import { history } from '../../../common/helpers/history';
import { getFolderPath } from '../../../common/routes';

const tranformApiPayloadToFormValues = bloc0 => ({
  ...bloc0,
  conseillerSocialUserId: bloc0.conseillerSocialUserId
    ? { label: bloc0.folder.operators.conseillerSocial, value: bloc0.conseillerSocialUserId }
    : null,
  // eslint-disable-next-line no-nested-ternary
  referentOrPiloteExtUserId: bloc0.piloteExtUserId
    ? {
        label: bloc0.folder.operators.piloteExt,
        value: bloc0.piloteExtUserId,
        role: 'pilote externe',
      }
    : bloc0.referentExtUserId
    ? {
        label: bloc0.folder.operators.referentExt,
        value: bloc0.referentExtUserId,
        role: 'référent externe',
      }
    : null,
  contactedAt: getDateFromString(bloc0.contactedAt),
  appointedAt: getDateFromString(bloc0.appointedAt),
  doesRecipientOwnTheHousing: getStringFromBoolean(bloc0.doesRecipientOwnTheHousing),
  isFundingsSearchUseful: getStringFromBoolean(bloc0.isFundingsSearchUseful),
  isDematerializedFolder: getStringFromBoolean(bloc0.isDematerializedFolder),
  tag: bloc0.folder?.tag ? { label: bloc0.folder?.tag, value: bloc0.folder?.tag } : null,
  folderType: folderTypes.find(folderType => folderType.value === bloc0.folder?.folderType) || null,
  completeFolderSentAt: getDateFromString(bloc0.completeFolderSentAt),
  folderValidatedAt: getDateFromString(bloc0.folderValidatedAt),
});

function getBloc0(folderId) {
  function request() {
    return { type: bloc0Constants.GET_BLOC0_REQUEST };
  }
  function success(bloc0) {
    return { type: bloc0Constants.GET_BLOC0_SUCCESS, bloc0 };
  }
  function failure(error) {
    return { type: bloc0Constants.GET_BLOC0_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return blocAPI
      .getBloc(folderId, 'bloc0_social_assessment')
      .then(({ bloc0SocialAssessment: bloc0 }) => {
        dispatch(success(tranformApiPayloadToFormValues(bloc0)));
        dispatch(foldersActions.getFolderFromBloc(bloc0.folder || {}));
      }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function updateBloc0(folderId, updatedBloc0) {
  function request(bloc0) {
    return { type: bloc0Constants.UPDATE_BLOC0_REQUEST, bloc0 };
  }
  function success(bloc0) {
    return { type: bloc0Constants.UPDATE_BLOC0_SUCCESS, bloc0 };
  }
  function failure(error) {
    return { type: bloc0Constants.UPDATE_BLOC0_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedBloc0));

    return blocAPI
      .updateBloc(folderId, 'bloc0_social_assessment', {
        ...updatedBloc0,
        contactedAt: updatedBloc0.contactedAt && getDateString(updatedBloc0.contactedAt),
        conseillerSocialUserId: updatedBloc0.conseillerSocialUserId?.value,
        referentExtUserId:
          updatedBloc0.referentOrPiloteExtUserId?.role === 'référent externe'
            ? updatedBloc0.referentOrPiloteExtUserId?.value
            : null,
        piloteExtUserId:
          updatedBloc0.referentOrPiloteExtUserId?.role === 'pilote externe'
            ? updatedBloc0.referentOrPiloteExtUserId?.value
            : null,
        appointedAt: updatedBloc0.appointedAt && getDateString(updatedBloc0.appointedAt),
        doesRecipientOwnTheHousing: getBooleanFromString(updatedBloc0.doesRecipientOwnTheHousing),
        isFundingsSearchUseful: getBooleanFromString(updatedBloc0.isFundingsSearchUseful),
        isDematerializedFolder: getBooleanFromString(updatedBloc0.isDematerializedFolder),
        completeFolderSentAt:
          updatedBloc0.completeFolderSentAt && getDateString(updatedBloc0.completeFolderSentAt),
        folderValidatedAt:
          updatedBloc0.folderValidatedAt && getDateString(updatedBloc0.folderValidatedAt),
        folderAttributes: {
          id: updatedBloc0.folderId,
          tag: getStringFromSelectValue(updatedBloc0.tag),
          folderType: getStringFromSelectValue(updatedBloc0.folderType),
          notificationRecipientsAttributes: updatedBloc0.notificationRecipients,
        },
      })
      .then(({ bloc0SocialAssessment: bloc0 }) => {
        dispatch(foldersActions.getFolderFromBloc(bloc0.folder || {}));
        dispatch(success(tranformApiPayloadToFormValues(bloc0)));
        history.push(getFolderPath('bloc_fio', bloc0.folder.id));
        dispatch(toastActions.success(TOAST_MESSAGE.success.update));
      }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

// Selectors

const getBloc0ById = (state, props) => state.blocs.bloc0[props.match.params.folderId];

export const bloc0Selectors = {
  getBloc0ById,
};

export const bloc0Actions = {
  getBloc0,
  updateBloc0,
};
