import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Flex, Heading, Text, Box } from '@chakra-ui/core';
import { Logo } from '../common/components/Logo';
import { PrimaryButton } from '../common/components/Buttons';

export const PageError = ({ statusCode, text, children, ...props }) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    flexDir="column"
    flexGrow="1"
    height="100%"
    {...props}
  >
    <Heading>Erreur{statusCode ? ` ${statusCode}` : null}</Heading>
    <Text>{text || 'Une erreur est survenue'}</Text>
    {children}
  </Flex>
);

export const PageError404 = props => (
  <PageError statusCode="404" text="Page introuvable" {...props} />
);

export const PageError500 = props => (
  <PageError statusCode="500" text="Une erreur serveur est survenue" {...props} />
);

export const PageErrorNetwork = props => (
  <React.Fragment>
    <Box as={Logo} position="fixed" top="40px" left="50vw" transform="translateX(-50%)" />
    <PageError
      text="Connexion au serveur impossible"
      {...props}
      height="100vh"
      bg="gray"
      css={{ color: 'white' }}
    >
      <PrimaryButton mt={4} onClick={() => window.location.replace(document.referrer)}>
        Rafraichir la page
      </PrimaryButton>
    </PageError>
  </React.Fragment>
);
