import React, { useEffect, useRef } from 'react';
import {
  Tabs as CTabs,
  Tab as CTab,
  TabPanels as CTabPanels,
  TabPanel as CTabPanel,
  TabList as CTabList,
  Box,
  Text,
  Heading,
  Icon,
  VisuallyHidden,
  Badge,
} from '@chakra-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { getFolderPath } from '../routes';
import { history } from '../helpers/history';
import { Form } from './Form/Form';
import { THeadRow, TH } from './Table';

export const BaseTabs = ({ children, ...props }) => {
  return (
    <CTabs size="md" variant="unstyled" height="100%" d="flex" flexDir="column" {...props}>
      {children}
    </CTabs>
  );
};

export const Tabs = ({ children, tabIndex, folderId, blocId, ...props }) => (
  <BaseTabs
    index={Number(tabIndex - 1)}
    onChange={index => {
      history.push(getFolderPath(blocId, folderId, index + 1));
    }}
    {...props}
  >
    {children}
  </BaseTabs>
);

export const TabList = ({ children, ...props }) => (
  <CTabList alignItems="stretch" maxWidth="calc(100% - 20px)" {...props}>
    {children}
  </CTabList>
);

export const Tab = React.forwardRef(({ children, isSelected, ...props }, ref) => {
  return (
    <CTab
      ref={ref}
      isSelected={isSelected}
      bg="tab.bg.notActive"
      rounded="15px 15px 0 0"
      shadow="0px 4px 10px rgba(46, 73, 112, 0.25)"
      fontSize="1.125rem"
      fontFamily="heading"
      color="lightGray2"
      p="17px 23px 4px"
      height="auto"
      pos="relative"
      _active={{ zIndex: 0 }}
      _selected={{
        bg: 'tab.bg.active',
        fontSize: '1.25rem',
        fontWeight: 600,
        color: 'semiDarkGray.900',
        '&:before,&:after': { bg: 'tab.bg.active' },
        zIndex: 10,
      }}
      _focus={{ outline: 'none' }}
      _before={{
        content: "''",
        d: 'block',
        w: '100%',
        height: '25px',
        bg: 'tab.bg.notActive',
        transform: 'translateY(50%)',
        pos: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: -1,
      }}
      _after={{
        content: "''",
        d: 'block',
        w: '113%',
        height: '12.5px',
        bg: 'tab.bg.notActive',
        pos: 'absolute',
        top: '100%',
        left: '-10%',
        zIndex: -1,
      }}
      _first={{ '&:after': { left: 0, width: '110%' } }}
      {...props}
    >
      {children}
    </CTab>
  );
});

export const TabWithBadge = React.forwardRef(
  ({ children, badgeBgColor, badgeContent, ...props }, ref) => {
    const badgeSize = props.isSelected ? 6 : 5;

    return (
      <Tab {...props} ref={ref}>
        {children}
        <Badge
          ml={2}
          lineHeight={1}
          opacity={props.isSelected ? 1 : 0.8}
          fontSize={props.isSelected ? 'sm' : 'xs'}
          rounded="full"
          d="flex"
          alignItems="center"
          justifyContent="center"
          h={badgeSize}
          minW={badgeSize}
          variantColor={null}
          bg={badgeBgColor}
          color="white"
        >
          {badgeContent}
        </Badge>
      </Tab>
    );
  },
);

export const TabPanels = ({ children, ...props }) => {
  const ref = useRef();
  useEffect(() => {
    if (ref) {
      // eslint-disable-next-line no-param-reassign
      ref.current.style.height = `${window.innerHeight -
        ref.current.getBoundingClientRect().top -
        24}px`;
    }
  }, [ref]);
  return (
    <CTabPanels
      ref={ref}
      d="flex"
      flexDir="column"
      bg="white"
      shadow="0px 4px 10px rgba(46, 73, 112, 0.25)"
      rounded="0px 15px 15px 15px"
      p="32px 0 38px"
      flexGrow="1"
      pos="relative"
      tabIndex={null}
      {...props}
    >
      {children}
    </CTabPanels>
  );
};

export const TabPanel = ({ children, ...props }) => (
  <CTabPanel
    h={0}
    tabIndex={null}
    flexGrow="1"
    overflowY="auto"
    pb={20}
    css={css`
      scroll-behavior: smooth;
    `}
    {...props}
  >
    {children}
  </CTabPanel>
);

export const TabForm = ({ children, ...props }) => (
  <Form
    pt={8}
    pb={0}
    css={css`
      button[type='submit'] {
        box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15);
        position: fixed;
        bottom: 0;
        right: 3.125rem;
        transform: translateY(-23%);
      }
    `}
    {...props}
  >
    {children}
  </Form>
);

export const TabTableWrap = ({ children, ...props }) => (
  <Box
    bg="lightGray"
    px="2.25rem"
    pt="0.625rem"
    pb={1}
    rounded="15px"
    textAlign="center"
    {...props}
  >
    {children}
  </Box>
);

export const TabTHeadRow = THeadRow;

export const TabTBodyRow = ({ children, ...props }) => (
  <THeadRow
    textAlign="left"
    css={{
      '&:last-of-type': { 'td, th': { borderBottom: 0 }, 'hr:last-of-type': { display: 'none' } },
    }}
    {...props}
  >
    {children}
  </THeadRow>
);

export const TabTH = ({ hasBorderRight, children, ...props }) => {
  const borderRightProp = hasBorderRight ? 'thin solid' : 'none';
  return (
    <TH
      textAlign="center"
      borderRight={borderRightProp}
      borderRightColor="table.row.borderColor"
      {...props}
    >
      {children}
    </TH>
  );
};

export const TabTBodyTH = ({ children, ...props }) => (
  <TabTH
    borderRight="1px solid"
    borderRightColor="coral.900"
    borderBottomColor="table.row.borderColor"
    {...props}
  >
    {children}
  </TabTH>
);

export const TabTD = ({ hasBorderRight, children, ...props }) => {
  const borderRightProp = hasBorderRight ? 'thin solid' : 'none';
  return (
    <Box
      as="td"
      borderBottom="thin solid"
      borderRight={borderRightProp}
      borderColor="table.row.borderColor"
      px="td.px"
      py="td.py"
      textAlign="center"
      fontWeight={600}
      color="gray"
      wordBreak="break-word"
      {...props}
    >
      {children}
    </Box>
  );
};

export const TabTDNumber = ({ children }) => {
  return (
    <Box
      as="td"
      borderBottom="thin solid"
      borderColor="table.row.borderColor"
      px="td.px"
      py="td.py"
      textAlign="center"
      fontSize="1.375rem"
      fontWeight="bold"
      color="gray"
    >
      {children}
    </Box>
  );
};

export const SecondaryTabList = ({ children, title, subtitle, ...props }) => (
  <Box
    bg="lightGray"
    color="semiDarkGray.900"
    rounded="0 20px 20px 0"
    p="12px 36px 40px 40px"
    position="sticky"
    top={0}
    {...props}
  >
    <Heading as="h3" size="sm" fontFamily="body" textTransform="uppercase">
      {title}
    </Heading>
    {subtitle && <Text fontWeight={300}>{subtitle}</Text>}
    <CTabList
      mt={5}
      bg="white"
      minW="200px"
      rounded="10px"
      border="1px solid rgba(210, 220, 231, 0.4)"
    >
      {children}
    </CTabList>
  </Box>
);

export const SecondaryTab = React.forwardRef(
  ({ children, isValid, isPending, isCanceled, ...props }, ref) => (
    <CTab
      ref={ref}
      px={4}
      py={3}
      border="1px solid transparent"
      borderBottomColor="rgba(210, 220, 231, 0.4)"
      justifyContent="space-between"
      margin={0}
      _first={{ rounded: '10px 10px 0 0' }}
      _last={{
        rounded: '0 0 10px 10px',
        '&:first-of-type': { rounded: '10px' },
        borderBottom: 0,
      }}
      _selected={{
        color: 'red',
        bg: 'rgba(179, 49, 49, 0.08)',
        boxShadow: '0 0 0 1px rgba(179, 49, 49, 0.08)',
        fontWeight: 600,
      }}
      {...props}
    >
      {children}
      {isValid && <VisuallyHidden> est valide</VisuallyHidden>}
      {isPending && <VisuallyHidden> est en attente</VisuallyHidden>}
      {isCanceled && <VisuallyHidden> est sans suite</VisuallyHidden>}
      {isValid && <Icon name="tick" color="green.900" />}
      {isPending && <Icon name="empty-dot" color="orange.900" />}
      {isCanceled && <Icon name="close" color="red" />}
    </CTab>
  ),
);
