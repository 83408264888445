import { apiV1 } from './api';
import { encodeQueryArray } from '../utils';

const getQuotes = (page = 1, search) =>
  apiV1
    .get(
      `/quote_request_summaries?page=${page}&by_keywords=${search.keywords ||
        ''}&by_delay=${search.delay || ''}${encodeQueryArray(
        'by_tags',
        search.tags,
      )}${encodeQueryArray('by_status', search.status)}${encodeQueryArray(
        'by_indicators',
        search.indicators,
      )}`,
    )
    .then(({ data }) => data);

const uploadQuoteFile = (quoteRequestId, quote) =>
  apiV1
    .post(`/quote_request_summaries/${quoteRequestId}/upload_quote`, quote, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data);

export const quotesAPI = {
  getQuotes,
  uploadQuoteFile,
};
