import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFolderPath } from '../../../../common/routes';
import { BlocETabs } from './BlocETabs';
import { blocESelectors, blocEActions } from '../../../../redux/blocs/blocE/actions';
import { PageError404 } from '../../../../errors/components';

const BlocE = ({
  match: {
    params: { folderId },
  },
  getBlocE,
  updateBlocE,
  ...props
}) => {
  useEffect(() => {
    getBlocE(folderId);
  }, [getBlocE, folderId]);

  const onSubmit = values => {
    return updateBlocE(folderId, values);
  };

  return (
    <Switch>
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', '')}`}
        exact
        render={() => <Redirect to={getFolderPath(props.blocId, folderId)} />}
      />
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', ':tabIndex')}`}
        exact
        render={routerProps => <BlocETabs {...props} {...routerProps} onSubmit={onSubmit} />}
      />
      <Route component={PageError404} />
    </Switch>
  );
};

const mapStateToProps = (state, props) => {
  const blocE = blocESelectors.getBlocEById(state, props) || {};

  return {
    blocE: { ...blocE, error: state.blocs.blocE.error },
    folder: state.folders.folder,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBlocE: blocEActions.getBlocE,
      updateBlocE: blocEActions.updateBlocE,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BlocE);
