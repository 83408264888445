import { blocGConstants } from './constants';
import { blocAPI } from '../../../common/api/blocs';
import {
  getDateFromString,
  getDateString,
  getStringFromBoolean,
  getBooleanFromString,
} from '../../../common/utils';
import { toastActions } from '../../toast/actions';
import { TOAST_MESSAGE } from '../../../common/components/Toasts/Toast';
import { foldersActions } from '../../folders/actions';
import { errorsActions } from '../../errors/actions';

const transformEligibleFundersMonitoringValues = (key, value, method = 'get') => {
  switch (key) {
    case 'areCompaniesDocumentsValid':
    case 'isDepositUnlocked':
    case 'hasProcuration':
    case 'managedBy':
    case 'fundingResponse':
    case 'isGrantedAmountKnown':
      return method === 'get' ? getStringFromBoolean(value) : getBooleanFromString(value);
    case 'companiesDocumentsRequestedAt':
    case 'companiesDocumentsReceivedAt':
    case 'depositRequestReceivedAt':
    case 'procurationReceivedAt':
    case 'fundingRequestedAt':
    case 'funderResponseReceivedAt':
      return method === 'get' ? getDateFromString(value) : getDateString(value);
    default:
      return value;
  }
};

export const transformApiPayloadToFormValues = ({
  fundingPlanSentAt,
  ownerAuthorizationRequestSentAt,
  ownerAuthorizationReceivedAt,
  hasOwnerAuthorizedWorks,
  financialInvestigationEndDate,
  ...blocG
}) => ({
  ...blocG,
  fundingPlanSentAt: getDateFromString(fundingPlanSentAt),
  ownerAuthorizationRequestSentAt: getDateFromString(ownerAuthorizationRequestSentAt),
  ownerAuthorizationReceivedAt: getDateFromString(ownerAuthorizationReceivedAt),
  hasOwnerAuthorizedWorks: getStringFromBoolean(hasOwnerAuthorizedWorks),
  financialInvestigationEndDate: getDateFromString(financialInvestigationEndDate),
  eligibleFundersMonitoring: Object.entries(blocG.eligibleFundersMonitoring).reduce(
    (acc, [key, eligibleFunder]) => {
      return {
        ...acc,
        [key]: Object.entries(eligibleFunder).reduce(
          (accEligibleFunder, [keyEligibleFunders, valueElibileFunder]) => ({
            ...accEligibleFunder,
            [keyEligibleFunders]: transformEligibleFundersMonitoringValues(
              keyEligibleFunders,
              valueElibileFunder,
            ),
          }),
          {},
        ),
      };
    },
    {},
  ),
});

function getBlocG(folderId) {
  function request() {
    return { type: blocGConstants.GET_BLOCG_REQUEST };
  }
  function success(blocG) {
    return { type: blocGConstants.GET_BLOCG_SUCCESS, blocG };
  }
  function failure(error) {
    return { type: blocGConstants.GET_BLOCG_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return blocAPI
      .getBloc(folderId, 'bloc_g_funding_validation')
      .then(({ blocGFundingValidation: blocG }) => {
        dispatch(success(transformApiPayloadToFormValues(blocG)));
        dispatch(foldersActions.getFolderFromBloc(blocG.folder || {}));
      }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function updateBlocG(folderId, updatedBlocG) {
  function request(blocG) {
    return { type: blocGConstants.UPDATE_BLOCG_REQUEST, blocG };
  }
  function success(blocG) {
    return { type: blocGConstants.UPDATE_BLOCG_SUCCESS, blocG };
  }
  function failure(error) {
    return { type: blocGConstants.UPDATE_BLOCG_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedBlocG));

    return blocAPI
      .updateBloc(folderId, 'bloc_g_funding_validation', {
        ...updatedBlocG,
        fundingPlanSentAt: getDateString(updatedBlocG.fundingPlanSentAt),
        ownerAuthorizationRequestSentAt: getDateString(
          updatedBlocG.ownerAuthorizationRequestSentAt,
        ),
        ownerAuthorizationReceivedAt: getDateString(updatedBlocG.ownerAuthorizationReceivedAt),
        hasOwnerAuthorizedWorks: getBooleanFromString(updatedBlocG.hasOwnerAuthorizedWorks),
        financialInvestigationEndDate: getDateString(updatedBlocG.financialInvestigationEndDate),
        eligibleFundersMonitoring: Object.entries(updatedBlocG.eligibleFundersMonitoring).reduce(
          (acc, [key, eligibleFunder]) => {
            return {
              ...acc,
              [key]: Object.entries(eligibleFunder).reduce(
                (accEligibleFunder, [keyEligibleFunders, valueElibileFunder]) => ({
                  ...accEligibleFunder,
                  [keyEligibleFunders]: transformEligibleFundersMonitoringValues(
                    keyEligibleFunders,
                    valueElibileFunder,
                    'update',
                  ),
                }),
                {},
              ),
            };
          },
          {},
        ),
      })
      .then(({ blocGFundingValidation: blocG }) => {
        dispatch(foldersActions.getFolderFromBloc(blocG.folder || {}));
        dispatch(success(transformApiPayloadToFormValues(blocG)));
        dispatch(toastActions.success(TOAST_MESSAGE.success.update));
      }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

// Selectors

const getBlocGById = (state, props) => state.blocs.blocG[props.match.params.folderId];

export const blocGSelectors = {
  getBlocGById,
};

export const blocGActions = {
  getBlocG,
  updateBlocG,
};
