import React from 'react';
import { Stack, Text, Box, Flex, Icon } from '@chakra-ui/core';
import {
  TabTableWrap,
  TabTHeadRow,
  TabTH,
  TabTBodyRow,
  TabTD,
  TabTBodyTH,
  TabTDNumber,
} from '../../../../common/components/Tabs';
import { Thead, Table } from '../../../../common/components/Table';
import { Section, SectionTitle, SubSectionTitle } from '../../../../common/components/Sections';
import { Notice, NoticeList, NoticeText } from '../../../../common/components/Notice';
import { FUNDERS } from '../BlocG/BlocGTab1';

export const BlocITab2 = ({
  blocI: {
    folder,
    fundersEligibilityEvolution,
    amountCoveredByFundersEvolution,
    phases,
    ignoredFunders,
  },
}) => {
  if (!folder) {
    return null;
  }

  return (
    <Container>
      <Stack spacing="3.5rem">
        {fundersEligibilityEvolution?.length > 0 ? (
          <Section>
            <SectionTitle>Evolution de l'égibilité aux financeurs</SectionTitle>
            {ignoredFunders?.length ? (
              <Notice variant="info" spacing="1rem">
                <NoticeText variant="info">
                  L'assistance ne tiendra pas compte des financeurs suivants :
                </NoticeText>
                <NoticeList variant="info" mx="auto" mt={1} fontWeight="thin">
                  {ignoredFunders?.map(
                    funder => FUNDERS[funder] && <li key={funder}>{FUNDERS[funder].name}</li>,
                  )}
                </NoticeList>
              </Notice>
            ) : null}
            <TabTableWrap mt="2.375rem" px="1.25rem">
              <Table>
                <Thead>
                  <TabTHeadRow>
                    <TabTBodyTH borderBottomColor="coral.900" />
                    <TabTH hasBorderRight>Ouverture</TabTH>
                    <TabTH hasBorderRight>Vérification des pièces</TabTH>
                    <TabTH hasBorderRight>Préconisations</TabTH>
                    <TabTH hasBorderRight>Type d'entreprises</TabTH>
                    <TabTH>Montant des travaux</TabTH>
                  </TabTHeadRow>
                </Thead>
                <tbody>
                  {fundersEligibilityEvolution &&
                    fundersEligibilityEvolution.map(funderEligibilityEvolution => (
                      <TabTBodyRow key={funderEligibilityEvolution.name}>
                        <TabTBodyTH w="180px">{funderEligibilityEvolution.name}</TabTBodyTH>
                        <TabTD hasBorderRight>
                          {funderEligibilityEvolution.opening ? (
                            funderEligibilityEvolution.opening
                          ) : (
                            <Icon name="close" color="coral.900" />
                          )}
                        </TabTD>
                        <TabTD hasBorderRight>
                          {funderEligibilityEvolution.documentsVerification ? (
                            funderEligibilityEvolution.documentsVerification
                          ) : (
                            <Icon name="close" color="coral.900" />
                          )}
                        </TabTD>
                        <TabTD hasBorderRight>
                          {funderEligibilityEvolution.recommandations ? (
                            funderEligibilityEvolution.recommandations
                          ) : (
                            <Icon name="close" color="coral.900" />
                          )}
                        </TabTD>
                        <TabTD hasBorderRight>
                          {funderEligibilityEvolution.companiesTypes ? (
                            funderEligibilityEvolution.companiesTypes
                          ) : (
                            <Icon name="close" color="coral.900" />
                          )}
                        </TabTD>
                        <TabTD>
                          {funderEligibilityEvolution.worksPricing ? (
                            funderEligibilityEvolution.worksPricing
                          ) : (
                            <Icon name="close" color="coral.900" />
                          )}
                        </TabTD>
                      </TabTBodyRow>
                    ))}
                </tbody>
              </Table>
            </TabTableWrap>
          </Section>
        ) : null}

        {amountCoveredByFundersEvolution?.length > 0 ? (
          <Section textAlign="center">
            <SectionTitle>Evolution de la prise en charge financière de l'AMO</SectionTitle>
            <Text fontWeight={600} color="semiDarkGray.600" mb={1}>
              Si la demande vient d'un client professionnel :
            </Text>
            <Text fontWeight="light">
              Vérifier auprès de la direction commerciale l'évolutivité de la prise en charge
              financière de l'AMO.
            </Text>

            <Text fontWeight={600} color="semiDarkGray.600" mb={1} mt={10}>
              Si la demande vient d'un client particulier :
            </Text>
            <Text fontWeight="light">Se fier au tableau suivant</Text>

            <TabTableWrap mt="2.375rem" px="1.25rem">
              <Table>
                <Thead>
                  <TabTHeadRow>
                    <TabTBodyTH borderBottomColor="coral.900" />
                    <TabTH hasBorderRight>Ouverture</TabTH>
                    <TabTH hasBorderRight>Vérification des pièces</TabTH>
                    <TabTH hasBorderRight>Préconisations</TabTH>
                    <TabTH hasBorderRight>Type d'entreprises</TabTH>
                    <TabTH>Montant des travaux</TabTH>
                  </TabTHeadRow>
                </Thead>
                <tbody>
                  {amountCoveredByFundersEvolution &&
                    amountCoveredByFundersEvolution.map(amountCoveredByFunderEvolution => (
                      <TabTBodyRow w="180px" key={amountCoveredByFunderEvolution.name}>
                        <TabTBodyTH>{amountCoveredByFunderEvolution.name}</TabTBodyTH>
                        <TabTD hasBorderRight>
                          {amountCoveredByFunderEvolution.opening ? (
                            amountCoveredByFunderEvolution.opening
                          ) : (
                            <Icon name="close" color="coral.900" />
                          )}
                        </TabTD>
                        <TabTD hasBorderRight>
                          {amountCoveredByFunderEvolution.documentsVerification ? (
                            amountCoveredByFunderEvolution.documentsVerification
                          ) : (
                            <Icon name="close" color="coral.900" />
                          )}
                        </TabTD>
                        <TabTD hasBorderRight>
                          {amountCoveredByFunderEvolution.recommandations ? (
                            amountCoveredByFunderEvolution.recommandations
                          ) : (
                            <Icon name="close" color="coral.900" />
                          )}
                        </TabTD>
                        <TabTD hasBorderRight>
                          {amountCoveredByFunderEvolution.companiesTypes ? (
                            amountCoveredByFunderEvolution.companiesTypes
                          ) : (
                            <Icon name="close" color="coral.900" />
                          )}
                        </TabTD>
                        <TabTD>
                          {amountCoveredByFunderEvolution.worksPricing ? (
                            amountCoveredByFunderEvolution.worksPricing
                          ) : (
                            <Icon name="close" color="coral.900" />
                          )}
                        </TabTD>
                      </TabTBodyRow>
                    ))}
                </tbody>
              </Table>
            </TabTableWrap>
          </Section>
        ) : null}

        <Section>
          <SectionTitle mb="0">Délais</SectionTitle>
          <Text color="semiDarkGray.600" textAlign="center">
            (En jours)
          </Text>

          <Flex align="flex-star" justify="space-between" mt="2.5rem" wrap="wrap">
            {phases &&
              phases.map(phase => (
                <Section key={phase.name} flexBasis="45%" mb="4.5rem">
                  <SubSectionTitle>{phase.name}</SubSectionTitle>
                  <TabTableWrap mt="2.375rem" px="0.5rem">
                    <Table>
                      <tbody>
                        {phase.data.map(data => (
                          <TabTBodyRow key={data.label}>
                            <TabTBodyTH w="300px" borderRight="0" textAlign="left">
                              {data.label}
                            </TabTBodyTH>
                            <TabTDNumber>
                              {typeof data.days === 'number' ? data.days : '?'}
                            </TabTDNumber>
                          </TabTBodyRow>
                        ))}
                      </tbody>
                    </Table>
                  </TabTableWrap>
                </Section>
              ))}
          </Flex>
        </Section>
      </Stack>
    </Container>
  );
};

const Container = ({ children, ...props }) => (
  <Box maxW="900px" mx="auto" {...props}>
    {children}
  </Box>
);
