import { apiV1 } from './api';

const getCompanies = (page = 1, name = '', params) =>
  apiV1.get(`/companies?page=${page}&by_name=${name}`, { params }).then(({ data }) => data);

const getCompany = companyId => apiV1.get(`/companies/${companyId}`).then(({ data }) => data);

const createCompany = company =>
  apiV1
    .post(`/companies`, {
      company,
    })
    .then(({ data }) => data);

const updateCompany = (companyId, company) =>
  apiV1
    .put(`/companies/${companyId}`, {
      company,
    })
    .then(({ data }) => data);

const deleteCompany = companyId => apiV1.delete(`/companies/${companyId}`);

export const companiesAPI = {
  getCompanies,
  getCompany,
  createCompany,
  updateCompany,
  deleteCompany,
};
