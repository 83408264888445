import { blocHConstants } from './constants';
import { blocAPI } from '../../../common/api/blocs';
import {
  getDateString,
  getDateFromString,
  getStringFromBoolean,
  getBooleanFromString,
} from '../../../common/utils';
import { toastActions } from '../../toast/actions';
import { TOAST_MESSAGE } from '../../../common/components/Toasts/Toast';
import { foldersActions } from '../../folders/actions';
import { errorsActions } from '../../errors/actions';

export const transformApiPayloadToFormValues = ({
  projectLaunchInfosReceivedAt,
  projectEndFeedbackRequestSentAt,
  projectEndReceivedAt,
  paidInvoicesReceivedAt,
  projectEndReviewPlannedAt,
  isRecipientContacted,
  projectEndReviewDate,
  bfpTransmissionType,
  touchUp,
  ...blocH
}) => ({
  ...blocH,
  projectLaunchInfosReceivedAt: getDateFromString(projectLaunchInfosReceivedAt),
  projectEndFeedbackRequestSentAt: getDateFromString(projectEndFeedbackRequestSentAt),
  projectEndReceivedAt: getDateFromString(projectEndReceivedAt),
  paidInvoicesReceivedAt: getDateFromString(paidInvoicesReceivedAt),
  projectEndReviewPlannedAt: getDateFromString(projectEndReviewPlannedAt),
  isRecipientContacted: getStringFromBoolean(isRecipientContacted),
  projectEndReviewDate: getDateFromString(projectEndReviewDate),
  bfpTransmissionType: getStringFromBoolean(bfpTransmissionType),
  touchUp: getStringFromBoolean(touchUp),
});

function getBlocH(folderId) {
  function request() {
    return { type: blocHConstants.GET_BLOCH_REQUEST };
  }
  function success(blocH) {
    return { type: blocHConstants.GET_BLOCH_SUCCESS, blocH };
  }
  function failure(error) {
    return { type: blocHConstants.GET_BLOCH_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return blocAPI
      .getBloc(folderId, 'bloc_h_recommendations_execution')
      .then(({ blocHRecommendationsExecution: blocH }) => {
        dispatch(success(transformApiPayloadToFormValues(blocH)));
        dispatch(foldersActions.getFolderFromBloc(blocH.folder || {}));
      }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function updateBlocH(folderId, updatedBlocH) {
  function request(blocH) {
    return { type: blocHConstants.UPDATE_BLOCH_REQUEST, blocH };
  }
  function success(blocH) {
    return { type: blocHConstants.UPDATE_BLOCH_SUCCESS, blocH };
  }
  function failure(error) {
    return { type: blocHConstants.UPDATE_BLOCH_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedBlocH));

    return blocAPI
      .updateBloc(folderId, 'bloc_h_recommendations_execution', {
        ...updatedBlocH,
        projectLaunchInfosReceivedAt: getDateString(updatedBlocH.projectLaunchInfosReceivedAt),
        projectEndFeedbackRequestSentAt: getDateString(
          updatedBlocH.projectEndFeedbackRequestSentAt,
        ),
        projectEndReceivedAt: getDateString(updatedBlocH.projectEndReceivedAt),
        paidInvoicesReceivedAt: getDateString(updatedBlocH.paidInvoicesReceivedAt),
        projectEndReviewPlannedAt: getDateString(updatedBlocH.projectEndReviewPlannedAt),
        isRecipientContacted: getBooleanFromString(updatedBlocH.isRecipientContacted),
        projectEndReviewDate: getDateString(updatedBlocH.projectEndReviewDate),
        bfpTransmissionType: getBooleanFromString(updatedBlocH.bfpTransmissionType),
      })
      .then(({ blocHRecommendationsExecution: blocH }) => {
        dispatch(foldersActions.getFolderFromBloc(blocH.folder || {}));
        dispatch(success(transformApiPayloadToFormValues(blocH)));
        dispatch(toastActions.success(TOAST_MESSAGE.success.update));
      }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

// Selectors

const getBlocHById = (state, props) => state.blocs.blocH[props.match.params.folderId];

export const blocHSelectors = {
  getBlocHById,
};

export const blocHActions = {
  getBlocH,
  updateBlocH,
};
