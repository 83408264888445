export const blocIConstants = {
  GET_BLOCI_REQUEST: 'GET_BLOCI_REQUEST',
  GET_BLOCI_SUCCESS: 'GET_BLOCI_SUCCESS',
  GET_BLOCI_FAILURE: 'GET_BLOCI_FAILURE',

  UPDATE_BLOCI_REQUEST: 'UPDATE_BLOCI_REQUEST',
  UPDATE_BLOCI_SUCCESS: 'UPDATE_BLOCI_SUCCESS',
  UPDATE_BLOCI_FAILURE: 'UPDATE_BLOCI_FAILURE',

  UPDATE_BLOCI_COMMENTS: 'UPDATE_BLOCI_COMMENTS',
};
