import { apiV1 } from './api';

const getUsers = (page = 1, name = '', filters = {}) =>
  apiV1.get(`/users`, { params: { page, by_name: name, ...filters } }).then(({ data }) => data);

const getUser = id => apiV1.get(`/users/${id}`).then(({ data: { user } }) => user);

const createUser = user =>
  apiV1
    .post(`/users`, {
      user,
    })
    .then(({ data }) => data);

const updateUser = (id, user) => apiV1.put(`/users/${id}`, { user }).then(({ data }) => data.user);

const deleteUser = id => apiV1.delete(`/users/${id}`);

export const usersAPI = { getUsers, getUser, createUser, updateUser, deleteUser };
