import { connect } from 'react-redux';
import { RBArules } from './rules';

export const check = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

const Can = ({ user, ...props }) => {
  return check(RBArules, user.role, props.perform, { user, ...props.data })
    ? props.yes(props)
    : props.no(props);
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

const mapStateToProps = ({ authentication: { user, profile } }) => ({
  user: Object.keys(profile || {}).length === 0 ? user : profile,
});

export default connect(mapStateToProps)(Can);

export const checkPermissions = (...args) => {
  return check(RBArules, ...args);
};
