/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useClipboard, Flex, Icon, Text } from '@chakra-ui/core';

export const CopyToClipboard = ({ value, children, ...props }) => {
  const { hasCopied, onCopy } = useClipboard(value);
  return (
    <Flex
      onClick={onCopy}
      alignItems="center"
      css={{ '&:hover': { cursor: 'pointer', '.copy': { transform: 'translate(0)', opacity: 1 } } }}
      {...props}
    >
      {children}
      <Flex
        className="copy"
        alignItems="center"
        opacity="0"
        transform="translateX(-1rem)"
        transition="opacity 0.3s, transform 0.5s"
        ml={2}
      >
        {hasCopied ? (
          <Text fontWeight="light" fontSize="sm">
            <Icon name="check" size={3} color="green.900" mr={1} />
            Copié
          </Text>
        ) : (
          <Icon name="tasks" color="semiDarkGray.600" />
        )}
      </Flex>
    </Flex>
  );
};
