import { configConstants } from './constants';

export const initialState = {};

export function config(state = initialState, action) {
  switch (action.type) {
    case configConstants.GET_CONFIG_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case configConstants.GET_CONFIG_SUCCESS:
      return {
        ...state,
        ...action.config,
        pending: false,
      };
    case configConstants.GET_CONFIG_FAILURE:
    default:
      return state;
  }
}
