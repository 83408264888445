import { foldersConstants } from './constants';

export const initialState = {
  data: [],
  pending: false,
  folder: {},
};

export function folders(state = initialState, action) {
  switch (action.type) {
    case foldersConstants.GET_FOLDERS_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case foldersConstants.GET_FOLDERS_SUCCESS:
      return {
        ...state,
        data: action.data.folders,
        meta: action.data.meta,
        pending: false,
      };
    case foldersConstants.CREATE_FOLDER_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case foldersConstants.CREATE_FOLDER_SUCCESS:
      return initialState;
    case foldersConstants.CREATE_FOLDER_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case foldersConstants.GET_FOLDER_FROM_BLOC: {
      return {
        ...initialState,
        folder: { ...state.folder, ...action.folder },
      };
    }
    case foldersConstants.UPDATE_FOLDERS: {
      const folderToUpdateIndex = state.data.findIndex(folder => folder.id === action.folder.id);

      const data = [...state.data];

      data[folderToUpdateIndex] = { ...data[folderToUpdateIndex], ...action.folder };

      return {
        ...state,
        data,
      };
    }
    case foldersConstants.GET_FOLDERS_FAILURE:
    default:
      return state;
  }
}
