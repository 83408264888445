import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFolderPath } from '../../../../common/routes';
import { BlocFTabs } from './BlocFTabs';
import { blocFActions, blocFSelectors } from '../../../../redux/blocs/blocF/actions';
import { PageError404 } from '../../../../errors/components';

const BlocF = ({
  match: {
    params: { folderId },
  },
  getBlocF,
  updateBlocF,
  ...props
}) => {
  useEffect(() => {
    getBlocF(folderId);
  }, [folderId, getBlocF]);

  const onSubmit = values => updateBlocF(folderId, values);

  return (
    <Switch>
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', '')}`}
        exact
        render={() => <Redirect to={getFolderPath(props.blocId, folderId)} />}
      />
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', ':tabIndex')}`}
        render={routerProps => <BlocFTabs {...props} {...routerProps} onSubmit={onSubmit} />}
      />
      <Route component={PageError404} />
    </Switch>
  );
};

const mapStateToProps = (state, props) => {
  const blocF = blocFSelectors.getBlocFById(state, props) || {};
  return { blocF };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBlocF: blocFActions.getBlocF,
      updateBlocF: blocFActions.updateBlocF,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BlocF);
