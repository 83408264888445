import { apiV1 } from './api';

const getRecipients = (page = 1, name) =>
  apiV1.get(`/recipients?page=${page}&by_name=${name}`).then(({ data }) => data);

const getRecipient = recipientId =>
  apiV1.get(`/recipients/${recipientId}`).then(({ data }) => data);

const createRecipient = recipient =>
  apiV1
    .post(`/recipients`, {
      recipient,
    })
    .then(({ data }) => data);

const updateRecipient = (recipientId, recipient) =>
  apiV1
    .put(`/recipients/${recipientId}`, {
      recipient,
    })
    .then(({ data }) => data);

const deleteRecipient = recipientId => apiV1.delete(`/recipients/${recipientId}`);

const getRecipientHistory = recipientId => apiV1.get(`/recipients/${recipientId}/versions`);

export const recipientsAPI = {
  getRecipients,
  getRecipient,
  createRecipient,
  updateRecipient,
  deleteRecipient,
  getRecipientHistory,
};
