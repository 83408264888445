import React, { useEffect, useState } from 'react';
import { Stack } from '@chakra-ui/core';
import { useFormikContext } from 'formik';
import { FormSection } from '../Form/Form';
import { RadioGroup, RadioInput, TextInput } from '../Form/FormInputs';
import { DateInput } from '../Form/FormDateInput';
import { FormAddressGroup } from '../Form/FormAddressGroup';

export const RecipientFormFields = ({
  showRequired = true,
  pending,
  isReadOnly,
  isFolderCreation,
}) => {
  const formik = useFormikContext();

  return (
    <>
      <ChangeRecipientContactInfoValidationErrors />

      <Stack spacing={16}>
        <FormSection title="Bénéficiaire">
          <RadioGroup
            isInline
            name="title"
            requiredInput={showRequired}
            legend="Civilité"
            readOnly={isReadOnly}
          >
            <RadioInput value="Mme" disabled={pending}>
              Madame
            </RadioInput>
            <RadioInput value="M" disabled={pending}>
              Monsieur
            </RadioInput>
          </RadioGroup>
          <TextInput
            label="Nom"
            type="text"
            id="lastName"
            name="lastName"
            placeholder="Dupond"
            disabled={pending}
            requiredInput={showRequired}
            readOnly={isReadOnly}
          />
          <TextInput
            label="Prénom"
            type="text"
            id="firstName"
            name="firstName"
            placeholder="Jean"
            disabled={pending}
            requiredInput={showRequired}
            readOnly={isReadOnly}
          />

          <TextInput
            label="Numéro de sécurité sociale"
            type="text"
            id="socialSecurityNumber"
            name="socialSecurityNumber"
            placeholder="XXXXXXXXXXXXX(XX)"
            pattern="[0-9]+"
            disabled={pending}
            readOnly={isReadOnly}
          />

          <RadioGroup
            isInline
            name="recipientIsTheContact"
            legend="Le bénéficiaire est-il la personne à contacter ?"
            readOnly={isReadOnly}
          >
            <RadioInput value="true" disabled={pending}>
              Oui
            </RadioInput>
            <RadioInput value="false" disabled={pending}>
              Non
            </RadioInput>
          </RadioGroup>

          {formik.values.recipientIsTheContact === 'true' && (
            <>
              <RadioGroup
                isInline
                name="sendDocumentsBy"
                legend="Modalité d’envoi des documents"
                readOnly={isReadOnly}
              >
                <RadioInput value="post" disabled={pending}>
                  Courrier
                </RadioInput>
                <RadioInput value="email" disabled={pending}>
                  Email
                </RadioInput>
              </RadioGroup>
            </>
          )}

          <DateInput
            label="Date de naissance"
            id="birthDate"
            name="birthDate"
            disabled={pending}
            requiredInput={showRequired}
            readOnly={isReadOnly}
          />
          <TextInput
            label="Mail"
            type="email"
            id="email"
            name="email"
            placeholder="beneficiaire@gmail.com"
            disabled={pending}
            readOnly={isReadOnly}
            requiredInput={
              formik.values.sendDocumentsBy === 'email' &&
              formik.values.recipientIsTheContact === 'true' &&
              isFolderCreation &&
              showRequired
            }
          />
          <TextInput
            label="Téléphone"
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            w="16ch"
            placeholder="0602050842"
            pattern="0[1-9][0-9]{8}"
            disabled={pending}
            readOnly={isReadOnly}
            requiredInput={formik.values.recipientIsTheContact === 'true' && showRequired}
          />

          <FormAddressGroup
            addressName="streetAddress"
            postalCodeName="postalCode"
            cityName="city"
            disabled={pending}
            isReadOnly={isReadOnly}
            isRequired={isFolderCreation}
          />
        </FormSection>

        {/* Displays only when recipient is not the contact */}
        {formik.values.recipientIsTheContact === 'false' && (
          <FormSection title="Personne à contacter">
            <RadioGroup
              isInline
              name="contactTitle"
              requiredInput={showRequired}
              legend="Civilité"
              readOnly={isReadOnly}
            >
              <RadioInput value="Mme" disabled={pending}>
                Madame
              </RadioInput>
              <RadioInput value="M" disabled={pending}>
                Monsieur
              </RadioInput>
            </RadioGroup>
            <TextInput
              label="Nom"
              type="text"
              id="contactLastName"
              name="contactLastName"
              placeholder="Dupond"
              disabled={pending}
              requiredInput={showRequired}
              readOnly={isReadOnly}
            />
            <TextInput
              label="Prénom"
              type="text"
              id="contactFirstName"
              name="contactFirstName"
              placeholder="Jean"
              disabled={pending}
              requiredInput={showRequired}
              readOnly={isReadOnly}
            />

            <RadioGroup
              isInline
              name="sendDocumentsBy"
              legend="Modalité d’envoi des documents"
              readOnly={isReadOnly}
            >
              <RadioInput value="post" disabled={pending}>
                Courrier
              </RadioInput>
              <RadioInput value="email" disabled={pending}>
                Email
              </RadioInput>
            </RadioGroup>

            <TextInput
              label="Mail"
              type="email"
              id="contactEmail"
              name="contactEmail"
              placeholder="beneficiaire@gmail.com"
              disabled={pending}
              readOnly={isReadOnly}
              requiredInput={
                formik.values.sendDocumentsBy === 'email' && isFolderCreation && showRequired
              }
            />
            <TextInput
              label="Téléphone"
              type="tel"
              id="contactPhoneNumber"
              name="contactPhoneNumber"
              w="16ch"
              placeholder="0602050842"
              pattern="0[1-9][0-9]{8}"
              disabled={pending}
              readOnly={isReadOnly}
              requiredInput={showRequired}
            />
            <FormAddressGroup
              addressName="contactStreetAddress"
              postalCodeName="contactPostalCode"
              cityName="contactCity"
              disabled={pending}
              readOnly={isReadOnly}
              isRequired={
                formik.values.sendDocumentsBy === 'post' && isFolderCreation && showRequired
              }
            />
          </FormSection>
        )}
      </Stack>
    </>
  );
};

const ChangeRecipientContactInfoValidationErrors = () => {
  const { values, errors, setErrors } = useFormikContext();
  const [recipientIsTheContact] = useState(values.recipientIsTheContact);

  useEffect(() => {
    if (
      values.recipientIsTheContact === 'false' &&
      values.recipientIsTheContact !== recipientIsTheContact
    ) {
      // When recipientIsTheContact is set to false, reset phoneNumber, email and address errors
      setErrors({
        ...errors,
        email: null,
        phoneNumber: null,
        streetAddress: null,
        postalCode: null,
        city: null,
      });
    }
  }, [values.recipientIsTheContact, errors, setErrors, recipientIsTheContact]);

  return null;
};
