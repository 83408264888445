import { parse, differenceInYears } from 'date-fns';
import { blocFioConstants } from './constants';
import { blocAPI } from '../../../common/api/blocs';
import { toastActions } from '../../toast/actions';
import { TOAST_MESSAGE } from '../../../common/components/Toasts/Toast';
import { foldersActions } from '../../folders/actions';
import { errorsActions } from '../../errors/actions';

const transformAnswer = (question, answer) => {
  if (question === 'q_2') {
    return answer ? 'A' : 'B';
  }
  if (question === 'q_1') {
    return differenceInYears(new Date(), parse(answer, 'dd/MM/yyyy', new Date()));
  }
  if (question === 'q5') {
    return answer.length ? answer : ['', '', '', ''];
  }
  return answer;
};

export const transformApiPayloadToFormValues = questions => ({
  questions: Object.entries(questions),
  questionsValues: Object.entries(questions).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: transformAnswer(key, value.answers),
    }),
    {},
  ),
});

function getBlocFio(folderId) {
  function request() {
    return { type: blocFioConstants.GET_BLOCFIO_REQUEST };
  }
  function success(blocFio) {
    return { type: blocFioConstants.GET_BLOCFIO_SUCCESS, blocFio };
  }
  function failure(error) {
    return { type: blocFioConstants.GET_BLOCFIO_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return blocAPI.getBloc(folderId, 'bloc_fio').then(({ blocFio: { questions, ...blocFio } }) => {
      dispatch(success({ ...transformApiPayloadToFormValues(questions), ...blocFio }));
      if (blocFio.folder) {
        dispatch(foldersActions.getFolderFromBloc(blocFio.folder || {}));
      }
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function updateBlocFio(folderId, updatedBlocFio) {
  function request(questionsValues) {
    return { type: blocFioConstants.UPDATE_BLOCFIO_REQUEST, questionsValues };
  }
  function success(blocFio) {
    return { type: blocFioConstants.UPDATE_BLOCFIO_SUCCESS, blocFio };
  }
  function failure(error) {
    return { type: blocFioConstants.UPDATE_BLOCFIO_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedBlocFio));

    return blocAPI
      .updateBloc(
        folderId,
        'bloc_fio',
        Object.entries(updatedBlocFio).reduce((acc, [key, value]) => {
          if (key === 'q5') {
            return {
              ...acc,
              [`${key}_answer`]: value.length ? value.map(answer => answer || 'NSP') : value,
            };
          }
          return {
            ...acc,
            [`${key}_answer`]: value,
          };
        }, {}),
      )
      .then(
        ({ blocFio: { questions, ...blocFio } }) => {
          dispatch(foldersActions.getFolderFromBloc(blocFio.folder || {}));
          dispatch(success({ ...transformApiPayloadToFormValues(questions), ...blocFio }));
          dispatch(toastActions.success(TOAST_MESSAGE.success.update));
        },
        apiError => {
          const {
            response: { data: error },
          } = apiError;

          dispatch(
            errorsActions.handleErrors(
              failure,
              error.errors?.folder[0] === "L'état du dossier ne permet pas la sauvegarde."
                ? toastActions.error(TOAST_MESSAGE.error.folderClosed)
                : toastActions.error(TOAST_MESSAGE.error.update),
            ),
          ).call(null, apiError);
        },
      );
  };
}

export const blocFioActions = {
  getBlocFio,
  updateBlocFio,
};
