import { blocAConstants } from './constants';

export const initialState = {
  pending: false,
};

export function blocA(state = initialState, action) {
  switch (action.type) {
    case blocAConstants.GET_BLOCA_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case blocAConstants.GET_BLOCA_SUCCESS:
      return {
        ...state,
        [action.blocA.folderId]: action.blocA,
        pending: false,
      };
    case blocAConstants.UPDATE_BLOCA_REQUEST:
      return {
        ...state,
        [action.blocA.folderId]: action.blocA,
        error: null,
        pending: true,
      };
    case blocAConstants.UPDATE_BLOCA_SUCCESS:
      return {
        ...state,
        [action.blocA.folderId]: action.blocA,
        error: null,
        pending: false,
      };
    case blocAConstants.UPDATE_BLOCA_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case blocAConstants.GET_BLOCA_FAILURE:
    default:
      return state;
  }
}
