import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { authActions } from '../redux/auth/actions';
import { UserForm } from '../common/components/UserForm/UserForm';
import { PageDefault } from '../common/components/layouts/Page/PageDefault';

const Profile = ({ profile, userId, error, pending, getProfile, updateProfile }) => {
  const [user, setUser] = useState(profile);

  useEffect(() => {
    getProfile(userId);
  }, [getProfile, userId]);

  useEffect(() => {
    setUser(profile);
  }, [pending, profile]);

  const onSubmit = updatedUser => {
    updateProfile(userId, updatedUser);
  };

  return (
    <PageDefault title="Profil">
      <UserForm
        initialValues={{ ...user, password: '', passwordConfirmation: '' }}
        pending={pending}
        onSubmit={onSubmit}
        error={error}
        mode="edit"
      />
    </PageDefault>
  );
};

const mapStateToProps = ({ authentication: state }) => ({
  error: state.error,
  pending: state.pending,
  profile: state.profile,
  userId: state.user.id,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProfile: authActions.getProfile,
      updateProfile: authActions.updateProfile,
    },
    dispatch,
  );

Profile.propTypes = {
  pending: PropTypes.bool,
  profile: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
};

Profile.defaultProps = {
  pending: true,
  profile: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
