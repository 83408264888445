import { authConstants } from './constants';

const user = JSON.parse(localStorage.getItem('user'));
export const initialState = user
  ? { loggedIn: true, user, profile: {} }
  : { loggedIn: false, user: {}, profile: {} };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        ...(action.reattempting
          ? { ...state, reattempting: true }
          : { loggedIn: false, user: {}, profile: {} }),
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        sendingSMS: true,
        auth: action.auth,
        ...(state.reattempting
          ? { ...state, reattempting: false, loggingIn: false }
          : { loggedIn: false, user: {}, profile: {} }),
      };
    case authConstants.LOGIN_FAILURE:
      return {
        error: action.error || true,
        ...(action.reattempting ? state : { loggedIn: false, user: {}, profile: {} }),
        loggingIn: false,
      };
    case authConstants.LOGIN_OTP_REQUEST:
      return {
        ...state,
        auth: { ...state.auth, otpAttempt: action.otpAttempt },
        loggingIn: true,
        loggedIn: false,
        user: {},
        profile: {},
      };
    case authConstants.LOGIN_OTP_SUCCESS:
      return {
        loggedIn: true,
        user: action.user,
        profile: {},
      };
    case authConstants.LOGIN_OTP_FAILURE:
      return {
        ...state,
        loggingIn: false,
        auth: { ...state.auth, otpAttempt: null },
        error: action.error,
      };

    case authConstants.LOGOUT:
      return { loggedIn: false, user: {}, profile: {} };
    case authConstants.GET_PROFILE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case authConstants.GET_PROFILE_SUCCESS:
      return {
        ...state,
        pending: false,
        profile: {
          ...action.profile,
        },
      };
    case authConstants.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        pending: true,
        profile: {
          ...action.profile,
        },
      };
    case authConstants.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.profile,
        },
        pending: false,
        profile: {
          ...action.profile,
        },
      };
    case authConstants.UPDATE_PROFILE_FAILURE:
      return {
        ...state,
        pending: false,
        error: action.error,
        profile: {
          ...state.profile,
        },
      };
    case authConstants.GET_PROFILE_FAILURE:
    default:
      return state;
  }
}
