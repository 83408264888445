import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFolderPath } from '../../../../common/routes';
import { BlocATabs } from './BlocATabs';
import { operatorsActions } from '../../../../redux/operators/actions';
import { blocAActions, blocASelectors } from '../../../../redux/blocs/blocA/actions';
import { PageError404 } from '../../../../errors/components';
import { companiesActions } from '../../../../redux/companies/actions';
import { isExtern } from '../../../../roleBasedAccess/Is';

const BlocA = ({
  match: {
    params: { folderId },
  },
  getOperators,
  getCompanies,
  getBlocA,
  updateBlocA,
  ...props
}) => {
  useEffect(() => {
    getBlocA(folderId);
    if (props.user && !isExtern({ user: props.user }))
      if (!props.user?.role?.match(/(référent externe|pilote externe)/)) {
        getOperators(0, '', { perPage: 1000, byRoles: ['ergothérapeute'] });
        getCompanies(0, '', { perPage: 1000 });
      }
  }, [getOperators, getCompanies, getBlocA, folderId, props.user]);

  const onSubmit = values => updateBlocA(folderId, values);

  return (
    <Switch>
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', '')}`}
        exact
        render={() => <Redirect to={getFolderPath(props.blocId, folderId)} />}
      />
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', ':tabIndex')}`}
        exact
        render={routerProps => <BlocATabs {...props} {...routerProps} onSubmit={onSubmit} />}
      />
      <Route component={PageError404} />
    </Switch>
  );
};

const mapStateToProps = (
  {
    operators: { data: operators },
    companies: { data: companies },
    authentication: { user },
    folders: { folder },
    ...state
  },
  props,
) => {
  const blocA = blocASelectors.getBlocAById(state, props) || {};
  return {
    blocA,
    companies,
    operators,
    user,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOperators: operatorsActions.getOperators,
      getCompanies: companiesActions.getCompanies,
      getBlocA: blocAActions.getBlocA,
      updateBlocA: blocAActions.updateBlocA,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BlocA);
