import { history } from '../../common/helpers/history';
import { authConstants } from './constants';
import { authAPI } from '../../common/api/auth';
import { usersAPI } from '../../common/api/users';
import routes from '../../common/routes';
import { toastActions } from '../toast/actions';
import { TOAST_MESSAGE } from '../../common/components/Toasts/Toast';
import { errorsActions } from '../errors/actions';

function login(username, password, otpAttempt, reattempting) {
  function request(isReattempting) {
    return { type: authConstants.LOGIN_REQUEST, reattempting: isReattempting };
  }
  function success(auth) {
    return { type: authConstants.LOGIN_SUCCESS, auth };
  }
  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error };
  }
  function requestOTP(newOtpAttempt) {
    return { type: authConstants.LOGIN_OTP_REQUEST, otpAttempt: newOtpAttempt };
  }
  function successOTP(user) {
    return { type: authConstants.LOGIN_OTP_SUCCESS, user };
  }
  function failureOTP(error) {
    return { type: authConstants.LOGIN_OTP_FAILURE, error };
  }

  return async dispatch => {
    try {
      const {
        data: { otpRequiredForLogin },
      } = await authAPI.isOtpRequired(username);

      if (otpRequiredForLogin && process.env.REACT_APP_TWO_FACTOR_AUTH_ENABLED === 'true') {
        if (otpAttempt) {
          dispatch(requestOTP(otpAttempt));
          return authAPI.loginOTP(username, password, otpAttempt).then(user => {
            dispatch(successOTP(user));
            localStorage.setItem('user', JSON.stringify(user));
            history.push(routes.home);
          }, dispatch(errorsActions.handleErrors(failureOTP)));
        }

        dispatch(request(reattempting));
        return authAPI
          .login(username, password)
          .then(
            () => dispatch(success({ username, password })),
            dispatch(errorsActions.handleErrors(failure)),
          );
      }

      dispatch(requestOTP());

      return authAPI.loginOTP(username, password).then(user => {
        dispatch(successOTP(user));
        localStorage.setItem('user', JSON.stringify(user));
        history.push(routes.home);
      }, dispatch(errorsActions.handleErrors(failure)));
    } catch (e) {
      return dispatch(errorsActions.handleErrors(failure));
    }
  };
}

function logout() {
  authAPI.logout();
  localStorage.removeItem('user');
  return { type: authConstants.LOGOUT };
}

function getProfile(userId) {
  function request() {
    return { type: authConstants.GET_PROFILE_REQUEST };
  }
  function success(profile) {
    return { type: authConstants.GET_PROFILE_SUCCESS, profile };
  }
  function failure(error) {
    return { type: authConstants.GET_PROFILE_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    // const currentUserId = store.getState().authentication.user.id;
    // console.log({ currentUserId });

    return usersAPI.getUser(userId).then(user => {
      dispatch(success(user));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function updateProfile(userId, userProfile) {
  function request(profile) {
    return { type: authConstants.UPDATE_PROFILE_REQUEST, profile };
  }
  function success(profile) {
    return { type: authConstants.UPDATE_PROFILE_SUCCESS, profile };
  }
  function failure(error) {
    return { type: authConstants.UPDATE_PROFILE_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(userProfile));

    return usersAPI.updateUser(userId, userProfile).then(user => {
      dispatch(success(user));
      const { phoneNumber, ...userInfo } = user;
      localStorage.setItem('user', JSON.stringify(userInfo));
      dispatch(toastActions.success(TOAST_MESSAGE.success.update));
      if (userProfile.password !== undefined) {
        dispatch(logout());
      }
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

export const authActions = {
  login,
  logout,
  getProfile,
  updateProfile,
};
