import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFolderPath } from '../../../../common/routes';
import { Bloc0Tabs } from './Bloc0Tabs';
import { operatorsActions } from '../../../../redux/operators/actions';
import { bloc0Actions } from '../../../../redux/blocs/bloc0/actions';
import { PageError404 } from '../../../../errors/components';

const Bloc0 = ({
  match: {
    params: { folderId },
  },
  getOperators,
  getBloc0,
  updateBloc0,
  ...props
}) => {
  useEffect(() => {
    getBloc0(folderId);
    if (!props.user?.role?.match(/(référent externe|pilote externe)/)) {
      getOperators(0, '', { perPage: 1000, byRoles: ['conseiller social'] });
    }
  }, [getOperators, folderId, getBloc0, props.user]);

  const onSubmit = values => updateBloc0(folderId, values);

  return (
    <Switch>
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', '')}`}
        exact
        render={() => <Redirect to={getFolderPath(props.blocId, folderId)} />}
      />
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', ':tabIndex')}`}
        exact
        render={routerProps => <Bloc0Tabs {...props} {...routerProps} onSubmit={onSubmit} />}
      />
      <Route component={PageError404} />
    </Switch>
  );
};

const mapStateToProps = ({
  operators: { data: operators },
  blocs: { bloc0 },
  authentication: { user },
}) => {
  return {
    bloc0,
    operators,
    user,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOperators: operatorsActions.getOperators,
      getBloc0: bloc0Actions.getBloc0,
      updateBloc0: bloc0Actions.updateBloc0,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Bloc0);
