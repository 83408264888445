import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageDefault } from '../common/components/layouts/Page/PageDefault';
import { ErgoResourceForm } from '../common/components/ErgoResourceForm/ErgoResourceForm';
import { ergoResourcesActions } from '../redux/ergoResources/actions';

const ErgoResourceCreate = ({ error, pending, createErgoResource }) => {
  const onSubmit = newErgoResourceValues => {
    createErgoResource(newErgoResourceValues);
  };

  return (
    <PageDefault title="Création de la fiche ressource méthodologique">
      <ErgoResourceForm
        initialValues={{ name: null, link: null }}
        pending={pending}
        onSubmit={onSubmit}
        error={error}
        mode="create"
      />
    </PageDefault>
  );
};

const mapStateToProps = ({ ergoResources: state }) => ({
  error: state.error,
  pending: state.pending,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createErgoResource: ergoResourcesActions.createErgoResource,
    },
    dispatch,
  );

ErgoResourceCreate.propTypes = {
  pending: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  createErgoResource: PropTypes.func.isRequired,
};

ErgoResourceCreate.defaultProps = {
  pending: false,
  error: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ErgoResourceCreate);
