import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageDefault } from '../common/components/layouts/Page/PageDefault';
import { CompanyForm } from '../common/components/CompanyForm/CompanyForm';
import { companiesActions } from '../redux/companies/actions';

const CompanyCreate = ({ error, pending, createCompany, departments }) => {
  const onSubmit = newCompanyValues => {
    createCompany(newCompanyValues);
  };

  return (
    <PageDefault title="Création de la fiche entreprise">
      <CompanyForm
        departments={departments}
        initialValues={{
          certification: 'false',
          hasAccessToProjectPlace: 'false',
          hasNotifications: 'false',
          departments: [],
        }}
        pending={pending}
        onSubmit={onSubmit}
        error={error}
        mode="create"
      />
    </PageDefault>
  );
};

const mapStateToProps = ({ companies: state }) => ({
  error: state.error,
  pending: state.pending,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createCompany: companiesActions.createCompany,
    },
    dispatch,
  );

CompanyCreate.propTypes = {
  pending: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  createCompany: PropTypes.func.isRequired,
};

CompanyCreate.defaultProps = {
  pending: false,
  error: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCreate);
