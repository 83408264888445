import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Stack, Box, Heading, Text, Spinner, Link } from '@chakra-ui/core';
import { Section, SectionTitle } from '../../../../common/components/Sections';

import {
  TabTableWrap,
  TabTHeadRow,
  TabTH,
  TabTBodyRow,
  TabTD,
  TabTBodyTH,
} from '../../../../common/components/Tabs';
import { Notice, NoticeList, NoticeText } from '../../../../common/components/Notice';
import { Thead, Table } from '../../../../common/components/Table';
import { FormCTAButton } from '../../../../common/components/Form/Form';
import { getFolderPath } from '../../../../common/routes';
import { history } from '../../../../common/helpers/history';
import { PrimaryButton } from '../../../../common/components/Buttons';

export const BlocFioTab2 = ({ blocFio: { availableFunders, questionsValues, folderId } }) => {
  if (!availableFunders) {
    return (
      <Container d="flex" alignItems="center" justifyContent="center" mt={20}>
        {availableFunders === undefined ? (
          <Spinner label="Chargement..." size="xl" color="coral.900" />
        ) : (
          <Text>Aucune synthèse disponible</Text>
        )}
      </Container>
    );
  }
  return (
    <Container>
      <Stack spacing="3.125rem">
        <Section>
          <SectionTitle>Financeurs éligibles</SectionTitle>
          <Notice variant="info">
            <NoticeText variant="info">
              Cette éligibilité est indicative. Elle est mentionnée sous réserve :
            </NoticeText>
            <NoticeList variant="info">
              <li>Des caractéristiques précises des futurs aménagements ou équipements</li>
              <li>Des caractéristiques des entreprises choisies pour l’exécution du projet</li>
              <li>
                De la transmission de l’autorisation formelle du propriétaire du logement à
                effectuer des travaux (le cas échéant et pour les locataires ou occupants à titre
                gratuit uniquement)
              </li>
              <li>De l’exactitude des réponses apportées lors du diagnostic socio-administratif</li>
            </NoticeList>
          </Notice>

          {availableFunders && Object.keys(availableFunders).length > 0 ? (
            <React.Fragment>
              <TabTableWrap mt="2.375rem">
                <Table>
                  <Thead>
                    <TabTHeadRow>
                      <TabTH>Financeur testé</TabTH>
                      <TabTH>Résultats</TabTH>
                    </TabTHeadRow>
                  </Thead>
                  <tbody>
                    {availableFunders &&
                      availableFunders.map(funder => (
                        <TabTBodyRow key={funder.name}>
                          <TabTBodyTH textAlign="center" borderRight="0">
                            {funder.name}
                          </TabTBodyTH>
                          <TabTD>{funder.option}</TabTD>
                        </TabTBodyRow>
                      ))}
                  </tbody>
                </Table>
              </TabTableWrap>
              <Stack spacing={8} mt="2.375rem" px="4.375rem" lineHeight="1.3">
                {availableFunders &&
                  availableFunders.map(funder => (
                    <Box key={funder.name}>
                      <FunderTitle>{funder.name}</FunderTitle>
                      <FunderDescription>{funder.description}</FunderDescription>
                    </Box>
                  ))}
              </Stack>
            </React.Fragment>
          ) : (
            <Text color="semiDarkGray.900" textAlign="center" fontWeight="600" mt={5}>
              Aucun financeur n'est éligible pour ce dossier.
            </Text>
          )}
        </Section>

        <Section>
          {questionsValues && questionsValues.q5.includes('G') ? (
            <Box textAlign="center" mt="3.56rem">
              <FormCTAButton onClick={() => history.push(getFolderPath('bloc_i', folderId))}>
                Clôture pour aboutissement
              </FormCTAButton>
            </Box>
          ) : (
            <React.Fragment>
              <Box textAlign="center" mt="3.56rem">
                <PrimaryButton
                  as={Link}
                  mr="1rem"
                  href={`${process.env.REACT_APP_API_URL}/api/v1/folders/${folderId}/bloc_fio/export`}
                  target="_blank"
                  css={{ ':hover': { textDecoration: 'none' } }}
                >
                  Générer un compte rendu des réponses
                </PrimaryButton>
              </Box>
              <Box textAlign="center" mt="3.56rem">
                <FormCTAButton onClick={() => history.push(getFolderPath('bloc_0', folderId, 2))}>
                  Envoi des documents initiaux
                </FormCTAButton>
              </Box>
            </React.Fragment>
          )}
        </Section>
      </Stack>
    </Container>
  );
};

const Container = ({ children, ...props }) => (
  <Box maxW="840px" mx="auto" {...props}>
    {children}
  </Box>
);

const FunderTitle = ({ children, ...props }) => (
  <Heading
    as="h3"
    color="gray"
    fontWeight={600}
    fontSize="md"
    textAlign="center"
    mb="0.875rem"
    {...props}
  >
    {children}
  </Heading>
);

const FunderDescription = ({ children, ...props }) => (
  <Box
    spacing={7}
    fontWeight={300}
    color="semiDarkGray.900"
    css={{ strong: { fontWeight: 600 }, '> * + *': { marginTop: '1.69rem' } }}
    dangerouslySetInnerHTML={{ __html: children }}
    {...props}
  />
);
