import React from 'react';
import { Route, Switch } from 'react-router';

import { RecipientsIndex } from './RecipientsIndex';
import RecipientCreate from './RecipientCreate';
import RecipientEdit from './RecipientEdit';
import { PageError404 } from '../errors/components';

export const Recipients = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/new`} exact component={RecipientCreate} />
      <Route path={match.url} exact component={RecipientsIndex} />
      <Route path={`${match.url}/:recipientId/edit`} exact component={RecipientEdit} />
      <Route component={PageError404} />
    </Switch>
  );
};
