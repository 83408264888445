import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Stack, Box } from '@chakra-ui/core';

import { TabForm } from '../../../../common/components/Tabs';
import {
  FormSection,
  FormSubmitButton,
  FormCTAButton,
} from '../../../../common/components/Form/Form';
import { RadioGroup, RadioInput, SwitchInput } from '../../../../common/components/Form/FormInputs';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import { history } from '../../../../common/helpers/history';
import { getFolderPath } from '../../../../common/routes';

export const BlocDTab1 = ({ blocD: { error, pending, ...initialValues }, onSubmit }) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        return onSubmit({ ...initialValues, ...values }).then(() => {
          setTouched({});
          setSubmitting(false);
        });
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit}>
          <Stack spacing="3.5rem">
            <FormSection title="Réponse du bénéficiaire">
              <SwitchInput
                label="Validation du 2DPE"
                name="isRecipient_2dpeValidationReceived"
                id="isRecipient_2dpeValidationReceived"
              />
              <RadioGroup isInline name="clientFeedback" mb="1.5rem" mt="1.5rem">
                <RadioInput value="form_returned">Retour de formulaire</RadioInput>
                <RadioInput value="expired">Délai d’attente expiré</RadioInput>
              </RadioGroup>
              <DateInput
                label="Date de validation du 2DPE"
                name="recipient_2dpeValidationReceivedAt"
                id="recipient_2dpeValidationReceivedAt"
                disabled={formik.values.isRecipient_2dpeValidationReceived === false}
              />
            </FormSection>

            <Box textAlign="center">
              <FormCTAButton
                disabled={formik.values.isRecipient_2dpeValidationReceived === true}
                onClick={() =>
                  formik.submitForm().then(() => {
                    history.push(getFolderPath('bloc_a', initialValues.folderId, 2));
                  })
                }
              >
                Mettre à jour le 2DPE
              </FormCTAButton>
            </Box>
          </Stack>

          <FormSubmitButton
            type="submit"
            disabled={pending}
            isLoading={formik.isSubmitting}
            loadingText="Enregistrer"
          >
            Enregistrer
          </FormSubmitButton>
        </TabForm>
      )}
    </Formik>
  );
};
