import React from 'react';
import { Tabs, Tab, TabPanels, TabPanel, TabList } from '../../../../common/components/Tabs';
import { BlocATab1 } from './BlocATab1';
import { BlocATab2 } from './BlocATab2';
import { BlocATab3 } from './BlocATab3';

export const BlocATabs = ({
  match: {
    params: { folderId, tabIndex },
  },
  blocId,
  ...props
}) => {
  return (
    <Tabs folderId={folderId} tabIndex={tabIndex} blocId={blocId}>
      <TabList>
        <Tab>Visite à domicile</Tab>
        <Tab>Fiche de synthèse</Tab>
        <Tab>Transmission du 2DPE</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <BlocATab1 {...props} />
        </TabPanel>
        <TabPanel>
          <BlocATab2 {...props} />
        </TabPanel>
        <TabPanel>
          <BlocATab3 {...props} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
