import { blocFConstants } from './constants';

export const initialState = {
  pending: false,
};

export function blocF(state = initialState, action) {
  switch (action.type) {
    case blocFConstants.GET_BLOCF_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case blocFConstants.GET_BLOCF_SUCCESS:
      return {
        ...state,
        [action.blocF.folderId]: action.blocF,
        pending: false,
      };
    case blocFConstants.UPDATE_BLOCF_REQUEST:
      return {
        ...state,
        [action.blocF.folderId]: action.blocF,
        error: null,
        pending: true,
      };
    case blocFConstants.UPDATE_BLOCF_SUCCESS:
      return {
        ...state,
        [action.blocF.folderId]: action.blocF,
        error: null,
        pending: false,
      };
    case blocFConstants.UPDATE_BLOCF_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case blocFConstants.GET_BLOCF_FAILURE:
    default:
      return state;
  }
}
