import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import { Box } from '@chakra-ui/core';
import { NavLink } from 'react-router-dom';

import { PageIndex } from '../common/components/layouts/Page/PageIndex';
import { SearchInput } from '../common/components/Form/FormInputs';
import { SecondaryButton, PrimaryButton } from '../common/components/Buttons';
import {
  Table,
  Thead,
  TH,
  TD,
  THeadRow,
  TBodyRow,
  TablePlaceholder,
  TableEditLink,
  TableResults,
} from '../common/components/Table';
import { recipientsActions } from '../redux/recipients/actions';
import { Pagination } from '../common/components/Pagination';

const ConnectedRecipientsIndex = ({ match, getRecipients, pending, recipients, meta }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getRecipients(currentPage, search);
  }, [getRecipients, currentPage, search]);

  return (
    <PageIndex pageAction={<Header match={match} meta={meta} onSearch={name => setSearch(name)} />}>
      <Table>
        <Thead>
          <THeadRow>
            <TH>Civilité</TH>
            <TH>Nom</TH>
            <TH>Prénom</TH>
            <TH>Mail</TH>
            <TH>Téléphone</TH>
            <TH>Date de création</TH>
          </THeadRow>
        </Thead>
        <tbody>
          {!recipients || pending ? (
            <TablePlaceholder colSpan={6} />
          ) : (
            recipients.map(({ id, title, firstName, lastName, email, phoneNumber, createdAt }) => (
              <TBodyRow key={id}>
                <TD textTransform="capitalize">
                  <TableEditLink to={`${match.url}/${id}/edit`}>{title}.</TableEditLink>
                </TD>
                <TD textTransform="uppercase">
                  <TableEditLink to={`${match.url}/${id}/edit`}>{lastName}</TableEditLink>
                </TD>
                <TD>
                  <TableEditLink to={`${match.url}/${id}/edit`}>{firstName}</TableEditLink>
                </TD>
                <TD>
                  <TableEditLink to={`${match.url}/${id}/edit`}>{email}</TableEditLink>
                </TD>
                <TD>
                  <TableEditLink to={`${match.url}/${id}/edit`}>{phoneNumber}</TableEditLink>
                </TD>
                <TD isLast>
                  <TableEditLink to={`${match.url}/${id}/edit`}>{createdAt}</TableEditLink>
                </TD>
              </TBodyRow>
            ))
          )}
        </tbody>
      </Table>
      {meta && (
        <Pagination
          current={currentPage}
          total={meta.total}
          defaultPageSize={meta.perPage}
          onChange={page => setCurrentPage(page)}
        />
      )}
    </PageIndex>
  );
};

const mapStateToProps = ({ recipients: state }) => ({
  pending: state.pending,
  recipients: state.data,
  meta: state.meta,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRecipients: recipientsActions.getRecipients,
    },
    dispatch,
  );

export const RecipientsIndex = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedRecipientsIndex);

const SearchBar = ({ onSearch }) => {
  return (
    <Formik initialValues={{ search: '' }} onSubmit={values => onSearch(values.search)}>
      {formik => (
        <Box as="form" onSubmit={formik.handleSubmit} d="flex" alignItems="start" flexWrap="wrap">
          <SearchInput name="search" />
          <SecondaryButton type="submit" size="sm" ml={3}>
            Lancer la recherche
          </SecondaryButton>
        </Box>
      )}
    </Formik>
  );
};

const Header = ({ onSearch, match, meta }) => {
  return (
    <>
      <SearchBar onSearch={onSearch} meta={meta} />
      <PrimaryButton as={NavLink} to={`${match.url}/new`} rightIcon="plus">
        Créer un bénéficiaire
      </PrimaryButton>
      {meta && <TableResults ressource="bénéficiaire" total={meta.total} />}
    </>
  );
};
