import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFolderPath } from '../../../../common/routes';
import { BlocDTabs } from './BlocDTabs';
import { blocDActions, blocDSelectors } from '../../../../redux/blocs/blocD/actions';
import { PageError404 } from '../../../../errors/components';

const BlocD = ({
  match: {
    params: { folderId },
  },
  getBlocD,
  updateBlocD,
  ...props
}) => {
  useEffect(() => {
    getBlocD(folderId);
  }, [getBlocD, folderId]);

  const onSubmit = values => updateBlocD(folderId, values);

  return (
    <Switch>
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', '')}`}
        exact
        render={() => <Redirect to={getFolderPath(props.blocId, folderId)} />}
      />
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', ':tabIndex')}`}
        exact
        render={routerProps => <BlocDTabs {...props} {...routerProps} onSubmit={onSubmit} />}
      />
      <Route component={PageError404} />
    </Switch>
  );
};

const mapStateToProps = (state, props) => {
  const blocD = blocDSelectors.getBlocDById(state, props) || {};

  return {
    blocD: { ...blocD, error: state.blocs.blocD.error },
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBlocD: blocDActions.getBlocD,
      updateBlocD: blocDActions.updateBlocD,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BlocD);
