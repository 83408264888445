import { configConstants } from './constants';
import { configAPI } from '../../common/api/config';
import { errorsActions } from '../errors/actions';

function getConfig() {
  function request() {
    return { type: configConstants.GET_CONFIG_REQUEST };
  }
  function success(config) {
    return { type: configConstants.GET_CONFIG_SUCCESS, config };
  }
  function failure(error) {
    return { type: configConstants.GET_CONFIG_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return configAPI.getConfig().then(config => {
      dispatch(success(config));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

export const configActions = {
  getConfig,
};
