import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/core';

import { FastField, Formik } from 'formik';
import { PageIndex } from '../common/components/layouts/Page/PageIndex';
import { PrimaryButton, SecondaryButton } from '../common/components/Buttons';
import {
  Table,
  Thead,
  TH,
  TD,
  THeadRow,
  TBodyRow,
  TablePlaceholder,
  TableEditLink,
} from '../common/components/Table';
import { ergoProjectsActions } from '../redux/ergoProjects/actions';
import { Pagination } from '../common/components/Pagination';
import { SelectInput } from '../common/components/Form/FormSelectInput';
import Can from '../roleBasedAccess/Can';
import { ergoProjectsAPI } from '../common/api/ergoProjects';
import { toastActions } from '../redux/toast/actions';
import { TOAST_MESSAGE } from '../common/components/Toasts/Toast';
import { isExtern } from '../roleBasedAccess/Is';

const ConnectedErgoProjectsIndex = ({
  ergoProjects,
  getErgoProjects,
  assignErgoToProject,
  pending,
  meta,
}) => {
  const user = useSelector(state => state.authentication.profile);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPosition, setCurrentPosition] = useState();
  const [success, setSuccess] = useState(false);
  const [onSubmitPending, setOnSubmitPending] = useState(false);
  const [positionableErgos, setPositionableErgos] = useState([]);
  const canUserPositionHimSelf = ['ergothérapeute interne', 'ergothérapeute de cabinet'].includes(
    user.ergoQualification,
  );

  useEffect(() => {
    getErgoProjects(currentPage);
  }, [getErgoProjects, currentPage]);

  const onModalClose = () => {
    if (success) getErgoProjects(currentPage);
    setSuccess(false);
    setCurrentPosition({});
    onClose();
  };

  const onSubmit = defaultValues => {
    setOnSubmitPending(true);
    const { ergoUserId, ergoProjectId } = currentPosition || defaultValues;
    ergoProjectsAPI
      .assignErgoToProject(ergoProjectId, { ergoUserId: ergoUserId?.value || ergoUserId })
      .then(() => setSuccess(true))
      .catch(() => {
        onModalClose();
        dispatch(toastActions.error(TOAST_MESSAGE.error.ergoProjectPosition));
      })
      .finally(() => setOnSubmitPending(false));
  };

  const getPositionableErgoUsers = ergoProjectId => {
    return ergoProjectsAPI.getPositionableErgoUsers(ergoProjectId).then(({ users }) =>
      setPositionableErgos(
        users.map(({ id, firstName, lastName }) => ({
          key: id,
          value: id,
          label: `${firstName} ${lastName}`,
        })),
      ),
    );
  };

  return (
    <PageIndex>
      <Table>
        <Thead>
          <THeadRow>
            <TH width="0" minWidth="100px" size="sm">
              N° de dossier
            </TH>
            <TH width="0" minWidth="100px" size="sm">
              Commune
            </TH>
            <TH size="sm" idth="100%">
              Descriptif
            </TH>
            <TH width="0" minWidth="120px" size="sm">
              Date de mise à disposition
            </TH>
            <TH width="0" minWidth="80px" size="sm">
              Procédure
            </TH>
            <TH width="0" minWidth="50px" size="sm">
              Tarif
            </TH>
            {!isExtern({ user }) && (
              <TH width="0" minWidth="50px" size="sm">
                Ergo. éligibles
              </TH>
            )}
            <Can
              perform="project-place-ergo:position"
              yes={() => (
                <TH width="0" minWidth="150px">
                  Actions
                </TH>
              )}
            />
          </THeadRow>
        </Thead>
        <tbody>
          {!ergoProjects || pending ? (
            <TablePlaceholder colSpan={6} />
          ) : (
            ergoProjects?.map(
              ({
                id,
                formattedId,
                postalCodeAndCity,
                ergoInterventionType,
                ergoProjectPublicationDate,
                interventionProcedure,
                ergoProjectPricing,
                eligibleUsersCount,
                positionedErgoUser,
              }) => (
                <TBodyRow key={id} enableHover={false}>
                  <TD hasPadding>{formattedId}</TD>
                  <TD hasPadding>{postalCodeAndCity}</TD>
                  <TD hasPadding>{ergoInterventionType}</TD>
                  <TD hasPadding>{ergoProjectPublicationDate}</TD>
                  <TD hasPadding whiteSpace="pre-line" lineHeight="1.5">
                    <TableEditLink
                      target="_blank"
                      alignItems="left"
                      p={0}
                      m={0}
                      style={{ pointerEvents: interventionProcedure ? '' : 'none' }}
                      to={{ pathname: interventionProcedure }}
                    >
                      <SecondaryButton size="sm" disabled={!interventionProcedure}>
                        Voir
                      </SecondaryButton>
                    </TableEditLink>
                  </TD>
                  <TD textAlign="center">
                    {ergoProjectPricing} {ergoProjectPricing ? '€' : null}
                  </TD>
                  {!isExtern({ user }) && (
                    <TD hasPadding textAlign="center" size="sm">
                      {eligibleUsersCount}
                    </TD>
                  )}
                  <Can
                    perform="project-place-ergo:position"
                    yes={() => {
                      return (
                        <TD hasPadding size="sm">
                          <Formik
                            initialValues={{
                              ergoUserId: canUserPositionHimSelf ? user.id : null,
                              ergoProjectId: id,
                            }}
                            onSubmit={values => {
                              onOpen();
                              setCurrentPosition(values);
                            }}
                          >
                            {formik =>
                              canUserPositionHimSelf ? (
                                <Flex as="form" alignItems="center" onSubmit={formik.handleSubmit}>
                                  <FastField name="ergoUserId" hidden />
                                  <PrimaryButton type="submit" ml="0" size="sm">
                                    Se positionner
                                  </PrimaryButton>
                                </Flex>
                              ) : (
                                <Flex as="form" alignItems="center" onSubmit={formik.handleSubmit}>
                                  <SelectInput
                                    name="ergoUserId"
                                    placeholder="Ergothérapeute"
                                    styles={{ mb: 0, minWidth: '150px' }}
                                    onFocus={() => getPositionableErgoUsers(id)}
                                    onBlur={() => setPositionableErgos([])}
                                    options={positionableErgos}
                                  />
                                  <PrimaryButton
                                    type="submit"
                                    size="sm"
                                    ml="2"
                                    disabled={!formik.values.ergoUserId}
                                  >
                                    Positionner
                                  </PrimaryButton>
                                </Flex>
                              )
                            }
                          </Formik>
                        </TD>
                      );
                    }}
                  />
                </TBodyRow>
              ),
            )
          )}
        </tbody>
      </Table>
      <ConfirmationModal
        isOpen={isOpen}
        onSubmit={onSubmit}
        pending={onSubmitPending}
        success={success}
        onClose={onModalClose}
        canUserPositionHimSelf={canUserPositionHimSelf}
        currentPosition={currentPosition}
      />
      {meta && (
        <Pagination
          current={currentPage}
          total={meta.total}
          defaultPageSize={meta.perPage}
          onChange={page => setCurrentPage(page)}
        />
      )}
    </PageIndex>
  );
};

const mapStateToProps = ({ ergoProjects: state }) => ({
  pending: state?.pending,
  ergoProjects: state?.data,
  meta: state?.meta,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getErgoProjects: ergoProjectsActions.getErgoProjects,
      assignErgoToProject: ergoProjectsActions.assignErgoToProject,
    },
    dispatch,
  );

export const ErgoProjectsTab = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedErgoProjectsIndex);

const ConfirmationModal = ({
  isOpen,
  onSubmit,
  pending,
  success,
  onClose,
  canUserPositionHimSelf,
  currentPosition,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>Confirmation du positionnement de l'ergothérapeute</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {success ? (
            <p>
              Votre positionnement a bien été pris en compte, vous pouvez retrouver le dossier et
              les informations relatives au bénéficiaire dès maintenant dans le menu « Dossier ».
            </p>
          ) : (
            <p>Confirmez-vous votre choix de positionnement pour ce projet ?</p>
          )}
        </ModalBody>

        <ModalFooter>
          {success ? (
            <PrimaryButton onClick={onClose}>OK</PrimaryButton>
          ) : (
            <>
              <SecondaryButton mr={3} onClick={onClose}>
                Annuler
              </SecondaryButton>
              <PrimaryButton
                onClick={() => onSubmit(currentPosition)}
                isLoading={pending}
                loadingText="Positionnement en cours"
              >
                Confirmer
              </PrimaryButton>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
