import React from 'react';
import { Switch, Route } from 'react-router';
import routes from '../common/routes';
import TasksTabs from './TasksTabs';

export const Tasks = () => {
  return (
    <Switch>
      <Route path={`${routes.tasks.index}/:tasksType`} component={TasksTabs} />
      <Route path={routes.tasks.index} component={TasksTabs} />
    </Switch>
  );
};
