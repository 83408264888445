/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Text, Stack, Icon, Flex, PseudoBox, Link } from '@chakra-ui/core';
import { useField, useFormikContext } from 'formik';
import { useRouteMatch } from 'react-router';

import {
  RadioGroup,
  RadioInput,
  TextInput,
  CheckboxGroup,
  CheckboxGroupInput,
} from '../../../../common/components/Form/FormInputs';
import { Notice, NoticeText, NoticeThinText } from '../../../../common/components/Notice';
import { FormCTAButton } from '../../../../common/components/Form/Form';
import { getFolderPath } from '../../../../common/routes';
import { history } from '../../../../common/helpers/history';

export const Section = ({ indexes, ...props }) => {
  const [startIndex, endIndex] = indexes;
  return (
    <Box mb="50px">
      <SectionContent startIndex={startIndex} endIndex={endIndex} {...props} />
    </Box>
  );
};

export const SectionContent = ({
  startIndex,
  endIndex,
  questions,
  questionsDisabled,
  currentQuestion,
  nextQuestion,
  isEndForm,
  preFilledInfo,
}) => {
  const { values, submitForm, isSubmitting } = useFormikContext();
  const {
    params: { folderId },
  } = useRouteMatch();

  const renderChoices = props => {
    switch (props.answerType) {
      case 'simple_choice':
        return <QuestionSimpleChoice {...props} />;
      case 'custom_multiple_choice_conditional':
        return <QuestionMultipleChoiceCustomConditional {...props} />;
      case 'multiple_choice':
        return <QuestionMultipleChoice {...props} />;
      case 'custom_multiple_choice':
        return <QuestionMultipleChoiceCustom {...props} />;
      case 'custom_text':
        return <QuestionCustomText {...props} />;
      case 'number_person':
        return <QuestionNumberOfPersons {...props} />;
      case 'number_currency':
        return <QuestionNumberCurrency {...props} />;
      default:
        return null;
    }
  };

  const renderPreFilledInfo = (questionName, availableAnswers) => {
    const preFilledInfoText = () => {
      switch (questionName) {
        case 'q4':
          return availableAnswers[preFilledInfo.fioQ4Answer?.toLowerCase()];
        case 'q13':
          return availableAnswers[preFilledInfo.fioQ13Answer?.toLowerCase()];
        case 'q17':
          return preFilledInfo.fioQ17Answer && `${preFilledInfo.fioQ17Answer} €`;
        case 'q20':
          return preFilledInfo.fioQ20Answer && `${preFilledInfo.fioQ20Answer} €`;
        default:
          return null;
      }
    };

    const text = preFilledInfoText();

    return text ? (
      <QuestionChoiceWrapper>
        <Notice inline variant="info" my={5} spacing={3} width="fit-content">
          <NoticeText variant="info">Réponse du pré-dossier</NoticeText>
          <NoticeThinText>
            <strong>{text}</strong>
          </NoticeThinText>
        </Notice>
      </QuestionChoiceWrapper>
    ) : null;
  };

  const displayNextQuestionLink = name =>
    !isEndForm &&
    currentQuestion === name &&
    nextQuestion !== name &&
    !nextQuestion.match(`${currentQuestion}Other`) &&
    (!values[nextQuestion]?.length ||
      (nextQuestion === 'q5' &&
        values[nextQuestion].some(answer => answer === '' || answer === 'NSP')));

  return (
    <Stack spacing={10}>
      {questions.slice(startIndex, endIndex).map(([name, value]) => (
        <Box
          key={name}
          css={{
            display: value.answerType === 'custom_text' && questionsDisabled[name] ? 'none' : null,
          }}
        >
          <Question
            key={name}
            id={name}
            css={{
              opacity: questionsDisabled[name] ? 0.5 : 1,
              marginTop: value.answerType === 'custom_text' ? '-6rem' : 0,
            }}
          >
            <QuestionTitle
              index={name
                .split('q')
                .map(s => (s.match(/bis/i) ? `${parseInt(s, 10)}B` : parseInt(s, 10)))
                .slice(1)}
              selected={name === nextQuestion}
              title={value.title}
              css={
                value.answerType === 'custom_text' ? { opacity: 0, pointerEvents: 'none' } : null
              }
            />
            {renderPreFilledInfo(name, value.availableAnswers)}
            {renderChoices({ name, ...value, disabled: questionsDisabled[name] })}
          </Question>
          {values.q5.includes('NSP') &&
            !values.q5.includes('G') &&
            name === 'q5' &&
            nextQuestion === name && <AlertQ5 />}
          {displayNextQuestionLink(name) && (
            <Box textAlign="center" mt="60px">
              <QuestionNextLink nextQuestion={nextQuestion} />
            </Box>
          )}
          {(isEndForm || (!!values.q22 && name === 'q22')) && nextQuestion === name && (
            <Box textAlign="center" mt="60px">
              <FormCTAButton
                isLoading={isSubmitting}
                loadingText="Enregistrer"
                onClick={() =>
                  submitForm().then(() => history.push(getFolderPath('bloc_fio', folderId, 2)))
                }
              >
                Accéder à la synthèse
              </FormCTAButton>
            </Box>
          )}
        </Box>
      ))}
    </Stack>
  );
};

export const QuestionNextLink = ({ nextQuestion }) => (
  <Link
    href={`#${nextQuestion}`}
    color="coral.900"
    _focus={{ boxShadow: 'none' }}
    onClick={e => {
      e.preventDefault();
      document.querySelector('[role="tabpanel"]').scrollTop =
        document.getElementById(nextQuestion).offsetTop - 50;
    }}
  >
    Question suivante
    <Icon
      name="chevron-down"
      size={3}
      color="coral.900"
      transform="rotate(-90deg)"
      strokeWidth="3"
      ml={2}
    />
  </Link>
);

export const AlertQ5 = () => (
  <Notice variant="warning" w="400px" m="60px auto">
    <NoticeText variant="warning">
      Impossible de continuer tant qu'au moins un des éléments est inconnu. Le bénéficiaire est
      invité à consulter le propriétaire du logement.
    </NoticeText>
  </Notice>
);

export const Question = ({ children, ...props }) => (
  <Box ml="10%" {...props}>
    {children}
  </Box>
);

export const QuestionTitle = ({ index, selected, title, ...props }) => (
  <Box as="h3" color="semiDarkGray.600" d="flex" alignItems="baseline" mb={5} {...props}>
    <Text as="p" fontWeight={300} mr="0.5ch" flexShrink="0">
      <Text as="span" color={selected ? 'red' : null}>
        <Text as="span" textTransform="uppercase">
          Question n°
        </Text>{' '}
        <Text
          as="strong"
          d="inline-block"
          textAlign="center"
          width="3.5ch"
          fontWeight={700}
          fontSize="xl"
          color={selected ? 'red' : 'semiDarkGray.900'}
        >
          {index}
        </Text>
      </Text>{' '}
      -
    </Text>
    <Text as="strong" fontWeight={600}>
      {title}
    </Text>
  </Box>
);

const QuestionChoiceWrapper = ({ children, ...props }) => (
  <Box ml="159px" {...props}>
    {children}
  </Box>
);

export const QuestionSimpleChoice = ({ availableAnswers, name, disabled }) => {
  const answers = Object.entries(availableAnswers);
  const isInline = answers.some(([, label]) => label === 'Oui');
  return (
    <QuestionChoiceWrapper
      maxW={isInline ? '400px' : '800px'}
      css={{ fieldset: { marginBottom: 0 } }}
    >
      <RadioGroup isInline={isInline} name={name} disabled={disabled}>
        {answers.map(([value, label]) => (
          <RadioInput key={value} value={value.toUpperCase()} disabled={disabled}>
            {label}
          </RadioInput>
        ))}
      </RadioGroup>
    </QuestionChoiceWrapper>
  );
};

export const QuestionMultipleChoice = ({ availableAnswers, name, disabled }) => (
  <QuestionChoiceWrapper>
    <CheckboxGroup name={name} spacing={2} disabled={disabled}>
      {Object.entries(availableAnswers).map(([value, label]) => (
        <CheckboxGroupInput key={value} value={value.toUpperCase()}>
          {label}
        </CheckboxGroupInput>
      ))}
    </CheckboxGroup>
  </QuestionChoiceWrapper>
);

export const QuestionMultipleChoiceCustomConditional = ({ availableAnswers, name, disabled }) => {
  const { values } = useFormikContext();

  return (
    <QuestionChoiceWrapper>
      <CheckboxGroup name={name} spacing={2} disabled={disabled}>
        {Object.entries(availableAnswers).map(([value, label]) => (
          <CheckboxGroupInput
            key={value}
            value={value.toUpperCase()}
            disabled={
              (value.toUpperCase() === 'A' && !(values.q_1 >= 55)) ||
              (value.toUpperCase() === 'C' && !(values.q_1 >= 60))
            }
          >
            {label}
          </CheckboxGroupInput>
        ))}
      </CheckboxGroup>
    </QuestionChoiceWrapper>
  );
};

export const QuestionCustomText = ({ name, disabled }) => (
  <QuestionChoiceWrapper>
    <TextInput
      name={name}
      placeholder="Saisir..."
      disabled={disabled}
      ml={8}
      width="80%"
      maxLength="150"
    />
  </QuestionChoiceWrapper>
);

export const QuestionNumberOfPersons = ({ name, disabled }) => (
  <QuestionChoiceWrapper css={{ '[role="group"]': { marginBottom: 0 } }}>
    <Stack isInline spacing="1ch" alignItems="flex-end">
      <TextInput type="number" name={name} placeholder="XX" width="6ch" disabled={disabled} />
      <Text color="semiDarkGray.600" fontWeight={300} pb="0.5ch">
        Personnes
      </Text>
    </Stack>
  </QuestionChoiceWrapper>
);

export const QuestionNumberCurrency = ({ name, disabled }) => (
  <QuestionChoiceWrapper css={{ '[role="group"]': { marginBottom: 0 } }}>
    <Stack isInline spacing="1ch" alignItems="flex-end">
      <TextInput type="number" name={name} placeholder="XX XXX" width="12ch" disabled={disabled} />
      <Text color="semiDarkGray.600" fontWeight={600} pb="0.5ch">
        €
      </Text>
    </Stack>
  </QuestionChoiceWrapper>
);

export const QuestionMultipleChoiceCustom = ({ name, availableAnswers, disabled }) => {
  const [, meta, helpers] = useField(name);

  const { value } = meta;
  const { setValue } = helpers;

  const isSelected = (inputValue, index) => value[index] === inputValue;

  const handleOnClick = (inputValue, index) => {
    const newValue = [...value];
    newValue[index] = inputValue;
    setValue(newValue);
  };

  return (
    <QuestionChoiceWrapper maxW="480px">
      <Stack spacing={10}>
        {Object.entries(availableAnswers).map(([title, answers], index) => {
          return (
            <Stack key={title} spacing="0.875rem">
              {index < 1 && (
                <Stack isInline width="calc(100% - 240px - 4rem)" alignSelf="flex-end">
                  {Object.values(answers).map(label => (
                    <Text
                      key={label}
                      color="semiDarkGray.600"
                      fontSize="sm"
                      flexGrow="1"
                      textAlign="center"
                      whiteSpace="nowrap"
                    >
                      {label}
                    </Text>
                  ))}
                </Stack>
              )}
              <Flex justifyContent="space-between">
                <Text
                  color="semiDarkGray.900"
                  textAlign="right"
                  fontWeight={600}
                  flex="0 0 240px"
                  mr={16}
                >
                  {title}
                </Text>
                <Stack isInline spacing={8} flexGrow={1}>
                  {Object.entries(answers).map(([inputValue, label], answersIndex) => {
                    return (
                      <Box key={inputValue} flexGrow="1" textAlign="center">
                        <QuestionMultipleChoiceCustomButton
                          inputValue={inputValue.toUpperCase()}
                          answersIndex={answersIndex}
                          aria-label={label}
                          aria-checked={isSelected(inputValue.toUpperCase(), index)}
                          onClick={() => handleOnClick(inputValue.toUpperCase(), index)}
                          isSelected={isSelected(inputValue.toUpperCase(), index)}
                          disabled={disabled}
                        />
                      </Box>
                    );
                  })}
                </Stack>
              </Flex>
            </Stack>
          );
        })}
      </Stack>
    </QuestionChoiceWrapper>
  );
};

const iconsName = ['check', 'question-mark', 'close'];
const iconsColor = ['green.900', 'orange.900', 'coral.900'];

const QuestionMultipleChoiceCustomButton = ({ isSelected, answersIndex, ...props }) => {
  return (
    <PseudoBox
      as="button"
      type="button"
      rounded="full"
      d="flex"
      alignItems="center"
      justifyContent="center"
      size={8}
      color={isSelected ? iconsColor[answersIndex] : 'lightGray2'}
      outline="none"
      pos="relative"
      transition="all 0.3s ease"
      _before={{
        content: "''",
        display: 'block',
        width: '100%',
        height: '100%',
        opacity: '0.07',
        rounded: 'full',
        transition: 'inherit',
        transitionProperty: 'opcacity, background',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      _focus={{
        svg: {
          color: iconsColor[answersIndex],
        },
        '&:before': {
          color: iconsColor[answersIndex],
          bg: iconsColor[answersIndex],
          opacity: '0.07',
        },
      }}
      _hover={{
        svg: {
          height: '1.5rem',
          width: '1.5rem',
          color: iconsColor[answersIndex],
        },
        '&:before': {
          bg: iconsColor[answersIndex],
          width: '131%',
          height: '131%',
          color: iconsColor[answersIndex],
          opacity: '0.07',
        },
      }}
      {...props}
    >
      <Icon
        name={iconsName[answersIndex]}
        size={4}
        transition="inherit"
        transitionProperty="color,height, width"
      />
    </PseudoBox>
  );
};
