import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Stack } from '@chakra-ui/core';

import { TabForm } from '../../../../common/components/Tabs';
import {
  FormSection,
  FormSubmitButton,
  FormCTAButton,
} from '../../../../common/components/Form/Form';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import { LockedInput } from '../../../../common/components/Form/FormLockedInput';
import {
  Notice,
  NoticeText,
  NoticeThinText,
  NoticeLink,
} from '../../../../common/components/Notice';
import { getFolderPath } from '../../../../common/routes';
import { history } from '../../../../common/helpers/history';
import Exclude from '../../../../roleBasedAccess/Exclude';

export const BlocATab3 = ({
  blocA: { error, pending, folder = {}, areRequiredDocumentsReceived, ...initialValues },
  onSubmit,
}) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  const {
    visitReminderSentAt,
    adaptableHousing,
    hasTechnicalSheet,
    hasLpprTechnicalRecommandation,
    typeOfVisit,
    worksQuoteReceived,
    worksQuoteReceivedAt,
    worksQuoteStatus,
    worksQuoteAdditionalRequestsSentAt,
  } = initialValues;

  const arePreviousTabsFieldsEmpty =
    !visitReminderSentAt ||
    !adaptableHousing ||
    !hasTechnicalSheet ||
    !hasLpprTechnicalRecommandation ||
    (typeOfVisit?.match(/(joint|existing_quote)/) &&
      (!worksQuoteReceived ||
        (worksQuoteReceived === 'true' &&
          (!worksQuoteReceivedAt ||
            !worksQuoteStatus ||
            (worksQuoteStatus === 'additional_request' && !worksQuoteAdditionalRequestsSentAt)))));

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        // Relocked fields with lock
        document.querySelectorAll('[data-locked]').forEach(button => {
          if (button.getAttribute('data-locked') === 'false') button.click();
        });

        return onSubmit({ ...initialValues, folder, ...values }).then(() => {
          setTouched({});
          setSubmitting(false);
        });
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit}>
          <Stack spacing="2rem">
            <FormSection mt="-1.5rem">
              {!adaptableHousing || !hasTechnicalSheet || !hasLpprTechnicalRecommandation ? (
                <Notice variant="warning" mb="2rem">
                  <NoticeText variant="warning">
                    La{' '}
                    <NoticeLink to={getFolderPath('bloc_a', initialValues.folderId, 2)}>
                      fiche de synthèse
                    </NoticeLink>{' '}
                    n'est pas complète
                  </NoticeText>
                </Notice>
              ) : null}

              <DateInput
                label="Date de finalisation primaire du 2DPE"
                name="primary_2dpeFinalizedAt"
                id="primary_2dpeFinalizedAt"
              />

              {initialValues.typeOfVisit === 'joint' ? (
                <Notice variant="info" my="2rem">
                  <NoticeThinText>
                    Si le devis travaux n’est pas reçu, transmettre la version « travaux » du 2DPE à
                    l’entreprise
                  </NoticeThinText>
                </Notice>
              ) : null}

              <LockedInput defaultIsLocked={!!formik.values.updated_2dpeAt} left="37%">
                <DateInput
                  label="Dernière date de mise à jour du 2DPE"
                  name="updated_2dpeAt"
                  id="updated_2dpeAt"
                  disabled={!formik.values.primary_2dpeFinalizedAt || folder.folderType === 'AMOe'}
                />
              </LockedInput>

              <Exclude
                exclude="folder:bloc_a:tab_3:sent_2dpe_at:read"
                no={() => (
                  <DateInput
                    label="Dernière date d'envoi du 2DPE"
                    name="sent_2dpeAt"
                    id="sent_2dpeAt"
                    disabled={
                      ((folder.folderType === 'AMOc' || folder.folderType === 'AMOad') &&
                        !formik.values.primary_2dpeFinalizedAt) ||
                      folder.folderType === 'AMOe'
                    }
                  />
                )}
              />
            </FormSection>

            <FormSection mt="-3rem">
              <Notice variant="info">
                <NoticeText variant="info">
                  Documents à envoyer à la personne à contacter :
                </NoticeText>
                <NoticeThinText mt={-6}>
                  Si nécessaire, consulter les fiches-procédures ou l'administrateur
                </NoticeThinText>
              </Notice>
            </FormSection>

            {folder.folderType === 'AMOc' || folder.folderType === 'AMOad' ? (
              <FormCTAButton
                mb={0}
                mx="auto"
                disabled={
                  (folder.folderType === 'AMOc' && !formik.values.sent_2dpeAt) ||
                  (folder.folderType === 'AMOad' && !formik.values.sent_2dpeAt) ||
                  arePreviousTabsFieldsEmpty
                }
                onClick={() =>
                  formik
                    .submitForm()
                    .then(() => history.push(getFolderPath('bloc_d', initialValues.folderId)))
                }
              >
                Préparer la demande de devis
              </FormCTAButton>
            ) : (
              <FormCTAButton
                mb={0}
                mx="auto"
                disabled={
                  (folder.folderType === 'AMOe' && !formik.values.primary_2dpeFinalizedAt) ||
                  arePreviousTabsFieldsEmpty
                }
                onClick={() =>
                  formik
                    .submitForm()
                    .then(() => history.push(getFolderPath('bloc_i', initialValues.folderId)))
                }
              >
                Clôture pour aboutissement
              </FormCTAButton>
            )}
          </Stack>

          <FormSubmitButton
            type="submit"
            disabled={pending}
            isLoading={formik.isSubmitting}
            loadingText="Enregistrer"
          >
            Enregistrer
          </FormSubmitButton>
        </TabForm>
      )}
    </Formik>
  );
};
