import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router';
import { Form, FormActions } from '../Form/Form';
import { RecipientFormFields } from './RecipientFormFields';
import { PrimaryButton, SecondaryButton } from '../Buttons';

export const RecipientForm = ({ initialValues, onSubmit, error, mode, submitText, ...props }) => {
  const formikRef = useRef();
  const history = useHistory();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={mode === 'edit'}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, setTouched }) => {
        setSubmitting(false);
        onSubmit(values);
        setTouched({});
      }}
    >
      {formik => (
        <Form onSubmit={formik.handleSubmit}>
          <RecipientFormFields {...props} />
          <FormActions mt={20}>
            <SecondaryButton type="button" onClick={() => history.goBack()}>
              Annuler
            </SecondaryButton>
            <PrimaryButton type="submit" disabled={props.pending}>
              {submitText || 'Enregistrer'}
            </PrimaryButton>
          </FormActions>
        </Form>
      )}
    </Formik>
  );
};
