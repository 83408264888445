import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFolderPath } from '../../../../common/routes';
import { BlocFioTabs } from './BlocFioTabs';
import { blocFioActions } from '../../../../redux/blocs/blocFio/actions';
import { PageError404 } from '../../../../errors/components';

const BlocFio = ({
  match: {
    params: { folderId },
  },
  blocId,
  getBlocFio,
  ...props
}) => {
  useEffect(() => {
    getBlocFio(folderId);
  }, [folderId, getBlocFio]);

  return (
    <Switch>
      <Route
        path={`${getFolderPath(blocId, ':folderId', '')}`}
        exact
        render={() => <Redirect to={getFolderPath(blocId, folderId)} />}
      />
      <Route
        path={`${getFolderPath(blocId, ':folderId', ':tabIndex')}`}
        exact
        render={routerProps => <BlocFioTabs blocId={blocId} {...props} {...routerProps} />}
      />
      <Route component={PageError404} />
    </Switch>
  );
};

const mapStateToProps = ({ blocs: { blocFio } }) => ({ blocFio });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBlocFio: blocFioActions.getBlocFio,
      updateBlocFio: blocFioActions.updateBlocFio,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BlocFio);
