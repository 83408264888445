export const companiesConstants = {
  GET_COMPANIES_REQUEST: 'GET_COMPANIES_REQUEST',
  GET_COMPANIES_SUCCESS: 'GET_COMPANIES_SUCCESS',
  GET_COMPANIES_FAILURE: 'GET_COMPANIES_FAILURE',

  GET_COMPANY_REQUEST: 'GET_COMPANY_REQUEST',
  GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS',
  GET_COMPANY_FAILURE: 'GET_COMPANY_FAILURE',

  CREATE_COMPANY_REQUEST: 'CREATE_COMPANY_REQUEST',
  CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_FAILURE: 'CREATE_COMPANY_FAILURE',

  UPDATE_COMPANY_REQUEST: 'UPDATE_COMPANY_REQUEST',
  UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_FAILURE: 'UPDATE_COMPANY_FAILURE',

  DELETE_COMPANY_REQUEST: 'DELETE_COMPANY_REQUEST',
  DELETE_COMPANY_SUCCESS: 'DELETE_COMPANY_SUCCESS',
  DELETE_COMPANY_FAILURE: 'DELETE_COMPANY_FAILURE',
};
