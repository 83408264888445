import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageDefault } from '../common/components/layouts/Page/PageDefault';
import { UserForm } from '../common/components/UserForm/UserForm';
import { operatorsActions } from '../redux/operators/actions';

export const ConnectedOperatorCreate = ({ error, pending, createOperator }) => {
  const onSubmit = newOperator => {
    createOperator(newOperator);
  };

  return (
    <PageDefault title="Création de la fiche opérateur">
      <UserForm pending={pending} onSubmit={onSubmit} error={error} mode="create" />
    </PageDefault>
  );
};

const mapStateToProps = ({ operators: state }) => ({
  error: state.error,
  pending: state.pending,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createOperator: operatorsActions.createOperator,
    },
    dispatch,
  );

ConnectedOperatorCreate.propTypes = {
  pending: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  createOperator: PropTypes.func.isRequired,
};

ConnectedOperatorCreate.defaultProps = {
  pending: false,
  error: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedOperatorCreate);
