import { foldersConstants } from './constants';
import { foldersAPI } from '../../common/api/folders';
import { getDateString } from '../../common/utils';
import { history } from '../../common/helpers/history';
import { getFolderPath } from '../../common/routes';
import { toastActions } from '../toast/actions';
import { TOAST_MESSAGE } from '../../common/components/Toasts/Toast';
import { errorsActions } from '../errors/actions';

function getFolders(page, name) {
  function request() {
    return { type: foldersConstants.GET_FOLDERS_REQUEST };
  }
  function success(data) {
    return { type: foldersConstants.GET_FOLDERS_SUCCESS, data };
  }
  function failure(error) {
    return { type: foldersConstants.GET_FOLDERS_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return foldersAPI.getFolders(page, name).then(({ folders, meta }) => {
      dispatch(success({ folders, meta }));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function createFolder(recipient) {
  function request() {
    return { type: foldersConstants.CREATE_FOLDER_REQUEST };
  }
  function success() {
    return { type: foldersConstants.CREATE_FOLDER_SUCCESS };
  }
  function failure(error) {
    return { type: foldersConstants.CREATE_FOLDER_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(recipient));

    return foldersAPI
      .createFolder({
        recipientId: recipient.id,
        recipientAttributes: {
          ...recipient,
          recipientIsTheContact: recipient.recipientIsTheContact === 'true' || false,
          birthDate: recipient.birthDate && getDateString(recipient.birthDate),
        },
      })
      .then(
        ({ folder }) => {
          dispatch(success());
          history.push(getFolderPath('bloc_0', folder.id));
          dispatch(toastActions.success(TOAST_MESSAGE.success.add));
        },
        apiError => {
          const {
            response: { data: error },
          } = apiError;

          dispatch(
            errorsActions.handleErrors(
              () => failure({ errors: error.errors.recipient }),
              toastActions.error(TOAST_MESSAGE.error.add),
            ),
          ).call(null, apiError);
        },
      );
  };
}

function updateFolders(folder) {
  return { type: foldersConstants.UPDATE_FOLDERS, folder };
}

const getFolderFromBloc = folder => {
  return { type: foldersConstants.GET_FOLDER_FROM_BLOC, folder };
};

export const foldersActions = {
  getFolders,
  createFolder,
  updateFolders,
  getFolderFromBloc,
};
