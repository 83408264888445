import { encodeQueryArray } from '../utils';
import { apiV1 } from './api';

const getFolders = (page = 1, { keywords, folderType, tags, department, phases, status }) =>
  apiV1
    .get(
      `/folders?page=${page}&by_keywords=${keywords}&by_department=${
        department?.value
      }&by_folder_type=${folderType.value}${encodeQueryArray('by_tags', tags)}${encodeQueryArray(
        'by_phases',
        phases,
      )}${encodeQueryArray('by_status', status)}`,
    )
    .then(({ data }) => data);

const getTags = filters =>
  apiV1.get(`/folders/search_tags`, { params: filters }).then(({ data }) => data);

const createFolder = folder =>
  apiV1
    .post(`/folders`, {
      folder,
    })
    .then(({ data }) => data);

const editFolder = (id, folder) =>
  apiV1
    .put(`/folders/${id}`, {
      folder,
    })
    .then(({ data }) => data);

export const foldersAPI = {
  getFolders,
  getTags,
  createFolder,
  editFolder,
};
