import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Box, Flex } from '@chakra-ui/core';

import { recipientsActions } from '../redux/recipients/actions';
import { foldersActions } from '../redux/folders/actions';
import { PageDefault } from '../common/components/layouts/Page/PageDefault';
import { RecipientForm } from '../common/components/RecipientForm/RecipientForm';
import { PageTitle } from '../common/components/Titles';
import { AutosuggestInput } from '../common/components/Form/FormAutosuggestInput';
import { debounce } from '../common/utils';

const FolderCreate = ({
  error,
  pending,
  createFolder,
  getRecipients,
  recipients,
  recipientsPending,
}) => {
  const [selectedRecipient, setSelectedRecipient] = useState({});

  const onSubmit = recipient => {
    createFolder(recipient);
  };

  return (
    <>
      <FolderCreateHeader
        getRecipients={getRecipients}
        recipients={recipients}
        selectRecipient={setSelectedRecipient}
        pending={recipientsPending}
      />
      {Object.keys(selectedRecipient).length > 0 && (
        <PageDefault title="Informations du bénéficiaire">
          <RecipientForm
            initialValues={{ ...selectedRecipient }}
            pending={pending}
            onSubmit={onSubmit}
            error={error}
            mode="edit"
            isFolderCreation
            submitText="Créer un dossier"
          />
        </PageDefault>
      )}
    </>
  );
};

const mapStateToProps = ({ recipients, folders }) => ({
  recipients: recipients.data,
  recipientsPending: recipients.pending,
  pending: folders.pending,
  error: folders.error,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createFolder: foldersActions.createFolder,
      getRecipients: recipientsActions.getRecipients,
    },
    dispatch,
  );

FolderCreate.propTypes = {
  pending: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  createFolder: PropTypes.func.isRequired,
};

FolderCreate.defaultProps = {
  pending: false,
  error: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(FolderCreate);

const FolderCreateHeader = ({ recipients, getRecipients, selectRecipient, pending }) => {
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    setSuggestions(recipients);
  }, [recipients]);

  const onSuggestionsFetchRequested = ({ value }) => {
    if (value.length > 2) {
      getRecipients(1, value);
    }
  };

  const onSuggestionsFetchRequestedDebounced = useCallback(
    debounce(onSuggestionsFetchRequested, 250),
    [],
  );

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  return (
    <Box w="100%" px={10} pt={5}>
      <PageTitle mb={6}>Nouveau dossier</PageTitle>
      <Flex
        align="center"
        justify="space-between"
        bg="coral.900"
        rounded="15px"
        shadow="layout"
        wrap="wrap"
        px={8}
        py={5}
        mb={4}
      >
        <PageTitle as="h2" color="white">
          à partir d’un bénéficiaire existant
        </PageTitle>
        <Box minW="426px">
          <AutosuggestInput
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequestedDebounced}
            getSuggestionValue={suggestion => `${suggestion.firstName} ${suggestion.lastName}`}
            onSuggestionSelected={(event, { suggestion }) => {
              let birthDate;
              if (suggestion.birthDate) {
                const [day, month, year] = suggestion.birthDate.split('/');
                birthDate = new Date(year, month - 1, day);
              }
              selectRecipient({
                ...suggestion,
                recipientIsTheContact: suggestion.recipientIsTheContact ? 'true' : 'false',
                birthDate: birthDate && birthDate,
              });
              setSearch('');
            }}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            renderSuggestion={suggestion => (
              <span>{`${suggestion.firstName} ${suggestion.lastName}`}</span>
            )}
            inputProps={{
              placeholder: 'Recherche',
              value: search,
              onChange: (event, { newValue }) => setSearch(newValue),
            }}
            name="recipient"
            noResultText="Non présent dans la base de données"
            noResultTextColor="white"
            pending={pending}
          />
        </Box>
      </Flex>
    </Box>
  );
};
