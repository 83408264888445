import { apiV1 } from './api';

const getPreFolders = (page = 1) =>
  apiV1.get(`/preliminary_folders?page=${page}`).then(({ data }) => data);

const getPreFolder = preFolderId =>
  apiV1.get(`/preliminary_folders/${preFolderId}`).then(({ data }) => data);

const createPreFolder = preliminaryFolder =>
  apiV1.post('/preliminary_folders', { preliminaryFolder }).then(({ data }) => data);

const editPreFolder = (preFolderId, preliminaryFolder) =>
  apiV1.put(`/preliminary_folders/${preFolderId}`, { preliminaryFolder }).then(({ data }) => data);

const transformPreFolderIntoFolder = preFolderId =>
  apiV1.post(`/preliminary_folders/${preFolderId}/transform_into_folder`).then(({ data }) => data);

const deletePreFolder = preFolderId => apiV1.delete(`/preliminary_folders/${preFolderId}`);

export const preFoldersAPI = {
  getPreFolders,
  getPreFolder,
  createPreFolder,
  editPreFolder,
  transformPreFolderIntoFolder,
  deletePreFolder,
};
