import { useMemo } from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Box, Text, Spinner } from '@chakra-ui/core';
import Autosuggest from 'react-autosuggest';

import { BaseInput } from './FormInputs';
import { useFormSubmitDisabled } from './FormSubmitDisabled.context';

export const AutosuggestInput = ({
  name,
  disabled,
  isInvalid,
  isLocked,
  noResultText,
  noResultTextColor,
  pending = false,
  readOnly,
  ...props
}) => {
  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  const renderNoResultMessage = useMemo(
    () =>
      props.inputProps.value.length > 2 &&
      props.suggestions.length < 1 &&
      pending !== true && (
        <Text ml={3} mt={2} color={noResultTextColor || 'semiDarkGray.600'}>
          {noResultText}
        </Text>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.suggestions],
  );
  const renderInputComponent = inputProps => (
    <BaseInput
      {...inputProps}
      name={name}
      disabled={disabled}
      isInvalid={isInvalid}
      isLocked={isLocked}
      readOnly={readOnly || isFormSubmitDisabled}
    />
  );

  return (
    <Box
      pos="relative"
      css={theme => css`
        .react-autosuggest {
          &__container {
            position: relative;
          }

          &__suggestions-container {
            margin-top: 10px;
            width: 100%;
            max-height: 20vh;
            overflow: auto;
            transform: translateY(100%);
            z-index: 10;
            position: absolute;
            bottom: -5px;
            left: 0;

            &--open {
              background-color: white;
              border-radius: ${theme.radii.lg};
              box-shadow: ${theme.shadows.popover};
            }
          }

          &__suggestions-list {
            list-style: none;
            margin: 0;
            padding-left: 0;
          }

          &__suggestion {
            color: ${theme.colors.semiDarkGray[900]};
            padding: 0.5em 1em;

            &--highlighted {
              background-color: ${theme.colors.select.bg.isSelected};
              color: ${theme.colors.red};
              font-weight: 600;
            }
          }
        }
      `}
    >
      <Autosuggest renderInputComponent={renderInputComponent} {...props} />
      {pending && <Spinner color="gray" pos="absolute" top={2} right={2} size={4} />}
      {renderNoResultMessage}
    </Box>
  );
};
