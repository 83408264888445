import { blocHConstants } from './constants';

export const initialState = {
  pending: false,
};

export function blocH(state = initialState, action) {
  switch (action.type) {
    case blocHConstants.GET_BLOCH_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case blocHConstants.GET_BLOCH_SUCCESS:
      return {
        ...state,
        [action.blocH.folderId]: action.blocH,
        pending: false,
      };
    case blocHConstants.UPDATE_BLOCH_REQUEST:
      return {
        ...state,
        [action.blocH.folderId]: action.blocH,
        error: null,
        pending: true,
      };
    case blocHConstants.UPDATE_BLOCH_SUCCESS:
      return {
        ...state,
        [action.blocH.folderId]: action.blocH,
        error: null,
        pending: false,
      };
    case blocHConstants.UPDATE_BLOCH_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case blocHConstants.GET_BLOCH_FAILURE:
    default:
      return state;
  }
}
