import { ergoProjectsConstants } from './constants';
import { ergoProjectsAPI } from '../../common/api/ergoProjects';
import { errorsActions } from '../errors/actions';

function getErgoProjects(page, search) {
  function request() {
    return { type: ergoProjectsConstants.GET_ERGO_PROJECTS_REQUEST };
  }
  function success(data) {
    return { type: ergoProjectsConstants.GET_ERGO_PROJECTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: ergoProjectsConstants.GET_ERGO_PROJECTS_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return ergoProjectsAPI.getErgoProjects(page, search).then(({ ergoProjects, meta }) => {
      dispatch(success({ ergoProjects, meta }));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

export const ergoProjectsActions = {
  getErgoProjects,
};
