import React, { useEffect, useState } from 'react';
import { Icon } from '@chakra-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';
import { PrimaryButton } from '../common/components/Buttons';
import { PageIndex } from '../common/components/layouts/Page/PageIndex';
import {
  Table,
  TableEditLink,
  TablePlaceholder,
  TBodyRow,
  TD,
  TH,
  Thead,
  THeadRow,
} from '../common/components/Table';
import routes from '../common/routes';
import { Pagination } from '../common/components/Pagination';
import { preFoldersActions } from '../redux/preFolders/actions';

const STATUS_COLORS = {
  "En cours d'édition": 'tag.color.gray',
  Transmis: 'tag.color.orange',
  'Pris en charge': 'toast.success',
};

const ConnectedPreFoldersIndex = ({ getPreFolders, pending, preFolders, meta }) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getPreFolders(currentPage);
  }, [getPreFolders, currentPage]);

  return (
    <PageIndex pageAction={<PageAction />}>
      <Table>
        <Thead>
          <THeadRow>
            <TH>N° de pré-dossier</TH>
            <TH>Opérateur</TH>
            <TH>Bénéficiaire</TH>
            <TH>Créé le</TH>
            <TH>État</TH>
          </THeadRow>
        </Thead>
        <tbody>
          {!preFolders || pending ? (
            <TablePlaceholder colSpan={10} />
          ) : (
            preFolders.map(({ id, formattedId, recipient, createdBy, createdAt, status }) => {
              const editPath = `${routes.preFolders}/${id}/edit`;

              return (
                <TBodyRow key={id}>
                  <TD>
                    <TableEditLink to={editPath}>{formattedId}</TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink to={editPath}>
                      {createdBy.firstName} {createdBy.lastName?.toUpperCase()}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink to={editPath}>
                      {recipient.firstName} {recipient.lastName?.toUpperCase()}
                    </TableEditLink>
                  </TD>
                  <TD>
                    <TableEditLink to={editPath}>{createdAt}</TableEditLink>
                  </TD>
                  <TD isLast={status === "En cours d'édition"}>
                    <TableEditLink to={editPath} d="flex" alignItems="center">
                      <Icon name="dot" color={STATUS_COLORS[status]} mr={2} />
                      {status}
                    </TableEditLink>
                  </TD>
                </TBodyRow>
              );
            })
          )}
        </tbody>
      </Table>
      {meta && (
        <Pagination
          current={currentPage}
          total={meta.total}
          defaultPageSize={meta.perPage}
          onChange={page => setCurrentPage(page)}
        />
      )}
    </PageIndex>
  );
};

const mapStateToProps = ({ preFolders: state }) => {
  return {
    pending: state.pending,
    preFolders: state.data,
    meta: state.meta,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPreFolders: preFoldersActions.getPreFolders,
    },
    dispatch,
  );

export const PreFoldersIndex = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConnectedPreFoldersIndex);

const PageAction = () => {
  return (
    <PrimaryButton as={NavLink} to={`${routes.preFolders}/new`} ml="auto" mb={3}>
      Créer un pré-dossier
    </PrimaryButton>
  );
};
