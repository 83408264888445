import { ergoOfficesConstants } from './constants';

export const initialState = { data: [], ergoOffice: {} };

export function ergoOffices(state = initialState, action) {
  switch (action.type) {
    case ergoOfficesConstants.GET_ERGO_OFFICES_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case ergoOfficesConstants.GET_ERGO_OFFICES_SUCCESS:
      return {
        data: action.data.ergoOffices,
        meta: action.data.meta,
      };
    case ergoOfficesConstants.GET_ERGO_OFFICE_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case ergoOfficesConstants.GET_ERGO_OFFICE_SUCCESS:
      return {
        ...initialState,
        pending: false,
        ergoOffice: action.ergoOffice,
      };
    case ergoOfficesConstants.GET_ERGO_OFFICE_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case ergoOfficesConstants.CREATE_ERGO_OFFICE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ergoOfficesConstants.CREATE_ERGO_OFFICE_SUCCESS:
      return initialState;
    case ergoOfficesConstants.CREATE_ERGO_OFFICE_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case ergoOfficesConstants.UPDATE_ERGO_OFFICE_REQUEST:
      return {
        ...initialState,
        ergoOffice: action.ergoOffice,
        pending: true,
      };
    case ergoOfficesConstants.UPDATE_ERGO_OFFICE_SUCCESS:
      return {
        ...initialState,
        pending: false,
      };
    case ergoOfficesConstants.UPDATE_ERGO_OFFICE_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };

    case ergoOfficesConstants.DELETE_ERGO_OFFICE_REQUEST:
      return {
        ...state,
        deletePending: true,
      };
    case ergoOfficesConstants.DELETE_ERGO_OFFICE_SUCCESS:
      return {
        ...state,
        deletePending: false,
      };
    case ergoOfficesConstants.DELETE_ERGO_OFFICE_FAILURE:
      return {
        ...state,
        error: action.error,
        deletePending: false,
      };
    case ergoOfficesConstants.GET_ERGO_OFFICES_FAILURE:
    default:
      return state;
  }
}
