import { css } from '@emotion/core';

export const globalStyle = theme => css`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;600;700&display=swap');

  html {
    font-family: ${theme.fonts.body};
    line-height: ${theme.lineHeights.shorter};
  }

  input[type='search']::-webkit-search-cancel-button {
    filter: brightness(0) saturate(100%) invert(61%) sepia(32%) saturate(5147%) hue-rotate(320deg)
      brightness(96%) contrast(104%);
  }
`;
