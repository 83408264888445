import React from 'react';
import { AccordionItem, AccordionHeader, AccordionPanel, Box, Icon, Stack } from '@chakra-ui/core';

export const TabAccordionItem = ({ children, isActive, title, ...props }) => {
  return (
    <AccordionItem border={0} {...props}>
      {({ isExpanded, isDisabled }) => (
        <>
          <TabAccordionHeader isActive={isActive}>
            <Box as="span" whiteSpace="nowrap" textAlign="left">
              {title}
            </Box>
            <Icon
              color={isDisabled ? 'gray2' : 'coral.900'}
              size="0.8125rem"
              name="chevron-down"
              strokeWidth="3px"
              transform={isExpanded ? 'rotate(180deg)' : 'none'}
            />
          </TabAccordionHeader>
          <AccordionPanel pb={4}>{children}</AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

const TabAccordionHeader = ({ children, isActive, ...props }) => {
  return (
    <AccordionHeader
      border={0}
      borderTop="1px solid"
      borderTopColor={isActive ? 'coral.900' : 'gray2'}
      padding={0}
      mt={3}
      pos="relative"
      _expanded={{ mb: '75px' }}
      _disabled={{
        '>span': { borderColor: 'gray2', color: 'gray2' },
        borderTopColor: 'transparent',
      }}
      _focus={{}}
      {...props}
    >
      <Stack
        as="span"
        isInline
        spacing={2}
        alignItems="center"
        justifyContent="center"
        p="0.125rem 1.5rem"
        bg="white"
        textTransform="uppercase"
        border="1px solid"
        borderColor={isActive ? 'coral.900' : 'lightGray2'}
        rounded="full"
        fontWeight={isActive ? 'bold' : 600}
        pos="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        {children}
      </Stack>
    </AccordionHeader>
  );
};
