export const foldersConstants = {
  GET_FOLDERS_REQUEST: 'GET_FOLDERS_REQUEST',
  GET_FOLDERS_SUCCESS: 'GET_FOLDERS_SUCCESS',
  GET_FOLDERS_FAILURE: 'GET_FOLDERS_FAILURE',

  CREATE_FOLDER_REQUEST: 'CREATE_FOLDER_REQUEST',
  CREATE_FOLDER_SUCCESS: 'CREATE_FOLDER_SUCCESS',
  CREATE_FOLDER_FAILURE: 'CREATE_FOLDER_FAILURE',

  UPDATE_FOLDERS: 'UPDATE_FOLDERS',

  GET_FOLDER_FROM_BLOC: 'GET_FOLDER_FROM_BLOC',
};
