import axios from 'axios';
import applyConverters from 'axios-case-converter';
import routes from '../routes';

axios.defaults.withCredentials = true;

// Perserve bloc_fio Q5 questions from being transformed to camel case
const options = {
  preservedKeys: input => {
    return input.match(/\?/) || input.match(/AMO/) || input.match(/^_/);
  },
};

export const api = applyConverters(
  axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/`,
    withCredentials: true,
  }),
);

const apiV1 = applyConverters(
  axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/v1`,
  }),
  options,
);

apiV1.interceptors.response.use(
  response => response,
  error => {
    // Network Error
    if (!error.response) {
      window.location.replace(routes.error.network);
    }

    // Unauthorized error
    if (error?.response?.status === 401) {
      window.location = routes.logout;
    }

    return Promise.reject(error);
  },
);

export { apiV1 };
