import { quotesConstants } from './constants';
import { quotesAPI } from '../../common/api/quotes';
import { errorsActions } from '../errors/actions';

function getQuotes(page, search) {
  function request() {
    return { type: quotesConstants.GET_QUOTES_REQUEST };
  }
  function success(data) {
    return { type: quotesConstants.GET_QUOTES_SUCCESS, data };
  }
  function failure(error) {
    return { type: quotesConstants.GET_QUOTES_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return quotesAPI.getQuotes(page, search).then(({ quoteRequestSummaries: quotes, meta }) => {
      dispatch(success({ quotes, meta }));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function updateQuotesComments(quote, folder) {
  return { type: quotesConstants.UPDATE_QUOTES_COMMENTS, quote, folder };
}

export const quotesActions = {
  getQuotes,
  updateQuotesComments,
};
