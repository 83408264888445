export const preFoldersConstants = {
  GET_PRE_FOLDERS_REQUEST: 'GET_PRE_FOLDERS_REQUEST',
  GET_PRE_FOLDERS_SUCCESS: 'GET_PRE_FOLDERS_SUCCESS',
  GET_PRE_FOLDERS_FAILURE: 'GET_PRE_FOLDERS_FAILURE',

  GET_PRE_FOLDER_REQUEST: 'GET_PRE_FOLDER_REQUEST',
  GET_PRE_FOLDER_SUCCESS: 'GET_PRE_FOLDER_SUCCESS',
  GET_PRE_FOLDER_FAILURE: 'GET_PRE_FOLDER_FAILURE',

  CREATE_PRE_FOLDER_REQUEST: 'CREATE_PRE_FOLDER_REQUEST',
  CREATE_PRE_FOLDER_SUCCESS: 'CREATE_PRE_FOLDER_SUCCESS',
  CREATE_PRE_FOLDER_FAILURE: 'CREATE_PRE_FOLDER_FAILURE',

  UPDATE_PRE_FOLDER_REQUEST: 'UPDATE_PRE_FOLDER_REQUEST',
  UPDATE_PRE_FOLDER_SUCCESS: 'UPDATE_PRE_FOLDER_SUCCESS',
  UPDATE_PRE_FOLDER_FAILURE: 'UPDATE_PRE_FOLDER_FAILURE',

  TRANSFORM_PRE_FOLDER_REQUEST: 'TRANSFORM_PRE_FOLDER_REQUEST',
  TRANSFORM_PRE_FOLDER_SUCCESS: 'TRANSFORM_PRE_FOLDER_SUCCESS',
  TRANSFORM_PRE_FOLDER_FAILURE: 'TRANSFORM_PRE_FOLDER_FAILURE',

  DELETE_PRE_FOLDER_REQUEST: 'DELETE_PRE_FOLDER_REQUEST',
  DELETE_PRE_FOLDER_SUCCESS: 'DELETE_PRE_FOLDER_SUCCESS',
  DELETE_PRE_FOLDER_FAILURE: 'DELETE_PRE_FOLDER_FAILURE',
};
