import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Stack, Box, Text } from '@chakra-ui/core';

import { TabForm } from '../../../../common/components/Tabs';
import {
  FormSection,
  FormIconSection,
  FormSubmitButton,
  FormCTAButton,
} from '../../../../common/components/Form/Form';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import { TextInput } from '../../../../common/components/Form/FormInputs';
import { SelectInput } from '../../../../common/components/Form/FormSelectInput';
import { Notice, NoticeText, NoticeThinText } from '../../../../common/components/Notice';
import { getFolderPath } from '../../../../common/routes';
import { history } from '../../../../common/helpers/history';
import { QUOTE_GROUP_TYPES } from '../../../../redux/blocs/blocE/actions';
import { isCompanyFilled, isCompanyStatus } from './utils';

export const BlocFTabSummary = ({
  blocF: { error, pending, folder = {}, ...initialValues },
  onSubmit,
}) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  const checkSomeQuoteGroupCompaniesAreNotValid = quoteGroup => {
    return quoteGroup.companies.some(company => !isCompanyFilled(company));
  };

  const checkSomeQuoteGroupsAreNotValid = quoteGroups => {
    return Object.values(quoteGroups).some(checkSomeQuoteGroupCompaniesAreNotValid);
  };

  const checkSomeCompaniesAreNotNotifiedAt = quoteGroups => {
    return Object.values(quoteGroups)
      .filter(quoteGroup => quoteGroup.companies.some(company => !company.noFollowup))
      .some(quoteGroup => !quoteGroup.chosenCompanyNotifiedAt);
  };

  const checkSomeQuoteRequestsAlmostValid = quoteGroups => {
    return Object.values(quoteGroups).some(quoteGroup =>
      quoteGroup.companies.some(company => isCompanyStatus(company, /almost_valid/)),
    );
  };

  const showNotice = formik => {
    return (
      (typeof formik.values.worksTotalPrice === 'number' &&
        typeof formik.values.projectTotalPrice === 'number') ||
      (formik.values.quoteGroups && checkSomeQuoteRequestsAlmostValid(formik.values.quoteGroups))
    );
  };

  const canGoToNextBloc = formik => {
    return (
      formik.values.analysisFinishedAt &&
      formik.values.sentToRecipientAt &&
      formik.values.confirmationFromRecipientReceivedAt &&
      Object.values(formik.values.quoteGroups).filter(quoteGroup =>
        quoteGroup.companies.some(company => !company.noFollowup),
      ).length > 0 &&
      Object.values(formik.values.quoteGroups)
        .filter(quoteGroup => quoteGroup.companies.some(company => !company.noFollowup))
        .every(quoteGroup => quoteGroup.chosenCompany) &&
      typeof formik.values.worksTotalPrice === 'number' &&
      typeof formik.values.projectTotalPrice === 'number'
    );
  };

  const nextBloc = folder.folderType === 'AMOc' ? 'bloc_g' : 'bloc_i';

  const nextBlocCTAText =
    folder.folderType === 'AMOc' ? 'Valider le financement' : 'Clôturer pour aboutissement';

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        return onSubmit({ ...initialValues, folder, ...values }).then(() => {
          setTouched({});
          setSubmitting(false);
        });
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit}>
          <Stack spacing="3.5rem">
            <FormSection title="Analyse de devis">
              <DateInput
                label="Date de finalisation de l'analyse des devis"
                name="analysisFinishedAt"
                id="analysisFinishedAt"
                disabled={
                  formik.values.quoteGroups &&
                  checkSomeQuoteGroupsAreNotValid(formik.values.quoteGroups)
                }
              />

              <DateInput
                label="Date d'envoi au bénéficiaire"
                name="sentToRecipientAt"
                id="sentToRecipientAt"
                disabled={!formik.values.analysisFinishedAt}
              />

              <Notice variant="info" mb="2rem">
                <NoticeText variant="info">
                  Documents à envoyer à la personne à contacter :
                </NoticeText>
                <NoticeThinText mt="-1rem">
                  Si nécessaire, consulter les fiches-procédures ou l'administrateur
                </NoticeThinText>
              </Notice>

              <DateInput
                label="Date de réception de confirmation du choix du bénéficiaire"
                name="confirmationFromRecipientReceivedAt"
                id="confirmationFromRecipientReceivedAt"
                disabled={!formik.values.sentToRecipientAt}
              />
            </FormSection>

            <FormSection title="Sélection du devis">
              <Notice variant="info" mb="2rem">
                <NoticeText variant="info">Documents à envoyer aux entreprises :</NoticeText>
                <NoticeThinText mt="-1rem">
                  Si nécessaire, consulter les fiches-procédures ou l'administrateur
                </NoticeThinText>
              </Notice>

              {formik.values.quoteGroups &&
                formik.values.quoteGroups.map((quoteGroup, index) =>
                  quoteGroup.companies.some(company => !company.noFollowup) ? (
                    <FormIconSection
                      key={quoteGroup.id}
                      title={QUOTE_GROUP_TYPES[quoteGroup.name]?.label}
                      icon={QUOTE_GROUP_TYPES[quoteGroup.name]?.icon}
                      mb="4rem"
                    >
                      <SelectInput
                        label="Entreprise sélectionnée par le bénéficiaire"
                        name={`quoteGroups[${index}].chosenCompany`}
                        id={`quoteGroups[${index}].chosenCompany`}
                        options={quoteGroup.companies
                          .filter(company => !company.noFollowup)
                          .map(company => ({
                            label: company.name,
                            value: company.companyId,
                          }))}
                        placeholder="--"
                        isDisabled={checkSomeQuoteGroupCompaniesAreNotValid(quoteGroup)}
                      />

                      <DateInput
                        label="Date de notification à l'entreprise"
                        name={`quoteGroups[${index}].chosenCompanyNotifiedAt`}
                        id={`quoteGroups[${index}].chosenCompanyNotifiedAt`}
                        disabled={!formik.values.quoteGroups[index].chosenCompany}
                      />
                    </FormIconSection>
                  ) : null,
                )}
            </FormSection>

            <FormSection title="Validation du coût du projet">
              <Stack isInline spacing="1ch" alignItems="center">
                <TextInput
                  label="Montant des travaux"
                  type="number"
                  id="worksTotalPrice"
                  name="worksTotalPrice"
                  placeholder="XXXXX"
                  width="12ch"
                  disabled={
                    !formik.values.confirmationFromRecipientReceivedAt ||
                    (formik.values.quoteGroups &&
                      checkSomeCompaniesAreNotNotifiedAt(formik.values.quoteGroups))
                  }
                />
                <Text
                  color="semiDarkGray.600"
                  fontWeight={600}
                  fontSize="lg"
                  pt="0.5ch"
                  ml="-5.5ch"
                >
                  €{' '}
                  <Text as="small" ml={2}>
                    TTC
                  </Text>
                </Text>
              </Stack>

              <Stack isInline spacing="1ch" alignItems="center">
                <TextInput
                  label="Montant total des préconisations (hors coût de l'AMO)"
                  type="number"
                  id="projectTotalPrice"
                  name="projectTotalPrice"
                  placeholder="XXXXX"
                  width="12ch"
                  disabled={
                    !formik.values.confirmationFromRecipientReceivedAt ||
                    (formik.values.quoteGroups &&
                      checkSomeCompaniesAreNotNotifiedAt(formik.values.quoteGroups))
                  }
                />
                <Text
                  color="semiDarkGray.600"
                  fontWeight={600}
                  fontSize="lg"
                  pt="0.5ch"
                  ml="-30.25ch"
                >
                  €{' '}
                  <Text as="small" ml={2}>
                    TTC
                  </Text>
                </Text>
              </Stack>

              {showNotice(formik) ? (
                <Notice variant="warning" mt="4rem" mb="2rem">
                  {checkSomeQuoteRequestsAlmostValid(formik.values.quoteGroups) && (
                    <NoticeText variant="warning" mb="0rem">
                      Vérifier sur les données des entreprises : <br />
                      <Text as="span" fontWeight={300}>
                        au moins un statut d'analyse de devis est encore en
                      </Text>{' '}
                      "légers détails à modifier".
                    </NoticeText>
                  )}
                  <NoticeText variant="warning">
                    Vérifier le 2DPE et mettre à jour si nécessaire
                  </NoticeText>
                </Notice>
              ) : null}
            </FormSection>

            <Box textAlign="center">
              <FormCTAButton
                disabled={formik.values.quoteGroups && !canGoToNextBloc(formik)}
                onClick={() =>
                  formik
                    .submitForm()
                    .then(() => history.push(getFolderPath(nextBloc, initialValues.folderId)))
                }
              >
                {nextBlocCTAText}
              </FormCTAButton>
            </Box>
          </Stack>

          <FormSubmitButton
            type="submit"
            disabled={pending}
            isLoading={formik.isSubmitting}
            loadingText="Enregistrer"
          >
            Enregistrer
          </FormSubmitButton>
        </TabForm>
      )}
    </Formik>
  );
};
