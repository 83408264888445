import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useRouteMatch } from 'react-router-dom';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Box, Icon, Text, ListItem, List } from '@chakra-ui/core';
import { getFolderPath } from '../../routes';
import { check } from '../../../roleBasedAccess/Can';
import { RBArules } from '../../../roleBasedAccess/rules';

const BLOCKS_LABELS = {
  bloc_0: (
    <React.Fragment>
      Ouverture de <br /> dossier
    </React.Fragment>
  ),
  bloc_fio: 'Diagnostic socio-administratif',
  bloc_c: (
    <React.Fragment>
      Vérification des <br /> pièces
    </React.Fragment>
  ),
  bloc_a: 'Diagnostic ergothérapique',
  bloc_d: 'Préparation de la demande de devis',
  bloc_e: 'Demande de devis',
  bloc_f: 'Analyse de devis',
  bloc_g: 'Validation du financement',
  bloc_h: 'Mise en oeuvre des préconisations',
  bloc_i: 'Synthèse et clôture',
};

const initialNavItems = [
  {
    id: 'caracteristiques-du-projet',
    label: (
      <React.Fragment>
        Caractéristiques
        <br /> du projet
      </React.Fragment>
    ),
  },
  { id: 'statut-d-occupation', label: "Statut d'occupation" },
  { id: 'situation-personnelle', label: 'Situation personnelle' },
  { id: 'situation-economique', label: 'Situation économique' },
];

const BlocFioFormNavigation = () => {
  const [navItems, setNavItems] = useState(initialNavItems);

  const isInView = (element, offset = 50) => {
    const parent = document.querySelector("[role='tabpanel']:first-of-type");
    const parentViewTop = parent.scrollTop;
    const parentViewBottom = parentViewTop + parent.clientHeight;

    const childTop = element.offsetTop;
    const childBottom = childTop + element.clientHeight;

    return childTop <= parentViewBottom - offset && childBottom >= parentViewTop + offset;
  };

  const spy = () => {
    const items = navItems
      .filter(navItem => document.getElementById(navItem.id))
      .map(navItem => {
        const element = document.getElementById(navItem.id);
        return {
          ...navItem,
          inView: isInView(element),
        };
      });
    setNavItems(items);
  };

  useEffect(() => {
    const timer = setInterval(() => spy(), 50);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box borderLeft="thin solid" p="0.25rem 1rem 0.5rem 1.5rem" fontSize="xs">
      <Box pl={4} pr={2} pt={5}>
        {navItems.map(({ id, label, inView }) => (
          <InnerNavLink key={id} to={`#${id}`} isActive={() => inView}>
            {label}
          </InnerNavLink>
        ))}
      </Box>
    </Box>
  );
};

const InnerNavLink = props => {
  const scrollIntoView = e => {
    e.preventDefault();
    const anchor = document.querySelector(props.to);
    const anchorSectionButton = document.getElementById(
      `accordion-header-${props.to.replace('#', '')}`,
    );

    if (anchor) {
      document.querySelector('[role="tabpanel"]').scrollTop = anchorSectionButton.offsetTop - 50;
      if (anchorSectionButton && anchorSectionButton.getAttribute('aria-expanded') === 'false') {
        anchorSectionButton.click();
      }
    }
  };

  return (
    <NavLink
      onClick={scrollIntoView}
      css={theme => css`
        display: block;
        font-weight: 300;
        font-family: ${theme.fonts.heading};
        &.active {
          font-weight: 600;
        }
        &:hover {
          color: ${theme.colors.red};
        }
        &:not(:first-of-type) {
          margin-top: ${theme.space[3]};
        }
      `}
      {...props}
    />
  );
};

const BLOCS_INNER_NAVIGATION = {
  bloc_fio: BlocFioFormNavigation,
};

export const FolderNav = ({ blocs, folderId, currentBloc = 'bloc_0', user }) => {
  const blocsList = [
    'bloc_0',
    ...blocs.filter(blocId => !blocId.match('bloc_0') && blocId !== 'bloc_i'),
  ];

  const isDisabled = blocId => {
    const indexOfCurrentBloc = blocs.indexOf(currentBloc);
    const indexOfBloc = blocs.indexOf(blocId);
    return (
      indexOfBloc > indexOfCurrentBloc ||
      (!check(RBArules, user?.role, `folder:${blocId}:read`, { user }) &&
        !check(RBArules, user?.role, `folder:read`, { user }))
    );
  };

  return (
    <Box d="flex" flexDir="column">
      <Box
        as="nav"
        bg="white"
        shadow="layout"
        pl="1.375rem"
        pt="1.125rem"
        w="220px"
        height={0}
        flexGrow="1"
        position="relative"
        overflowY="auto"
      >
        <List as="ul" listStyleType="none" alignItems="start">
          {blocsList.map(blocId => {
            return (
              <FolderNavLink
                key={blocId}
                disabled={isDisabled(blocId)}
                current={currentBloc.match(blocId)}
                to={getFolderPath(blocId, folderId, '')}
                folderId={folderId}
                InnerNavigation={BLOCS_INNER_NAVIGATION[blocId]}
              >
                {BLOCKS_LABELS[blocId]}
              </FolderNavLink>
            );
          })}
        </List>
      </Box>
    </Box>
  );
};

FolderNav.prototype = {
  blocs: PropTypes.arrayOf(
    PropTypes.oneOf([
      'bloc_0_contact',
      'bloc_fio',
      'bloc_0_contract',
      'bloc_c',
      'bloc_0_validation',
      'bloc_a',
      'bloc_d',
      'bloc_e',
      'bloc_f',
      'bloc_g',
      'bloc_h',
      'bloc_i',
    ]),
  ).isRequired,
  folderId: PropTypes.string.isRequired,
  currentBloc: PropTypes.oneOf([
    'bloc_0_contact',
    'bloc_fio',
    'bloc_0_contract',
    'bloc_c',
    'bloc_0_validation',
    'bloc_a',
    'bloc_d',
    'bloc_e',
    'bloc_f',
    'bloc_g',
    'bloc_h',
    'bloc_i',
  ]),
};

FolderNav.defaultProps = {
  currentBloc: 'bloc_0',
};

const FolderNavLink = ({ children, InnerNavigation, current, folderId, ...props }) => {
  const match = useRouteMatch({ path: getFolderPath('bloc_fio', folderId, '') });

  return (
    <ListItem
      as="li"
      css={theme => css`
        &:first-of-type {
          a {
            padding-top: 0.531rem;
            svg {
              top: calc(50% - 0.5 * 0.531rem);
            }
          }
        }
        ${current
          ? `font-weight: 600; svg { width: ${theme.space[3]}; height: ${theme.space[3]}; }`
          : ''}
      `}
    >
      <FolderNavLinkText {...props}>
        <Icon
          name="dot"
          size={0}
          position="absolute"
          left={0}
          top="50%"
          transform="translate(-50%, -50%)"
          transition="0.3s ease"
        />
        {children}
      </FolderNavLinkText>

      {InnerNavigation && match && <InnerNavigation folderId={folderId} />}
    </ListItem>
  );
};

const FolderNavLinkText = ({ children, ...props }) => {
  const stateStyle = useMemo(() => {
    if (props.disabled) {
      return {
        css: theme =>
          css`
            transition: none;
            position: relative;
            border-color: currentColor;
            color: ${theme.colors.lightGray2};
            &.active {
              font-weight: 700;
            }
          `,
      };
    }

    return {
      as: NavLink,
      to: props.to,
      css: theme =>
        css`
          position: relative;

          &.active {
            font-weight: 700;
            padding: 0 1.25rem;
            span {
              display: block;
              padding: 1.062rem 0.75rem;
              border-radius: 8px;
              background: rgba(227, 230, 233, 70%);
            }
          }
          &:hover:not(.active) {
            color: ${theme.colors.red};
            border-color: black;
            svg {
              color: black;
            }
          }
        `,
    };
  }, [props.disabled, props.to]);

  return (
    <Box d="block" borderLeft="thin solid" padding="1.062rem 1.25rem" {...stateStyle} {...props}>
      <Text as="span" fontSize="sm" d="block" transition="opacity 0.3s ease" color="inherit">
        {children}
      </Text>
    </Box>
  );
};
