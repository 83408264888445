import { toastConstants } from './constants';

export function toast(state = {}, action) {
  switch (action.type) {
    case toastConstants.TOAST_SUCCESS:
      return {
        type: 'toast-success',
        title: 'Succès',
        message: action.message,
      };

    case toastConstants.TOAST_ERROR:
      return {
        type: 'toast-error',
        title: 'Attention',
        message: action.message,
      };

    case toastConstants.TOAST_CLEAR:
      return {};

    default:
      return state;
  }
}
