import React, { useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route, useLocation, Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Box } from '@chakra-ui/core';
import { Global, css } from '@emotion/core';

import { PageTitle } from '../../common/components/Titles';
import { SecondaryButton } from '../../common/components/Buttons';
import { configActions } from '../../redux/config/actions';
import { FolderNav } from '../../common/components/Folder/FolderNav';
import { FolderInfos, FolderInfosContent } from '../../common/components/Folder/FolderInfos';
import { getFolderPath } from '../../common/routes';
import { FormSubmitDisabledProvider } from '../../common/components/Form/FormSubmitDisabled.context';

import Bloc0 from './blocs/Bloc0';
import BlocFio from './blocs/BlocFio';
import BlocC from './blocs/BlocC';
import BlocA from './blocs/BlocA';
import BlocD from './blocs/BlocD';
import BlocE from './blocs/BlocE';
import BlocF from './blocs/BlocF';
import BlocG from './blocs/BlocG';
import BlocH from './blocs/BlocH';
import BlocI from './blocs/BlocI';
import { PageError404 } from '../../errors/components';
import Can, { checkPermissions } from '../../roleBasedAccess/Can';
import Comments from '../../common/components/Comments/Comments';
import { foldersActions } from '../../redux/folders/actions';

const BLOCS_COMPONENTS = {
  bloc_0: Bloc0,
  bloc_fio: BlocFio,
  bloc_c: BlocC,
  bloc_a: BlocA,
  bloc_d: BlocD,
  bloc_e: BlocE,
  bloc_f: BlocF,
  bloc_g: BlocG,
  bloc_h: BlocH,
  bloc_i: BlocI,
};

const FolderEdit = ({
  match: {
    params: { folderId },
  },
  config,
  folder,
  updateFolder,
  getConfig,
  user,
}) => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    let timer;

    if (hash !== '') {
      timer = setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.closest('[role="tabpanel"]').scrollTop = element.offsetTop - 50;
        }
      }, 0);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [hash, pathname]);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  const availableBlocs = useMemo(
    () => (config.folderTypes && config.folderTypes[folder.folderType || 'default']) || ['bloc_0'],
    [config, folder],
  );

  return (
    <Box d="flex" flexGrow="1" maxH="100vh" overflow="hidden">
      <Global
        styles={css`
          html {
            overflow: hidden;
          }
          *::-webkit-scrollbar {
            width: 7px;
            border-radius: 20px;
          }

          *::-webkit-scrollbar-track {
            background: rgba(54, 61, 68, 0.2);
            border-radius: 20px;
          }

          *::-webkit-scrollbar-thumb {
            background: rgba(54, 61, 68, 0.6);
            border-radius: 20px;
          }

          *::-webkit-scrollbar-thumb:hover {
            background: rgba(54, 61, 68, 0.8);
          }
        `}
      />
      <FolderNav
        folderId={folderId}
        blocs={availableBlocs}
        currentBloc={folder.currentBloc || 'bloc_0'}
        user={user}
      />
      <Box px={8} pt={5} pb={6} flexGrow={1} height="100%" d="flex" flexDirection="column">
        <Box d="flex" alignItems="center" mb={6}>
          <PageTitle mr={4}>Dossier n°{folder.formattedId}</PageTitle>
          {(checkPermissions(user?.role, `folder:bloc_i:read`, { user }) ||
            checkPermissions(user?.role, `folder:read`, { user })) && (
            <SecondaryButton
              as={NavLink}
              to={getFolderPath('bloc_i', folderId)}
              size="sm"
              py="0.4rem"
              px={3}
              ml={2}
            >
              Synthèse et clôture
            </SecondaryButton>
          )}
          <Can
            perform="comments:read"
            yes={() => (
              <Box ml={2}>
                <Comments
                  folder={{
                    formattedId: folder.formattedId,
                    id: folder.id,
                    state: folder.state,
                    recipientFirstName: folder?.recipient?.firstName,
                    recipientLastName: folder?.recipient?.lastName,
                  }}
                  comments={folder.comments}
                  updateFallback={updateFolder}
                  variant="full"
                />
              </Box>
            )}
          />
          <FolderInfos ml="auto">
            <FolderInfosContent folder={folder} />
          </FolderInfos>
        </Box>

        <FormSubmitDisabledProvider
          isFormSubmitDisabled={
            folder.state === 'Cloturé' || !checkPermissions(user.role, 'folder:edit', { user })
          }
        >
          {config.folderTypes && (
            <Box flexGrow="1">
              <Switch>
                {config.folderTypes
                  ? config.folderTypes.AMOc.map(bloc => {
                      let blocId = bloc;
                      if (blocId.match(/contract|validation/)) return null;
                      if (blocId.match(/contact/)) blocId = 'bloc_0';

                      const Bloc = BLOCS_COMPONENTS[blocId] || Box;

                      if (
                        checkPermissions(user.role, 'folder:read', { user }) ||
                        checkPermissions(user.role, `folder:${blocId}:read`, { user })
                      ) {
                        return (
                          <Route
                            key={blocId}
                            path={getFolderPath(blocId, ':folderId', '')}
                            render={props => <Bloc {...props} blocId={blocId} />}
                          />
                        );
                      }

                      if (checkPermissions(user.role, 'folder:bloc:read', { user })) {
                        return (
                          <Redirect
                            key={blocId}
                            from={getFolderPath(blocId, folderId, '')}
                            to={getFolderPath('bloc_i', folderId, '')}
                          />
                        );
                      }

                      return null;
                    })
                  : null}
                <Route component={PageError404} />
              </Switch>
            </Box>
          )}
        </FormSubmitDisabledProvider>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ config, folders: { folder }, authentication: { user } }) => {
  return { config, folder, user };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getConfig: configActions.getConfig,
      updateFolder: foldersActions.getFolderFromBloc,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FolderEdit);
