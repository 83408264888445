import React, { useState, useEffect } from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Formik, useFormikContext } from 'formik';

import { Stack, Box } from '@chakra-ui/core';
import { FormSubmitButton } from '../../../../common/components/Form/Form';
import { TabForm } from '../../../../common/components/Tabs';
import { Section } from './components';
import { TabAccordionItem } from '../../../../common/components/Accordion';
import { useIsMounted } from '../../../../common/utils/useIsMounted.hook';

const formSections = [
  { id: 'caracteristiques-du-projet', title: 'Caractéristiques du projet', indexes: [0, 5] },
  { id: 'statut-d-occupation', title: "Statut d'occupation", indexes: [5, 11] },
  { id: 'situation-personnelle', title: 'Situation personnelle', indexes: [11, 19] },
  { id: 'situation-economique', title: 'Situation économique', indexes: [19, 25] },
];

export const BlocFioTab1 = ({
  blocFio: {
    questions = [],
    questionsValues = {},
    anahReferenceTable,
    mpaReferenceTable,
    folder = {},
    preliminaryFolder = {},
  },
  onSubmit,
}) => {
  const [currentQuestion, setCurrentQuestion] = useState('');
  const [nextQuestion, setNextQuestion] = useState('');
  const [questionsDisabled, setQuestionsDisabled] = useState({});
  const [currentSection, setCurrentSection] = useState({});
  const [disabledSections, setDisabledSections] = useState(formSections);
  const [isEndForm, setIsEndForm] = useState(false);

  useEffect(() => {
    const currentSectionButton = document.getElementById(`accordion-header-${currentSection?.id}`);

    // Open accordion with nextQuestion in it only if the accordion is not opened yet
    if (currentSectionButton && currentSectionButton.getAttribute('aria-expanded') === 'false') {
      currentSectionButton.click();
    }
  }, [currentSection]);

  const cleanQuestionValue = questionKey => {
    const [, question] = questions.find(
      ([currentQuestionKey]) => currentQuestionKey === questionKey,
    );

    switch (question.answerType) {
      case 'simple_choice':
      case 'number_person':
      case 'number_currency':
        return null;
      case 'multiple_choice':
      case 'custom_multiple_choice_conditional':
      case 'custom_multiple_choice':
        return [];
      default:
        return null;
    }
  };

  const cleanDisableQuestionsAnswers = values => {
    return Object.entries(values).reduce((acc, [questionKey, questionValue]) => {
      return {
        ...acc,
        [questionKey]:
          questionsDisabled[questionKey] && !questionKey.match(/q_/)
            ? cleanQuestionValue(questionKey)
            : questionValue,
      };
    }, {});
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={questionsValues}
      onSubmit={(values, { setSubmitting }) => {
        const cleanedValues = cleanDisableQuestionsAnswers(values);
        return onSubmit(cleanedValues, folder).then(() => setSubmitting(false));
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit} maxW="none">
          {Object.keys(formik.values).length > 0 && (
            <React.Fragment>
              <BlocFioFormState
                setQuestionsDisabled={setQuestionsDisabled}
                setCurrentQuestion={setCurrentQuestion}
                setNextQuestion={setNextQuestion}
                nextQuestion={nextQuestion}
                setCurrentSection={setCurrentSection}
                setDisabledSections={setDisabledSections}
                anahReferenceTable={anahReferenceTable}
                mpaReferenceTable={mpaReferenceTable}
                questionsDisabled={questionsDisabled}
                setIsEndForm={setIsEndForm}
              />
              <Stack spacing={10}>
                {nextQuestion &&
                  formSections.map(({ title, id, ...formSection }) => (
                    <Box key={id} id={id}>
                      <TabAccordionItem
                        key={formSection.id}
                        id={id}
                        title={title}
                        isActive={currentSection?.id === id}
                        isDisabled={
                          !!disabledSections.find(disabledSection => disabledSection.id === id)
                        }
                      >
                        <Section
                          questions={questions}
                          currentQuestion={currentQuestion}
                          nextQuestion={nextQuestion}
                          questionsDisabled={questionsDisabled}
                          isEndForm={isEndForm}
                          preFilledInfo={preliminaryFolder}
                          {...formSection}
                        />
                      </TabAccordionItem>
                    </Box>
                  ))}
              </Stack>
            </React.Fragment>
          )}
          <FormSubmitButton type="submit" isLoading={formik.isSubmitting} loadingText="Enregistrer">
            Enregistrer
          </FormSubmitButton>
        </TabForm>
      )}
    </Formik>
  );
};

const BlocFioFormState = ({
  setQuestionsDisabled,
  setCurrentQuestion,
  setNextQuestion,
  nextQuestion,
  setCurrentSection,
  setDisabledSections,
  anahReferenceTable,
  mpaReferenceTable,
  setIsEndForm,
}) => {
  const isMounted = useIsMounted();
  const { values } = useFormikContext();

  useEffect(() => {
    if (nextQuestion) {
      // Current section contains next question
      const currentSection = formSections.find(formSection =>
        Object.keys(values)
          .slice(formSection.indexes[0], formSection.indexes[1])
          .includes(nextQuestion),
      );

      // A disabled section is a section with every questions having no answer
      const disabledSections = formSections.filter(
        formSection =>
          Object.values(values)
            .slice(formSection.indexes[0], formSection.indexes[1])
            .every(val => {
              if (val) {
                if (val.length > 1) return val.includes('');
                return !val.length;
              }
              return true;
            }) && formSection.id !== currentSection?.id,
      );

      setCurrentSection(currentSection);
      setDisabledSections(disabledSections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextQuestion]);

  const checkANAH = () => {
    let region = 'province';
    if (['75', '77', '78', '91', '92', '93', '94', '95'].includes(values.q0.slice(0, 2))) {
      region = 'idf';
    }

    if (values.q9 <= 5) {
      return anahReferenceTable[region].resources[values.q9] >= values.q17;
    }

    return (
      anahReferenceTable[region].resources[5] +
        anahReferenceTable[region].surplus * (values.q9 - 5) >=
      values.q17
    );
  };

  const checkMPA = () => {
    let region = 'province';
    if (['75', '77', '78', '91', '92', '93', '94', '95'].includes(values.q0.slice(0, 2))) {
      region = 'idf';
    }

    if (values.q9 <= 5) {
      return mpaReferenceTable[region].resources[values.q9] >= values.q17;
    }

    return (
      mpaReferenceTable[region].resources[5] +
        mpaReferenceTable[region].surplus * (values.q9 - 5) >=
      values.q17
    );
  };

  useEffect(() => {
    if (isMounted) {
      const endWithQ4 = values.q4 && values.q4 === 'A' && values.q_2 === 'B' && values.q3;
      const endWithQ5 =
        values.q5.includes('G') &&
        values.q_2 === 'A' &&
        values.q1 === 'B' &&
        (values.q4 === 'B' || values.q4 === 'C');

      const endWithQ6 = values.q6 && values.q4 === 'B' && values.q_2 === 'B';

      const endWithQ7 = values.q7 && values.q4 === 'C' && values.q_2 === 'B';

      const isEndForm = endWithQ5 || endWithQ4 || endWithQ6 || endWithQ7;

      setIsEndForm(isEndForm);

      const questionsAvailability = {
        q1: true,
        q1Bis: values.q1 === 'B',
        get q1BisOther() {
          return questionsAvailability.q1Bis && values.q1Bis.includes('F');
        },
        q2: (values.q1 === 'B' && values.q1Bis) || values.q1,
        q3: values.q2,
        q4: values.q3,
        q5: values.q_2 === 'A' && values.q1 === 'B' && (values.q4 === 'B' || values.q4 === 'C'),
        get q6() {
          return (
            values.q4 === 'B' &&
            (values.q_2 === 'B' ||
              (questionsAvailability.q5 && ['A', 'H'].every(answer => values.q5.includes(answer))))
          );
        },
        get q7() {
          return (
            values.q4 === 'C' &&
            (values.q_2 === 'B' ||
              (questionsAvailability.q5 && ['A', 'H'].every(answer => values.q5.includes(answer))))
          );
        },
        get q8() {
          return !isEndForm && questionsAvailability.q7 && (values.q7 === 'A' || values.q7 === 'B');
        },
        get q9() {
          return !isEndForm && questionsAvailability.q4 && values.q4;
        },
        q10: !isEndForm && values.q_2 === 'A' && values.q_1 >= 55,
        q11: !isEndForm && values.q_2 === 'A' && values.q_1 >= 16,
        q11Bis: !isEndForm && values.q11 && values.q11 !== 'B',
        get q12() {
          return questionsAvailability.q10;
        },
        get q13() {
          return questionsAvailability.q12 && values.q12 === 'A';
        },
        get q14() {
          return questionsAvailability.q12 && values.q12 === 'B';
        },
        q15: !isEndForm && values.q_2 === 'A' && values.q_1 >= 60 && values.q10 === 'B',
        q16: !isEndForm && values.q_2 === 'A' && values.q_1 >= 60 && values.q_1 < 75,
        get q17() {
          return questionsAvailability.q9;
        },
        get q18() {
          return values.q4 === 'A' || values.q6 === 'A' || values.q7 === 'A' || values.q7 === 'B';
        },
        get q19() {
          return questionsAvailability.q18 && values.q18 === 'A';
        },
        get q20() {
          return (
            !isEndForm &&
            ((values.q_2 === 'A' &&
              values.q_1 >= 55 &&
              values.q1 === 'B' &&
              values.q4 === 'A' &&
              values.q10 === 'A' &&
              values.q11 === 'A' &&
              questionsAvailability.q13 &&
              (values.q13 === 'E' || values.q13 === 'F')) ||
              (values.q_2 === 'A' &&
                values.q_1 >= 55 &&
                values.q1 === 'B' &&
                values.q4 === 'A' &&
                values.q10 === 'A' &&
                values.q11 === 'A' &&
                questionsAvailability.q14 &&
                (values.q14 === 'A' || values.q14 === 'B')) ||
              (values.q_2 === 'A' &&
                values.q_1 >= 55 &&
                values.q1 === 'B' &&
                questionsAvailability.q6 &&
                values.q6 === 'A' &&
                values.q10 === 'A' &&
                values.q11 === 'A' &&
                questionsAvailability.q13 &&
                (values.q13 === 'E' || values.q13 === 'F')) ||
              (values.q_2 === 'A' &&
                values.q_1 >= 55 &&
                values.q1 === 'B' &&
                questionsAvailability.q6 &&
                values.q6 === 'A' &&
                values.q10 === 'A' &&
                values.q11 === 'A' &&
                questionsAvailability.q14 &&
                (values.q14 === 'A' || values.q14 === 'B')) ||
              (values.q_2 === 'A' &&
                values.q_1 >= 55 &&
                values.q1 === 'B' &&
                questionsAvailability.q8 &&
                values.q10 === 'A' &&
                values.q11 === 'A' &&
                questionsAvailability.q13 &&
                (values.q13 === 'E' || values.q13 === 'F')) ||
              (values.q_2 === 'A' &&
                values.q_1 >= 55 &&
                values.q1 === 'B' &&
                questionsAvailability.q8 &&
                values.q10 === 'A' &&
                values.q11 === 'A' &&
                questionsAvailability.q14 &&
                (values.q14 === 'A' || values.q14 === 'B')) ||
              (values.q_2 === 'A' && values.q11 === 'B'))
          );
        },
        q21: !isEndForm && values.q_2 === 'A',
        get q22() {
          return questionsAvailability.q21;
        },
      };

      const questionsAvailable = Object.keys(questionsAvailability).filter(
        question => questionsAvailability[question],
      );

      const newQuestionsDisabled = Object.entries(questionsAvailability).reduce(
        (acc, [question, availability]) => {
          const index = questionsAvailable.indexOf(question);
          return {
            ...acc,
            [question]:
              // A question is disabled if it is not available or
              // at least one available question before it is not answered yet
              !availability ||
              !questionsAvailable.slice(0, index).every(questionAvailable => {
                if (values[questionAvailable]) {
                  if (values[questionAvailable].length === 0) return false;
                  if (
                    values[questionAvailable].length > 1 &&
                    typeof values[questionAvailable] !== 'string' &&
                    values[questionAvailable].some(answer => answer === '' || answer === 'NSP')
                  )
                    return false;
                  return true;
                }
                return false;
              }),
          };
        },
        {},
      );

      setQuestionsDisabled(newQuestionsDisabled);

      const questionsEnabled = Object.keys(newQuestionsDisabled).filter(
        question => !newQuestionsDisabled[question],
      );

      const newNextQuestion = questionsEnabled[questionsEnabled.length - 1];
      if (nextQuestion !== newNextQuestion) {
        setNextQuestion(newNextQuestion);
        setCurrentQuestion(questionsEnabled[questionsEnabled.length - 2]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return null;
};
