import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageDefault } from '../common/components/layouts/Page/PageDefault';
import { UserForm } from '../common/components/UserForm/UserForm';
import { operatorsActions } from '../redux/operators/actions';
import Can from '../roleBasedAccess/Can';
import { PrimaryButton } from '../common/components/Buttons';

const OperatorEdit = ({
  match: {
    params: { operatorId },
  },
  operator,
  error,
  pending,
  deletePending,
  getOperator,
  updateOperator,
  deleteOperator,
}) => {
  useEffect(() => {
    getOperator(operatorId);
  }, [operatorId, getOperator]);

  const onSubmit = updatedOperatorValues => {
    updateOperator(operatorId, updatedOperatorValues);
  };

  const onDeleteOperator = () => {
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(
      `Voulez-vous supprimer l'opérateur n°${operator.id} - ${operator.firstName} ${operator.lastName} ?`,
    );
    if (confirm) deleteOperator(operatorId);
  };

  return (
    <PageDefault
      title="édition de la fiche opérateur"
      pageAction={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Can
          perform="delete"
          yes={() => (
            <PrimaryButton
              isLoading={deletePending}
              loadingText="En supression..."
              onClick={onDeleteOperator}
            >
              Supprimer
            </PrimaryButton>
          )}
        />
      }
    >
      <UserForm
        initialValues={operator}
        pending={pending}
        onSubmit={onSubmit}
        error={error}
        mode="edit"
      />
    </PageDefault>
  );
};

const mapStateToProps = ({ operators: state }) => ({
  error: state.error,
  pending: state.pending,
  deletePending: state.deletePending,
  operator: state.operator,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOperator: operatorsActions.getOperator,
      updateOperator: operatorsActions.updateOperator,
      deleteOperator: operatorsActions.deleteOperator,
    },
    dispatch,
  );

OperatorEdit.propTypes = {
  pending: PropTypes.bool,
  deletePending: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  getOperator: PropTypes.func.isRequired,
  updateOperator: PropTypes.func.isRequired,
  deleteOperator: PropTypes.func.isRequired,
};

OperatorEdit.defaultProps = {
  pending: true,
  deletePending: false,
  error: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(OperatorEdit);
