/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, PseudoBox, Icon, Text } from '@chakra-ui/core';
import { Link } from 'react-router-dom';

const cellPadding = {
  sm: { px: 1, py: 2 },
  md: {
    px: 'td.px',
    py: 'td.py',
  },
};

export const Table = ({ children, ...props }) => {
  return (
    <Box as="table" w="100%" borderCollapse="seperate" tableLayout="fixed" {...props}>
      {children}
    </Box>
  );
};

export const Thead = ({ children, ...props }) => {
  return (
    <Box as="thead" {...props}>
      {children}
    </Box>
  );
};

export const TH = ({ children, size = 'md', ...props }) => {
  return (
    <Box
      as="th"
      px={cellPadding[size].px}
      py={cellPadding[size].py}
      borderBottom="thin solid"
      borderColor="coral.900"
      {...props}
    >
      {children}
    </Box>
  );
};

export const THeadRow = ({ children, ...props }) => {
  return (
    <PseudoBox as="tr" fontWeight="bold" textAlign="left" color="semiDarkDray.900" {...props}>
      {children}
    </PseudoBox>
  );
};

export const TBodyRow = ({ children, enableHover = true, ...props }) => {
  const hover = {
    color: 'semiDarkGray.900',
    textStroke: '0.5px currentColor',
    WebkitTextStroke: '0.5px currentColor',
    '.edit': {
      display: 'inline-block',
    },
  };

  return (
    <PseudoBox
      as="tr"
      transition="transform 0.3s ease, box-shadow 0.3s ease"
      bg="white"
      color="gray"
      rounded="4px"
      position="relative"
      zIndex="1"
      _hover={enableHover ? hover : null}
      _focusWithin={{ zIndex: 200 }}
      {...props}
    >
      {children}
    </PseudoBox>
  );
};

export const TD = ({ children, isLast, hasPadding = false, size = 'md', ...props }) => {
  return (
    <PseudoBox
      as="td"
      borderBottom="thin solid"
      borderColor="table.row.borderColor"
      _first={{ borderRadius: '4px 0 0 4px' }}
      _last={{ borderRadius: '0 4px 4px 0', position: 'relative', pr: isLast ? 5 : null }}
      {...(hasPadding ? { px: cellPadding[size].px, py: cellPadding[size].py } : {})}
      {...props}
    >
      {children}
      {isLast && (
        <PseudoBox
          as="span"
          className="edit"
          d="none"
          position="absolute"
          top="50%"
          right="20px"
          transform="translateY(-50%)"
          pointerEvents="none"
        >
          <Icon name="edit" size={4} color="coral.900" />
        </PseudoBox>
      )}
    </PseudoBox>
  );
};

export const TablePlaceholder = props => (
  <THeadRow>
    <TD colSpan="5" h={40} border={0} color="gray" textAlign="center" {...props}>
      {props.children || 'Chargement...'}
    </TD>
  </THeadRow>
);

export const TableEditLink = ({ children, size = 'md', ...props }) => {
  return (
    <Box
      as={Link}
      d="block"
      h="100%"
      w="100%"
      px={cellPadding[size].px}
      py={cellPadding[size].py}
      {...props}
    >
      {children}
    </Box>
  );
};

export const TableResults = ({ total, style, ...props }) => {
  return (
    <PseudoBox d="flex" alignItems="center" w="100%" mt="9px" {...style}>
      <Text my="5px" mr="15px" color="placeholder" whiteSpace="nowrap" {...props}>
        <Text as="strong">{total}</Text> résultat{total > 1 && 's'}
      </Text>
      <PseudoBox
        pos="relative"
        w="100%"
        _after={{
          content: "''",
          w: '100%',
          h: '0',
          borderBottom: '1px solid #A9C6E6',
          pos: 'absolute',
          top: '50%',
          right: '5px',
          opacity: '0.4',
        }}
      />
    </PseudoBox>
  );
};
