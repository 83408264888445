import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PageDefault } from '../common/components/layouts/Page/PageDefault';
import { preFoldersActions } from '../redux/preFolders/actions';
import { PreFolderForm } from './components/PreFolderForm';
import routes from '../common/routes';

export const PreFolderCreateComponent = ({
  error,
  pending,
  createPreFolder,
  transformPreFolderIntoFolder,
}) => {
  const history = useHistory();
  const initialValues = {
    title: null,
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    birthDate: '',
    city: null,
    postalCode: null,
    streetAddress: null,
    contactTitle: null,
    contactFirstName: null,
    contactLastName: null,
    contactEmail: null,
    contactPhoneNumber: null,
    contactCity: null,
    contactPostalCode: null,
    contactStreetAddress: null,
    fioQ4Answer: null,
    fioQ13Answer: null,
    fioQ17Answer: null,
    fioQ20Answer: null,
    bloc_0DoesRecipientOwnTheHousing: null,
    bloc_0HousingCity: null,
    bloc_0HousingPostalCode: null,
    bloc_0HousingStreetAddress: null,
    recipientIsTheContact: 'true',
    sendDocumentsBy: 'email',
  };

  const onSubmit = updatedPreFolder =>
    createPreFolder(updatedPreFolder, false).then(newPreFolder => {
      history.push(`${routes.preFolders}/${newPreFolder.id}/edit`);
      return newPreFolder;
    });

  return (
    <PageDefault title="Création d'un pré-dossier">
      <PreFolderForm
        initialValues={initialValues}
        pending={pending}
        onSubmit={onSubmit}
        onTransform={updatedPreFolder => {
          createPreFolder(updatedPreFolder).then(data => transformPreFolderIntoFolder(data.id));
        }}
        error={error}
        mode="create"
      />
    </PageDefault>
  );
};

const mapStateToProps = ({ preFolders: state }) => ({
  error: state.error,
  pending: state.pending,
  preFolder: state.preFolder,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createPreFolder: preFoldersActions.createPreFolder,
      updatePreFolder: preFoldersActions.updatePreFolder,
      transformPreFolderIntoFolder: preFoldersActions.transformPreFolderIntoFolder,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(PreFolderCreateComponent);
