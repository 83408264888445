export const ergoOfficesConstants = {
  GET_ERGO_OFFICES_REQUEST: 'GET_ERGO_OFFICES_REQUEST',
  GET_ERGO_OFFICES_SUCCESS: 'GET_ERGO_OFFICES_SUCCESS',
  GET_ERGO_OFFICES_FAILURE: 'GET_ERGO_OFFICES_FAILURE',

  GET_ERGO_OFFICE_REQUEST: 'GET_ERGO_OFFICE_REQUEST',
  GET_ERGO_OFFICE_SUCCESS: 'GET_ERGO_OFFICE_SUCCESS',
  GET_ERGO_OFFICE_FAILURE: 'GET_ERGO_OFFICE_FAILURE',

  CREATE_ERGO_OFFICE_REQUEST: 'CREATE_ERGO_OFFICE_REQUEST',
  CREATE_ERGO_OFFICE_SUCCESS: 'CREATE_ERGO_OFFICE_SUCCESS',
  CREATE_ERGO_OFFICE_FAILURE: 'CREATE_ERGO_OFFICE_FAILURE',

  UPDATE_ERGO_OFFICE_REQUEST: 'UPDATE_ERGO_OFFICE_REQUEST',
  UPDATE_ERGO_OFFICE_SUCCESS: 'UPDATE_ERGO_OFFICE_SUCCESS',
  UPDATE_ERGO_OFFICE_FAILURE: 'UPDATE_ERGO_OFFICE_FAILURE',

  DELETE_ERGO_OFFICE_REQUEST: 'DELETE_ERGO_OFFICE_REQUEST',
  DELETE_ERGO_OFFICE_SUCCESS: 'DELETE_ERGO_OFFICE_SUCCESS',
  DELETE_ERGO_OFFICE_FAILURE: 'DELETE_ERGO_OFFICE_FAILURE',
};
