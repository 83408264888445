import { companiesConstants } from './constants';

export const initialState = { data: [], company: {} };

export function companies(state = initialState, action) {
  switch (action.type) {
    case companiesConstants.GET_COMPANIES_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case companiesConstants.GET_COMPANIES_SUCCESS:
      return {
        data: action.data.companies,
        meta: action.data.meta,
      };
    case companiesConstants.GET_COMPANY_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case companiesConstants.GET_COMPANY_SUCCESS:
      return {
        ...initialState,
        pending: false,
        company: action.company,
      };
    case companiesConstants.GET_COMPANY_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case companiesConstants.CREATE_COMPANY_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case companiesConstants.CREATE_COMPANY_SUCCESS:
      return initialState;
    case companiesConstants.CREATE_COMPANY_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case companiesConstants.UPDATE_COMPANY_REQUEST:
      return {
        ...initialState,
        company: action.company,
        pending: true,
      };
    case companiesConstants.UPDATE_COMPANY_SUCCESS:
      return {
        ...initialState,
        pending: false,
      };
    case companiesConstants.UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };

    case companiesConstants.DELETE_COMPANY_REQUEST:
      return {
        ...state,
        deletePending: true,
      };
    case companiesConstants.DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        deletePending: false,
      };
    case companiesConstants.DELETE_COMPANY_FAILURE:
      return {
        ...state,
        error: action.error,
        deletePending: false,
      };
    case companiesConstants.GET_COMPANIES_FAILURE:
    default:
      return state;
  }
}
