import React, { useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PseudoBox,
  Stack,
  Text,
  Textarea,
  useDisclosure,
} from '@chakra-ui/core';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Field, Form, Formik } from 'formik';
import { connect, useSelector } from 'react-redux';
import { PrimaryButton } from '../Buttons';
import { foldersAPI } from '../../api/folders';
import { CheckBoxInput, ErrorMessage } from '../Form/FormInputs';
import { checkPermissions } from '../../../roleBasedAccess/Can';

const Comments = ({ folder, comments, updateFallback, user, isInline, variant = 'ghost' }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onAddComment = (values, { setSubmitting, setErrors, resetForm }, successFallback) => {
    return foldersAPI
      .editFolder(folder.id, {
        comments_attributes: [{ ...values, userId: user.id }],
      })
      .then(data => {
        setSubmitting(false);
        updateFallback({ id: folder.id, comments: data.folder.comments });
        resetForm();
        successFallback();
      })
      .catch(err => {
        setSubmitting(false);
        if (err.response) setErrors(err.response.data.errors);
      });
  };

  const CommentsSection = () => {
    return (
      <>
        <CommentsForm user={user} onSubmit={onAddComment} isDisabled={folder.state === 'Cloturé'} />
        <Text mb={3} mt={8} color="gray" fontSize="sm">
          {comments.length} commentaire(s)
        </Text>
        <Stack spacing={4}>
          {comments
            .sort(
              (commentA, commentB) => new Date(commentB.createdAt) - new Date(commentA.createdAt),
            )
            .map(comment => (
              <CommentsForm
                key={comment.id}
                onSubmit={onAddComment}
                initialComment={comment}
                isDisabled={folder.state === 'Cloturé'}
              />
            ))}
        </Stack>
      </>
    );
  };

  const buttonStyle =
    variant === 'ghost'
      ? {
          variant: 'ghost',
          color: 'coral.900',
          _hover: { color: 'white', backgroundColor: 'coral.900' },
        }
      : {
          color: 'coral.900',
          border: '1px solid',
          borderColor: 'currentColor',
          _hover: { color: 'white', borderColor: 'coral.900', bg: 'coral.900' },
        };

  if (isInline) {
    return <CommentsSection />;
  }

  return (
    <>
      <Button
        aria-label="Commentaires"
        onClick={onOpen}
        variantColor={null}
        p="2px"
        height="32px"
        minWidth="32px"
        rounded="full"
        _focus={{ shadow: 'none' }}
        {...buttonStyle}
      >
        <Icon name="comment" size={4} />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent rounded="lg">
          <ModalHeader as="div" bg="darkGray" color="white" roundedTop="lg">
            <Heading as="h2" fontSize="xl" fontWeight="bold">
              <Text
                as="span"
                fontFamily="heading"
                opacity="0.5"
                textTransform="uppercase"
                fontWeight="600"
              >
                Dossier n°{' '}
              </Text>
              {folder.formattedId}{' '}
              <Text as="span" d="inline-block" mx={2} opacity="0.65">
                -
              </Text>{' '}
              <Text mt={2} as="small">
                <Text as="span" fontWeight="semibold" textTransform="uppercase">
                  {folder.recipientLastName || '-'}
                </Text>{' '}
                {folder.recipientFirstName || '-'}
              </Text>
            </Heading>
            <Text as="small" fontFamily="heading" fontSize="sm">
              <Icon
                name="dot"
                size={2}
                verticalAlign="middle"
                color={
                  // eslint-disable-next-line no-nested-ternary
                  folder.state === 'Actif'
                    ? 'green.900'
                    : folder.state === 'Cloturé'
                    ? 'red'
                    : 'orange.900'
                }
              />{' '}
              {folder.state}
            </Text>
          </ModalHeader>
          <ModalCloseButton color="white" _focus={{ shadow: 'none' }} />
          <ModalBody pb={5}>
            <CommentsSection />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ authentication: { user } }) => ({
  user,
});

export default connect(mapStateToProps)(Comments);

const CommentsForm = ({ onSubmit, initialComment, isDisabled, ...props }) => {
  const [isEditing, setIsEditing] = useState(!initialComment);
  const { profile, user } = useSelector(state => state.authentication);
  const currentUser = Object.keys(profile || {}).length === 0 ? user : profile;

  const canCreateSensitiveComments = checkPermissions(
    currentUser?.role,
    'comments:create:sensitive',
    { currentUser },
  );
  const canDeleteComment =
    checkPermissions(currentUser?.role, 'comments:delete') ||
    initialComment?.creator?.id === currentUser?.id;
  const canEditComment =
    checkPermissions(currentUser?.role, 'comments:edit') ||
    initialComment?.creator?.id === currentUser?.id;

  const defaultValues = {
    text: '',
    isSensitive: checkPermissions(currentUser?.role, 'comments:create:sensitive'),
  };

  const successFallback = () => (initialComment ? setIsEditing(false) : null);

  return (
    <Formik
      enableReinitialize
      initialValues={initialComment || defaultValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, actions) => {
        return onSubmit(values, actions, successFallback);
      }}
    >
      {formik => (
        <PseudoBox
          as={Form}
          onSubmit={formik.handleSubmit}
          my={initialComment ? null : 5}
          _first={
            initialComment
              ? {
                  pt: 4,
                  borderTopWidth: 1,
                  borderTopColor: 'rgb(153 153 153 / 28%)',
                }
              : null
          }
          _notLast={
            initialComment
              ? {
                  borderBottomWidth: 1,
                  borderBottomColor: 'rgb(153 153 153 / 28%)',
                  pb: 4,
                }
              : null
          }
          {...props}
        >
          {!isEditing && Boolean(initialComment) ? (
            <DisplayComment comment={initialComment} />
          ) : null}
          {isEditing ? (
            <Field name="text">
              {({ field, form: { isSubmitting, errors, touched } }) => (
                <FormControl isInvalid={Boolean(touched[field.name] && errors.commentsText)}>
                  <Textarea
                    isInvalid={Boolean(touched[field.name] && errors.commentsText)}
                    rows={5}
                    height="auto"
                    placeholder={initialComment ? 'Éditer un commentaire' : 'Écrire un commentaire'}
                    borderColor="lightGray2"
                    _focus={{ outline: 0, borderColor: 'coral.900' }}
                    _invalid={{ borderColor: 'errorBorderColor' }}
                    resize="vertical"
                    disabled={isDisabled || isSubmitting}
                    {...field}
                  />
                  <ErrorMessage>{errors.commentsText}</ErrorMessage>
                </FormControl>
              )}
            </Field>
          ) : null}

          <Box d="flex" justifyContent="space-between" alignItems="center" mt={3}>
            <ButtonGroup d="flex" size={initialComment ? 'xs' : null} variantColor={null}>
              {!isEditing && Boolean(initialComment) && canEditComment ? (
                <Button
                  leftIcon="edit"
                  variant="ghost"
                  color="gray"
                  rounded="full"
                  _hover={{ backgroundColor: 'lightGray' }}
                  _focus={{ backgroundColor: 'lightGray' }}
                  disabled={isDisabled}
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  Éditer
                </Button>
              ) : null}

              {isEditing ? (
                <PrimaryButton type="submit" isLoading={formik.isSubmitting} disabled={isDisabled}>
                  {initialComment ? 'Enregistrer' : 'Commenter'}
                </PrimaryButton>
              ) : null}

              {isEditing ? (
                <Button
                  variant="ghost"
                  rounded="full"
                  type="reset"
                  variantColor={null}
                  _hover={{ backgroundColor: 'lightGray' }}
                  _focus={{ backgroundColor: 'lightGray' }}
                  onClick={initialComment ? () => setIsEditing(false) : null}
                  disabled={isDisabled}
                >
                  Annuler
                </Button>
              ) : null}

              {initialComment && canDeleteComment ? (
                <Button
                  variant="ghost"
                  ml="auto"
                  color="red"
                  leftIcon="trash"
                  variantColor={null}
                  _hover={{ backgroundColor: 'select.bg.isSelected' }}
                  _focus={{ backgroundColor: 'select.bg.isSelected' }}
                  rounded="full"
                  disabled={isDisabled}
                  onClick={() => {
                    // eslint-disable-next-line no-alert
                    const confirm = window.confirm(`Voulez-vous supprimer ce commentaire ?`);
                    if (confirm) {
                      formik.setFieldValue('_destroy', true);
                      formik.submitForm();
                    }
                  }}
                >
                  Supprimer
                </Button>
              ) : null}
            </ButtonGroup>
            {isEditing && canCreateSensitiveComments ? (
              <CheckBoxInput name="isSensitive">Commentaire sensible</CheckBoxInput>
            ) : null}
          </Box>
        </PseudoBox>
      )}
    </Formik>
  );
};

const DisplayComment = ({ comment }) => {
  const createdAt = new Date(comment.createdAt);
  const updatedAt = new Date(comment.updatedAt);

  return (
    <PseudoBox key={comment.id}>
      <Text d="inline-block" as="small" opacity={0.4} mb={1}>
        Publié par{' '}
        <strong>
          {comment.creator?.firstName} {comment.creator?.lastName?.toUpperCase()}
        </strong>
        , {comment.creator?.role} le {format(createdAt, "d LLL yyyy 'à' HH:mm", { locale: fr })}
        {createdAt < updatedAt ? (
          <>
            <br />
            Modifié par{' '}
            <strong>
              {comment.operator?.firstName} {comment.operator?.lastName?.toUpperCase()}
            </strong>
            , {comment.operator?.role} le{' '}
            {format(updatedAt, "d LLL yyyy 'à' HH:mm", {
              locale: fr,
            })}
          </>
        ) : null}
      </Text>
      <Text whiteSpace="pre-wrap">{comment.text}</Text>
    </PseudoBox>
  );
};
