import { blocGConstants } from './constants';

export const initialState = {
  pending: false,
};

export function blocG(state = initialState, action) {
  switch (action.type) {
    case blocGConstants.GET_BLOCG_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case blocGConstants.GET_BLOCG_SUCCESS:
      return {
        ...state,
        [action.blocG.folderId]: action.blocG,
        pending: false,
      };
    case blocGConstants.UPDATE_BLOCG_REQUEST:
      return {
        ...state,
        [action.blocG.folderId]: action.blocG,
        error: null,
        pending: true,
      };
    case blocGConstants.UPDATE_BLOCG_SUCCESS:
      return {
        ...state,
        [action.blocG.folderId]: action.blocG,
        error: null,
        pending: false,
      };
    case blocGConstants.UPDATE_BLOCG_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case blocGConstants.GET_BLOCG_FAILURE:
    default:
      return state;
  }
}
