export const ergoResourcesConstants = {
  GET_ERGO_RESOURCES_REQUEST: 'GET_ERGO_RESOURCES_REQUEST',
  GET_ERGO_RESOURCES_SUCCESS: 'GET_ERGO_RESOURCES_SUCCESS',
  GET_ERGO_RESOURCES_FAILURE: 'GET_ERGO_RESOURCES_FAILURE',

  GET_ERGO_RESOURCE_REQUEST: 'GET_ERGO_RESOURCE_REQUEST',
  GET_ERGO_RESOURCE_SUCCESS: 'GET_ERGO_RESOURCE_SUCCESS',
  GET_ERGO_RESOURCE_FAILURE: 'GET_ERGO_RESOURCE_FAILURE',

  CREATE_ERGO_RESOURCE_REQUEST: 'CREATE_ERGO_RESOURCE_REQUEST',
  CREATE_ERGO_RESOURCE_SUCCESS: 'CREATE_ERGO_RESOURCE_SUCCESS',
  CREATE_ERGO_RESOURCE_FAILURE: 'CREATE_ERGO_RESOURCE_FAILURE',

  UPDATE_ERGO_RESOURCE_REQUEST: 'UPDATE_ERGO_RESOURCE_REQUEST',
  UPDATE_ERGO_RESOURCE_SUCCESS: 'UPDATE_ERGO_RESOURCE_SUCCESS',
  UPDATE_ERGO_RESOURCE_FAILURE: 'UPDATE_ERGO_RESOURCE_FAILURE',

  DELETE_ERGO_RESOURCE_REQUEST: 'DELETE_ERGO_RESOURCE_REQUEST',
  DELETE_ERGO_RESOURCE_SUCCESS: 'DELETE_ERGO_RESOURCE_SUCCESS',
  DELETE_ERGO_RESOURCE_FAILURE: 'DELETE_ERGO_RESOURCE_FAILURE',
};
