import React from 'react';
import { Formik } from 'formik';
import { Stack } from '@chakra-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { sub } from 'date-fns';

import { TabForm } from '../../common/components/Tabs';
import { FormSection, FormActions } from '../../common/components/Form/Form';
import { PrimaryButton } from '../../common/components/Buttons';
import { SelectInput } from '../../common/components/Form/FormSelectInput';
import { DateInput } from '../../common/components/Form/FormDateInput';
import { tagsAPI } from '../../common/api/tags';
import { getDateString } from '../../common/utils';
import { usersAPI } from '../../common/api/users';
import { toastActions } from '../../redux/toast/actions';
import rc from './constants';

export const ReportingForm = ({ mode = rc.OPERATORS_REPORTING }) => {
  const user = useSelector(state => state.authentication.user);
  const dispatch = useDispatch();

  const displayTagInput = [
    rc.OPERATORS_REPORTING,
    rc.FOLDERS_REPORTING,
    rc.FOLDER_LIST_REPORTING,
  ].includes(mode);
  const displaySingleDateInput = [rc.OPERATORS_REPORTING].includes(mode);
  const displayDoubleDateInput = [rc.FOLDERS_REPORTING, rc.FOLDER_LIST_REPORTING].includes(mode);

  const titles = {
    [rc.OPERATORS_REPORTING]: 'Activité opérateurs',
    [rc.FOLDERS_REPORTING]: 'Activité dossiers',
    [rc.ERGOS_REPORTING]: 'Ergothératpeutes présents par département',
    [rc.FOLDER_LIST_REPORTING]: 'Liste des dossiers',
  };

  const validateDoubleDate = values => {
    const errors = {};
    if (mode === rc.FOLDERS_REPORTING && !values.startDate && !values.endDate) {
      errors.startDate = ["n'est pas rempli(e)"];
      errors.endDate = ["n'est pas rempli(e)"];
    }
    return errors;
  };

  const validateSingleDate = values => {
    const errors = {};
    if (!values.date) errors.date = ["n'est pas rempli(e)"];
    return errors;
  };

  const validate = values =>
    ({
      [rc.OPERATORS_REPORTING]: validateSingleDate(values),
      [rc.FOLDERS_REPORTING]: validateDoubleDate(values),
      [rc.FOLDER_LIST_REPORTING]: validateDoubleDate(values),
      [rc.ERGOS_REPORTING]: validateSingleDate(values),
    }[mode]);

  const getInitialValues = () => {
    if (mode === rc.FOLDERS_REPORTING || mode === rc.FOLDER_LIST_REPORTING)
      return { startDate: sub(new Date(), { months: 1 }), endDate: new Date(), tags: [] };
    return { date: new Date(), tags: [] }; // default
  };

  const getTags = inputValue =>
    user.role === 'pilote externe'
      ? usersAPI.getUser(user.id).then(({ tags }) => tags.map(tag => ({ label: tag, value: tag })))
      : tagsAPI
          .getTags({ search: inputValue || '' })
          .then(tags => tags.map(tag => ({ label: tag, value: tag })));

  const getDownloadUrl = values =>
    ({
      [rc.FOLDERS_REPORTING]: `${
        process.env.REACT_APP_API_URL
      }/api/v1/reportings/${mode}_export?start_date=${getDateString(
        values.startDate,
      )}&end_date=${getDateString(values.endDate)}&${values.tags
        ?.map(tag => `tags[]=${tag.value}`)
        .join('&') || ''}`,
      [rc.FOLDER_LIST_REPORTING]: `${
        process.env.REACT_APP_API_URL
      }/api/v1/reportings/${mode}_export?start_date=${getDateString(
        values.startDate,
      )}&end_date=${getDateString(values.endDate)}&${values.tags
        ?.map(tag => `tags[]=${tag.value}`)
        .join('&') || ''}`,
      [rc.OPERATORS_REPORTING]: `${
        process.env.REACT_APP_API_URL
      }/api/v1/reportings/${mode}_export?date=${getDateString(values.date)}&${values.tags
        ?.map(tag => `tags[]=${tag.value}`)
        .join('&') || ''}`,
      [rc.ERGOS_REPORTING]: `${process.env.REACT_APP_API_URL}/api/v1/reportings/${mode}_export`,
    }[mode]);

  return (
    <Formik
      initialValues={getInitialValues()}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, setErrors, setTouched }) => {
        setSubmitting(true);
        const errors = validate(values);

        if (errors && Object.keys(errors).length === 0 && errors.constructor === Object) {
          window.open(getDownloadUrl(values));
        } else {
          setErrors(errors);
          dispatch(toastActions.error('Certains champs du formulaire sont manquants'));
          setTouched({});
        }

        setSubmitting(false);
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit}>
          <Stack spacing={10}>
            <FormSection title={titles[mode]}>
              {displayDoubleDateInput && (
                <>
                  <DateInput label="Date de début" name="startDate" id="startDate" />
                  <DateInput label="Date de fin" name="endDate" id="endDate" />
                </>
              )}
              {displaySingleDateInput && <DateInput label="Date" name="date" id="date" />}
              {displayTagInput && (
                <SelectInput
                  mt="2.8rem"
                  css={{
                    '[class*="-option"], [class*="-ValueContainer"]': {
                      textTransform: 'uppercase',
                    },
                    '[class*="-placeholder"]': { textTransform: 'none' },
                  }}
                  placeholder="Choisir un tag"
                  label="Tags"
                  name="tags"
                  id="tags"
                  defaultOptions
                  cacheOptions
                  isAsync
                  isMulti
                  isUpperCase
                  noOptionsMessage={() => 'Aucun tag ne correspond à cette recherche'}
                  loadingMessage={() => 'Recherche en cours...'}
                  loadOptions={getTags}
                  disabled={formik.isSubmitting}
                />
              )}
            </FormSection>

            <FormActions mt={0}>
              <PrimaryButton
                pos="relative!important"
                transform="none!important"
                right="0!important"
                type="submit"
                isLoading={formik.isSubmitting}
                loadingText="Génération en cours"
              >
                Télécharger le rapport
              </PrimaryButton>
            </FormActions>
          </Stack>
        </TabForm>
      )}
    </Formik>
  );
};
