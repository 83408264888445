import React from 'react';
import { Box } from '@chakra-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Comments from '../../../../common/components/Comments/Comments';
import { blocIActions } from '../../../../redux/blocs/blocI/actions';

const BlocITab3 = ({ blocI: { folder, comments, id }, updateBlocIComments }) => {
  if (!folder) {
    return null;
  }

  return (
    <Container>
      <Comments
        folder={folder}
        comments={comments}
        isInline
        updateFallback={updatedFolder => updateBlocIComments(id, updatedFolder)}
      />
    </Container>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateBlocIComments: blocIActions.updateBlocIComments,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(BlocITab3);

const Container = ({ children, ...props }) => (
  <Box maxW="900px" mx="auto" px={4} {...props}>
    {children}
  </Box>
);
