import React, { useMemo } from 'react';
import { Box, Button, Stack, Text } from '@chakra-ui/core';
import { FieldArray, useFormikContext } from 'formik';
import { TabForm } from '../../../../common/components/Tabs';
import { FormSection, FormSubmitButton } from '../../../../common/components/Form/Form';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import {
  RadioInput,
  RadioGroup,
  CheckBoxInput,
  Label,
} from '../../../../common/components/Form/FormInputs';
import { LockedInput } from '../../../../common/components/Form/FormLockedInput';
import { traverseObject } from '../../../../common/utils';
import { isQuoteVersionFilled } from './utils';
import {
  QuoteUploadFilename,
  QuoteUploadWrapper,
} from '../../../../common/components/QuoteUpload/QuoteUpload';

export const BlocTabForm = ({ pending, disabled, fieldPrefix, quoteVersions, ...props }) => {
  const { values, handleSubmit, isSubmitting } = useFormikContext();

  const formQuoteVersions = traverseObject(values, `${fieldPrefix}.quoteVersions`);

  const currentQuote = useMemo(() => {
    const currentQuoteVersions = [...formQuoteVersions].reverse();
    return currentQuoteVersions.findIndex(quote => quote.quoteReceivedAt);
  }, [formQuoteVersions]);

  const isAddButtonDisabled =
    !isQuoteVersionFilled(quoteVersions[quoteVersions.length - 1]) ||
    formQuoteVersions.length > quoteVersions.length ||
    disabled;

  return (
    <TabForm mx="0" ml={5} maxWidth="none" onSubmit={handleSubmit} {...props}>
      <Stack spacing="3.5rem">
        <FormSection title="Reception des devis">
          <CheckBoxInput mb={5} id={`${fieldPrefix}.noFollowup`} name={`${fieldPrefix}.noFollowup`}>
            Proposition sans suite
          </CheckBoxInput>

          <FieldArray
            name={`${fieldPrefix}.quoteVersions`}
            render={arrayHelpers => (
              <>
                <Button
                  color="coral.900"
                  variantColor={null}
                  leftIcon="plus"
                  mb={6}
                  bg="select.bg.isSelected"
                  _hover={{ bg: 'select.bg.isSelected' }}
                  _focus={{ bg: 'select.bg.isSelected' }}
                  disabled={isAddButtonDisabled}
                  onClick={() =>
                    arrayHelpers.push({
                      quoteReceivedAt: null,
                      additionalRequestsSentAt: null,
                      quoteStatus: null,
                    })
                  }
                >
                  Ajouter un nouveau devis
                </Button>
                <Stack spacing={6} minW="100%">
                  {[...formQuoteVersions].reverse().map((quote, mapIndex) => {
                    const index = formQuoteVersions.length - mapIndex - 1;

                    const isCurrentQuote = currentQuote === mapIndex;
                    const isQuoteVersionDisabled = disabled;
                    const isQuoteVersionReadOnly = isQuoteVersionFilled(quoteVersions[index]);

                    return (
                      <Box
                        key={index}
                        bg="lightGray4"
                        rounded="15px"
                        p={6}
                        shadow={isCurrentQuote ? 'sm' : null}
                        border={isCurrentQuote ? '1px solid' : null}
                        borderColor="lightGray2"
                        opacity={isQuoteVersionReadOnly && !isCurrentQuote ? 0.8 : null}
                      >
                        <Text color="lightGray2" fontWeight="bold" mb={5}>
                          Devis n°{index + 1}
                        </Text>
                        <Box mb={6}>
                          <Label>Devis</Label>
                          <QuoteUploadWrapper bg="none" p={0}>
                            <QuoteUploadFilename
                              quoteVersion={quote}
                              placeholder="Aucun devis disponible"
                            />
                          </QuoteUploadWrapper>
                        </Box>
                        <DateInput
                          label="Date de réception du devis"
                          name={`${fieldPrefix}.quoteVersions.${index}.quoteReceivedAt`}
                          id={`${fieldPrefix}.quoteVersions.${index}.quoteReceivedAt`}
                          disabled={isQuoteVersionDisabled}
                          readOnly={
                            (quoteVersions[index]?.quoteReceivedAt || isQuoteVersionReadOnly) &&
                            !isQuoteVersionDisabled
                          }
                        />
                        <RadioGroup
                          name={`${fieldPrefix}.quoteVersions.${index}.quoteStatus`}
                          legend="Statut de l’analyse du devis"
                          disabled={!quote.quoteReceivedAt || isQuoteVersionDisabled}
                          readOnly={
                            (quoteVersions[index]?.quoteStatus || isQuoteVersionReadOnly) &&
                            !isQuoteVersionDisabled
                          }
                          mb={2}
                        >
                          <RadioInput value="valid">Totalement valide</RadioInput>
                          <RadioInput value="almost_valid">Légers détails à modifier</RadioInput>
                          <RadioInput value="additional_request">
                            Demandes complémentaires
                          </RadioInput>
                        </RadioGroup>
                        <LockedInput
                          defaultLocked={!!quote.additionalRequestsSentAt}
                          disabled={
                            quoteVersions[index]?.additionalRequestsSentAt ||
                            isQuoteVersionReadOnly ||
                            isQuoteVersionDisabled
                          }
                          left="150px"
                        >
                          <DateInput
                            label="Date de demandes complémentaires"
                            name={`${fieldPrefix}.quoteVersions.${index}.additionalRequestsSentAt`}
                            id={`${fieldPrefix}.quoteVersions.${index}.additionalRequestsSentAt`}
                            style={{ mb: 0 }}
                            disabled={
                              quote.quoteStatus?.match(/valid/) ||
                              !quote.quoteStatus ||
                              isQuoteVersionDisabled
                            }
                            readOnly={
                              (quoteVersions[index]?.additionalRequestsSentAt ||
                                isQuoteVersionReadOnly) &&
                              !isQuoteVersionDisabled
                            }
                          />
                        </LockedInput>
                      </Box>
                    );
                  })}
                </Stack>
              </>
            )}
          />
        </FormSection>
      </Stack>

      <FormSubmitButton
        type="submit"
        disabled={isSubmitting || pending}
        isLoading={isSubmitting}
        loadingText="Enregistrer"
      >
        Enregistrer
      </FormSubmitButton>
    </TabForm>
  );
};
