import React from 'react';
import { Redirect, Route, withRouter } from 'react-router';
import { connect } from 'react-redux';
import routes from '.';

function PrivateRoute({ component: Component, loggedIn, ...restProps }) {
  return (
    <Route
      {...restProps}
      render={props =>
        loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: routes.login, state: { from: props.location } }} />
        )
      }
    />
  );
}

const mapStateToProps = ({ authentication: state }) => {
  return {
    loggedIn: state.loggedIn,
  };
};

export default withRouter(connect(mapStateToProps)(PrivateRoute));
