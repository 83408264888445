import { history } from '../../common/helpers/history';
import { companiesConstants } from './constants';
import { companiesAPI } from '../../common/api/companies';
import routes from '../../common/routes';
import { toastActions } from '../toast/actions';
import { TOAST_MESSAGE } from '../../common/components/Toasts/Toast';
import { errorsActions } from '../errors/actions';
import {
  getBooleanFromString,
  getSelectValueFromString,
  getStringFromBoolean,
  getStringFromSelectValue,
} from '../../common/utils';

const tranformApiPayloadToFormValues = company => ({
  ...company,
  departments: company.departments.map(departmentCode => getSelectValueFromString(departmentCode)),
  certification: getStringFromBoolean(company.certification),
  hasAccessToProjectPlace: getStringFromBoolean(company.hasAccessToProjectPlace),
  hasNotifications: getStringFromBoolean(company.hasNotifications),
});

const tranformFormValuesToApiPayload = company => ({
  ...company,
  departments:
    company.departments?.map(departmentCode => getStringFromSelectValue(departmentCode)) || [],
  certification: getBooleanFromString(company.certification),
  hasAccessToProjectPlace: getBooleanFromString(company.hasAccessToProjectPlace),
  hasNotifications:
    company.hasAccessToProjectPlace === 'true'
      ? getBooleanFromString(company.hasNotifications)
      : 'false',
});

function getCompanies(page, name, params) {
  function request() {
    return { type: companiesConstants.GET_COMPANIES_REQUEST };
  }
  function success(data) {
    return { type: companiesConstants.GET_COMPANIES_SUCCESS, data };
  }
  function failure(error) {
    return { type: companiesConstants.GET_COMPANIES_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return companiesAPI.getCompanies(page, name, params).then(({ companies, meta }) => {
      dispatch(success({ companies, meta }));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function getCompany(companyId) {
  function request() {
    return { type: companiesConstants.GET_COMPANY_REQUEST };
  }
  function success(company) {
    return { type: companiesConstants.GET_COMPANY_SUCCESS, company };
  }
  function failure(error) {
    return { type: companiesConstants.GET_COMPANY_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return companiesAPI.getCompany(companyId).then(({ company }) => {
      dispatch(success(tranformApiPayloadToFormValues(company)));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function createCompany(newCompany) {
  function request() {
    return { type: companiesConstants.CREATE_COMPANY_REQUEST };
  }
  function success() {
    return { type: companiesConstants.CREATE_COMPANY_SUCCESS };
  }
  function failure(error) {
    return { type: companiesConstants.CREATE_COMPANY_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return companiesAPI.createCompany(tranformFormValuesToApiPayload(newCompany)).then(() => {
      dispatch(success());
      history.push(routes.companies);
      dispatch(toastActions.success(TOAST_MESSAGE.success.add));
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.add))));
  };
}

function updateCompany(companyId, updatedCompany) {
  function request(company) {
    return { type: companiesConstants.UPDATE_COMPANY_REQUEST, company };
  }
  function success() {
    return { type: companiesConstants.UPDATE_COMPANY_SUCCESS };
  }
  function failure(error) {
    return { type: companiesConstants.UPDATE_COMPANY_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedCompany));

    return companiesAPI
      .updateCompany(companyId, tranformFormValuesToApiPayload(updatedCompany))
      .then(() => {
        dispatch(success());
        history.push(routes.companies);
        dispatch(toastActions.success(TOAST_MESSAGE.success.update));
      }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

function deleteCompany(recipientId) {
  function request() {
    return { type: companiesConstants.DELETE_COMPANY_REQUEST };
  }
  function success() {
    return { type: companiesConstants.DELETE_COMPANY_SUCCESS };
  }
  function failure(error) {
    return { type: companiesConstants.DELETE_COMPANY_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return companiesAPI.deleteCompany(recipientId).then(() => {
      dispatch(success());
      history.push(routes.companies);
      dispatch(toastActions.success(TOAST_MESSAGE.success.delete));
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.delete))));
  };
}

export const companiesActions = {
  getCompanies,
  getCompany,
  createCompany,
  updateCompany,
  deleteCompany,
};
