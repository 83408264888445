import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageDefault } from '../common/components/layouts/Page/PageDefault';
import { RecipientForm } from '../common/components/RecipientForm/RecipientForm';
import { recipientsActions } from '../redux/recipients/actions';

const RecipientCreate = ({ error, pending, createRecipient }) => {
  const onSubmit = newRecipientValues => {
    createRecipient(newRecipientValues);
  };

  return (
    <PageDefault title="Création de la fiche bénéficiaire">
      <RecipientForm
        initialValues={{
          recipientIsTheContact: 'true',
          sendDocumentsBy: 'email',
        }}
        pending={pending}
        onSubmit={onSubmit}
        error={error}
        mode="create"
      />
    </PageDefault>
  );
};

const mapStateToProps = ({ recipients: state }) => ({
  error: state.error,
  pending: state.pending,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createRecipient: recipientsActions.createRecipient,
    },
    dispatch,
  );

RecipientCreate.propTypes = {
  pending: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  createRecipient: PropTypes.func.isRequired,
};

RecipientCreate.defaultProps = {
  pending: true,
  error: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientCreate);
