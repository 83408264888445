import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Stack } from '@chakra-ui/core';

import { TabForm } from '../../../../common/components/Tabs';
import { FormSection, FormSubmitButton } from '../../../../common/components/Form/Form';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import {
  Notice,
  NoticeLink,
  NoticeList,
  NoticeText,
  NoticeThinText,
} from '../../../../common/components/Notice';
import { RadioGroup, RadioInput } from '../../../../common/components/Form/FormInputs';
import { LockedInput } from '../../../../common/components/Form/FormLockedInput';
import { getFolderPath } from '../../../../common/routes';

export const FUNDERS = {
  anah: {
    name: 'ANAH',
  },
  cnav: {
    name: 'CNAV',
  },
  action_logement: {
    name: 'Action Logement',
  },
  ville_de_paris: {
    name: 'Ville de Paris',
  },
};

export const BlocGTab1 = ({
  blocG: { error, pending, ignoredEligibleFunders, ...initialValues },
  onSubmit,
}) => {
  const { needOwnerAutorisation, hasWorks, areRequiredDocumentsReceived, folder } = initialValues;
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        // Relocked fields with lock
        document.querySelectorAll('[data-locked]').forEach(button => {
          if (button.getAttribute('data-locked') === 'false') button.click();
        });

        return onSubmit({ ...initialValues, ...values }).then(() => {
          setTouched({});
          setSubmitting(false);
        });
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit}>
          <Stack spacing="3.5rem">
            <FormSection title="Plan de financement">
              <Notice variant="info" mb={5}>
                <NoticeText variant="info" d="grid">
                  Documents à envoyer <br /> à la personne à contacter :
                  <NoticeThinText mt={2} as="span">
                    Si nécessaire, consulter les fiches-procédures ou l'administrateur
                  </NoticeThinText>
                </NoticeText>
              </Notice>

              {folder?.folderType === 'AMOc' && areRequiredDocumentsReceived === false ? (
                <Notice variant="warning" mb="1.25rem">
                  <NoticeText variant="warning">
                    Il manque certaines prérequis liés à la recherche de financements avant de
                    procéder à l'envoi : <br />
                    <NoticeLink to={getFolderPath('bloc_c', initialValues.folderId, 3)}>
                      vérifier ici
                    </NoticeLink>
                  </NoticeText>
                </Notice>
              ) : null}

              <DateInput
                label="Date d'envoi du plan de financement"
                name="fundingPlanSentAt"
                id="fundingPlanSentAt"
                disabled={!areRequiredDocumentsReceived}
              />
            </FormSection>

            {ignoredEligibleFunders?.length ? (
              <FormSection mt="-2.5rem">
                <Notice variant="info" spacing="1rem">
                  <NoticeText variant="info">
                    L'assistance ne tiendra pas compte des financeurs suivants :
                  </NoticeText>
                  <NoticeList variant="info" mx="auto" mt={1} fontWeight="thin">
                    {ignoredEligibleFunders?.map(
                      funder => FUNDERS[funder] && <li key={funder}>{FUNDERS[funder].name}</li>,
                    )}
                  </NoticeList>
                </Notice>
              </FormSection>
            ) : null}

            {needOwnerAutorisation && hasWorks ? (
              <FormSection title="Demande d'autorisation au propriétaire">
                <LockedInput
                  left="16ch"
                  defaultIsLocked={!!formik.values.ownerAuthorizationRequestSentAt}
                >
                  <DateInput
                    label="Dernière date d'envoi de la demande d’autorisation au propriétaire"
                    name="ownerAuthorizationRequestSentAt"
                    id="ownerAuthorizationRequestSentAt"
                    disabled={needOwnerAutorisation && !formik.values.fundingPlanSentAt}
                  />
                </LockedInput>

                <DateInput
                  label="Date de réception de l'autorisation du propriétaire"
                  name="ownerAuthorizationReceivedAt"
                  id="ownerAuthorizationReceivedAt"
                  disabled={needOwnerAutorisation && !formik.values.ownerAuthorizationRequestSentAt}
                />

                <RadioGroup
                  legend="Positionnement du propriétaire"
                  name="hasOwnerAuthorizedWorks"
                  disabled={needOwnerAutorisation && !formik.values.ownerAuthorizationReceivedAt}
                >
                  <RadioInput value="true">Autorise les travaux</RadioInput>
                  <RadioInput value="false">N'autorise pas les travaux</RadioInput>
                </RadioGroup>
              </FormSection>
            ) : null}
          </Stack>

          <FormSubmitButton
            type="submit"
            disabled={formik.isSubmitting || pending}
            isLoading={formik.isSubmitting}
            loadingText="Enregistrer"
          >
            Enregistrer
          </FormSubmitButton>
        </TabForm>
      )}
    </Formik>
  );
};
