import React from 'react';
import { Flex } from '@chakra-ui/core';
import { TabList, Tab, TabPanels, TabPanel } from '../common/components/Tabs';
import { PageTitle } from '../common/components/Titles';
import { ReportingForm } from './components';
import { Tabs } from './components/Tabs';
import Can from '../roleBasedAccess/Can';

const ReportingTabs = () => {
  return (
    <Flex w="100%" px={10} pt={5} pb={6} flexGrow={1} direction="column">
      <Flex>
        <PageTitle mb={6}>Reporting</PageTitle>
      </Flex>
      <Can
        perform="dashboard:visit"
        yes={() => (
          <Tabs>
            <TabList>
              <TabList>
                <Tab>Opérateurs</Tab>
                <Tab>Dossiers</Tab>
                <Tab>Ergothérapeutes</Tab>
                <Tab>Bénéficiaires</Tab>
              </TabList>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ReportingForm mode="operators" />
              </TabPanel>
              <TabPanel>
                <ReportingForm mode="folders" />
              </TabPanel>
              <TabPanel>
                <ReportingForm mode="ergo" />
              </TabPanel>
              <TabPanel>
                <ReportingForm mode="folder_list" />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
        no={() => (
          <Tabs>
            <TabList>
              <TabList>
                <Tab>Dossiers</Tab>
              </TabList>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ReportingForm mode="folders" />
              </TabPanel>
            </TabPanels>
          </Tabs>
        )}
      />
    </Flex>
  );
};

export default ReportingTabs;
