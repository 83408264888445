import { useState } from 'react';

export const useTasksFilters = tasks => {
  const [currentTags, setCurrentTags] = useState([]);
  const [currentOperators, setCurrentOperators] = useState([]);
  const [currentDetails, setCurrentDetails] = useState([]);

  const filteredTasks = tasks
    .filter(task => !currentTags.length || task.tags.find(tag => currentTags.includes(tag)))
    .filter(
      task =>
        !currentOperators.length ||
        currentOperators.includes(`${task.userFirstName} ${task.userLastName}`),
    )
    .filter(task => !currentDetails.length || currentDetails.includes(task.details));

  return [
    filteredTasks,
    {
      currentTags,
      setCurrentTags,
      currentOperators,
      setCurrentOperators,
      currentDetails,
      setCurrentDetails,
    },
  ];
};
