import { operatorsConstants } from './constants';
import { usersAPI } from '../../common/api/users';
import routes from '../../common/routes';
import { history } from '../../common/helpers/history';
import { toastActions } from '../toast/actions';
import { TOAST_MESSAGE } from '../../common/components/Toasts/Toast';
import { errorsActions } from '../errors/actions';

function getOperators(page, name, params) {
  function request() {
    return { type: operatorsConstants.GET_OPERATORS_REQUEST };
  }
  function success(data) {
    return { type: operatorsConstants.GET_OPERATORS_SUCCESS, data };
  }
  function failure(error) {
    return { type: operatorsConstants.GET_OPERATORS_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return usersAPI.getUsers(page, name, params).then(({ users: operators, meta }) => {
      dispatch(success({ operators, meta }));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function getOperator(operatorId) {
  function request() {
    return { type: operatorsConstants.GET_OPERATOR_REQUEST };
  }
  function success(operator) {
    return { type: operatorsConstants.GET_OPERATOR_SUCCESS, operator };
  }
  function failure(error) {
    return { type: operatorsConstants.GET_OPERATOR_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return usersAPI.getUser(operatorId).then(operator => {
      dispatch(success(operator));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function createOperator(newOperator) {
  function request() {
    return { type: operatorsConstants.CREATE_OPERATOR_REQUEST };
  }
  function success() {
    return { type: operatorsConstants.CREATE_OPERATOR_SUCCESS };
  }
  function failure(error) {
    return { type: operatorsConstants.CREATE_OPERATOR_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return usersAPI.createUser(newOperator).then(() => {
      dispatch(success());
      history.push(routes.users);
      dispatch(toastActions.success(TOAST_MESSAGE.success.add));
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.add))));
  };
}

function updateOperator(operatorId, updatedOperator) {
  function request(operator) {
    return { type: operatorsConstants.UPDATE_OPERATOR_REQUEST, operator };
  }
  function success() {
    return { type: operatorsConstants.UPDATE_OPERATOR_SUCCESS };
  }
  function failure(error) {
    return { type: operatorsConstants.UPDATE_OPERATOR_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedOperator));

    return usersAPI.updateUser(operatorId, updatedOperator).then(() => {
      dispatch(success());
      history.push(routes.users);
      dispatch(toastActions.success(TOAST_MESSAGE.success.update));
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

function deleteOperator(operatorId) {
  function request() {
    return { type: operatorsConstants.DELETE_OPERATOR_REQUEST };
  }
  function success() {
    return { type: operatorsConstants.DELETE_OPERATOR_SUCCESS };
  }
  function failure(error) {
    return { type: operatorsConstants.DELETE_OPERATOR_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return usersAPI.deleteUser(operatorId).then(() => {
      dispatch(success());
      history.push(routes.users);
      dispatch(toastActions.success(TOAST_MESSAGE.success.delete));
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.delete))));
  };
}

export const operatorsActions = {
  getOperators,
  getOperator,
  createOperator,
  updateOperator,
  deleteOperator,
};
