import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router';
import routes from '../common/routes';
import { PageError404 } from '../errors/components';
import Can from '../roleBasedAccess/Can';
import ReportingTabs from './ReportingTabs';

export const Reporting = () => {
  const match = useRouteMatch();

  return (
    <Can
      perform="report:read"
      yes={() => (
        <Switch>
          <Route exact path={`${match.url}/:tabIndex`} component={ReportingTabs} />
          <Redirect exact path={match.url} to={`${match.url}/1`} />
          <Route component={PageError404} />
        </Switch>
      )}
      no={() => <Redirect path={match.url} to={routes.error[404]} />}
    />
  );
};
