import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Stack, Text } from '@chakra-ui/core';
import { connect } from 'react-redux';

import { TabForm } from '../../../../common/components/Tabs';
import {
  FormSection,
  FormSubmitButton,
  FormCTAButton,
} from '../../../../common/components/Form/Form';
import {
  CheckboxGroup,
  CheckboxGroupInput,
  TextInput,
} from '../../../../common/components/Form/FormInputs';
import { SelectInput } from '../../../../common/components/Form/FormSelectInput';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import { Notice, NoticeText, NoticeThinText } from '../../../../common/components/Notice';
import { getFolderPath } from '../../../../common/routes';
import { LockedInput } from '../../../../common/components/Form/FormLockedInput';
import { history } from '../../../../common/helpers/history';

export const folderTypes = [
  { label: 'AMOc - complète', value: 'AMOc' },
  { label: "AMOe - visite de l'ergothérapeute", value: 'AMOe' },
  { label: 'AMOad - assistance aux devis', value: 'AMOad' },
];

const Bloc0Tab2 = ({
  bloc0: { error, pending, folder, availableFunders, ...initialValues },
  onSubmit,
  config,
}) => {
  const availableBlocs = (config.folderTypes && config.folderTypes[folder?.folderType]) || [
    'bloc_0_contact',
    'bloc_fio',
    'bloc_0_contract',
    'bloc_c',
    'bloc_0_validation',
    'bloc_a',
  ];

  const isDisabled =
    !folder?.folderType &&
    availableBlocs &&
    availableBlocs.indexOf(folder?.currentBloc) < availableBlocs.indexOf('bloc_0_contract');

  const isValidationSectionDisabled =
    availableBlocs &&
    availableBlocs.indexOf(folder?.currentBloc) < availableBlocs.indexOf('bloc_0_validation');

  const availableFunderToIgnore =
    availableFunders
      ?.filter(funder => funder.name.match(/(Action Logement|CNAV|ANAH)/))
      ?.map(funder => ({
        label: funder.name,
        value: funder.name
          .split(' ')
          .join('_')
          .toLowerCase(),
      })) || [];

  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  const isQuoteRequestPerimeterDisabled = isDisabled || isValidationSectionDisabled;

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        // Relocked fields with lock
        document.querySelectorAll('[data-locked]').forEach(button => {
          if (button.getAttribute('data-locked') === 'false') button.click();
        });

        return onSubmit({ ...initialValues, folder, ...values }).then(() => {
          setTouched({});
          setSubmitting(false);
        });
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit}>
          <Stack spacing="2rem">
            <FormSection title="Choix d'AMO">
              <LockedInput defaultIsLocked={!!formik.values.folderType}>
                <SelectInput
                  label="Type d'AMO"
                  name="folderType"
                  id="folderType"
                  options={folderTypes}
                  placeholder="type d'AMO"
                  isDisabled={isDisabled}
                />
              </LockedInput>
              {formik.values.folderType?.value === 'AMOc' && availableFunderToIgnore.length ? (
                <CheckboxGroup
                  name="ignoredFunders"
                  legend="Le bénéficiaire souhaite t-il ne pas tenir compte des financeurs suivants :"
                >
                  {availableFunderToIgnore.map(funder => (
                    <CheckboxGroupInput key={funder.value} value={funder.value}>
                      {funder.label}
                    </CheckboxGroupInput>
                  ))}
                </CheckboxGroup>
              ) : null}
            </FormSection>

            <FormSection mt="-1.5rem">
              <Notice variant="info">
                <NoticeText variant="info">
                  Documents initiaux à envoyer à la personne à contacter :
                </NoticeText>
                <NoticeThinText mt={-6}>
                  Si nécessaire, consulter les fiches-procédures ou l'administrateur
                </NoticeThinText>
              </Notice>
            </FormSection>

            <FormSection title="Documents initiaux">
              <DateInput
                label="Dernière date d'envoi des documents"
                name="completeFolderSentAt"
                id="completeFolderSentAt"
                disabled={isDisabled}
              />
            </FormSection>

            <FormCTAButton
              type="button"
              mt={-4}
              mx="auto"
              disabled={!formik.values.folderType || !formik.values.completeFolderSentAt}
              onClick={() =>
                formik
                  .submitForm()
                  .then(() => history.push(getFolderPath('bloc_c', initialValues.folderId)))
              }
            >
              Suivre la réception des documents de validation préliminaire
            </FormCTAButton>

            <FormSection mt={8} title="Validation préliminaire de l'AMO">
              <CheckboxGroup
                mb={2}
                name="quoteRequestPerimeter"
                legend="Périmètre de la demande de devis :"
                disabled={
                  isQuoteRequestPerimeterDisabled || formik.values?.folderType?.value === 'AMOe'
                }
              >
                <CheckboxGroupInput value="works" disabled={initialValues.fioQ1 !== 'B'}>
                  Travaux
                </CheckboxGroupInput>
                <CheckboxGroupInput value="is_technical_assistance_required">
                  Aide(s) technique(s) aux gestes de la vie quotidienne
                </CheckboxGroupInput>
                <CheckboxGroupInput value="is_wheelchair_or_other_equipments_required">
                  Fauteuil roulant électrique ou manuel et équipements annexes
                </CheckboxGroupInput>
                <CheckboxGroupInput value="is_specific_vehicle_arrangements_required">
                  Aménagements spécifiques de véhicule
                </CheckboxGroupInput>
                <CheckboxGroupInput value="human_help_or_service">
                  Aide(s) humaine(s) ou service(s)
                </CheckboxGroupInput>
              </CheckboxGroup>
              <DateInput
                label="Date de validation de l'AMO"
                name="folderValidatedAt"
                id="folderValidatedAt"
                disabled={
                  isDisabled ||
                  isValidationSectionDisabled ||
                  (formik.values.quoteRequestPerimeter?.length === 0 &&
                    formik.values?.folderType?.value !== 'AMOe')
                }
              />
              <Stack isInline spacing="1ch" alignItems="center">
                <TextInput
                  label="Tarif de l'AMO"
                  type="number"
                  id="amoAmount"
                  name="amoAmount"
                  placeholder="XXXXX"
                  width="16ch"
                  disabled={
                    isDisabled ||
                    isValidationSectionDisabled ||
                    (formik.values.quoteRequestPerimeter?.length === 0 &&
                      formik.values?.folderType?.value !== 'AMOe')
                  }
                />
                <Text color="semiDarkGray.600" fontWeight={600} fontSize="lg" pt="0.5ch">
                  €
                </Text>
              </Stack>
            </FormSection>

            <FormSection mt="-3.5rem">
              <Notice variant="info">
                <NoticeText variant="info">Tarif de l'AMO</NoticeText>
                <NoticeThinText mt={-6}>
                  Si nécessaire, consulter les fiches-procédures ou l'administrateur
                </NoticeThinText>
              </Notice>
            </FormSection>

            <FormCTAButton
              mb={0}
              mx="auto"
              disabled={
                !formik.values.folderType ||
                !formik.values.completeFolderSentAt ||
                !formik.values.folderValidatedAt ||
                typeof formik.values.amoAmount !== 'number' ||
                (formik.values.quoteRequestPerimeter?.length === 0 &&
                  formik.values?.folderType?.value !== 'AMOe')
              }
              onClick={() =>
                formik
                  .submitForm()
                  .then(() => history.push(getFolderPath('bloc_a', initialValues.folderId)))
              }
            >
              Lancer le diagnostic ergothérapique
            </FormCTAButton>
          </Stack>

          <FormSubmitButton
            type="submit"
            disabled={pending}
            isLoading={formik.isSubmitting}
            loadingText="Enregistrer"
          >
            Enregistrer
          </FormSubmitButton>
        </TabForm>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ config }) => ({ config });

export default connect(mapStateToProps)(Bloc0Tab2);
