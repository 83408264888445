export const operatorsConstants = {
  GET_OPERATORS_REQUEST: 'GET_OPERATORS_REQUEST',
  GET_OPERATORS_SUCCESS: 'GET_OPERATORS_SUCCESS',
  GET_OPERATORS_FAILURE: 'GET_OPERATORS_FAILURE',

  GET_OPERATOR_REQUEST: 'GET_OPERATOR_REQUEST',
  GET_OPERATOR_SUCCESS: 'GET_OPERATOR_SUCCESS',
  GET_OPERATOR_FAILURE: 'GET_OPERATOR_FAILURE',

  CREATE_OPERATOR_REQUEST: 'CREATE_OPERATOR_REQUEST',
  CREATE_OPERATOR_SUCCESS: 'CREATE_OPERATOR_SUCCESS',
  CREATE_OPERATOR_FAILURE: 'CREATE_OPERATOR_FAILURE',

  UPDATE_OPERATOR_REQUEST: 'UPDATE_OPERATOR_REQUEST',
  UPDATE_OPERATOR_SUCCESS: 'UPDATE_OPERATOR_SUCCESS',
  UPDATE_OPERATOR_FAILURE: 'UPDATE_OPERATOR_FAILURE',

  DELETE_OPERATOR_REQUEST: 'DELETE_OPERATOR_REQUEST',
  DELETE_OPERATOR_SUCCESS: 'DELETE_OPERATOR_SUCCESS',
  DELETE_OPERATOR_FAILURE: 'DELETE_OPERATOR_FAILURE',
};
