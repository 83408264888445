import React from 'react';
import { Tabs, Tab, TabPanels, TabPanel, TabList } from '../../../../common/components/Tabs';
import { BlocFioTab1 } from './BlocFioTab1';
import { BlocFioTab2 } from './BlocFioTab2';

export const BlocFioTabs = ({
  match: {
    params: { folderId, tabIndex },
  },
  blocId,
  updateBlocFio,
  ...props
}) => {
  const onSubmit = values => {
    return updateBlocFio(folderId, values);
  };

  return (
    <Tabs folderId={folderId} blocId={blocId} tabIndex={tabIndex}>
      <TabList>
        <Tab>Formulaire</Tab>
        <Tab>Synthèse</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <BlocFioTab1 {...props} onSubmit={onSubmit} />
        </TabPanel>
        <TabPanel>
          <BlocFioTab2 {...props} onSubmit={onSubmit} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
