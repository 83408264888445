import { blocIConstants } from './constants';

export const initialState = {
  pending: false,
};

export function blocI(state = initialState, action) {
  switch (action.type) {
    case blocIConstants.GET_BLOCI_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case blocIConstants.GET_BLOCI_SUCCESS:
      return {
        ...state,
        [action.blocI.folderId]: action.blocI,
        pending: false,
      };
    case blocIConstants.UPDATE_BLOCI_REQUEST:
      return {
        ...state,
        [action.blocI.folderId]: action.blocI,
        error: null,
        pending: true,
      };
    case blocIConstants.UPDATE_BLOCI_SUCCESS:
      return {
        ...state,
        [action.blocI.folderId]: action.blocI,
        error: null,
        pending: false,
      };
    case blocIConstants.UPDATE_BLOCI_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case blocIConstants.UPDATE_BLOCI_COMMENTS:
      return {
        ...state,
        [action.blocIId]: {
          ...state[action.blocIId],
          comments: action.folder.comments,
        },
        error: null,
        pending: false,
      };
    case blocIConstants.GET_BLOCI_FAILURE:
    default:
      return state;
  }
}
