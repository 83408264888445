import { camelCase } from 'camel-case';
import { tasksConstants } from './constants';
import { tasksAPI } from '../../common/api/tasks';
import { errorsActions } from '../errors/actions';

function getTasks() {
  function request() {
    return { type: tasksConstants.GET_TASKS_REQUEST };
  }
  function success(tasks) {
    return { type: tasksConstants.GET_TASKS_SUCCESS, tasks };
  }
  function failure(error) {
    return { type: tasksConstants.GET_TASKS_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return tasksAPI.getTasks().then(({ tasks }) => {
      dispatch(
        success(tasks.map(task => ({ ...task, targetAttribute: camelCase(task.targetAttribute) }))),
      );
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

export const tasksActions = {
  getTasks,
};
