import React from 'react';
import { Box, Heading } from '@chakra-ui/core';

export const Section = ({ children, ...props }) => (
  <Box as="section" {...props}>
    {children}
  </Box>
);

export const SectionTitle = ({ children, ...props }) => (
  <Heading
    textAlign="center"
    fontSize="lg"
    textTransform="uppercase"
    mb="3.125rem"
    color="semiDarkGray.900"
    {...props}
  >
    {children}
  </Heading>
);

export const SubSectionTitle = ({ children, ...props }) => (
  <Heading
    textAlign="center"
    fontSize="md"
    fontWeight={600}
    mb="1.75rem"
    color="semiDarkGray.600"
    {...props}
  >
    {children}
  </Heading>
);
