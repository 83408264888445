import React from 'react';
import { Route, Switch } from 'react-router';

import { PreFoldersIndex } from './PreFoldersIndex';
import { PageError404 } from '../errors/components';
import PreFoldersCreate from './PreFolderCreate';
import PreFoldersEdit from './PreFolderEdit';

export const PreFolders = ({ match }) => {
  return (
    <Switch>
      <Route path={match.url} exact component={PreFoldersIndex} />
      <Route path={`${match.url}/new`} exact component={PreFoldersCreate} />
      <Route path={`${match.url}/:preFolderId/edit`} exact component={PreFoldersEdit} />
      <Route component={PageError404} />
    </Switch>
  );
};
