import React from 'react';
import { Button } from '@chakra-ui/core';

export const PrimaryButton = ({ children, _disabled, ...props }) => {
  return (
    <Button
      rounded="full"
      bg="coral.900"
      variantColor={null}
      color="white"
      height="auto"
      py="0.5em"
      px={props.rightIcon || props.leftIcon ? 6 : 5}
      _hover={{ bg: 'red' }}
      _disabled={{
        bg: 'lightGray2',
        cursor: 'not-allowed',
        a: { pointerEvents: 'none', cursor: 'not-allowed' },
        ..._disabled,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export const SecondaryButton = ({ children, ...props }) => {
  return (
    <PrimaryButton
      rounded="full"
      bg="transparent"
      variantColor={null}
      color="coral.900"
      borderWidth={1}
      borderColor="coral.900"
      _hover={{ '&:not(:disabled)': { bg: 'coral.900', color: 'white' } }}
      _disabled={{
        borderColor: 'gray2',
        color: 'gray2',
        cursor: 'not-allowed',
        bg: 'transparent',
      }}
      {...props}
    >
      {children}
    </PrimaryButton>
  );
};
