import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { BaseTabs } from '../../common/components/Tabs';
import routes from '../../common/routes';

export const Tabs = ({ children }) => {
  const match = useRouteMatch();
  const history = useHistory();

  return (
    <BaseTabs
      index={Number(match.params.tabIndex - 1)}
      onChange={index => {
        history.push(`${routes.reporting}/${index + 1}`);
      }}
    >
      {children}
    </BaseTabs>
  );
};
