import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { differenceInDays } from 'date-fns';

import { Box, Flex, IconButton } from '@chakra-ui/core';
import { Formik } from 'formik';
import { PageIndex } from '../common/components/layouts/Page/PageIndex';
import {
  Table,
  Thead,
  TH,
  TD,
  THeadRow,
  TBodyRow,
  TablePlaceholder,
  TableResults,
} from '../common/components/Table';
import { quotesActions } from '../redux/quotes/actions';
import { Pagination } from '../common/components/Pagination';
import { TypeTag } from '../tasks/components';
import { getDateFromString, encodeQueryArray } from '../common/utils';

import { SearchInput } from '../common/components/Form/FormInputs';
import { SelectInput, SelectInputMultiple } from '../common/components/Form/FormSelectInput';
import { PrimaryButton, SecondaryButton } from '../common/components/Buttons';
import { usersAPI } from '../common/api/users';
import { foldersAPI } from '../common/api/folders';
import { is } from '../roleBasedAccess/Is';
import { Funders } from '../common/components/Funders/Funders';
import QuoteUpload from '../common/components/QuoteUpload/QuoteUpload';
import Comments from '../common/components/Comments/Comments';
import Can from '../roleBasedAccess/Can';

export const DELAYS_TYPES = {
  blue: '< 15j',
  orange: '15j << x >> 45j',
  red: '> 45j',
};

const ConnectedQuotesIndex = ({
  match,
  getQuotes,
  updateQuotesComments,
  pending,
  quotes = [],
  meta,
}) => {
  const { user } = useSelector(state => state.authentication);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState({
    keywords: '',
    delay: '',
    tags: [],
    indicators: [],
    status: [],
  });

  useEffect(() => {
    getQuotes(currentPage, search);
  }, [getQuotes, currentPage, search]);

  return (
    <PageIndex
      pageAction={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Header match={match} meta={meta} onSearch={setSearch} />
      }
    >
      <Table>
        <Thead>
          <THeadRow>
            <TH size="sm">
              Nom de
              <br />
              l'entreprise
            </TH>
            <TH size="sm">
              N° de
              <br />
              dossier
            </TH>
            <TH size="sm">Bénéficiaire</TH>
            <TH size="sm">Ville</TH>
            <TH size="sm">Indicateur</TH>
            <TH size="sm">Statut</TH>
            <TH size="sm">Date de demande</TH>
            <TH size="sm">
              {user.role === 'coordinateur entreprises' ? 'Tag' : 'Type de dossier'}
            </TH>
            <TH size="sm">Financ.</TH>
            <TH size="sm">Actions</TH>
          </THeadRow>
        </Thead>
        <tbody>
          {!quotes || pending ? (
            <TablePlaceholder colSpan={10} />
          ) : (
            quotes.map((quote, index) => {
              const {
                id,
                quoteGroupId,
                companyId,
                companyName,
                folderFormattedId,
                recipientFullName,
                recipientLastName,
                recipientFirstName,
                folderId,
                folderState,
                comments,
                recipientHousingCity,
                quoteRequestSentAt,
                status,
                lastQuoteUploaded,
                folderTag,
                indicator,
                typeOfVisit,
              } = quote;

              const nbOfDaysDelayQuoteRequestSentAt = quoteRequestSentAt
                ? differenceInDays(
                    new Date().setHours(0, 0, 0, 0),
                    getDateFromString(quoteRequestSentAt),
                  )
                : null;

              let variantColor = 'blue';
              let folderType = 'Devis après rapport ergo';

              if (
                nbOfDaysDelayQuoteRequestSentAt > 45 &&
                indicator?.match(
                  /(Devis non reçu|Devis reçu en cours d’analyse|Devis reçu en cours d’analyse\*|Devis conforme en attente de transmission au bénéficiaire|Demande de modifications)/,
                )
              ) {
                variantColor = 'red';
              }

              if (
                nbOfDaysDelayQuoteRequestSentAt > 15 &&
                nbOfDaysDelayQuoteRequestSentAt <= 45 &&
                indicator?.match(
                  /(Devis non reçu|Devis reçu en cours d’analyse|Devis reçu en cours d’analyse\*|Devis conforme en attente de transmission au bénéficiaire|Demande de modifications)/,
                )
              ) {
                variantColor = 'orange';
              }

              if (typeOfVisit === 'joint') {
                folderType = 'VAD conjoint';
              }
              if (typeOfVisit === 'existing_quote') {
                folderType = 'Intervention sur devis';
              }

              return (
                // eslint-disable-next-line react/no-array-index-key
                <TBodyRow key={index} hasScaleOnHover={false} enableHover={false}>
                  <TD hasPadding size="sm">
                    {companyName}
                  </TD>
                  <TD hasPadding size="sm">
                    {folderFormattedId}
                  </TD>
                  <TD hasPadding size="sm">
                    {recipientFullName}
                  </TD>
                  <TD hasPadding size="sm">
                    {recipientHousingCity}
                  </TD>
                  <TD hasPadding size="sm">
                    {indicator}
                  </TD>
                  <TD hasPadding size="sm">
                    {status}
                  </TD>
                  <TD hasPadding size="sm">
                    {nbOfDaysDelayQuoteRequestSentAt === null ||
                    nbOfDaysDelayQuoteRequestSentAt < 0 ? (
                      'En attente'
                    ) : (
                      <TypeTag variantColor={variantColor} whiteSpace="nowrap">
                        {quoteRequestSentAt} (+{nbOfDaysDelayQuoteRequestSentAt}j)
                      </TypeTag>
                    )}
                  </TD>
                  <TD
                    hasPadding
                    size="sm"
                    textTransform={user.role === 'coordinateur entreprises' ? 'uppercase' : null}
                  >
                    {user.role === 'coordinateur entreprises' ? folderTag : folderType}
                  </TD>

                  <TD hasPadding size="sm" textAlign="center">
                    <Funders quote={quote} />
                  </TD>
                  <TD textAlign="center" whiteSpace="nowrap">
                    <QuoteUpload
                      folder={{ formattedId: folderFormattedId, recipientFullName }}
                      quote={quote}
                      quoteRequest={{ id, quoteGroupId, companyId, indicator }}
                      successCallback={() => getQuotes(currentPage, search)}
                    />

                    <IconButton
                      as="a"
                      icon="view"
                      variant="ghost"
                      variantColor={null}
                      isDisabled={!lastQuoteUploaded?.downloadLink}
                      href={lastQuoteUploaded?.downloadLink}
                      target="_blank"
                      color="coral.900"
                      height={8}
                      minW={8}
                      rounded="full"
                      aria-label="Voir le dernier devis"
                      _hover={{
                        '&:not([aria-disabled])': { color: 'white', backgroundColor: 'coral.900' },
                      }}
                      _focus={{ shadow: 'none' }}
                    />

                    <Can
                      perform="comments:read"
                      yes={() => (
                        <Comments
                          folder={{
                            formattedId: folderFormattedId,
                            id: folderId,
                            state: folderState,
                            recipientFirstName,
                            recipientLastName,
                          }}
                          comments={comments}
                          updateFallback={updatedFolder => updateQuotesComments(id, updatedFolder)}
                        />
                      )}
                    />
                  </TD>
                </TBodyRow>
              );
            })
          )}
        </tbody>
      </Table>
      {meta && (
        <Pagination
          current={currentPage}
          total={meta.total}
          defaultPageSize={meta.perPage}
          onChange={page => setCurrentPage(page)}
        />
      )}
    </PageIndex>
  );
};

const mapStateToProps = ({ quotes: state }) => {
  return {
    pending: state.pending,
    quotes: state.data,
    meta: state.meta,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getQuotes: quotesActions.getQuotes,
      updateQuotesComments: quotesActions.updateQuotesComments,
    },
    dispatch,
  );

export const QuotesIndex = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuotesIndex);

const SearchBar = ({ onSearch }) => {
  const [availableTags, setAvailableTags] = useState([]);
  const [currentTags, setCurrentTags] = useState([]);
  const [currentIndicators, setCurrentIndicators] = useState([]);
  const [currentStatus, setCurrentStatus] = useState([]);
  const { user } = useSelector(state => state.authentication);

  const form = useRef();

  useEffect(() => {
    if (is(user, 'pilote externe') || is(user, 'référent externe'))
      usersAPI
        .getUser(-1)
        .then(u => {
          setAvailableTags(u.tags);
        })
        .catch(console.log);
    else
      foldersAPI
        .getTags({ search: '' })
        .then(tags => {
          setAvailableTags(tags);
        })
        .catch(console.log);
  }, [setAvailableTags, user]);

  const openQuotesReport = () => {
    const keywords = form.current.values.search || '';
    const delay = form.current.values.delay?.value || '';

    const url = `${
      process.env.REACT_APP_API_URL
    }/api/v1/quote_request_summaries/export?by_keywords=${keywords}&by_delay=${delay}${encodeQueryArray(
      'by_tags',
      currentTags,
    )}${encodeQueryArray('by_status', currentStatus)}${encodeQueryArray(
      'by_indicators',
      currentIndicators,
    )}`;
    window.open(url);
  };

  return (
    <Flex isInline justifyContent="space-between" w="100%">
      <Formik
        innerRef={form}
        initialValues={{ search: '', delay: '' }}
        onSubmit={values =>
          onSearch({
            keywords: values.search,
            delay: values.delay?.value || values.delay,
            tags: currentTags,
            status: currentStatus,
            indicators: currentIndicators,
          })
        }
      >
        {formik => (
          <Box
            as="form"
            onSubmit={formik.handleSubmit}
            d="flex"
            alignItems="start"
            flexGrow="1"
            minW={0}
            mr={3}
          >
            <Box d="flex" flexDir="column" width="100%" flexGrow={1} minW={0}>
              <SearchInput name="search" />
              <Box
                d="flex"
                alignItems="start"
                justifyContent="space-between"
                flexWrap="wrap"
                mt={2}
              >
                <SelectInputMultiple
                  placeholder="Filtrer par TAGS"
                  styles={{ flexGrow: 1, maxW: '24%', mr: 3, textTransform: 'uppercase' }}
                  options={availableTags}
                  menuOptionGroupOptions={{
                    onChange: setCurrentTags,
                    value: currentTags,
                    clear: () => {
                      setCurrentTags([]);
                    },
                  }}
                  label="Tag"
                />
                <SelectInput
                  name="delay"
                  isClearable
                  placeholder="Délais"
                  styles={{
                    flexGrow: 1,
                    maxW: '24%',
                    mr: 3,
                  }}
                  css={{
                    '[class*=-indicatorContainer]:first-of-type': {
                      position: 'static',
                    },
                  }}
                  size="sm"
                  options={[
                    { label: '< 15j', value: '< 15' },
                    { label: '15j < x < 45j', value: '15 < x < 45' },
                    { label: '> 45j', value: '> 45' },
                  ]}
                />
                <SelectInputMultiple
                  styles={{
                    flexGrow: 1,
                    maxW: '24%',
                    mr: 3,
                  }}
                  options={[
                    'Diagnostic en attente de programmation',
                    'Diagnostic en attente de réalisation',
                    'Devis non reçu',
                    'Devis reçu en cours d’analyse',
                    'Demande de modifications',
                    'Devis conforme en attente de transmission au bénéficiaire',
                    'Devis conforme en attente de retour du bénéficiaire',
                    'Devis sans suite par le bénéficiaire',
                    'Devis choisi par le bénéficiaire - attente de dernières modifications',
                    'Devis choisi par le bénéficiaire - en attente de transmission aux financeurs',
                    'Devis choisi par le bénéficiaire',
                    'Devis transmis aux financeurs - instruction en cours',
                    'Notification de financeurs reçue',
                    'BFP réalisé - réserves émises',
                    'BFP réalisé - sans réserve',
                    'Projet réalisé - en attente de BFP',
                  ]}
                  menuOptionGroupOptions={{
                    onChange: setCurrentIndicators,
                    value: currentIndicators,
                    clear: () => {
                      setCurrentIndicators([]);
                    },
                  }}
                  label="Indicateurs"
                />
                <SelectInputMultiple
                  styles={{ flexGrow: 1, maxW: '24%' }}
                  options={[
                    'Proposition active',
                    'Proposition sans suite',
                    'En instance',
                    'Cloturé',
                  ]}
                  menuOptionGroupOptions={{
                    onChange: setCurrentStatus,
                    value: currentStatus,
                    clear: () => {
                      setCurrentStatus([]);
                    },
                  }}
                  label="Statuts"
                />
              </Box>
            </Box>
            <SecondaryButton type="submit" size="sm" ml={3}>
              Lancer la recherche
            </SecondaryButton>
          </Box>
        )}
      </Formik>
      <Box>
        <PrimaryButton type="button" size="sm" onClick={openQuotesReport}>
          Télécharger
        </PrimaryButton>
      </Box>
    </Flex>
  );
};

const Header = ({ onSearch, meta }) => {
  return (
    <>
      <SearchBar onSearch={onSearch} meta={meta} />
      <TableResults ressource="devis" style={{ mt: -3 }} total={meta ? meta.total : 0} />
    </>
  );
};
