import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageDefault } from '../common/components/layouts/Page/PageDefault';
import { ErgoOfficeForm } from '../common/components/ErgoOfficeForm/ErgoOfficeForm';
import { ergoOfficesActions } from '../redux/ergoOffices/actions';

const ErgoOfficeCreate = ({ error, pending, createErgoOffice }) => {
  const onSubmit = newErgoOfficeValues => {
    createErgoOffice(newErgoOfficeValues);
  };

  return (
    <PageDefault title="Création de la fiche cabinet">
      <ErgoOfficeForm
        initialValues={{ name: null }}
        pending={pending}
        onSubmit={onSubmit}
        error={error}
        mode="create"
      />
    </PageDefault>
  );
};

const mapStateToProps = ({ ergoOffices: state }) => ({
  error: state.error,
  pending: state.pending,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createErgoOffice: ergoOfficesActions.createErgoOffice,
    },
    dispatch,
  );

ErgoOfficeCreate.propTypes = {
  pending: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  createErgoOffice: PropTypes.func.isRequired,
};

ErgoOfficeCreate.defaultProps = {
  pending: false,
  error: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ErgoOfficeCreate);
