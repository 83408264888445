import { Link } from 'react-router-dom';
import { Box, Stack, Icon } from '@chakra-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';

export const Notice = ({ children, spacing, inline, ...props }) => {
  const icon = props.variant === 'info' ? 'info' : 'error';

  return (
    <Box
      rounded="15px"
      bg={`panel.${props.variant}.bg`}
      px="38px"
      py={inline ? 5 : 8}
      textAlign={inline ? null : 'center'}
      position="relative"
      {...props}
    >
      <Icon
        name={icon}
        size="28px"
        color={`panel.${props.variant}.text`}
        position="absolute"
        top={inline ? '50%' : '0%'}
        left={inline ? '0%' : '50%'}
        transform="translate(-50%, -50%)"
      />
      <Stack spacing={spacing || 8}>{children}</Stack>
    </Box>
  );
};

export const NoticeText = ({ children, ...props }) => {
  return (
    <Box as="p" color={`panel.${props.variant}.text`} fontWeight={600} {...props}>
      {children}
    </Box>
  );
};

export const NoticeList = ({ children, ...props }) => {
  return (
    <Box
      as="ul"
      textAlign="left"
      color="gray"
      fontWeight="normal"
      pl={6}
      listStyleType="none"
      {...props}
      css={theme => css`
        li {
          position: relative;
        }
        li:before {
          content: '-';
          position: absolute;
          left: 0;
          top: 0.1ch;
          transform: translateX(-300%);
          color: ${theme.colors.panel[props.variant].text};
          font-weight: bold;
        }
      `}
    >
      {children}
    </Box>
  );
};

export const NoticeThinText = ({ children, ...props }) => {
  return (
    <Box as="p" color="semiDarkGray.900" fontWeight={300} {...props}>
      {children}
    </Box>
  );
};

export const NoticeLink = ({ to, children, ...props }) => {
  return (
    <Box as={Link} to={to} textDecoration="underline" {...props}>
      {children}
    </Box>
  );
};
