import React from 'react';
import {
  Tag,
  Box,
  Menu,
  MenuButton,
  Button,
  Icon,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from '@chakra-ui/core';
import { TabPanel as BaseTabPanel } from '../common/components/Tabs';
import { TableEditLink as BaseTableEditLink } from '../common/components/Table';

export const TASKS_TYPES = {
  orange: 'Actions sur dossier',
  red: 'Envois de documents',
  purple: 'Appels',
  gray: 'Aléas',
};

export const TypeTag = ({ children, variantColor, ...props }) => (
  <Tag
    variantColor={null}
    variant="solid"
    bg={`tag.bg.${variantColor}`}
    color={`tag.color.${variantColor}`}
    rounded="0.5rem"
    fontWeight="600"
    {...props}
  >
    {children}
  </Tag>
);

export const TableEditLink = ({ children, ...props }) => {
  return (
    <BaseTableEditLink display="flex" alignItems="center" {...props}>
      {children}
    </BaseTableEditLink>
  );
};

export const TabPanel = ({ children, ...props }) => {
  return (
    <BaseTabPanel pos="relative" pb={0} {...props}>
      {children}
    </BaseTabPanel>
  );
};

export const TBody = ({ children, ...props }) => {
  return (
    <Box as="tbody" d="block" flexGrow="1" overflow="auto" marginTop={3} pos="relative" {...props}>
      {children}
    </Box>
  );
};

export const THFilter = ({ menuOptionGroupOptions, options, label, placement, isUpppercase }) => {
  return (
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            variant="ghost"
            p={0}
            h="auto"
            fontWeight="bold"
            color={menuOptionGroupOptions.value.length ? 'coral.900' : null}
            variantColor={null}
            _focus={{ boxShadow: 'none' }}
          >
            {label}
            <Icon
              name="chevron-down"
              color="coral.900"
              size="13px"
              strokeWidth={2}
              ml={3}
              transform={isOpen ? 'rotate(180deg)' : undefined}
            />
          </MenuButton>
          <MenuList
            minWidth="240px"
            placement={placement || 'bottom-start'}
            boxShadow="popover"
            borderRadius="lg"
            marginTop={-1}
            border={0}
            zIndex="dropdown"
            py={0}
            maxW="450px"
            maxH="50vh"
            overflow="auto"
          >
            <MenuOptionGroup type="checkbox" {...menuOptionGroupOptions}>
              {options.map(option => (
                <MenuItemOption
                  key={option}
                  value={option}
                  css={{ svg: { color: 'coral' } }}
                  paddingY={1}
                  textTransform={isUpppercase ? 'uppercase' : undefined}
                  _checked={{ fontWeight: 'bold' }}
                  _hover={{ backgroundColor: 'lightGray' }}
                  _focus={{ backgroundColor: 'select.bg.isSelected', outline: 0 }}
                  _first={{ borderTopLeftRadius: 'lg', borderTopRightRadius: 'lg' }}
                  _last={{ borderBottomLeftRadius: 'lg', borderBottomRightRadius: 'lg' }}
                >
                  {option}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
