import { connect } from 'react-redux';

export const is = (user, role) => {
  return user.role === role;
};

export const hasQualification = (user, qualification) => {
  return user?.ergoQualification === qualification || false;
};

const IsComponent = ({ user, ...props }) => {
  return is(user, props.userRole) ? props.yes(props) : props.no(props);
};

export const isExtern = data =>
  hasQualification(data?.user, 'référent de cabinet') ||
  hasQualification(data?.user, 'ergothérapeute de cabinet') ||
  false;

export const isOfficeRef = data =>
  (data?.user && hasQualification(data?.user, 'référent de cabinet')) || false;

export const isErgoIntern = data =>
  data?.user && hasQualification(data?.user, 'ergothérapeute interne');

IsComponent.defaultProps = {
  yes: () => null,
  no: () => null,
};

const mapStateToProps = ({ authentication: { user } }) => ({
  user,
});

export const Is = connect(mapStateToProps)(IsComponent);
