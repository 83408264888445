import React from 'react';
import { connect } from 'react-redux';
import { errorsConstants } from '../redux/errors/constants';
import { PageError, PageError404, PageError500 } from './components';

const ErrorHandler = ({ children, error }) => {
  switch (error) {
    case errorsConstants.ERROR_404:
      return <PageError404 />;
    case errorsConstants.ERROR_500:
      return <PageError500 />;
    case errorsConstants.ERROR_OTHER:
      return <PageError />;
    default:
      return children;
  }
};

const mapStateToProps = ({ errors }) => ({ ...errors });

const ErrorHandlerWithRedux = connect(mapStateToProps)(ErrorHandler);

export { ErrorHandlerWithRedux as ErrorHandler };
