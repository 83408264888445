import { blocEConstants } from './constants';
import { blocAPI } from '../../../common/api/blocs';
import { getDateString, getDateFromString, getStringFromSelectValue } from '../../../common/utils';
import { toastActions } from '../../toast/actions';
import { TOAST_MESSAGE } from '../../../common/components/Toasts/Toast';
import { foldersActions } from '../../folders/actions';
import { errorsActions } from '../../errors/actions';

export const QUOTE_GROUP_TYPES = {
  works: {
    label: 'Travaux',
    icon: 'works',
  },
  technical_assistances: {
    label: 'Aides techniques',
    icon: 'technical-assistances',
  },
  vehicle_arrangements: {
    label: 'Aménagements de véhicule',
    icon: 'vehicule-arrangements',
  },
  wheelchair: {
    label: 'Fauteuils roulants',
    icon: 'manual-wheelchairs',
  },
  human_help_or_service: {
    label: 'Aide(s) humaine(s) ou service(s)',
    icon: 'human-help',
  },
};

export const transformApiPayloadToFormValues = ({
  recipientNotifiedAt,
  quoteGroups,
  ...blocE
}) => ({
  ...blocE,
  quoteGroups:
    quoteGroups.length < 1
      ? [
          {
            name: { value: 'works', label: QUOTE_GROUP_TYPES.works.label },
            companies: [],
            quoteRequestSentAt: null,
          },
        ]
      : quoteGroups.map(quoteGroup => ({
          ...quoteGroup,
          name: { value: quoteGroup.name, label: QUOTE_GROUP_TYPES[quoteGroup.name]?.label },
          quoteRequestSentAt: getDateFromString(quoteGroup.quoteRequestSentAt),
        })),
});

function getBlocE(folderId) {
  function request() {
    return { type: blocEConstants.GET_BLOCE_REQUEST };
  }
  function success(blocE) {
    return { type: blocEConstants.GET_BLOCE_SUCCESS, blocE };
  }
  function failure(error) {
    return { type: blocEConstants.GET_BLOCE_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return blocAPI
      .getBloc(folderId, 'bloc_e_quote_request')
      .then(({ blocEQuoteRequest: blocE }) => {
        dispatch(success(transformApiPayloadToFormValues(blocE)));
        dispatch(foldersActions.getFolderFromBloc(blocE.folder || {}));
      }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function updateBlocE(folderId, updatedBlocE) {
  function request(blocE) {
    return { type: blocEConstants.UPDATE_BLOCE_REQUEST, blocE };
  }
  function success(blocE) {
    return { type: blocEConstants.UPDATE_BLOCE_SUCCESS, blocE };
  }
  function failure(error) {
    return { type: blocEConstants.UPDATE_BLOCE_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedBlocE));

    return blocAPI
      .updateBloc(folderId, 'bloc_e_quote_request', {
        ...updatedBlocE,
        folderAttributes: {
          id: updatedBlocE.folderId,
          quoteGroupsAttributes: updatedBlocE.quoteGroups.map(quoteGroup => ({
            ...quoteGroup,
            name: getStringFromSelectValue(quoteGroup.name),
            quoteRequestSentAt: getDateString(quoteGroup.quoteRequestSentAt),
            companyIds: quoteGroup.companies.map(company => company.companyId || company.id),
            companies: undefined,
            requestSource:
              quoteGroup.name === 'works' && updatedBlocE.isBiddingCompetitive !== 'yes'
                ? 'recipient'
                : 'belavie',
          })),
        },
      })
      .then(({ blocEQuoteRequest: blocE }) => {
        dispatch(foldersActions.getFolderFromBloc(blocE.folder || {}));
        dispatch(success(transformApiPayloadToFormValues(blocE)));
        dispatch(toastActions.success(TOAST_MESSAGE.success.update));
      })
      .catch(e => {
        dispatch(
          errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update)),
        )(e);
        throw e;
      });
  };
}

// Selectors

const getBlocEById = (state, props) => state.blocs.blocE[props.match.params.folderId];

export const blocESelectors = {
  getBlocEById,
};

export const blocEActions = {
  getBlocE,
  updateBlocE,
};
