import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Stack, Box } from '@chakra-ui/core';

import { TabForm } from '../../../../common/components/Tabs';
import { FormSection, FormSubmitButton } from '../../../../common/components/Form/Form';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import { Notice, NoticeText, NoticeThinText } from '../../../../common/components/Notice';
import { RadioGroup, RadioInput } from '../../../../common/components/Form/FormInputs';

export const BlocITab1 = ({
  blocI: { error, pending, folder = {}, ...initialValues },
  onSubmit,
}) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        return onSubmit({ ...initialValues, folder, ...values }).then(() => {
          setTouched({});
          setSubmitting(false);
        });
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit}>
          <Stack spacing="3.5rem">
            <FormSection title="Etat du dossier">
              <RadioGroup name="state">
                <RadioInput value="Actif">Dossier actif</RadioInput>
                <RadioInput value="En instance">Mise en instance</RadioInput>
                <RadioInput value="Cloturé">Clôture</RadioInput>
              </RadioGroup>
            </FormSection>

            {formik.values.state === 'Cloturé' && (
              <>
                <Notice variant="warning">
                  <NoticeText variant="warning">Attention action irréversible</NoticeText>
                </Notice>

                <Box
                  as="section"
                  bg="lightGray"
                  borderRadius="15px"
                  p="1.5rem 1.75rem 1rem 1.75rem"
                  mt="1.25rem"
                  mb="1.5rem"
                >
                  <RadioGroup
                    legend="Motif de clôture"
                    name="closureCause"
                    disabled={folder.state === 'Cloturé'}
                  >
                    <RadioInput value="Aboutissement">Aboutissement</RadioInput>
                    <RadioInput value="Demande du bénéficiaire">Demande du bénéficiaire</RadioInput>
                    <RadioInput value="Déménagement en structure d'hébergement spécialisé">
                      Déménagement en structure d'hébergement spécialisée
                    </RadioInput>
                    <RadioInput value="Déménagement hors structure d'hébergement spécialisé">
                      Déménagement hors structure d'hébergement spécialisé
                    </RadioInput>
                    <RadioInput value="Non adaptable">Non adaptable</RadioInput>
                    <RadioInput value="Non finançable">Non finançable</RadioInput>
                    <RadioInput value="Décès">Décès</RadioInput>
                  </RadioGroup>

                  <DateInput
                    label="Date d'envoi des documents de clôture"
                    name="closureDocumentsSentAt"
                    id="closureDocumentsSentAt"
                    disabled={folder.state === 'Cloturé'}
                  />
                </Box>

                <Notice variant="info" spacing="1rem">
                  <NoticeText variant="info">
                    Documents à envoyer à la personne à contacter
                  </NoticeText>
                  <NoticeThinText>
                    Si nécessaire, consulter les fiches-procédures ou l'administrateur
                  </NoticeThinText>
                </Notice>

                <Box textAlign="center" mt="3.125rem">
                  <FormSubmitButton
                    onClick={() => formik.submitForm()}
                    disabled={!formik.values.closureCause || !formik.values.closureDocumentsSentAt}
                  >
                    Clôturer
                  </FormSubmitButton>
                </Box>
              </>
            )}
          </Stack>

          <FormSubmitButton
            type="submit"
            disabled={formik.isSubmitting || pending}
            isLoading={formik.isSubmitting}
            loadingText="Enregistrer"
          >
            Enregistrer
          </FormSubmitButton>
        </TabForm>
      )}
    </Formik>
  );
};
