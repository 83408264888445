import { apiV1 } from './api';

const getBloc = (folderId, blocLabel) =>
  apiV1.get(`/folders/${folderId}/${blocLabel}`).then(({ data }) => data);

const updateBloc = (folderId, blocLabel, blocData) =>
  apiV1
    .put(`/folders/${folderId}/${blocLabel}`, { [blocLabel]: blocData })
    .then(({ data }) => data);

export const blocAPI = {
  getBloc,
  updateBloc,
};

const getBlocFio = folderId => apiV1.get(`/bloc_fios/${folderId}`).then(({ data }) => data);

const updateBlocFio = (folderId, blocFio) =>
  apiV1
    .put(`/bloc_fios/${folderId}`, {
      blocFio,
    })
    .then(({ data }) => data);

export const blocFioAPI = {
  getBlocFio,
  updateBlocFio,
};
