import { connect } from 'react-redux';
import { RBArules } from './rules';

const exclude = (rules, role, action, data) => {
  const permissions = rules[role];
  if (!permissions) {
    return false;
  }

  const excludedPermissions = permissions.exclude;

  if (excludedPermissions) {
    const permissionCondition = excludedPermissions[action];
    if (!permissionCondition) {
      return false;
    }
    return permissionCondition(data);
  }
  return false;
};

const Exclude = ({ user, ...props }) => {
  return exclude(RBArules, user.role, props.exclude, { user, ...props.data })
    ? props.yes(props)
    : props.no(props);
};

Exclude.defaultProps = {
  yes: () => null,
  no: () => null,
};

const mapStateToProps = ({ authentication: { user, profile } }) => ({
  user: Object.keys(profile || {}).length === 0 ? user : profile,
});

export default connect(mapStateToProps)(Exclude);
