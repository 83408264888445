import React from 'react';
import { Tabs, Tab, TabPanels, TabPanel, TabList } from '../../../../common/components/Tabs';
import Can from '../../../../roleBasedAccess/Can';
import { BlocITab1 } from './BlocITab1';
import { BlocITab2 } from './BlocITab2';
import BlocITab3 from './BlocITab3';

export const BlocITabs = ({
  match: {
    params: { folderId, tabIndex },
  },
  blocId,
  ...props
}) => {
  return (
    <Can
      perform="folder:edit"
      yes={() => (
        <Tabs folderId={folderId} tabIndex={tabIndex} blocId={blocId}>
          <TabList>
            <Tab>Clôture du dossier</Tab>
            <Tab>Synthèse</Tab>
            <Tab>Commentaires</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <BlocITab1 {...props} />
            </TabPanel>
            <TabPanel>
              <BlocITab2 {...props} />
            </TabPanel>
            <TabPanel>
              <BlocITab3 {...props} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
      no={() => (
        <Can
          perform="comments:read"
          yes={() => (
            <Tabs folderId={folderId} tabIndex={tabIndex} blocId={blocId}>
              <TabList>
                <Tab>Synthèse</Tab>
                <Tab>Commentaires</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <BlocITab2 {...props} />
                </TabPanel>
                <TabPanel>
                  <BlocITab3 {...props} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
          no={() => (
            <Tabs folderId={folderId} tabIndex={tabIndex} blocId={blocId}>
              <TabList>
                <Tab>Synthèse</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <BlocITab2 {...props} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          )}
        />
      )}
    />
  );
};
