import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { Box, Stack, Text } from '@chakra-ui/core';

import { TabForm } from '../../../../common/components/Tabs';
import { FormSection, FormSubmitButton } from '../../../../common/components/Form/Form';
import { Notice, NoticeText } from '../../../../common/components/Notice';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import { RadioGroup, RadioInput, TextInput } from '../../../../common/components/Form/FormInputs';
import { LockedInput } from '../../../../common/components/Form/FormLockedInput';
import { traverseObject } from '../../../../common/utils';

export const BlocGTabForm = ({
  pending,
  fieldPrefix,
  funderName,
  needOwnerAutorisation,
  hasWorks,
  hasOwnerAuthorizedWorks,
  ...props
}) => {
  const { values, setFieldValue, handleSubmit, isSubmitting } = useFormikContext();

  const companiesRequestedAt = traverseObject(
    values,
    `${fieldPrefix}.companiesDocumentsRequestedAt`,
  );
  const areCompaniesDocumentsValid = traverseObject(
    values,
    `${fieldPrefix}.areCompaniesDocumentsValid`,
  );
  const isDepositUnlocked = traverseObject(values, `${fieldPrefix}.isDepositUnlocked`);
  const hasProcuration = traverseObject(values, `${fieldPrefix}.hasProcuration`);
  const managedBy = traverseObject(values, `${fieldPrefix}.managedBy`);
  const fundingResponse = traverseObject(values, `${fieldPrefix}.fundingResponse`);
  const fundingRequestedAt = traverseObject(values, `${fieldPrefix}.fundingRequestedAt`);
  const isGrantedAmountKnown = traverseObject(values, `${fieldPrefix}.isGrantedAmountKnown`);

  const isFunderResponseNotApproval = !fundingResponse || fundingResponse !== 'approval';

  const [previousManagedBy, setPreviousManagedBy] = useState(managedBy);

  useEffect(() => {
    // Reset grantedAmount and isGrantedAmountKnown when funderResponse is not 'approval'
    if (isFunderResponseNotApproval) {
      setFieldValue(`${fieldPrefix}.grantedAmount`, null);
      setFieldValue(`${fieldPrefix}.isGrantedAmountKnown`, '');
    }
  }, [values, fieldPrefix, setFieldValue, isFunderResponseNotApproval]);

  useEffect(() => {
    // Update fundingRequestedAt date with fundingPlanSentAt date when funding handler is the recipient
    if (managedBy === 'recipient') {
      setFieldValue(`${fieldPrefix}.fundingRequestedAt`, values.fundingPlanSentAt);
      setPreviousManagedBy(managedBy);
    }
    if (managedBy === 'belavie' && previousManagedBy === 'recipient') {
      setFieldValue(`${fieldPrefix}.fundingRequestedAt`, null);
      setPreviousManagedBy(managedBy);
    }
  }, [values, fieldPrefix, setFieldValue, managedBy, previousManagedBy]);

  return (
    <TabForm mx="0" ml={5} maxWidth="450px" onSubmit={handleSubmit} {...props}>
      <Stack spacing="3.5rem">
        {needOwnerAutorisation &&
          hasWorks &&
          (!hasOwnerAuthorizedWorks || hasOwnerAuthorizedWorks === 'false') && (
            <Notice variant="warning">
              {!hasOwnerAuthorizedWorks && (
                <NoticeText variant="warning">
                  Il n'est pas recommandé de solliciter de financeurs car le positionnement du
                  propriétaire n'est pas reçu.
                </NoticeText>
              )}
              {hasOwnerAuthorizedWorks === 'false' && (
                <NoticeText variant="warning">
                  Le propriétaire n'autorise pas les travaux. Il n'est pas recommandé de solliciter
                  des financeurs pour l'ensemble du projet.
                </NoticeText>
              )}
            </Notice>
          )}

        {funderName === 'actionLogement' ? (
          <FormSection title="Certification des entreprises" mb="3rem">
            <RadioGroup
              legend="Documents des entreprises valides"
              name={`${fieldPrefix}.areCompaniesDocumentsValid`}
            >
              <RadioInput value="true">Oui</RadioInput>
              <RadioInput value="false">Non</RadioInput>
            </RadioGroup>

            <LockedInput defaultIsLocked={!!companiesRequestedAt} left="16ch">
              <DateInput
                label="Dernière date de demande des documents des entreprises valides"
                name={`${fieldPrefix}.companiesDocumentsRequestedAt`}
                id={`${fieldPrefix}.companiesDocumentsRequestedAt`}
                disabled={!areCompaniesDocumentsValid || areCompaniesDocumentsValid === 'true'}
              />
            </LockedInput>

            <DateInput
              label="Date de réception de documents des entreprises"
              name={`${fieldPrefix}.companiesDocumentsReceivedAt`}
              id={`${fieldPrefix}.companiesDocumentsReceivedAt`}
              disabled={!companiesRequestedAt || areCompaniesDocumentsValid === 'true'}
            />
          </FormSection>
        ) : null}

        {['anah', 'mpa'].includes(funderName) ? (
          <FormSection title="Acompte et procuration">
            <RadioGroup legend="Déblocage acompte" name={`${fieldPrefix}.isDepositUnlocked`}>
              <RadioInput value="true">Oui</RadioInput>
              <RadioInput value="false">Non</RadioInput>
            </RadioGroup>

            <DateInput
              label="Date de réception de demande d'acompte"
              name={`${fieldPrefix}.depositRequestReceivedAt`}
              id={`${fieldPrefix}.depositRequestReceivedAt`}
              disabled={!isDepositUnlocked || isDepositUnlocked === 'false'}
            />

            <RadioGroup legend="Procuration" name={`${fieldPrefix}.hasProcuration`}>
              <RadioInput value="true">Oui</RadioInput>
              <RadioInput value="false">Non</RadioInput>
            </RadioGroup>

            <DateInput
              label="Date de réception de procuration"
              name={`${fieldPrefix}.procurationReceivedAt`}
              id={`${fieldPrefix}.procurationReceivedAt`}
              disabled={!hasProcuration || hasProcuration === 'false'}
            />
          </FormSection>
        ) : null}

        <FormSection title="Suivi de l'instruction">
          <RadioGroup legend="Gestionnaire de la demande" name={`${fieldPrefix}.managedBy`}>
            <RadioInput value="belavie">Bel'Avie</RadioInput>
            <RadioInput value="recipient">Bénéficiaire</RadioInput>
          </RadioGroup>

          <RadioGroup legend="Positionnement" name={`${fieldPrefix}.fundingResponse`}>
            <RadioInput value="approval" disabled={!managedBy}>
              Accord de financement
            </RadioInput>
            <RadioInput value="refusal" disabled={!managedBy}>
              Refus de financement
            </RadioInput>
            <RadioInput value="not_requested" disabled={!managedBy}>
              Non sollicité après accord du bénéficiaire
            </RadioInput>
          </RadioGroup>

          <LockedInput defaultIsLocked={!!fundingRequestedAt} left="16ch">
            <DateInput
              label={
                managedBy === 'recipient'
                  ? "Date d'envoi du plan de financement"
                  : 'Dernière date de demande de financement'
              }
              name={`${fieldPrefix}.fundingRequestedAt`}
              id={`${fieldPrefix}.fundingRequestedAt`}
              disabled={!managedBy}
            />
          </LockedInput>

          <DateInput
            label="Date de retour du financeur"
            name={`${fieldPrefix}.funderResponseReceivedAt`}
            id={`${fieldPrefix}.funderResponseReceivedAt`}
            disabled={!fundingRequestedAt}
          />
        </FormSection>

        <FormSection title="Montant octroyable">
          <RadioGroup name={`${fieldPrefix}.isGrantedAmountKnown`}>
            <RadioInput value="true" disabled={isFunderResponseNotApproval}>
              Connu
            </RadioInput>
            <RadioInput value="false" disabled={isFunderResponseNotApproval}>
              Inconnu
            </RadioInput>
          </RadioGroup>

          <Box pos="relative">
            <TextInput
              label="Montant (comprenant le financement de l'AMO si existant et le financement des préconisations)"
              type="number"
              id={`${fieldPrefix}.grantedAmount`}
              name={`${fieldPrefix}.grantedAmount`}
              placeholder="XXXXX"
              width="12ch"
              disabled={!isGrantedAmountKnown || isGrantedAmountKnown === 'false'}
            />
            <Text
              color="semiDarkGray.600"
              pos="absolute"
              bottom="0.5ch"
              left="11.5ch"
              fontWeight={600}
              fontSize="lg"
              pt="0.5ch"
            >
              €
            </Text>
          </Box>
        </FormSection>
      </Stack>

      <FormSubmitButton
        type="submit"
        disabled={isSubmitting || pending}
        isLoading={isSubmitting}
        loadingText="Enregistrer"
      >
        Enregistrer
      </FormSubmitButton>
    </TabForm>
  );
};
