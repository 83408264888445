import React, { useEffect } from 'react';
import { Box, Stack, Heading, List, ListItem, Text, Icon, Flex } from '@chakra-ui/core';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { useFormSubmitDisabled } from './FormSubmitDisabled.context';
import { useIsMounted } from '../../utils/useIsMounted.hook';

export const Form = ({ children, onSubmit, ...props }) => {
  const { isFormSubmitDisabled } = useFormSubmitDisabled();
  const isMounted = useIsMounted();

  const preventOnSubmit = e => e.preventDefault();

  useEffect(() => {
    if (isMounted) {
      document.addEventListener('wheel', () => {
        if (document.activeElement.type === 'number') {
          document.activeElement.blur();
        }
      });
    }
  }, [isMounted]);

  return (
    <Box
      as="form"
      pt={20}
      pb={16}
      mx="auto"
      maxW="472px"
      px={4}
      onSubmit={isFormSubmitDisabled ? preventOnSubmit : onSubmit}
      {...props}
    >
      {children}
    </Box>
  );
};

export const FormSection = ({ title, children, ...props }) => (
  <Box as="section" {...props}>
    <Heading
      as="h3"
      fontSize="lg"
      textTransform="uppercase"
      color="semiDarkGray.900"
      mb={6}
      fontFamily="body"
    >
      {title}
    </Heading>
    <Box px={5}>{children}</Box>
  </Box>
);

export const FormActions = ({ children, ...props }) => (
  <Stack isInline spacing={4} justifyContent="center" mt={10} {...props}>
    {children}
  </Stack>
);

export const FormList = ({ children, ...props }) => (
  <List styleType="none" spacing="2.812rem" {...props}>
    {children}
  </List>
);

export const FormListItem = ({ children, ...props }) => (
  <ListItem
    pos="relative"
    _before={{
      content: "'-'",
      position: 'absolute',
      left: '-1.5ch',
      top: '0ch',
      color: 'semiDarkGray.600',
    }}
    {...props}
  >
    {children}
  </ListItem>
);

export const FormIconSection = ({ title, icon, children, ...props }) => (
  <Box as="section" {...props}>
    <Flex align="center" ml="-2.1rem" mb="1rem">
      <Icon name={icon} size={6} color="semiDarkGray.900" />
      <Text color="semiDarkGray.900" fontWeight="600" fontFamily="body" ml="10px">
        {title}
      </Text>
    </Flex>
    <Box>{children}</Box>
  </Box>
);

export const FormSubmitButton = ({ children, ...props }) => {
  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  return (
    <PrimaryButton {...props} d={isFormSubmitDisabled ? 'none' : 'inline-flex'}>
      {children}
    </PrimaryButton>
  );
};

export const FormCTAButton = ({ children, ...props }) => {
  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  return (
    <SecondaryButton {...props} d={isFormSubmitDisabled ? 'none' : 'inline-flex'}>
      {children}
    </SecondaryButton>
  );
};
