import { api } from './api';

const isOtpRequired = email => api.get(`users/otp_required?email=${email}`);

const login = (email, password) =>
  api.post('users/code_sms', {
    user: {
      email,
      password,
    },
  });

const loginOTP = (email, password, otpAttempt) =>
  api
    .post('users/sign_in', {
      user: {
        email,
        password,
        otpAttempt,
      },
    })
    .then(({ data: { user } }) => user);

const logout = () => api.delete('users/sign_out');

export const authAPI = { isOtpRequired, logout, login, loginOTP };
