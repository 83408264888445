import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Icon, Tooltip, VisuallyHidden } from '@chakra-ui/core';
import { Tabs, Tab, TabPanels, TabPanel, TabList } from '../../../../common/components/Tabs';
import { BlocFTab } from './BlocFTab';
import { QUOTE_GROUP_TYPES } from '../../../../redux/blocs/blocE/actions';
import { BlocFTabSummary } from './BlocFTabSummary';
import { getFolderPath } from '../../../../common/routes';
import { PageError404 } from '../../../../errors/components';
import Exclude from '../../../../roleBasedAccess/Exclude';

export const BlocFTabs = ({ blocId, ...props }) => {
  const {
    match: {
      params: { folderId, tabIndex },
    },
    blocF: { quoteGroups },
  } = props;

  return (
    <Tabs folderId={folderId} tabIndex={tabIndex} blocId={blocId}>
      <Exclude
        exclude="folder:bloc_f:tab_summary:read"
        no={() => (
          <TabList>
            {quoteGroups &&
              quoteGroups.map(quoteGroup => (
                <Tab key={quoteGroup.id} px={3} pt={2}>
                  <Tooltip
                    bg="semiDarkGray.900"
                    label={QUOTE_GROUP_TYPES[quoteGroup.name]?.label}
                    placement="top"
                  >
                    <Icon name={QUOTE_GROUP_TYPES[quoteGroup.name]?.icon} size={6} />
                  </Tooltip>
                  <VisuallyHidden>{QUOTE_GROUP_TYPES[quoteGroup.name]?.label}</VisuallyHidden>
                </Tab>
              ))}
            <Tab>Synthèse</Tab>
          </TabList>
        )}
        yes={() => (
          <TabList>
            {quoteGroups &&
              quoteGroups.map(quoteGroup => (
                <Tab key={quoteGroup.id} px={3} pt={2}>
                  <Tooltip
                    bg="semiDarkGray.900"
                    label={QUOTE_GROUP_TYPES[quoteGroup.name]?.label}
                    placement="top"
                  >
                    <Icon name={QUOTE_GROUP_TYPES[quoteGroup.name]?.icon} size={6} />
                  </Tooltip>
                  <VisuallyHidden>{QUOTE_GROUP_TYPES[quoteGroup.name]?.label}</VisuallyHidden>
                </Tab>
              ))}
          </TabList>
        )}
      />

      <Switch>
        <Route
          path={getFolderPath(blocId, ':folderId', ':tabIndex')}
          exact
          render={routerProps => (
            <Redirect to={getFolderPath(blocId, folderId, `${tabIndex}/1`)} {...routerProps} />
          )}
        />
        <Route
          path={getFolderPath(blocId, ':folderId', ':tabIndex/:companyIndex')}
          render={routerProps => (
            <TabPanels>
              {quoteGroups &&
                quoteGroups.map((quoteGroup, quoteGroupIndex) => (
                  <TabPanel key={quoteGroup.id}>
                    <BlocFTab
                      {...props}
                      quoteGroup={quoteGroup}
                      quoteGroupIndex={quoteGroupIndex}
                      blocId={blocId}
                      {...routerProps}
                    />
                  </TabPanel>
                ))}

              <TabPanel>
                <Exclude
                  exclude="folder:bloc_f:tab_summary:read"
                  no={() => <BlocFTabSummary {...props} />}
                />
              </TabPanel>
            </TabPanels>
          )}
        />
        <Route component={PageError404} />
      </Switch>
    </Tabs>
  );
};
