import React from 'react';
import { Box, Flex, Heading, Text, Divider, PseudoBox } from '@chakra-ui/core';
import { TabTH, TabTBodyRow } from '../common/components/Tabs';

export const HorizontalSection = ({
  title,
  subtitle,
  children,
  justifyText,
  headerPadding,
  ...props
}) => {
  return (
    <Box as="section" rounded="15px" bg="rgba(255, 255, 255, 0.8)" w="100%" {...props}>
      <Flex
        boxShadow="0 4px 10px rgba(46, 73, 112, 0.25)"
        rounded="15px 15px 0 0"
        bg="white"
        p={!headerPadding ? '8px 15px 5px 15px' : headerPadding}
        align="center"
        justify={justifyText}
      >
        <Heading
          as="h2"
          fontFamily="body"
          fontSize="1.125rem"
          fontWeight="700"
          textTransform="uppercase"
          pr="8px"
        >
          {title}
        </Heading>
        {subtitle && (
          <Text color="semiDarkGray.600" fontSize="0.75rem">
            {subtitle}
          </Text>
        )}
      </Flex>
      {children}
    </Box>
  );
};

export const VerticalTableWrap = ({ children, ...props }) => (
  <Box
    bg="white"
    pt="0.625rem"
    pb={1}
    rounded="15px"
    textAlign="center"
    boxShadow="0 4px 10px rgba(46, 73, 112, 0.1)"
    {...props}
  >
    {children}
  </Box>
);

export const VerticalTBody = ({ children, shadowStyles, ...props }) => {
  return (
    <Box
      as="tbody"
      d="flex"
      flexDir="column"
      position="relative"
      bg="lightGray"
      rounded="15px"
      {...props}
    >
      <Box
        as="tr"
        bg="white"
        pos="absolute"
        width="50%"
        height="100%"
        top="0"
        left="0"
        boxShadow="0px 4px 10px rgba(46, 73, 112, 0.25)"
        rounded="15px 0px 0px 15px"
        {...shadowStyles}
      />
      {children}
    </Box>
  );
};

export const VerticalTBodyRowGrid = ({ children, ...props }) => (
  <TabTBodyRow zIndex="1" d="grid" gridTemplateColumns="1fr 4.6rem" {...props}>
    {children}
  </TabTBodyRow>
);

export const VerticalTBodyRowFlex = ({ children, ...props }) => (
  <TabTBodyRow zIndex="1" d="flex" alignItems="center" {...props}>
    {children}
  </TabTBodyRow>
);

export const VerticalTDNumber = ({ children, ...props }) => {
  return (
    <PseudoBox
      as="td"
      px="td.px"
      py="td.py"
      textAlign="center"
      fontSize="1.625rem"
      fontWeight="700"
      _notLast={{ hr: { display: 'block!important' } }}
      _last={{ hr: 'display: none' }}
      {...props}
    >
      {children}
    </PseudoBox>
  );
};

export const VerticalTDNumberSmallBorder = ({ children, ...props }) => {
  return (
    <VerticalTDNumber
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderBottom="0"
      whiteSpace="nowrap"
      position="relative"
      flexGrow={1}
      py={2}
      px={1}
      {...props}
    >
      {children}
      <Divider
        borderColor="coral.900"
        width="45px"
        margin="0 auto"
        position="absolute"
        bottom="0"
        left="50%"
        transform="translateX(-50%)"
      />
    </VerticalTDNumber>
  );
};

export const VerticalTDLargeNumber = ({ children, currency, ...props }) => {
  return (
    <VerticalTDNumber
      borderBottom="0"
      d="flex"
      flexDir="column"
      whiteSpace="nowrap"
      height="100%"
      flexGrow={1}
      py="1.75rem"
      {...props}
    >
      <Text textAlign="right">
        {children}
        {currency ? (
          <Text as="span" fontWeight="normal">
            {' '}
            €
          </Text>
        ) : null}
      </Text>
      <Divider
        borderColor="coral.900"
        width="45px"
        margin="0 auto"
        position="relative"
        top="2rem"
      />
    </VerticalTDNumber>
  );
};

export const VerticalTDNumberBorderRight = ({ number, text, children, percentage, ...props }) => {
  return (
    <VerticalTDNumber borderBottom="0" d="block" p="0.75rem 1.125rem" pos="relative" {...props}>
      <Text mb={percentage ? 2 : 0}>
        {number?.toLocaleString() || '?'}
        {percentage && (
          <Box as="span" fontWeight="thin">
            %
          </Box>
        )}
      </Text>
      <Text color="gray" fontSize="0.875rem" fontWeight="normal">
        {text}
      </Text>
      <Divider
        d="block"
        orientation="vertical"
        h="45px"
        borderColor="coral.900"
        position="absolute"
        m="0"
        right="0"
        top="50%"
        transform="translateY(-50%)"
      />
    </VerticalTDNumber>
  );
};

export const VerticalTBodyTH = ({ children, ...props }) => (
  <TabTH
    border="0"
    textAlign="left"
    pl="2rem"
    pr="1rem"
    color="semiDarkGray.900"
    fontSize="0.875rem"
    fontWeight="600"
    display="flex"
    alignItems="center"
    {...props}
  >
    {children}
  </TabTH>
);

export const VerticalTBodyTHShadow = ({ children, ...props }) => (
  <VerticalTBodyTH
    textTransform="uppercase"
    textAlign="center"
    fontSize="1rem"
    fontWeight="700"
    py="1.25rem"
    {...props}
  >
    {children}
  </VerticalTBodyTH>
);

export const DoubleColorHeading = ({ title, coralTitle, ...props }) => {
  return (
    <Heading
      as="h3"
      fontFamily="body"
      fontSize="1rem"
      fontWeight="700"
      textTransform="uppercase"
      pb="10px"
      ml={4}
      {...props}
    >
      {title}
      <Box as="span" color="coral.900">
        {coralTitle}
      </Box>
    </Heading>
  );
};
