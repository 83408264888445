import { combineReducers } from 'redux';

import { authentication } from './auth/reducer';
import { operators } from './operators/reducer';
import { recipients } from './recipients/reducer';
import { toast } from './toast/reducer';
import { companies } from './companies/reducer';
import { ergoOffices } from './ergoOffices/reducer';
import { folders } from './folders/reducer';
import { quotes } from './quotes/reducer';
import { projects } from './projects/reducer';
import { preFolders } from './preFolders/reducer';
import { config } from './config/reducer';
import { blocsReducer } from './blocs/blocsReducer';
import { tasks } from './tasks/reducer';
import { dashboard } from './dashboard/reducer';
import { ergoProjects } from './ergoProjects/reducer';
import { errors } from './errors/reducer';
import { ergoResources } from './ergoResources/reducer';

const rootReducer = combineReducers({
  authentication,
  operators,
  recipients,
  toast,
  companies,
  folders,
  preFolders,
  quotes,
  projects,
  config,
  blocs: blocsReducer,
  tasks,
  dashboard,
  errors,
  ergoOffices,
  ergoProjects,
  ergoResources,
});

export default rootReducer;
