import { blocFioConstants } from './constants';

export const initialState = {
  pending: false,
};

export function blocFio(state = initialState, action) {
  switch (action.type) {
    case blocFioConstants.GET_BLOCFIO_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case blocFioConstants.GET_BLOCFIO_SUCCESS:
      return {
        ...initialState,
        ...action.blocFio,
        pending: false,
      };
    case blocFioConstants.UPDATE_BLOCFIO_REQUEST:
      return {
        ...state,
        questionsValues: action.questionsValues,
        error: null,
        pending: true,
      };
    case blocFioConstants.UPDATE_BLOCFIO_SUCCESS:
      return {
        ...state,
        ...action.blocFio,
        error: null,
        pending: false,
      };
    case blocFioConstants.UPDATE_BLOCFIO_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case blocFioConstants.GET_BLOCFIO_FAILURE:
    default:
      return state;
  }
}
