export const isQuoteVersionFilled = quote =>
  quote?.quoteReceivedAt &&
  (quote?.quoteStatus && quote?.quoteStatus?.match(/additional_request/)
    ? quote?.additionalRequestsSentAt
    : quote?.quoteStatus);

export const isCompanyStatus = (company, status) => {
  const lastQuote = [...company.quoteVersions].reverse().find(quote => quote.quoteReceivedAt);
  return !company.noFollowup && lastQuote?.quoteStatus?.match(status);
};

export const isCompanyValid = company => isCompanyStatus(company, /valid/);

export const isCompanyPending = company => isCompanyStatus(company, /additional_request/);

export const isCompanyFilled = company => company.noFollowup || isCompanyValid(company);
