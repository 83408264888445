import React, { useState, useCallback, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';
import { TagCloseButton, TagLabel, Tag, Text, Box, Stack } from '@chakra-ui/core';
import { AutosuggestInput } from './FormAutosuggestInput';
import { companiesActions } from '../../../redux/companies/actions';
import { debounce, traverseObject } from '../../utils';
import { Label, ErrorMessage } from './FormInputs';
import { useFormSubmitDisabled } from './FormSubmitDisabled.context';

const MultiSelectCompanies = ({
  label,
  companies,
  getCompanies,
  fieldName,
  fieldErrorName,
  isInline,
  disabled,
  noResultText,
  isLocked,
  pending,
  readOnly,
  filter,
  ...props
}) => {
  const { values, setFieldValue, errors } = useFormikContext();
  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  const [search, setSearch] = useState('');

  const error =
    fieldErrorName && errors && traverseObject(values, fieldName).length < 1
      ? traverseObject(errors, fieldErrorName)
      : null;

  const currentCompanies = traverseObject(values, fieldName);

  const suggestions = useMemo(
    () =>
      search.length > 2
        ? companies.filter(
            company => !currentCompanies?.map(suggCompany => suggCompany.id).includes(company.id),
          )
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [companies, search],
  );

  const onSuggestionsFetchRequested = ({ value }) => {
    if (value.length > 2) {
      getCompanies(1, value);
    }
  };

  const onSuggestionsFetchRequestedDebounced = useCallback(
    debounce(onSuggestionsFetchRequested, 250),
    [],
  );

  const onSuggestionsClearRequested = () => {
    getCompanies(1, '!?...');
  };

  return (
    <Box {...props}>
      <Label htmlFor="companyAutoComplete">{label}</Label>

      <Stack isInline={isInline} flexWrap="wrap" mb={3}>
        {currentCompanies.length > 0 ? (
          currentCompanies.map(company => (
            <Box key={company.id}>
              <Tag
                key={company.id}
                variantColor={null}
                mb={isInline ? 1 : 0}
                variant="solid"
                bg="coral.900"
                rounded="0.5rem"
                fontWeight="600"
              >
                <TagLabel>{company.name}</TagLabel>
                <TagCloseButton
                  type="button"
                  color="white"
                  ml={2}
                  opacity="1"
                  name="close"
                  disabled={isLocked || isFormSubmitDisabled}
                  onClick={() =>
                    setFieldValue(
                      fieldName,
                      currentCompanies.filter(companyBis => companyBis.id !== company.id),
                    )
                  }
                />
              </Tag>
            </Box>
          ))
        ) : (
          <Text color="semiDarkGray.600" mt={2}>
            Aucune entreprise sélectionnée
          </Text>
        )}
      </Stack>

      <AutosuggestInput
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequestedDebounced}
        getSuggestionValue={suggestion => suggestion.name}
        onSuggestionSelected={(event, { suggestion, method }) => {
          if (method === 'enter') {
            event.preventDefault();
          }
          setFieldValue(fieldName, [...currentCompanies, suggestion]);
          setSearch('');
        }}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        renderSuggestion={suggestion => <span>{suggestion.name}</span>}
        inputProps={{
          placeholder: 'Les nouveaux artisans',
          value: search,
          onChange: (event, { newValue }) => setSearch(newValue),
        }}
        disabled={disabled}
        isInvalid={error}
        noResultText={noResultText}
        isLocked={isLocked}
        pending={pending}
        id="companyAutoComplete"
        readOnly={readOnly}
      />
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </Box>
  );
};

const mapStateToProps = ({ companies }, { filter: { byDept, excluded = [] } = {} }) => {
  let filteredCompanies = companies.data;

  if (byDept) {
    filteredCompanies = filteredCompanies
      .filter(company => company.departments.map(dept => dept.slice(0, 2)).includes(byDept))
      .filter(company => company.hasAccessToProjectPlace);
  }

  if (excluded.length) {
    filteredCompanies = filteredCompanies.filter(company => !excluded.includes(company.id));
  }

  return {
    companies: filteredCompanies,
    pending: companies.pending,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCompanies: companiesActions.getCompanies,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelectCompanies);
