import React, { useRef, useEffect } from 'react';
import { Formik, useFormikContext } from 'formik';
import { Box, Stack, Text, usePrevious } from '@chakra-ui/core';
import { useHistory } from 'react-router';
import { RecipientFormFields } from '../../common/components/RecipientForm/RecipientFormFields';
import { Form, FormActions, FormSection } from '../../common/components/Form/Form';
import { PrimaryButton, SecondaryButton } from '../../common/components/Buttons';
import { RadioGroup, RadioInput, TextInput } from '../../common/components/Form/FormInputs';
import { FormAddressGroup } from '../../common/components/Form/FormAddressGroup';

export const PreFolderForm = ({
  initialValues,
  onSubmit,
  onTransform,
  error,
  mode,
  submitText,
  ...props
}) => {
  const formikRef = useRef();
  const history = useHistory();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setTouched({});
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  const isFormReadOnly = initialValues.status !== 'En cours de rédaction' && mode === 'edit';

  const isFormInvalid = formik => {
    let notRequiredQuestions = [
      'fioQ4Answer',
      'fioQ13Answer',
      'fioQ17Answer',
      'fioQ20Answer',
      'socialSecurityNumber',
    ];
    if (formik.values.recipientIsTheContact === 'true') {
      notRequiredQuestions = notRequiredQuestions.concat([
        'contactTitle',
        'contactFirstName',
        'contactLastName',
        'contactEmail',
        'contactPhoneNumber',
        'contactCity',
        'contactPostalCode',
        'contactStreetAddress',
      ]);

      if (formik.values.sendDocumentsBy === 'post') {
        notRequiredQuestions.push('email');
      }
    } else {
      notRequiredQuestions = notRequiredQuestions.concat(['phoneNumber', 'email']);

      if (formik.values.sendDocumentsBy === 'post') {
        notRequiredQuestions.push('contactEmail');
      } else {
        notRequiredQuestions = notRequiredQuestions.concat([
          'contactCity',
          'contactPostalCode',
          'contactStreetAddress',
        ]);
      }
    }

    return Object.entries(formik.values).some(
      ([key, val]) => !notRequiredQuestions.includes(key) && !val,
    );
  };

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={mode === 'edit'}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, setTouched }) => {
        setSubmitting(false);
        onSubmit(values);
        setTouched({});
      }}
    >
      {formik => (
        <Form onSubmit={formik.handleSubmit}>
          <RecipientFormFields {...props} isFolderCreation isReadOnly={isFormReadOnly} />
          <Stack spacing={16} mt={16}>
            <FormSection title="Logement à adapter">
              <RadioGroup
                isInline
                name="bloc_0DoesRecipientOwnTheHousing"
                legend="Le logement est-il occupé par le bénéficiaire ?"
                readOnly={isFormReadOnly}
                requiredInput
              >
                <RadioInput value="true">Oui</RadioInput>
                <RadioInput value="false">Non</RadioInput>
              </RadioGroup>

              {formik.values.bloc_0DoesRecipientOwnTheHousing !== undefined && (
                <AutoChangeAddress />
              )}

              {formik.values.bloc_0DoesRecipientOwnTheHousing && (
                <FormAddressGroup
                  addressName="bloc_0HousingStreetAddress"
                  postalCodeName="bloc_0HousingPostalCode"
                  cityName="bloc_0HousingCity"
                  isReadOnly={isFormReadOnly}
                  isRequired
                />
              )}
            </FormSection>
            <FormSection title="Informations complémentaires" spacing={10}>
              <RadioGroup
                name="fioQ4Answer"
                legend="Statut d’occupation du logement"
                readOnly={isFormReadOnly}
              >
                <RadioInput value="A">Locataire</RadioInput>
                <RadioInput value="B">Propriétaire occupant</RadioInput>
                <RadioInput value="C">Occupant à titre gratuit</RadioInput>
              </RadioGroup>
              <RadioGroup
                name="fioQ13Answer"
                legend="GIR (Groupe Iso-Ressource)"
                readOnly={isFormReadOnly}
              >
                <RadioInput value="A">1</RadioInput>
                <RadioInput value="B">2</RadioInput>
                <RadioInput value="C">3</RadioInput>
                <RadioInput value="D">4</RadioInput>
                <RadioInput value="E">5</RadioInput>
                <RadioInput value="F">6</RadioInput>
              </RadioGroup>
              <Box pos="relative">
                <TextInput
                  type="number"
                  label="Revenu fiscal de référence"
                  name="fioQ17Answer"
                  placeholder="XX XXX"
                  width="12ch"
                  readOnly={isFormReadOnly}
                />
                <Text
                  color="semiDarkGray.600"
                  fontWeight={600}
                  pb="0.5ch"
                  position="absolute"
                  left="13ch"
                  bottom="0.25ch"
                >
                  €
                </Text>
              </Box>
              <Box pos="relative">
                <TextInput
                  type="number"
                  label="Revenu brut global"
                  name="fioQ20Answer"
                  placeholder="XX XXX"
                  width="12ch"
                  readOnly={isFormReadOnly}
                />
                <Text
                  color="semiDarkGray.600"
                  fontWeight={600}
                  pb="0.5ch"
                  position="absolute"
                  left="13ch"
                  bottom="0.25ch"
                >
                  €
                </Text>
              </Box>
            </FormSection>
          </Stack>
          <FormActions mt={20}>
            <SecondaryButton type="button" onClick={() => history.goBack()}>
              {isFormReadOnly ? 'Retour' : 'Annuler'}
            </SecondaryButton>
            <PrimaryButton type="submit" disabled={props.pending || isFormReadOnly}>
              {submitText || 'Enregistrer'}
            </PrimaryButton>
            <PrimaryButton
              bg="red"
              _hover={{ bg: 'semiDarkGray.600' }}
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-alert
                const confirm = window.confirm(
                  'Confirmez-vous transmettre votre pré-dossier à Bel’Avie ? Le pré-dossier ne pourra plus être modifié ni supprimé.',
                );
                if (confirm) onTransform({ ...formik.values, id: initialValues.id });
              }}
              disabled={props.pending || isFormInvalid(formik) || isFormReadOnly}
            >
              Transmettre
            </PrimaryButton>
          </FormActions>
        </Form>
      )}
    </Formik>
  );
};

const AutoChangeAddress = () => {
  const { values, setValues } = useFormikContext();

  const doesRecipientOwnTheHousing = usePrevious(values.bloc_0DoesRecipientOwnTheHousing);

  useEffect(() => {
    if (
      values.bloc_0DoesRecipientOwnTheHousing === 'false' &&
      values.bloc_0DoesRecipientOwnTheHousing !== doesRecipientOwnTheHousing &&
      doesRecipientOwnTheHousing !== undefined
    ) {
      setValues({
        ...values,
        bloc_0HousingStreetAddress: '',
        bloc_0HousingPostalCode: '',
        bloc_0HousingCity: '',
      });
    }
    if (values.bloc_0DoesRecipientOwnTheHousing === 'true') {
      setValues({
        ...values,
        bloc_0HousingStreetAddress: values.streetAddress,
        bloc_0HousingPostalCode: values.postalCode,
        bloc_0HousingCity: values.city,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.bloc_0DoesRecipientOwnTheHousing, setValues]);
  return null;
};
