import React from 'react';
import { Redirect } from 'react-router';
import DashboardIndex from './DashboardIndex';
import Can from '../roleBasedAccess/Can';
import { Is } from '../roleBasedAccess/Is';
import routes from '../common/routes';

export const Dashboard = () => (
  <Can
    perform="dashboard:visit"
    yes={() => <DashboardIndex />}
    no={() => (
      <Is
        userRole="coordinateur entreprises"
        yes={() => <Redirect to={routes.folders.index} />}
        no={() => <Redirect to={routes.tasks.index} />}
      />
    )}
  />
);
