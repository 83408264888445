import React from 'react';
import { Flex } from '@chakra-ui/core';
import { useHistory } from 'react-router';
import { TabList, Tab, TabPanels, TabPanel, BaseTabs } from '../common/components/Tabs';
import { PageTitle } from '../common/components/Titles';
import { ProjectsTab } from './ProjectsTab';
import { ErgoProjectsTab } from './ErgoProjectsTab';

import routes from '../common/routes';
import Can from '../roleBasedAccess/Can';

export const ProjectsTabs = ({
  match: {
    params: { tabIndex },
  },
  ...props
}) => {
  const history = useHistory();

  return (
    <Flex w="100%" px={10} pt={5} pb={6} flexGrow={1} direction="column">
      <Flex>
        <PageTitle mb={6}>Project Place</PageTitle>
      </Flex>
      <BaseTabs
        index={Number(tabIndex - 1)}
        onChange={index => {
          history.push(`${routes.projectPlace}/${index + 1}`);
        }}
      >
        <Can
          perform="project-place-work:read"
          yes={() => (
            <Can
              perform="project-place-ergo:read"
              yes={() => (
                <>
                  <TabList>
                    <Tab>Travaux</Tab>
                    <Tab>Ergothérapeute</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <ProjectsTab {...props} />
                    </TabPanel>
                    <TabPanel>
                      <ErgoProjectsTab {...props} />
                    </TabPanel>
                  </TabPanels>
                </>
              )}
              no={() => (
                <Can
                  perform="project-place-work:read"
                  yes={() => (
                    <>
                      <TabList>
                        <Tab>Travaux</Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <ProjectsTab {...props} />
                        </TabPanel>
                      </TabPanels>
                    </>
                  )}
                />
              )}
            />
          )}
          no={() => (
            <Can
              perform="project-place-ergo:read"
              yes={() => (
                <>
                  <TabList>
                    <Tab>Ergothérapeute</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <ErgoProjectsTab {...props} />
                    </TabPanel>
                  </TabPanels>
                </>
              )}
            />
          )}
        />
      </BaseTabs>
    </Flex>
  );
};

export default { ProjectsTabs };
