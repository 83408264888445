import { tasksConstants } from './constants';

export const initialState = {
  data: [],
  pending: false,
};

export function tasks(state = initialState, action) {
  switch (action.type) {
    case tasksConstants.GET_TASKS_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case tasksConstants.GET_TASKS_SUCCESS:
      return {
        ...state,
        data: action.tasks,
        pending: false,
      };
    case tasksConstants.GET_TASKS_FAILURE:
    default:
      return state;
  }
}
