import React from 'react';
import { Stack } from '@chakra-ui/core';

import { TabForm } from '../../../../common/components/Tabs';
import { Notice, NoticeText } from '../../../../common/components/Notice';
import {
  FormSection,
  FormList,
  FormListItem,
  FormSubmitButton,
} from '../../../../common/components/Form/Form';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import { SwitchInput } from '../../../../common/components/Form/FormInputs';

export const BlocCTab1 = ({ formik }) => {
  return (
    <TabForm onSubmit={formik.handleSubmit}>
      <Stack spacing="3.5rem">
        <FormSection title="Liste des prérequis">
          <FormList mb="3.687rem">
            {formik.values.requiredLaunchDocuments &&
              Object.entries(formik.values.requiredLaunchDocuments).map(([key, document]) => (
                <FormListItem key={document.name}>
                  <SwitchInput
                    label={document.name}
                    name={`requiredLaunchDocuments.${key}.isReceived`}
                    id={`requiredLaunchDocuments.${key}.isReceived`}
                  />
                </FormListItem>
              ))}
          </FormList>
          <DateInput
            label="Date de réception des documents"
            name="launchDocumentsReceivedAt"
            id="launchDocumentsReceivedAt"
            disabled={
              formik.values.requiredLaunchDocuments &&
              Object.values(formik.values.requiredLaunchDocuments).some(doc => !doc.isReceived)
            }
          />
          <Notice variant="warning">
            <NoticeText variant="warning">
              Déclarer si besoin le contrat d'AMO avant de le valider
            </NoticeText>
          </Notice>
        </FormSection>
      </Stack>

      <FormSubmitButton
        type="submit"
        disabled={formik.isSubmitting}
        isLoading={formik.isSubmitting}
        loadingText="Enregistrer"
      >
        Enregistrer
      </FormSubmitButton>
    </TabForm>
  );
};
