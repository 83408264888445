import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from 'formik';
import { Box } from '@chakra-ui/core';

import { PageIndex } from '../common/components/layouts/Page/PageIndex';
import { SearchInput } from '../common/components/Form/FormInputs';
import { SecondaryButton, PrimaryButton } from '../common/components/Buttons';
import {
  Table,
  Thead,
  TH,
  TD,
  THeadRow,
  TBodyRow,
  TablePlaceholder,
  TableEditLink,
  TableResults,
} from '../common/components/Table';
import { companiesActions } from '../redux/companies/actions';
import { Pagination } from '../common/components/Pagination';

import Can from '../roleBasedAccess/Can';

const ConnectedCompaniesIndex = ({ match, getCompanies, pending, companies, meta }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getCompanies(currentPage, search);
  }, [getCompanies, currentPage, search]);

  return (
    <PageIndex pageAction={<Header match={match} meta={meta} onSearch={name => setSearch(name)} />}>
      <Table>
        <Thead>
          <THeadRow>
            <TH>Nom de l'entreprise</TH>
            <TH>Certification Action Logement</TH>
            <TH>Mail</TH>
            <TH>Téléphone</TH>
          </THeadRow>
        </Thead>
        <tbody>
          {!companies || pending ? (
            <TablePlaceholder />
          ) : (
            companies.map(({ id, name, certification, email, phoneNumber }) => (
              <TBodyRow key={id}>
                <TD>
                  <TableEditLink to={`${match.url}/${id}/edit`}>{name}</TableEditLink>
                </TD>
                <TD>
                  <TableEditLink to={`${match.url}/${id}/edit`}>
                    {certification && true ? 'Oui' : 'Non'}
                  </TableEditLink>
                </TD>
                <TD>
                  <TableEditLink to={`${match.url}/${id}/edit`}>{email}</TableEditLink>
                </TD>
                <TD isLast>
                  <TableEditLink to={`${match.url}/${id}/edit`}>{phoneNumber}</TableEditLink>
                </TD>
              </TBodyRow>
            ))
          )}
        </tbody>
      </Table>
      {meta && (
        <Pagination
          current={currentPage}
          total={meta.total}
          defaultPageSize={meta.perPage}
          onChange={page => setCurrentPage(page)}
        />
      )}
    </PageIndex>
  );
};

const mapStateToProps = ({ companies: state }) => ({
  pending: state.pending,
  companies: state.data,
  meta: state.meta,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCompanies: companiesActions.getCompanies,
    },
    dispatch,
  );

export const CompaniesIndex = connect(mapStateToProps, mapDispatchToProps)(ConnectedCompaniesIndex);

const SearchBar = ({ onSearch }) => {
  return (
    <Formik initialValues={{ search: '' }} onSubmit={values => onSearch(values.search)}>
      {formik => (
        <Box as="form" onSubmit={formik.handleSubmit} d="flex" alignItems="start" flexWrap="wrap">
          <SearchInput name="search" />
          <SecondaryButton type="submit" size="sm" ml={3}>
            Lancer la recherche
          </SecondaryButton>
        </Box>
      )}
    </Formik>
  );
};

const Header = ({ onSearch, match, meta }) => {
  return (
    <>
      <SearchBar onSearch={onSearch} />
      <Can
        perform="companies:edit"
        yes={() => (
          <PrimaryButton as={Link} to={`${match.url}/new`} rightIcon="plus">
            Créer une entreprise
          </PrimaryButton>
        )}
      />
      {meta && <TableResults ressource="bénéficiaire" total={meta.total} />}
    </>
  );
};
