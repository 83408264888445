import { operatorsConstants } from './constants';

export const initialState = { data: [] };

export function operators(state = initialState, action) {
  switch (action.type) {
    case operatorsConstants.GET_OPERATORS_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case operatorsConstants.GET_OPERATORS_SUCCESS:
      return {
        data: action.data.operators,
        meta: action.data.meta,
      };
    case operatorsConstants.GET_OPERATOR_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case operatorsConstants.GET_OPERATOR_SUCCESS:
      return {
        ...initialState,
        pending: false,
        operator: action.operator,
      };
    case operatorsConstants.GET_OPERATOR_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case operatorsConstants.CREATE_OPERATOR_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case operatorsConstants.CREATE_OPERATOR_SUCCESS:
      return initialState;
    case operatorsConstants.CREATE_OPERATOR_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case operatorsConstants.UPDATE_OPERATOR_REQUEST:
      return {
        ...initialState,
        operator: action.operator,
        pending: true,
      };
    case operatorsConstants.UPDATE_OPERATOR_SUCCESS:
      return {
        ...initialState,
        pending: false,
      };
    case operatorsConstants.UPDATE_OPERATOR_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case operatorsConstants.DELETE_OPERATOR_REQUEST:
      return {
        ...state,
        deletePending: true,
      };
    case operatorsConstants.DELETE_OPERATOR_SUCCESS:
      return {
        ...initialState,
        deletePending: false,
      };
    case operatorsConstants.DELETE_OPERATOR_FAILURE:
      return {
        ...state,
        error: action.error,
        deletePending: false,
      };
    case operatorsConstants.GET_OPERATORS_FAILURE:
    default:
      return state;
  }
}
