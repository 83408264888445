import React from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Icon, useTheme, Button } from '@chakra-ui/core';
import RCPagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/fr_FR';

export const Pagination = props => {
  const theme = useTheme();

  const paginationStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    listStyle: 'none',
    marginTop: 'auto',
    padding: 0,
    color: theme.colors.semiDarkGray[900],
    '.rc-pagination': {
      '&-prev, &-next': {
        cursor: 'pointer',
        margin: '0 0.5rem',
        svg: {
          strokeWidth: '3px',
          color: theme.colors.coral[900],
        },
      },
      '&-disabled': {
        cursor: 'default',
        svg: {
          opacity: '0.3',
        },
      },
      '&-item, &-jump-next, &-jump-prev': {
        cursor: 'pointer',
        '&:focus': {
          outline: 'none',
          a: {
            textDecoration: 'underline',
          },
        },
        '&:hover': {
          color: theme.colors.coral[900],
        },
        span: {
          display: 'inline-block',
          padding: '3px 6px',
          color: theme.colors.semiDarkGray[900],
          fontWeight: 'normal',
        },
      },
      '&-item': {
        '&-active': {
          fontWeight: 'bold',
          fontSize: theme.sizes[5],
          color: theme.colors.red,
        },
      },
    },
  };

  const navigationItemRender = (current, type) => {
    if (type === 'prev') {
      return <Icon name="chevron-down" size={3} css={{ transform: 'rotate(90deg)' }} />;
    }
    if (type === 'next') {
      return <Icon name="chevron-down" size={3} css={{ transform: 'rotate(-90deg)' }} />;
    }
    if (type === 'jump-next' || type === 'jump-prev') {
      return (
        <React.Fragment>
          <span>.</span>...
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {current > 1 && <span>.</span>}
        <Button
          variantColor={null}
          transition="color .2s ease"
          color="inherit"
          _focus={{ outline: 'none' }}
          p={1}
          size={2}
        >
          {current}
        </Button>
      </React.Fragment>
    );
  };

  return (
    <RCPagination
      {...props}
      locale={localeInfo}
      itemRender={navigationItemRender}
      css={paginationStyle}
    />
  );
};
