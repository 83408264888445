import { parse, format } from 'date-fns';

/* eslint-disable no-bitwise */
export const getDateString = date => (date ? format(date, 'dd/MM/yyyy') : '');

export const getDateFromString = dateString => {
  return dateString ? parse(dateString, 'dd/MM/yyyy', new Date()) : '';
};

export function sleeper(ms) {
  // eslint-disable-next-line func-names
  return function(x) {
    return new Promise(resolve => setTimeout(() => resolve(x), ms));
  };
}

export function debounce(func, wait, immediate) {
  let timeout;

  return (...args) => {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function getBooleanFromString(value) {
  if (value !== 'true' && value !== 'false') return value;
  return value === 'true' || false;
}

export function getStringFromBoolean(value) {
  if (value !== true && value !== false) return value;
  return value ? 'true' : 'false';
}

export const getSelectValueFromString = value => (value ? { label: value, value } : null);

export const getStringFromSelectValue = selectValue => (selectValue ? selectValue.value : null);

export const lightenDarkenColor = (col, amt) => {
  let usePound = false;

  if (col[0] === '#') {
    // eslint-disable-next-line no-param-reassign
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export const traverseObject = (obj, keys) => {
  return keys.split('.').reduce((cur, key) => {
    return cur[key];
  }, obj);
};

export const getKeyByValue = (obj, value) => Object.keys(obj).find(key => obj[key] === value);

export const getQueryParams = url => {
  const queryString = url.replace(/.*\?/, '');

  if (queryString === url || !queryString) {
    return null;
  }

  const urlParams = new URLSearchParams(queryString);
  const result = {};

  urlParams.forEach((val, key) => {
    if (Object.prototype.hasOwnProperty.call(result, key)) {
      result[key] = [result[key], val];
    } else {
      result[key] = val;
    }
  });

  return result;
};

/* eslint-disable no-param-reassign */
export const encodeQueryArray = (arrayName, array) => {
  return array.reduce((res, i) => {
    return (res += `&${arrayName}[]=${i}`);
  }, '');
};
/* eslint-enable no-param-reassign */

export const downloadInBrowser = (fileUrl, fileName) => {
  const a = document.createElement('a');
  a.href = fileUrl;
  a.target = '_blank';
  a.download = fileName;
  a.click();
  a.remove();
};
