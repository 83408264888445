import { projectsConstants } from './constants';
import { projectsAPI } from '../../common/api/projects';
import { errorsActions } from '../errors/actions';

function getProjects(page, search) {
  function request() {
    return { type: projectsConstants.GET_PROJECTS_REQUEST };
  }
  function success(data) {
    return { type: projectsConstants.GET_PROJECTS_SUCCESS, data };
  }
  function failure(error) {
    return { type: projectsConstants.GET_PROJECTS_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return projectsAPI.getProjects(page, search).then(({ projects, meta }) => {
      dispatch(success({ projects, meta }));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

export const projectsActions = {
  getProjects,
};
