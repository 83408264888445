import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFolderPath } from '../../../../common/routes';
import { BlocGTabs } from './BlocGTabs';
import { blocGActions, blocGSelectors } from '../../../../redux/blocs/blocG/actions';
import { PageError404 } from '../../../../errors/components';

const BlocG = ({
  match: {
    params: { folderId },
  },
  getBlocG,
  updateBlocG,
  ...props
}) => {
  useEffect(() => {
    getBlocG(folderId);
  }, [getBlocG, folderId]);

  const onSubmit = values => updateBlocG(folderId, values);

  return (
    <Switch>
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', '')}`}
        exact
        render={() => <Redirect to={getFolderPath(props.blocId, folderId)} />}
      />
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', ':tabIndex')}`}
        render={routerProps => <BlocGTabs {...props} {...routerProps} onSubmit={onSubmit} />}
      />
      <Route component={PageError404} />
    </Switch>
  );
};

const mapStateToProps = (state, props) => {
  const blocG = blocGSelectors.getBlocGById(state, props) || {};

  return {
    blocG,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBlocG: blocGActions.getBlocG,
      updateBlocG: blocGActions.updateBlocG,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BlocG);
