export const recipientsConstants = {
  GET_RECIPIENTS_REQUEST: 'GET_RECIPIENTS_REQUEST',
  GET_RECIPIENTS_SUCCESS: 'GET_RECIPIENTS_SUCCESS',
  GET_RECIPIENTS_FAILURE: 'GET_RECIPIENTS_FAILURE',

  GET_RECIPIENT_REQUEST: 'GET_RECIPIENT_REQUEST',
  GET_RECIPIENT_SUCCESS: 'GET_RECIPIENT_SUCCESS',
  GET_RECIPIENT_FAILURE: 'GET_RECIPIENT_FAILURE',

  CREATE_RECIPIENT_REQUEST: 'CREATE_RECIPIENT_REQUEST',
  CREATE_RECIPIENT_SUCCESS: 'CREATE_RECIPIENT_SUCCESS',
  CREATE_RECIPIENT_FAILURE: 'CREATE_RECIPIENT_FAILURE',

  UPDATE_RECIPIENT_REQUEST: 'UPDATE_RECIPIENT_REQUEST',
  UPDATE_RECIPIENT_SUCCESS: 'UPDATE_RECIPIENT_SUCCESS',
  UPDATE_RECIPIENT_FAILURE: 'UPDATE_RECIPIENT_FAILURE',

  DELETE_RECIPIENT_REQUEST: 'DELETE_RECIPIENT_REQUEST',
  DELETE_RECIPIENT_SUCCESS: 'DELETE_RECIPIENT_SUCCESS',
  DELETE_RECIPIENT_FAILURE: 'DELETE_RECIPIENT_FAILURE',
};
