import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { ErgoResourcesIndex } from './ErgoResourcesIndex';
import ErgoResourcesCreate from './ErgoResourceCreate';
import ErgoResourcesEdit from './ErgoResourceEdit';
import { PageError404 } from '../errors/components';
import routes from '../common/routes';
import Can from '../roleBasedAccess/Can';

export const ErgoResources = ({ match }) => {
  return (
    <Can
      perform="ergo-resources:read"
      yes={() => (
        <Switch>
          <Route
            path={`${match.url}/new`}
            exact
            render={props => <ErgoResourcesCreate {...props} />}
          />
          <Route
            path={`${match.url}/:ergoResourceId/edit`}
            exact
            render={props => <ErgoResourcesEdit {...props} />}
          />
          <Route path={match.url} exact component={ErgoResourcesIndex} />
          <Route component={PageError404} />
        </Switch>
      )}
      no={() => <Redirect to={routes.home} />}
    />
  );
};
