import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/core';

import { FastField, Formik } from 'formik';
import { PageIndex } from '../common/components/layouts/Page/PageIndex';
import { PrimaryButton, SecondaryButton } from '../common/components/Buttons';
import {
  Table,
  Thead,
  TH,
  TD,
  THeadRow,
  TBodyRow,
  TablePlaceholder,
} from '../common/components/Table';
import { projectsActions } from '../redux/projects/actions';
import { Pagination } from '../common/components/Pagination';
import { SelectInput } from '../common/components/Form/FormSelectInput';
import Can from '../roleBasedAccess/Can';
import { projectsAPI } from '../common/api/projects';
import { toastActions } from '../redux/toast/actions';
import { TOAST_MESSAGE } from '../common/components/Toasts/Toast';

const TYPE_OF_VISIT = {
  joint: (
    <>
      Conjointe&nbsp;
      <br />
      ergo-entreprise
    </>
  ),
  solo: (
    <>
      Ergothérapique&nbsp;
      <br />
      seule
    </>
  ),
  existing_quote: 'Devis existants',
};

const ConnectedProjectsIndex = ({ projects, getProjects, pending, meta }) => {
  const user = useSelector(state => state.authentication.profile);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPosition, setCurrentPosition] = useState({});
  const [success, setSuccess] = useState(false);
  const [onSubmitPending, setOnSubmitPending] = useState(false);

  useEffect(() => {
    getProjects(currentPage);
  }, [getProjects, currentPage]);

  const onModalClose = () => {
    if (success) getProjects(currentPage);
    setSuccess(false);
    setCurrentPosition({});
    onClose();
  };

  const onSubmit = () => {
    setOnSubmitPending(true);
    const { projectId, ...data } = currentPosition;

    const finalData = {
      ...data,
      companyIds: data.companyIds.map(company => company?.value),
    };

    projectsAPI
      .updateProject(projectId, finalData)
      .then(() => setSuccess(true))
      .catch(() => {
        onModalClose();
        dispatch(toastActions.error(TOAST_MESSAGE.error.projectPosition));
      })
      .finally(() => setOnSubmitPending(false));
  };

  return (
    <PageIndex>
      <Table>
        <Thead>
          <THeadRow>
            <TH width="0" minWidth="100px" size="sm">
              N° de dossier
            </TH>
            <TH width="0" minWidth="10vw" wordBreak="break-word">
              Ville
            </TH>
            <TH>Dépt.</TH>
            <TH width="100%">Qualification du marché</TH>
            <TH width="0" minWidth="150px">
              Date de mise
              <br /> à disposition
            </TH>
            <TH width="0" minWidth="170px">
              Modalité de visite
            </TH>
            <Can
              perform="project-place:position"
              yes={() => (
                <TH width="0" minWidth="350px">
                  Actions
                </TH>
              )}
            />
          </THeadRow>
        </Thead>
        <tbody>
          {!projects || pending ? (
            <TablePlaceholder colSpan={6} />
          ) : (
            projects?.map(
              ({
                id,
                formattedId,
                city,
                department,
                projectKind,
                projectPublicationDate,
                typeOfVisit,
                ignoredFromProjectCompanyIds,
              }) => (
                <TBodyRow key={id} enableHover={false}>
                  <TD hasPadding>{formattedId}</TD>
                  <TD hasPadding>{city}</TD>
                  <TD hasPadding>{department}</TD>
                  <TD hasPadding whiteSpace="pre-line" lineHeight="1.5">
                    {projectKind.map((kind, index) => (
                      <Text mt={index === 0 ? 0 : '6px'} lineHeight="1.2">
                        {kind}
                      </Text>
                    ))}
                  </TD>
                  <TD hasPadding>{projectPublicationDate}</TD>
                  <TD hasPadding>{TYPE_OF_VISIT[typeOfVisit] || ''}</TD>
                  <Can
                    perform="project-place:position"
                    yes={() => {
                      const excludedCompanies = ignoredFromProjectCompanyIds?.map(companyId =>
                        Number(companyId),
                      );
                      return (
                        <TD hasPadding>
                          <Formik
                            initialValues={{ projectId: id, companyIds: [null] }}
                            onSubmit={values => {
                              onOpen();
                              setCurrentPosition(values);
                            }}
                          >
                            {formik => (
                              <Flex as="form" alignItems="center" onSubmit={formik.handleSubmit}>
                                <FastField name="projectId" hidden />
                                <SelectInput
                                  name="companyIds[0]"
                                  placeholder="Entreprise"
                                  styles={{ mb: 0, flexBasis: '200px' }}
                                  options={user.companies
                                    ?.filter(company => {
                                      return company.departments
                                        .map(dept => dept.slice(0, 2))
                                        .includes(department);
                                    })
                                    .filter(company => company.hasAccessToProjectPlace)
                                    .filter(company => !excludedCompanies.includes(company.id))
                                    .map(company => ({
                                      label: company.name,
                                      value: company.id,
                                    }))}
                                />
                                <PrimaryButton
                                  type="submit"
                                  size="sm"
                                  ml="2"
                                  disabled={!formik.values.companyIds[0]}
                                >
                                  Positionner
                                </PrimaryButton>
                              </Flex>
                            )}
                          </Formik>
                        </TD>
                      );
                    }}
                  />
                </TBodyRow>
              ),
            )
          )}
        </tbody>
      </Table>
      <ConfirmationModal
        isOpen={isOpen}
        onSubmit={onSubmit}
        pending={onSubmitPending}
        success={success}
        onClose={onModalClose}
      />
      {meta && (
        <Pagination
          current={currentPage}
          total={meta.total}
          defaultPageSize={meta.perPage}
          onChange={page => setCurrentPage(page)}
        />
      )}
    </PageIndex>
  );
};

const mapStateToProps = ({ projects: state }) => ({
  pending: state?.pending,
  projects: state?.data,
  meta: state?.meta,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProjects: projectsActions.getProjects,
    },
    dispatch,
  );

export const ProjectsTab = connect(mapStateToProps, mapDispatchToProps)(ConnectedProjectsIndex);

const ConfirmationModal = ({ isOpen, onSubmit, pending, success, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent rounded="lg">
        <ModalHeader>Confirmation de prise de marché</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {success ? (
            <p>
              Votre positionnement a bien été enregistré. Vous recevrez prochainement les
              informations relatives au projet pour établir votre devis.
            </p>
          ) : (
            <p>Êtes-vous certain de vouloir vous positionner sur ce marché ?</p>
          )}
        </ModalBody>

        <ModalFooter>
          {success ? (
            <PrimaryButton onClick={onClose}>OK</PrimaryButton>
          ) : (
            <>
              <SecondaryButton mr={3} onClick={onClose}>
                Non
              </SecondaryButton>
              <PrimaryButton
                onClick={onSubmit}
                isLoading={pending}
                loadingText="Positionnement en cours"
              >
                Oui
              </PrimaryButton>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
