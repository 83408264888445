import React from 'react';
import { useFormikContext } from 'formik';
import { Box, Stack, usePrevious } from '@chakra-ui/core';

import { TextInput } from './FormInputs';

export const FormAddressGroup = ({
  addressName,
  postalCodeName,
  cityName,
  disabled,
  isRequired,
}) => {
  return (
    <>
      <TextInput
        label="Adresse"
        type="text"
        id={addressName}
        placeholder="3, rue de la paix"
        name={addressName}
        disabled={disabled}
        requiredInput={isRequired}
      />
      <Stack isInline spacing={4}>
        <Box>
          <TextInput
            label="Code postal"
            type="text"
            id={postalCodeName}
            name={postalCodeName}
            w="11ch"
            placeholder="94 000"
            disabled={disabled}
            requiredInput={isRequired}
          />
        </Box>
        <Box flexGrow={1}>
          <TextInput
            label="Ville"
            type="text"
            id={cityName}
            name={cityName}
            placeholder="Créteil"
            disabled={disabled}
            requiredInput={isRequired}
          />
        </Box>
      </Stack>
    </>
  );
};

export const AutoChangeAddress = () => {
  const { values, setValues } = useFormikContext();

  const doesRecipientOwnTheHousing = usePrevious(values.doesRecipientOwnTheHousing);

  React.useEffect(() => {
    if (
      values.doesRecipientOwnTheHousing === 'false' &&
      values.doesRecipientOwnTheHousing !== doesRecipientOwnTheHousing &&
      doesRecipientOwnTheHousing !== undefined
    ) {
      setValues({ ...values, housingStreetAddress: '', housingPostalCode: '', housingCity: '' });
    }
    if (values.doesRecipientOwnTheHousing === 'true' && doesRecipientOwnTheHousing === 'false') {
      setValues({
        ...values,
        housingStreetAddress: values.folder.recipient.streetAddress,
        housingPostalCode: values.folder.recipient.postalCode,
        housingCity: values.folder.recipient.city,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.doesRecipientOwnTheHousing, setValues]);
  return null;
};
