import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getFolderPath } from '../../../../common/routes';
import { BlocCTabs } from './BlocCTabs';
import { blocCActions } from '../../../../redux/blocs/blocC/actions';
import { PageError404 } from '../../../../errors/components';

const BlocC = ({
  match: {
    params: { folderId },
  },
  updateBlocC,
  getBlocC,
  ...props
}) => {
  useEffect(() => {
    getBlocC(folderId);
  }, [folderId, getBlocC]);

  const onSubmit = values => {
    return updateBlocC(folderId, values);
  };

  return (
    <Switch>
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', '')}`}
        exact
        render={() => <Redirect to={getFolderPath(props.blocId, folderId)} />}
      />
      <Route
        path={`${getFolderPath(props.blocId, ':folderId', ':tabIndex')}`}
        exact
        render={routerProps => <BlocCTabs {...props} {...routerProps} onSubmit={onSubmit} />}
      />
      <Route component={PageError404} />
    </Switch>
  );
};

const mapStateToProps = ({ blocs: { blocC } }) => ({ blocC });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getBlocC: blocCActions.getBlocC, updateBlocC: blocCActions.updateBlocC },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(BlocC);
