import { blocDConstants } from './constants';
import { blocAPI } from '../../../common/api/blocs';
import { getDateString, getDateFromString } from '../../../common/utils';
import { toastActions } from '../../toast/actions';
import { TOAST_MESSAGE } from '../../../common/components/Toasts/Toast';
import { foldersActions } from '../../folders/actions';
import { errorsActions } from '../../errors/actions';

export const transformApiPayloadToFormValues = ({
  recipient_2dpeValidationReceivedAt: recipient2dpeValidationReceivedAt,
  ...blocD
}) => ({
  ...blocD,
  recipient_2dpeValidationReceivedAt: getDateFromString(recipient2dpeValidationReceivedAt),
  isBiddingCompetitive:
    blocD.recommandationKind === 'only_works' ? 'no_only_works' : blocD.isBiddingCompetitive,
});

function getBlocD(folderId) {
  function request() {
    return { type: blocDConstants.GET_BLOCD_REQUEST };
  }
  function success(blocD) {
    return { type: blocDConstants.GET_BLOCD_SUCCESS, blocD };
  }
  function failure(error) {
    return { type: blocDConstants.GET_BLOCD_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return blocAPI
      .getBloc(folderId, 'bloc_d_quote_request_preparation')
      .then(({ blocDQuoteRequestPreparation: blocD }) => {
        dispatch(success(transformApiPayloadToFormValues(blocD)));
        dispatch(foldersActions.getFolderFromBloc(blocD.folder || {}));
      }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function updateBlocD(folderId, updatedBlocD) {
  function request(blocD) {
    return { type: blocDConstants.UPDATE_BLOCD_REQUEST, blocD };
  }
  function success(blocD) {
    return { type: blocDConstants.UPDATE_BLOCD_SUCCESS, blocD };
  }
  function failure(error) {
    return { type: blocDConstants.UPDATE_BLOCD_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedBlocD));

    return blocAPI
      .updateBloc(folderId, 'bloc_d_quote_request_preparation', {
        ...updatedBlocD,
        recipient_2dpeValidationReceivedAt: getDateString(
          updatedBlocD.recipient_2dpeValidationReceivedAt,
        ),
        companyIds: updatedBlocD.companies.map(company => company.id),
      })
      .then(({ blocDQuoteRequestPreparation: blocD }) => {
        dispatch(foldersActions.getFolderFromBloc(blocD.folder || {}));
        dispatch(success(transformApiPayloadToFormValues(blocD)));
        dispatch(toastActions.success(TOAST_MESSAGE.success.update));
      }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

// Selectors

const getBlocDById = (state, props) => state.blocs.blocD[props.match.params.folderId];

export const blocDSelectors = {
  getBlocDById,
};

export const blocDActions = {
  getBlocD,
  updateBlocD,
};
