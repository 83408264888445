import React, { useState, useEffect } from 'react';
import { Box, Icon, Button, VisuallyHidden } from '@chakra-ui/core';
import { useFormSubmitDisabled } from './FormSubmitDisabled.context';

export const LockedInput = ({
  children,
  defaultIsLocked,
  mb,
  maxW,
  withLabel = true,
  ...props
}) => {
  const [isLocked, setIsLocked] = useState(defaultIsLocked);
  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  useEffect(() => {
    setIsLocked(defaultIsLocked);
  }, [defaultIsLocked]);

  const childrenLocked = React.Children.map(children, child =>
    React.cloneElement(child, {
      disabled: child.props.disabled || isLocked,
      isDisabled: child.props.isDisabled || isLocked,
      isLocked,
    }),
  );

  return (
    <Box position="relative" mb={mb} maxW={maxW}>
      {childrenLocked}
      <Button
        type="button"
        data-locked={isLocked}
        data-default-locked={defaultIsLocked}
        variantColor={null}
        bg="transparent"
        pos="absolute"
        bottom={0}
        left="100%"
        height="34px"
        width="34px"
        minW="0"
        onClick={() => setIsLocked(!isLocked)}
        color="coral.900"
        _focus={{}}
        _hover={{ color: 'red' }}
        disabled={isFormSubmitDisabled}
        {...props}
      >
        <Icon name={isLocked ? 'lock-closed' : 'lock-opened'} />
        <VisuallyHidden>Déverrouiller</VisuallyHidden>
      </Button>
    </Box>
  );
};
