import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import { OperatorsIndex } from './OperatorsIndex';
import OperatorCreate from './OperatorCreate';
import OperatorEdit from './OperatorEdit';
import routes from '../common/routes';
import Can from '../roleBasedAccess/Can';
import { PageError404 } from '../errors/components';

export const Operators = ({ match }) => {
  return (
    <Can
      perform="dashboard:visit"
      yes={() => (
        <Switch>
          <Route path={`${match.url}/new`} exact component={OperatorCreate} />
          <Route path={`${match.url}/:operatorId/edit`} exact component={OperatorEdit} />
          <Route path={match.url} exact component={OperatorsIndex} />
          <Route component={PageError404} />
        </Switch>
      )}
      no={() => <Redirect to={routes.tasks} />}
    />
  );
};
