import React, { useEffect } from 'react';
import { Box, Flex, Text, Spinner, Grid } from '@chakra-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  HorizontalSection,
  DoubleColorHeading,
  VerticalTableWrap,
  VerticalTDNumber,
  VerticalTDNumberBorderRight,
  VerticalTBodyTH,
  VerticalTBodyTHShadow,
  VerticalTDNumberSmallBorder,
  VerticalTDLargeNumber,
  VerticalTBody,
  VerticalTBodyRowGrid,
  VerticalTBodyRowFlex,
} from './components';
import { Table } from '../common/components/Table';
import { TabTBodyRow } from '../common/components/Tabs';
import { dashboardActions } from '../redux/dashboard/actions';

const DashboardIndex = ({ dashboard, getDashboard }) => {
  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  if (!dashboard) {
    return (
      <Box height="100%" d="flex" justifyContent="center" alignItems="center">
        <Spinner label="Chargement..." />
      </Box>
    );
  }

  return (
    <Box as="section" m="20px 35px">
      <Grid templateColumns="minmax(min-content, 1fr) minmax(min-content, 0.55fr)" gap="2%">
        <Box as="section" minWidth="0">
          <Flex justify="space-between" mb="1rem">
            <VerticalTableWrap flexBasis="40%" p="0">
              <Table height="100%">
                <VerticalTBody shadowStyles={{ width: '55%' }} height="100%">
                  <VerticalTBodyRowFlex flexGrow="1">
                    <VerticalTBodyTHShadow textAlign="right" flex="0 0 55%" pl={6} pr={6} py={6}>
                      Nombre total de dossiers
                    </VerticalTBodyTHShadow>
                    <VerticalTDNumber
                      color="coral.900"
                      textAlign="center"
                      p={2}
                      fontSize="2.5rem"
                      flexGrow="1"
                    >
                      {dashboard.folderCount}
                    </VerticalTDNumber>
                  </VerticalTBodyRowFlex>
                </VerticalTBody>
              </Table>
            </VerticalTableWrap>

            <VerticalTableWrap flexBasis="56%" p="0">
              <Table height="100%">
                <VerticalTBody shadowStyles={{ width: '33%' }} height="100%">
                  <VerticalTBodyRowFlex flexGrow="1">
                    <VerticalTBodyTHShadow
                      flex="0 0 33%"
                      justifyContent="flex-end"
                      pl={6}
                      pr={6}
                      py={6}
                    >
                      Dossiers <br />
                      actifs
                    </VerticalTBodyTHShadow>
                    <VerticalTDNumberBorderRight
                      number={dashboard.folderCCount}
                      text="Type C"
                      flexGrow="1"
                    />
                    <VerticalTDNumberBorderRight
                      number={dashboard.folderAdCount}
                      text="Type AD"
                      flexGrow="1"
                    />
                    <VerticalTDNumberBorderRight
                      number={dashboard.folderECount}
                      text="Type E"
                      flexGrow="1"
                    />
                  </VerticalTBodyRowFlex>
                </VerticalTBody>
              </Table>
            </VerticalTableWrap>
          </Flex>

          <HorizontalSection title="Délais" subtitle="(Jours)">
            <Grid
              gridTemplateColumns="1fr 1fr"
              gridAutoRows="auto"
              gridGap="1.5rem"
              p="0.5rem 1.25rem 1.25rem"
            >
              {dashboard.phases ? (
                <VerticalTableWrap gridColumn="1/-1" p="0" flexGrow="1" mt={2}>
                  <Table d="grid">
                    <Box as="tbody" display="flex" flexDirection="column" justifyContent="center">
                      <VerticalTBodyRowGrid gridTemplateColumns="2fr repeat(4, 0.8fr)">
                        <VerticalTBodyTH textTransform="uppercase" fontWeight="bold">
                          Projets totaux
                        </VerticalTBodyTH>
                        <VerticalTDNumberBorderRight
                          number={dashboard.averageTotalDelay}
                          text="Total"
                          flexGrow="1"
                        />
                        <VerticalTDNumberBorderRight
                          number={dashboard.averageTotalDelayAmoC}
                          text="Type C"
                          flexGrow="1"
                        />
                        <VerticalTDNumberBorderRight
                          number={dashboard.averageTotalDelayAmoAd}
                          text="Type AD"
                          flexGrow="1"
                        />
                        <VerticalTDNumberBorderRight
                          number={dashboard.averageTotalDelayAmoE}
                          text="Type E"
                          flexGrow="1"
                        />
                      </VerticalTBodyRowGrid>
                    </Box>
                  </Table>
                </VerticalTableWrap>
              ) : null}
              {dashboard.phases?.map(phase => (
                <Box key={phase.name} as="section" display="flex" flexDirection="column">
                  <DoubleColorHeading
                    title={phase.name.match(/Phase [0-9]+ : /g)[0]}
                    coralTitle={phase.name.replace(/Phase [0-9] : /g, '')}
                  />
                  <VerticalTableWrap
                    d="grid"
                    flexGrow="1"
                    minH={phase.name.match(/1/) ? '206px' : null}
                  >
                    <Table d="grid">
                      <Box as="tbody" display="flex" flexDirection="column" justifyContent="center">
                        {phase.data.map(({ label, days }) => (
                          <VerticalTBodyRowGrid key={label}>
                            <VerticalTBodyTH>{label}</VerticalTBodyTH>
                            <VerticalTDNumberSmallBorder>
                              {days === null ? '?' : days?.toLocaleString()}
                            </VerticalTDNumberSmallBorder>
                          </VerticalTBodyRowGrid>
                        ))}
                      </Box>
                    </Table>
                  </VerticalTableWrap>
                </Box>
              ))}
            </Grid>
          </HorizontalSection>
        </Box>

        <Box as="section" minWidth="0">
          <VerticalTableWrap flexBasis="29%" p="0" mb="1rem">
            <Table>
              <VerticalTBody>
                <TabTBodyRow d="flex" alignItems="center" zIndex="1">
                  <VerticalTBodyTHShadow flex="0 0 50%" justifyContent="center">
                    Âge moyen
                  </VerticalTBodyTHShadow>
                  <VerticalTDNumber color="coral.900" fontSize="2.5rem" flexGrow={1}>
                    {dashboard.averageAge?.toLocaleString()}{' '}
                    <Text as="span" fontWeight="light" fontSize="1rem">
                      ans
                    </Text>
                  </VerticalTDNumber>
                </TabTBodyRow>
              </VerticalTBody>
            </Table>
          </VerticalTableWrap>

          <HorizontalSection
            mb="1rem"
            justifyText="center"
            title="Part des personnes âgées"
            headerPadding="18px 15px 15px"
          >
            <table>
              <tbody>
                <TabTBodyRow d="flex" py={3}>
                  <VerticalTDNumberBorderRight
                    number={dashboard.lessThan_60YearsPart?.toLocaleString()}
                    text="De moins de 60 ans"
                    percentage
                  />
                  <VerticalTDNumberBorderRight
                    number={dashboard.between_60And_75YearsPart?.toLocaleString()}
                    text="Entre 60 et 75 ans"
                    percentage
                  />
                  <VerticalTDNumberBorderRight
                    number={dashboard.between_75And_85YearsPart?.toLocaleString()}
                    text="Entre 76 et 85 ans"
                    percentage
                  />
                  <VerticalTDNumberBorderRight
                    number={dashboard.moreThan_85YearsPart?.toLocaleString()}
                    text="De plus de 85 ans"
                    percentage
                  />
                </TabTBodyRow>
              </tbody>
            </table>
          </HorizontalSection>

          <VerticalTableWrap p="0">
            <Table>
              <VerticalTBody shadowStyles={{ width: '55%' }}>
                <VerticalTBodyRowFlex>
                  <VerticalTBodyTH textAlign="right" flex="0 0 55%" px={5}>
                    Montant moyen total des projets
                  </VerticalTBodyTH>
                  <VerticalTDLargeNumber currency>
                    {dashboard.averageProjectPrice?.toLocaleString() || 0}
                  </VerticalTDLargeNumber>
                </VerticalTBodyRowFlex>
                <VerticalTBodyRowFlex>
                  <VerticalTBodyTH textAlign="right" flex="0 0 55%" px={5}>
                    Montant cumulé des montants octroyables
                  </VerticalTBodyTH>
                  <VerticalTDLargeNumber currency>
                    {dashboard.fullOctroyableMontant?.toLocaleString() || 0}
                  </VerticalTDLargeNumber>
                </VerticalTBodyRowFlex>
                <VerticalTBodyRowFlex>
                  <VerticalTBodyTH textAlign="right" flex="0 0 55%" px={5}>
                    Montant moyen des montants octroyables
                  </VerticalTBodyTH>
                  <VerticalTDLargeNumber currency>
                    {dashboard.averageOctroyableMontant?.toLocaleString() || 0}
                  </VerticalTDLargeNumber>
                </VerticalTBodyRowFlex>
                <VerticalTBodyRowFlex>
                  <VerticalTBodyTH textAlign="right" flex="0 0 55%" px={5}>
                    Montant moyen des restes à charges tout compris
                  </VerticalTBodyTH>
                  <VerticalTDLargeNumber currency>
                    {dashboard.averageToPayByRecipientMontant?.toLocaleString() || 0}
                  </VerticalTDLargeNumber>
                </VerticalTBodyRowFlex>
              </VerticalTBody>
            </Table>
          </VerticalTableWrap>
        </Box>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ dashboard: { data: dashboard, pending } }) => ({ dashboard, pending });

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDashboard: dashboardActions.getDashboard,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashboardIndex);
