import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik } from 'formik';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Box, Icon, Stack, Text, Spinner } from '@chakra-ui/core';
import { authActions } from '../redux/auth/actions';
import { TextInput, PasswordInput } from '../common/components/Form/FormInputs';
import { PrimaryButton } from '../common/components/Buttons';
import { PageTitle } from '../common/components/Titles';

import { Logo } from '../common/components/Logo';

const Login = ({ loggingIn, login, sendingSMS, auth, error, reattempting }) => {
  const onSubmit = ({ email, password, otpAttempt: newOtpAttempt }) =>
    login(email, password, newOtpAttempt);

  return (
    <Box
      minH="100vh"
      d="flex"
      justifyContent="start"
      alignItems="center"
      bg="gray"
      position="relative"
      flexDirection="column"
      css={css`
        label + div > svg {
          display: none;
        }
        label + div > div > svg {
          display: none;
        }
      `}
    >
      <Box
        height="39vh"
        minH="350px"
        width="100%"
        shadow="layout"
        position="absolute"
        bg="darkGray"
        zIndex="0"
        top={0}
      />
      <Logo
        width="300px"
        zIndex="1"
        mt="120px"
        mb={20}
        css={css`
          @media (max-height: 800px) {
            margin: 7.5vh 0 6vh;
          }
        `}
      />
      <Box bg="white" p={16} rounded="20px" shadow="layout" w="100%" maxW="480px" zIndex="1">
        <PageTitle as="h1" fontSize="22px" textAlign="center" fontFamily="body" mb={!error && 16}>
          Connexion
        </PageTitle>
        {!sendingSMS && !reattempting ? (
          <Formik initialValues={{ email: '', password: '' }} onSubmit={onSubmit}>
            {formik => (
              <form onSubmit={formik.handleSubmit} autoComplete="off">
                {error && (
                  <Box textAlign="center" maxW="85%" mb={10} mt={6} color="error" mx="auto">
                    <Icon name="error" size={8} mb={3} />
                    <p>
                      Connexion impossible, veuillez vérifier le mot de passe ou l’adresse email
                    </p>
                  </Box>
                )}
                <Stack spacing={5}>
                  <Box>
                    <TextInput
                      label="Mail"
                      type="email"
                      id="email"
                      name="email"
                      autoComplete="off"
                      placeholder="ergotherapeute@mail.com"
                      isInvalid={!!error}
                    />
                  </Box>
                  <PasswordInput
                    label="Mot de passe"
                    id="password"
                    name="password"
                    autoComplete="off"
                    isInvalid={!!error}
                  />
                </Stack>
                <Box textAlign="center" mt={10}>
                  <PrimaryButton type="submit" disabled={loggingIn}>
                    {loggingIn ? 'Connexion...' : 'Se connecter'}
                  </PrimaryButton>
                </Box>
              </form>
            )}
          </Formik>
        ) : (
          <Formik initialValues={{ ...auth }} onSubmit={onSubmit}>
            {formik => (
              <form onSubmit={formik.handleSubmit} autoComplete="off">
                {error && (
                  <Box textAlign="center" maxW="85%" mb={10} mt={6} color="error" mx="auto">
                    <Icon name="error" size={8} mb={3} />
                    <p>Connexion impossible, veuillez vérifier le code saisi.</p>
                  </Box>
                )}
                <Text mt={error ? 4 : -10} mb={10} color="semiDarkGray.600" textAlign="center">
                  Veuillez saisir le code envoyé par SMS au numéro lié à votre compte afin de
                  terminer la connexion.
                </Text>
                <Stack spacing={-4}>
                  <Box>
                    <TextInput
                      label="Code"
                      type="text"
                      id="otpAttempt"
                      name="otpAttempt"
                      autoComplete="off"
                      placeholder="7777"
                      isInvalid={!!error}
                    />
                  </Box>
                  <Text as="small" marginLeft={4} color="semiDarkGray.600">
                    Code non reçu ?{' '}
                    <Text
                      as="span"
                      textDecor="underline"
                      color="coral.900"
                      cursor="pointer"
                      onClick={() => login(auth.username, auth.password, undefined, true)}
                    >
                      {reattempting ? (
                        <Spinner size="xs" label="Renvoi du code par SMS..." />
                      ) : (
                        'Renvoyer le code'
                      )}
                    </Text>
                  </Text>
                </Stack>
                <Box textAlign="center" mt={10}>
                  <PrimaryButton type="submit" disabled={loggingIn}>
                    {loggingIn && !reattempting ? 'Connexion...' : 'Se connecter'}
                  </PrimaryButton>
                </Box>
              </form>
            )}
          </Formik>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ authentication: state }) => ({
  error: state.error,
  loggingIn: state.loggingIn,
  sendingSMS: state.sendingSMS,
  otpAttempt: state.otpAttempt,
  reattempting: state.reattempting,
  auth: state.auth,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      login: authActions.login,
    },
    dispatch,
  );

Login.propTypes = {
  loggingIn: PropTypes.bool,
  sendingSMS: PropTypes.bool,
};

Login.defaultProps = {
  loggingIn: false,
  sendingSMS: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
