import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Box, Text, Icon, Stack, MenuButton, Menu, MenuList, MenuItem } from '@chakra-ui/core';
import { Logo } from '../Logo';
import routes from '../../routes';
import Can from '../../../roleBasedAccess/Can';

const Header = ({ user }) => {
  return (
    <Box
      as="header"
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      bg="darkGray"
      color="white"
      py="4"
      px="5"
      boxShadow="layout"
    >
      {/* Logo */}
      <NavLink to="/">
        <Logo width="176px" />
      </NavLink>

      {/* User menu */}
      <Menu>
        <Stack
          as={MenuButton}
          isInline
          d="flex"
          alignItems="center"
          spacing={4}
          rounded="lg"
          px={3}
          py={2}
          mr={-3}
          transition="background 0.2s"
          css={theme => ({
            '&:focus': { outline: 'none' },
            '&:hover': { backgroundColor: theme.colors.semiDarkGray[900] },
          })}
        >
          <Text fontFamily="heading" fontWeight="600">
            {user.firstName} {user.lastName?.toUpperCase()}
          </Text>
          <Box
            bg="red"
            size="32px"
            p="6px"
            rounded="full"
            d="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Icon name="user" bg="red" />
          </Box>
          <Icon name="chevron-down" strokeWidth="2" />
        </Stack>

        <MenuList
          borderRadius="10px 0px 10px 10px"
          shadow="layout"
          placement="auto-end"
          zIndex="1000"
        >
          <Can
            perform="self:edit"
            yes={() => (
              <UserMenuItem to={routes.profile} transition="none">
                <Icon name="edit" color="coral" mr={2} transition="none" />
                <Text>Editer mon profil</Text>
              </UserMenuItem>
            )}
          />

          <UserMenuItem to={routes.logout} transition="none">
            <Icon name="logout" color="coral" mr={2} transition="none" />
            <Text>Déconnexion</Text>
          </UserMenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

const UserMenuItem = ({ children, ...props }) => (
  <MenuItem
    as={NavLink}
    color="gray"
    _hover={{ backgroundColor: 'coral.900', color: 'white', svg: { color: 'white' } }}
    {...props}
  >
    {children}
  </MenuItem>
);

const mapStateToProps = ({ authentication: { user } }) => ({ user });

export default connect(mapStateToProps)(Header);
