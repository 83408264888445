import React from 'react';
import { Tabs, Tab, TabPanels, TabPanel, TabList } from '../../../../common/components/Tabs';
import { Bloc0Tab1 } from './Bloc0Tab1';
import Bloc0Tab2 from './Bloc0Tab2';

export const Bloc0Tabs = ({
  match: {
    params: { folderId, tabIndex },
  },
  blocId,
  ...props
}) => {
  return (
    <Tabs folderId={folderId} tabIndex={tabIndex} blocId={blocId}>
      <TabList>
        <Tab>Informations préliminaires</Tab>
        <Tab>Contrat AMO</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Bloc0Tab1 {...props} />
        </TabPanel>
        <TabPanel>
          <Bloc0Tab2 {...props} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
