import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Stack } from '@chakra-ui/core';
import { TextInput } from '../Form/FormInputs';
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { Form, FormActions, FormSection } from '../Form/Form';
import { history } from '../../helpers/history';
import { SelectInput } from '../Form/FormSelectInput';
import Can from '../../../roleBasedAccess/Can';

export const ErgoResourceForm = ({ initialValues, onSubmit, pending, error, mode }) => {
  const formikRef = useRef();
  const RESSOURCE_TYPES = ['Méthodologie', 'Production', 'Approfondissement'];

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={mode === 'edit'}
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, setTouched }) => {
        setSubmitting(false);
        console.log(values);
        onSubmit({ ...values, resourceType: values?.resourceType?.value });
        setTouched({});
      }}
    >
      {formik => (
        <Form onSubmit={formik.handleSubmit}>
          <Stack spacing={16}>
            <FormSection title="Informations de la ressource">
              <TextInput
                label="Nom de la ressource"
                type="text"
                id="name"
                name="name"
                placeholder="Ma ressource"
                disabled={pending}
                requiredInput
              />
              <SelectInput
                label="Type de ressource"
                name="resourceType"
                id="resourceType"
                options={RESSOURCE_TYPES.map(type => ({ label: type, value: type }))}
                placeholder="Sélectionner un type de ressource"
                noOptionsMessage={() => 'Aucune ressource disponible'}
                requiredInput
              />
              <TextInput
                label="Les professionnels habilités"
                type="text"
                id="professionals"
                name="professionals"
                placeholder="Ergothérapeute, ..."
                disabled={pending}
              />
              <TextInput
                label="Lien de la ressource"
                type="text"
                id="link"
                name="link"
                placeholder="https://sharepoint.com/document/abcdefg"
                disabled={pending}
                requiredInput
              />
            </FormSection>
          </Stack>

          <Can
            perform="ergo-resources:edit"
            yes={() => (
              <FormActions mt={20}>
                <SecondaryButton type="button" onClick={() => history.goBack()}>
                  Annuler
                </SecondaryButton>
                <PrimaryButton type="submit" disabled={pending}>
                  Enregistrer
                </PrimaryButton>
              </FormActions>
            )}
          />
        </Form>
      )}
    </Formik>
  );
};
