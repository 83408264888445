import React from 'react';

export const FormSubmitDisabledContext = React.createContext({ isFormSubmitDisabled: false });

export const FormSubmitDisabledProvider = ({ children, isFormSubmitDisabled }) => (
  <FormSubmitDisabledContext.Provider value={{ isFormSubmitDisabled }}>
    {children}
  </FormSubmitDisabledContext.Provider>
);

export const useFormSubmitDisabled = () => {
  const context = React.useContext(FormSubmitDisabledContext);
  if (context === undefined) {
    return { isFormSubmitDisabled: false };
  }
  return context;
};
