import { ergoResourcesConstants } from './constants';

export const initialState = { data: [], ergoResource: {} };

export function ergoResources(state = initialState, action) {
  switch (action.type) {
    case ergoResourcesConstants.GET_ERGO_RESOURCES_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case ergoResourcesConstants.GET_ERGO_RESOURCES_SUCCESS:
      return {
        data: action.data.ergoResources,
        meta: action.data.meta,
      };
    case ergoResourcesConstants.GET_ERGO_RESOURCE_REQUEST:
      return {
        ...initialState,
        pending: true,
      };
    case ergoResourcesConstants.GET_ERGO_RESOURCE_SUCCESS:
      return {
        ...initialState,
        pending: false,
        ergoResource: action.ergoResource,
      };
    case ergoResourcesConstants.GET_ERGO_RESOURCE_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case ergoResourcesConstants.CREATE_ERGO_RESOURCE_REQUEST:
      return {
        ...state,
        pending: true,
      };
    case ergoResourcesConstants.CREATE_ERGO_RESOURCE_SUCCESS:
      return initialState;
    case ergoResourcesConstants.CREATE_ERGO_RESOURCE_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };
    case ergoResourcesConstants.UPDATE_ERGO_RESOURCE_REQUEST:
      return {
        ...initialState,
        ergoResource: action.ergoResource,
        pending: true,
      };
    case ergoResourcesConstants.UPDATE_ERGO_RESOURCE_SUCCESS:
      return {
        ...initialState,
        pending: false,
      };
    case ergoResourcesConstants.UPDATE_ERGO_RESOURCE_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };

    case ergoResourcesConstants.DELETE_ERGO_RESOURCE_REQUEST:
      return {
        ...state,
        deletePending: true,
      };
    case ergoResourcesConstants.DELETE_ERGO_RESOURCE_SUCCESS:
      return {
        ...state,
        deletePending: false,
      };
    case ergoResourcesConstants.DELETE_ERGO_RESOURCE_FAILURE:
      return {
        ...state,
        error: action.error,
        deletePending: false,
      };
    case ergoResourcesConstants.GET_ERGO_RESOURCES_FAILURE:
    default:
      return state;
  }
}
