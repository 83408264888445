import { apiV1 } from './api';

const getErgoProjects = (page = 1) =>
  apiV1.get(`/ergo_projects?page=${page}`).then(({ data }) => data);

const assignErgoToProject = (ergoProjectId, ergoProject) =>
  apiV1
    .put(`/ergo_projects/${ergoProjectId}/assign_user`, { ergoProject })
    .then(({ data }) => data);

const getPositionableErgoUsers = ergoProjectId =>
  apiV1.get(`/ergo_projects/${ergoProjectId}/positionnable_ergo_users`).then(({ data }) => data);

export const ergoProjectsAPI = {
  getErgoProjects,
  assignErgoToProject,
  getPositionableErgoUsers,
};
