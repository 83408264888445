import React from 'react';
import { Box } from '@chakra-ui/core';
import { PageTitle } from '../../Titles';

export const PageDefault = ({ title, pageAction, children }) => {
  return (
    <Box px={10} pt={5} pb={10} flexGrow={1} d="flex" flexDirection="column">
      <Box d="flex" justifyContent="space-between" alignItems="start">
        <PageTitle mb={6}>{title}</PageTitle>
        {pageAction && pageAction}
      </Box>
      <Box bg="white" rounded="lg" shadow="layout" flexGrow="1">
        {children}
      </Box>
    </Box>
  );
};
