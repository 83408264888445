import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Link } from '@chakra-ui/core';
import { PageDefault } from '../common/components/layouts/Page/PageDefault';
import { RecipientForm } from '../common/components/RecipientForm/RecipientForm';
import { recipientsActions } from '../redux/recipients/actions';
import Can from '../roleBasedAccess/Can';
import { PrimaryButton, SecondaryButton } from '../common/components/Buttons';

const RecipientEdit = ({
  match: {
    params: { recipientId },
  },
  recipient,
  error,
  pending,
  getRecipient,
  updateRecipient,
  deleteRecipient,
  deletePending,
}) => {
  useEffect(() => {
    getRecipient(recipientId);
  }, [recipientId, getRecipient]);

  const onSubmit = updatedRecipientValues => {
    updateRecipient(recipientId, updatedRecipientValues);
  };

  const onDeleteRecipient = () => {
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(
      `Voulez-vous supprimer le bénéficiaire n°${recipient.id} - ${recipient.firstName} ${recipient.lastName} ?`,
    );
    if (confirm) deleteRecipient(recipientId);
  };

  return (
    <PageDefault
      title="édition de la fiche bénéficiaire"
      pageAction={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Can
          perform="delete"
          yes={() => (
            <Grid gridAutoFlow="column" gap="1rem">
              <SecondaryButton
                as={Link}
                href={`${process.env.REACT_APP_API_URL}/api/v1/recipients/${recipientId}/versions`}
                target="_blank"
                css={{ ':hover': { textDecoration: 'none' } }}
              >
                Historique
              </SecondaryButton>
              <PrimaryButton
                isLoading={deletePending}
                loadingText="En supression..."
                onClick={onDeleteRecipient}
              >
                Supprimer
              </PrimaryButton>
            </Grid>
          )}
        />
      }
    >
      <RecipientForm
        initialValues={recipient}
        pending={pending}
        onSubmit={onSubmit}
        error={error}
        mode="edit"
      />
    </PageDefault>
  );
};

const mapStateToProps = ({ recipients: state }) => ({
  error: state.error,
  pending: state.pending,
  deletePending: state.deletePending,
  recipient: state.recipient,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRecipient: recipientsActions.getRecipient,
      updateRecipient: recipientsActions.updateRecipient,
      deleteRecipient: recipientsActions.deleteRecipient,
    },
    dispatch,
  );

RecipientEdit.propTypes = {
  pending: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  deletePending: PropTypes.bool,
  getRecipient: PropTypes.func.isRequired,
  updateRecipient: PropTypes.func.isRequired,
  deleteRecipient: PropTypes.func.isRequired,
};

RecipientEdit.defaultProps = {
  pending: true,
  deletePending: false,
  error: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientEdit);
