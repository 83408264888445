import { preFoldersConstants } from './constants';
import { preFoldersAPI } from '../../common/api/preFolders';
import { errorsActions } from '../errors/actions';
import { getDateString, getDateFromString } from '../../common/utils';
import { TOAST_MESSAGE } from '../../common/components/Toasts/Toast';
import { toastActions } from '../toast/actions';
import routes from '../../common/routes';
import { history } from '../../common/helpers/history';

function getPreFolders(page) {
  function request() {
    return { type: preFoldersConstants.GET_PRE_FOLDERS_REQUEST };
  }
  function success(data) {
    return { type: preFoldersConstants.GET_PRE_FOLDERS_SUCCESS, data };
  }
  function failure(error) {
    return { type: preFoldersConstants.GET_PRE_FOLDERS_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return preFoldersAPI.getPreFolders(page).then(({ preliminaryFolders, meta }) => {
      dispatch(success({ preFolders: preliminaryFolders, meta }));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function getPreFolder(preFolderId) {
  function request() {
    return { type: preFoldersConstants.GET_PRE_FOLDER_REQUEST };
  }
  function success(preFolder) {
    return { type: preFoldersConstants.GET_PRE_FOLDER_SUCCESS, preFolder };
  }
  function failure(error) {
    return { type: preFoldersConstants.GET_PRE_FOLDER_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return preFoldersAPI.getPreFolder(preFolderId).then(({ preliminaryFolder }) => {
      dispatch(
        success({
          ...preliminaryFolder,
          bloc_0DoesRecipientOwnTheHousing: preliminaryFolder.bloc_0DoesRecipientOwnTheHousing
            ? 'true'
            : 'false',
          recipientIsTheContact: preliminaryFolder.recipientIsTheContact ? 'true' : 'false',
          birthDate: getDateFromString(preliminaryFolder.birthDate),
        }),
      );
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function createPreFolder(newPreFolder, withRedirection = true) {
  function request() {
    return { type: preFoldersConstants.CREATE_PRE_FOLDER_REQUEST };
  }
  function success(preFolder) {
    return { type: preFoldersConstants.CREATE_PRE_FOLDER_SUCCESS, preFolder };
  }
  function failure(error) {
    return { type: preFoldersConstants.CREATE_PRE_FOLDER_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return preFoldersAPI
      .createPreFolder({
        ...newPreFolder,
        birthDate: newPreFolder.birthDate && getDateString(newPreFolder.birthDate),
        recipientIsTheContact: newPreFolder.recipientIsTheContact === 'true' || false,
        bloc_0DoesRecipientOwnTheHousing:
          newPreFolder.bloc_0DoesRecipientOwnTheHousing === 'true' || false,
      })
      .then(({ preliminaryFolder }) => {
        dispatch(success(preliminaryFolder));
        if (withRedirection) history.push(routes.preFolders);
        dispatch(toastActions.success(TOAST_MESSAGE.success.add));
        return preliminaryFolder;
      }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.add))));
  };
}

function updatePreFolder(preFolderId, updatedPreFolder, withRedirection = true) {
  function request(preFolder) {
    return { type: preFoldersConstants.UPDATE_PRE_FOLDER_REQUEST, preFolder };
  }
  function success(preFolder) {
    return { type: preFoldersConstants.UPDATE_PRE_FOLDER_SUCCESS, preFolder };
  }
  function failure(error) {
    return { type: preFoldersConstants.UPDATE_PRE_FOLDER_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedPreFolder));

    return preFoldersAPI
      .editPreFolder(preFolderId, {
        ...updatedPreFolder,
        birthDate: updatedPreFolder.birthDate && getDateString(updatedPreFolder.birthDate),
        recipientIsTheContact: updatedPreFolder.recipientIsTheContact === 'true' || false,
        bloc_0DoesRecipientOwnTheHousing:
          updatedPreFolder.bloc_0DoesRecipientOwnTheHousing === 'true' || false,
      })
      .then(({ preliminaryFolder }) => {
        dispatch(success(preliminaryFolder));
        if (withRedirection) history.push(routes.preFolders);
        dispatch(toastActions.success(TOAST_MESSAGE.success.update));
        return preliminaryFolder;
      }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

function transformPreFolderIntoFolder(preFolderId) {
  function request() {
    return { type: preFoldersConstants.TRANSFORM_PRE_FOLDER_REQUEST };
  }
  function success() {
    return { type: preFoldersConstants.TRANSFORM_PRE_FOLDER_SUCCESS };
  }
  function failure(error) {
    return { type: preFoldersConstants.TRANSFORM_PRE_FOLDER_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return preFoldersAPI.transformPreFolderIntoFolder(preFolderId).then(() => {
      dispatch(success());
      history.push(routes.preFolders);
      dispatch(toastActions.success(TOAST_MESSAGE.success.update));
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

function deletePreFolder(preFolderId) {
  function request() {
    return { type: preFoldersConstants.DELETE_PRE_FOLDER_REQUEST };
  }
  function success() {
    return { type: preFoldersConstants.DELETE_PRE_FOLDER_SUCCESS };
  }
  function failure() {
    return { type: preFoldersConstants.DELETE_PRE_FOLDER_FAILURE };
  }

  return dispatch => {
    dispatch(request());
    return preFoldersAPI.deletePreFolder(preFolderId).then(() => {
      dispatch(success());
      history.push(routes.preFolders);
      dispatch(toastActions.success(TOAST_MESSAGE.success.delete));
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.delete))));
  };
}

export const preFoldersActions = {
  getPreFolders,
  getPreFolder,
  createPreFolder,
  updatePreFolder,
  transformPreFolderIntoFolder,
  deletePreFolder,
};
