import React, { useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { Tabs, Tab, TabPanels, TabPanel, TabList } from '../../../../common/components/Tabs';
import { BlocCTab1 } from './BlocCTab1';
import { BlocCTab2 } from './BlocCTab2';
import { BlocCTab3 } from './BlocCTab3';

export const BlocCTabs = ({
  match: {
    params: { folderId, tabIndex },
  },
  blocId,
  onSubmit,
  blocC: { error, pending, ...initialValues },
}) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        return onSubmit(values).then(() => {
          setSubmitting(false);
          setTouched({});
        });
      }}
    >
      {formik => (
        <Tabs folderId={folderId} blocId={blocId} tabIndex={tabIndex}>
          <TabList>
            <Tab>Validation préliminaire</Tab>
            <Tab>Déclaration du contrat</Tab>
            <Tab>Validation du financement</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <BlocCTab1 formik={formik} />
            </TabPanel>
            <TabPanel>
              <BlocCTab2 formik={formik} />
            </TabPanel>
            <TabPanel>
              <BlocCTab3 formik={formik} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Formik>
  );
};
