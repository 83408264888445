import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageDefault } from '../common/components/layouts/Page/PageDefault';
import { ErgoResourceForm } from '../common/components/ErgoResourceForm/ErgoResourceForm';
import { ergoResourcesActions } from '../redux/ergoResources/actions';
import Can from '../roleBasedAccess/Can';
import { PrimaryButton } from '../common/components/Buttons';

const ErgoResourceEdit = ({
  match: {
    params: { ergoResourceId },
  },
  error,
  pending,
  deletePending,
  ergoResource,
  getErgoResource,
  updateErgoResource,
  deleteErgoResource,
}) => {
  useEffect(() => {
    getErgoResource(ergoResourceId);
  }, [ergoResourceId, getErgoResource]);

  const onSubmit = newErgoResourceValues => {
    updateErgoResource(ergoResourceId, newErgoResourceValues);
  };

  const onDeleteErgoResource = () => {
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(
      `Voulez-vous supprimer la ressource n°${ergoResource.id} - ${ergoResource.name} ?`,
    );
    if (confirm) deleteErgoResource(ergoResourceId);
  };

  return (
    <PageDefault
      title={<Can perform="ergo-resources:edit" yes={() => 'Edition de la fiche ressource'} />}
      pageAction={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Can
          perform="ergo-resources:edit"
          yes={() => (
            <PrimaryButton
              isLoading={deletePending}
              loadingText="En supression..."
              onClick={onDeleteErgoResource}
            >
              Supprimer
            </PrimaryButton>
          )}
        />
      }
    >
      <ErgoResourceForm
        initialValues={ergoResource}
        pending={pending}
        onSubmit={onSubmit}
        error={error}
        mode="edit"
      />
    </PageDefault>
  );
};

const mapStateToProps = ({ ergoResources: state }) => ({
  ergoResource: state.ergoResource,
  error: state.error,
  pending: state.pending,
  deletePending: state.deletePending,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateErgoResource: ergoResourcesActions.updateErgoResource,
      getErgoResource: ergoResourcesActions.getErgoResource,
      deleteErgoResource: ergoResourcesActions.deleteErgoResource,
    },
    dispatch,
  );

ErgoResourceEdit.propTypes = {
  pending: PropTypes.bool,
  deletePending: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  error: PropTypes.object,
  updateErgoResource: PropTypes.func.isRequired,
  getErgoResource: PropTypes.func.isRequired,
};

ErgoResourceEdit.defaultProps = {
  pending: false,
  deletePending: false,
  error: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(ErgoResourceEdit);
