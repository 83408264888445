import React, { useEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { CompaniesIndex } from './CompaniesIndex';
import CompanyCreate from './CompanyCreate';
import CompanyEdit from './CompanyEdit';
import { PageError404 } from '../errors/components';
import { configActions } from '../redux/config/actions';

const CompaniesComponent = ({ match, validDepartments = [], getConfig }) => {
  useEffect(() => {
    getConfig();
  }, [getConfig]);

  const departments = useMemo(
    () =>
      validDepartments
        ?.sort((a, b) => Number(a) - Number(b))
        .map(value => ({
          label: value,
          value,
        })),
    [validDepartments],
  );

  return (
    <Switch>
      <Route
        path={`${match.url}/new`}
        exact
        render={props => <CompanyCreate departments={departments} {...props} />}
      />
      <Route
        path={`${match.url}/:companyId/edit`}
        exact
        render={props => <CompanyEdit departments={departments} {...props} />}
      />
      <Route path={match.url} exact component={CompaniesIndex} />
      <Route component={PageError404} />
    </Switch>
  );
};

const mapStateToProps = ({ config: { validDepartments } }) => ({
  validDepartments,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getConfig: configActions.getConfig,
    },
    dispatch,
  );

export const Companies = connect(mapStateToProps, mapDispatchToProps)(CompaniesComponent);
