import React from 'react';
import { Route, Switch } from 'react-router';

import { FoldersIndex } from './FoldersIndex';
import FolderEdit from './FolderEdit';
import FolderCreate from './FolderCreate';
import { PageError404 } from '../errors/components';

export const Folders = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.url}/new`} exact component={FolderCreate} />
      <Route path={`${match.url}/:folderId`} component={FolderEdit} />
      <Route path={match.url} exact component={FoldersIndex} />
      <Route component={PageError404} />
    </Switch>
  );
};
