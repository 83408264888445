import { history } from '../../common/helpers/history';
import { ergoResourcesConstants } from './constants';
import { ergoResourcesAPI } from '../../common/api/ergoResources';
import routes from '../../common/routes';
import { toastActions } from '../toast/actions';
import { TOAST_MESSAGE } from '../../common/components/Toasts/Toast';
import { errorsActions } from '../errors/actions';

const transformApiPayloadToFormValues = values => {
  return {
    ...values.ergoResource,
    resourceType: values?.ergoResource.resourceType
      ? { label: values?.ergoResource.resourceType, value: values?.ergoResource.resourceType }
      : null,
  };
};

function getErgoResources(page, name, params) {
  function request() {
    return { type: ergoResourcesConstants.GET_ERGO_RESOURCES_REQUEST };
  }
  function success(data) {
    return { type: ergoResourcesConstants.GET_ERGO_RESOURCES_SUCCESS, data };
  }
  function failure(error) {
    return { type: ergoResourcesConstants.GET_ERGO_RESOURCES_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return ergoResourcesAPI.getErgoResources(page, name, params).then(({ ergoResources, meta }) => {
      dispatch(success({ ergoResources, meta }));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function getErgoResource(ergoResourceId) {
  function request() {
    return { type: ergoResourcesConstants.GET_ERGO_RESOURCE_REQUEST };
  }
  function success(ergoResource) {
    return { type: ergoResourcesConstants.GET_ERGO_RESOURCE_SUCCESS, ergoResource };
  }
  function failure(error) {
    return { type: ergoResourcesConstants.GET_ERGO_RESOURCE_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return ergoResourcesAPI.getErgoResource(ergoResourceId).then(({ ergoResource }) => {
      dispatch(success(transformApiPayloadToFormValues({ ergoResource })));
    }, dispatch(errorsActions.handleErrors(failure)));
  };
}

function createErgoResource(newErgoResource) {
  function request() {
    return { type: ergoResourcesConstants.CREATE_ERGO_RESOURCE_REQUEST };
  }
  function success() {
    return { type: ergoResourcesConstants.CREATE_ERGO_RESOURCE_SUCCESS };
  }
  function failure(error) {
    return { type: ergoResourcesConstants.CREATE_ERGO_RESOURCE_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return ergoResourcesAPI.createErgoResource(newErgoResource).then(() => {
      dispatch(success());
      history.push(routes.ergoResources);
      dispatch(toastActions.success(TOAST_MESSAGE.success.add));
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.add))));
  };
}

function updateErgoResource(ergoResourceId, updatedErgoResource) {
  function request(ergoResource) {
    return { type: ergoResourcesConstants.UPDATE_ERGO_RESOURCE_REQUEST, ergoResource };
  }
  function success() {
    return { type: ergoResourcesConstants.UPDATE_ERGO_RESOURCE_SUCCESS };
  }
  function failure(error) {
    return { type: ergoResourcesConstants.UPDATE_ERGO_RESOURCE_FAILURE, error };
  }

  return dispatch => {
    dispatch(request(updatedErgoResource));

    return ergoResourcesAPI.updateErgoResource(ergoResourceId, updatedErgoResource).then(() => {
      dispatch(success());
      history.push(routes.ergoResources);
      dispatch(toastActions.success(TOAST_MESSAGE.success.update));
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.update))));
  };
}

function deleteErgoResource(recipientId) {
  function request() {
    return { type: ergoResourcesConstants.DELETE_ERGO_RESOURCE_REQUEST };
  }
  function success() {
    return { type: ergoResourcesConstants.DELETE_ERGO_RESOURCE_SUCCESS };
  }
  function failure(error) {
    return { type: ergoResourcesConstants.DELETE_ERGO_RESOURCE_FAILURE, error };
  }

  return dispatch => {
    dispatch(request());

    return ergoResourcesAPI.deleteErgoResource(recipientId).then(() => {
      dispatch(success());
      history.push(routes.ergoResources);
      dispatch(toastActions.success(TOAST_MESSAGE.success.delete));
    }, dispatch(errorsActions.handleErrors(failure, toastActions.error(TOAST_MESSAGE.error.delete))));
  };
}

export const ergoResourcesActions = {
  getErgoResources,
  getErgoResource,
  createErgoResource,
  updateErgoResource,
  deleteErgoResource,
};
