import React from 'react';
import { Route, Switch } from 'react-router';

import { QuotesIndex } from './QuotesIndex';
import { PageError404 } from '../errors/components';

export const Quotes = ({ match }) => {
  return (
    <Switch>
      <Route path={match.url} exact component={QuotesIndex} />
      <Route component={PageError404} />
    </Switch>
  );
};
