/* eslint-disable no-nested-ternary */
import React from 'react';
import { useField } from 'formik';
import {
  Icon,
  FormControl,
  useTheme,
  Box,
  Text,
  Menu,
  MenuButton,
  Stack,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
} from '@chakra-ui/core';
import ReactSelect, { components as RCcomponents } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import { Label, IconError, ErrorMessage } from './FormInputs';
import { useFormSubmitDisabled } from './FormSubmitDisabled.context';

const selectStyle = (theme, isInvalid, isLocked, isUpperCase, size = 'md') => ({
  placeholder: provided => {
    return {
      ...provided,
      color: theme.colors.placeholder,
      fontWeight: 'normal',
    };
  },
  clearIndicator: provided => {
    return {
      ...provided,
      position: 'absolute',
      left: '100%',
    };
  },
  indicatorSeparator: provided => {
    return {
      ...provided,
      backgroundColor: 'none',
    };
  },
  indicatorsContainer: (provided, { isDisabled }) => {
    return {
      ...provided,
      svg: {
        color: isLocked
          ? theme.colors.lightGray2
          : isDisabled
          ? theme.colors.gray
          : theme.colors.coral[900],
      },
    };
  },
  option: (provided, { isSelected, isFocused }) => {
    let style;
    if (isSelected) {
      style = {
        backgroundColor: theme.colors.select.bg.isSelected,
        color: theme.colors.red,
        fontWeight: 600,
      };
    }
    if (isFocused && !isSelected) {
      style = { background: 'none', color: theme.colors.coral[900], fontWeight: 600 };
    }
    return {
      ...provided,
      ...style,
      ':active': {
        backgroundColor: 'none',
      },
      cursor: 'pointer',
      textTransform: isUpperCase ? 'uppercase' : undefined,
      transition: '0.3s',
    };
  },
  control: (provided, { isFocused, isDisabled }) => ({
    ...provided,
    backgroundColor: isLocked
      ? theme.colors.input.bg.locked
      : isDisabled
      ? theme.colors.input.bg.disabled
      : 'transparent',
    fontWeight: 600,
    borderRadius: theme.radii.lg,
    borderColor: isFocused
      ? theme.colors.coral[400]
      : isInvalid
      ? theme.colors.errorBorderColor
      : isLocked
      ? theme.colors.input.borderColor.locked
      : theme.colors.lightGray2,
    boxShadow: 'none',
    minHeight: 'auto',
    ':hover': {
      borderColor: isFocused
        ? theme.colors.coral[900]
        : isInvalid
        ? theme.colors.errorBorderColor
        : theme.colors.lightGray2,
    },
    '> div': {
      paddingLeft: theme.space[3],
    },
    opacity: isDisabled && !isLocked ? '0.3' : '1',
    input: {
      textTransform: isUpperCase ? 'uppercase' : undefined,
    },
  }),
  input: (provided, { isFocused }) => ({
    ...provided,
    border: isFocused ? 0 : '',
    textTransform: isUpperCase ? 'uppercase' : undefined,
  }),
  valueContainer: provided => ({
    ...provided,
    paddingTop: size === 'sm' ? 0 : undefined,
  }),
  singleValue: provided => ({
    ...provided,
    lineHeight: 1.5,
    textTransform: isUpperCase ? 'uppercase' : undefined,
  }),
  multiValue: (provided, { isFocused }) => ({
    ...provided,
    backgroundColor: theme.colors.coral[900],
    borderRadius: theme.radii.lg,
    ...(!isFocused || theme.colors.red),
    textTransform: isUpperCase ? 'uppercase' : undefined,
    span: {
      display: 'none',
    },
  }),
  multiValueLabel: provided => {
    return {
      ...provided,
      padding: `${theme.space[2]} ${theme.space[2]} ${theme.space[2]} ${theme.space[3]}`,
      paddingLeft: theme.space[3],
      color: theme.colors.white,
      textTransform: isUpperCase ? 'uppercase' : undefined,
    };
  },
  multiValueRemove: provided => {
    return {
      ...provided,
      color: theme.colors.white,
      paddingRight: theme.space[3],
      ':hover': {
        cursor: 'pointer',
        background: 'transparent',
        color: theme.colors.white,
        opacity: 0.8,
      },
    };
  },
  menu: provided => ({
    ...provided,
    boxShadow: theme.shadows.popover,
    borderRadius: theme.radii.lg,
    marginTop: `-${theme.space[1]}`,
    zIndex: theme.zIndices.dropdown,
  }),
  menuList: provided => ({
    ...provided,
    borderRadius: theme.radii.lg,
    padding: 0,
  }),
});

const DropdownIndicator = props => {
  return (
    <RCcomponents.DropdownIndicator {...props}>
      <Icon name="chevron-down" color="coral.900" size="13px" strokeWidth={2} />
    </RCcomponents.DropdownIndicator>
  );
};

const ClearIndicator = props => {
  return (
    <RCcomponents.ClearIndicator {...props}>
      <Icon name="close" color="coral.900" size="10px" strokeWidth={3} />
    </RCcomponents.ClearIndicator>
  );
};

const MultiValueRemove = props => {
  return (
    <RCcomponents.MultiValueRemove {...props}>
      <Icon name="close" size="0.625em" />
    </RCcomponents.MultiValueRemove>
  );
};

export const SelectInput = ({
  label,
  styles,
  css,
  requiredInput,
  readOnly,
  postOnChange,
  ...props
}) => {
  const { id, name } = props;
  const { isFormSubmitDisabled } = useFormSubmitDisabled();

  const [field, meta, helpers] = useField(props);
  const isInvalid = !meta.touched && meta.error;
  return (
    <FormControl mb={6} {...styles} css={css}>
      {label && (
        <Label htmlFor={id || name}>
          {label}
          {requiredInput && ' *'}
        </Label>
      )}
      <Box position="relative" cursor={props.isDisabled ? 'not-allowed' : 'pointer'}>
        {isInvalid && <IconError />}
        <SimpleSelectInput
          isInvalid={isInvalid}
          {...field}
          value={meta.value}
          onChange={value => {
            helpers.setValue(value);
            helpers.setTouched(true);
            if (postOnChange) postOnChange(value);
          }}
          {...props}
          isSearchable={!readOnly && !isFormSubmitDisabled}
          menuIsOpen={readOnly || isFormSubmitDisabled ? false : undefined}
        />
      </Box>
      {!meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
    </FormControl>
  );
};

export const SelectInputMultiple = ({
  label,
  options,
  menuOptionGroupOptions,
  styles,
  css,
  requiredInput,
  readOnly,
  ...props
}) => {
  const theme = useTheme();

  return (
    <FormControl mb={6} {...styles} css={css}>
      <Box position="relative" cursor={props.isDisabled ? 'not-allowed' : 'pointer'}>
        <Menu closeOnSelect={false}>
          {({ isOpen }) => (
            <>
              <MenuButton
                type="button"
                px={3}
                color={theme.colors.placeholder}
                fontStyle="normal"
                borderWidth="1px"
                borderColor={isOpen ? theme.colors.coral[900] : theme.colors.lightGray2}
                borderRadius="lg"
                w="full"
                textAlign="left"
                _focus={{ boxShadow: 'none' }}
                d="flex"
                justifyContent="space-between"
                alignItems="center"
                minWidth={0}
              >
                {menuOptionGroupOptions.value.length ? (
                  <>
                    <Text
                      w="full"
                      pt="0.3rem"
                      pb="0.35rem"
                      fontWeight={600}
                      color={theme.colors.semiDarkGray}
                      isTruncated
                      minWidth="0"
                      pr="2px"
                      textTransform={styles.textTransform}
                    >
                      {menuOptionGroupOptions.value.join(', ')}
                    </Text>
                    <Stack justifyContent="center" textAlign="center" p={2} ml="auto" mr={2}>
                      <Icon
                        onClick={() => menuOptionGroupOptions.clear()}
                        name="close"
                        color="coral.900"
                        size="10px"
                        strokeWidth={3}
                      />
                    </Stack>
                  </>
                ) : (
                  <Text w="full" pt="0.3rem" pb="0.35rem" isTruncated minWidth="0" pr="2px">
                    {label}
                  </Text>
                )}
                <Icon name="chevron-down" color="coral.900" size="13px" strokeWidth={2} />
              </MenuButton>
              <MenuList
                w="max-content"
                minW="100%"
                placement="bottom-start"
                boxShadow="popover"
                borderRadius="lg"
                mt="-0.25rem !important"
                border={0}
                zIndex="dropdown"
                py={0}
              >
                <MenuOptionGroup type="checkbox" {...menuOptionGroupOptions}>
                  {options.map(option => (
                    <MenuItemOption
                      key={option}
                      value={option}
                      as="div"
                      css={{ svg: { color: 'coral' } }}
                      _checked={{ fontWeight: 'semibold' }}
                      _hover={{ backgroundColor: 'lightGray' }}
                      _focus={{ backgroundColor: 'select.bg.isSelected', outline: 0 }}
                      _first={{ borderTopLeftRadius: 'lg', borderTopRightRadius: 'lg' }}
                      _last={{ borderBottomLeftRadius: 'lg', borderBottomRightRadius: 'lg' }}
                    >
                      {option}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </>
          )}
        </Menu>
      </Box>
    </FormControl>
  );
};

export const SimpleSelectInput = ({
  isInvalid,
  isLocked,
  isUpperCase,
  isCreatable,
  isAsync,
  size,
  components,
  ...props
}) => {
  const theme = useTheme();
  const Select = isCreatable
    ? isAsync
      ? AsyncCreatableSelect
      : CreatableSelect
    : isAsync
    ? AsyncSelect
    : ReactSelect;

  return (
    <Select
      styles={selectStyle(theme, isInvalid, isLocked, isUpperCase, size)}
      components={{ DropdownIndicator, ClearIndicator, MultiValueRemove, ...components }}
      noOptionsMessage={() => 'Aucune option'}
      formatCreateLabel={value => (
        <p>
          Créer le tag : "
          <Text as="b" textTransform="normal">
            {value}
          </Text>
          "
        </p>
      )}
      {...props}
    />
  );
};
