import React from 'react';
import { Text } from '@chakra-ui/core';

import { TabForm } from '../../../../common/components/Tabs';
import {
  FormSection,
  FormListItem,
  FormList,
  FormSubmitButton,
} from '../../../../common/components/Form/Form';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import { SwitchInput } from '../../../../common/components/Form/FormInputs';
import { Notice, NoticeText } from '../../../../common/components/Notice';

export const BlocCTab3 = ({ formik }) => {
  return (
    <TabForm onSubmit={formik.handleSubmit}>
      <FormSection title="Liste des prérequis">
        {formik.values.requiredFundingDocuments &&
        Object.keys(formik.values.requiredFundingDocuments).length > 0 ? (
          <>
            <FormList mb="3.687rem">
              {Object.entries(formik.values.requiredFundingDocuments).map(([key, document]) => (
                <FormListItem key={document.name}>
                  <SwitchInput
                    label={document.name}
                    name={`requiredFundingDocuments.${key}.isReceived`}
                    id={`requiredFundingDocuments.${key}.isReceived`}
                  />
                </FormListItem>
              ))}
            </FormList>

            <DateInput
              label="Date de réception des documents"
              name="fundingDocumentsReceivedAt"
              id="fundingDocumentsReceivedAt"
              disabled={
                formik.values.requiredFundingDocuments &&
                Object.values(formik.values.requiredFundingDocuments).some(doc => !doc.isReceived)
              }
            />

            <Notice variant="warning">
              <NoticeText variant="warning">
                Vérifier le Diagnostic socio-administratif et mettre les données à jour si
                nécessaire
              </NoticeText>
            </Notice>
          </>
        ) : (
          <Text color="semiDarkGray.600" mb={5} fontWeight={600}>
            Aucune pièce n'est attendue.
          </Text>
        )}
      </FormSection>

      <FormSubmitButton type="submit" isLoading={formik.isSubmitting} loadingText="Enregistrer">
        Enregistrer
      </FormSubmitButton>
    </TabForm>
  );
};
