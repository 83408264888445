import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import { Stack, Text, Box, List, ListItem } from '@chakra-ui/core';

import { TabForm } from '../../../../common/components/Tabs';
import {
  FormSection,
  FormSubmitButton,
  FormCTAButton,
} from '../../../../common/components/Form/Form';
import { Notice, NoticeText, NoticeThinText } from '../../../../common/components/Notice';
import { DateInput } from '../../../../common/components/Form/FormDateInput';
import { Label } from '../../../../common/components/Form/FormInputs';
import { getFolderPath } from '../../../../common/routes';
import { history } from '../../../../common/helpers/history';

export const BlocGTab3 = ({ blocG: { error, pending, ...initialValues }, onSubmit }) => {
  const formikRef = useRef();

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setErrors(error && error.errors);
    }
  }, [error]);

  const {
    needOwnerAutorisation,
    hasWorks,
    totalToPayByRecipient,
    amoPrice,
    totalGrantedAmount,
    recommendationsTotalPrice,
  } = initialValues;

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setTouched, setSubmitting }) => {
        return onSubmit({ ...initialValues, totalToPayByRecipient, ...values }).then(() => {
          setTouched({});
          setSubmitting(false);
        });
      }}
    >
      {formik => (
        <TabForm onSubmit={formik.handleSubmit}>
          <Stack spacing="3.5rem">
            <FormSection title="Financements inconnus et exclus">
              <Stack spacing="1ch">
                <Label as="p">Montant octroyable inconnu pour :</Label>
                {formik.values.eligibleFundersMonitoring &&
                Object.values(formik.values.eligibleFundersMonitoring).some(
                  eligibleFunder =>
                    eligibleFunder.fundingResponse === 'approval' &&
                    (!eligibleFunder.isGrantedAmountKnown ||
                      eligibleFunder.isGrantedAmountKnown === 'false'),
                ) ? (
                  <List color="semiDarkGray.600">
                    {formik.values.eligibleFundersMonitoring &&
                      Object.entries(formik.values.eligibleFundersMonitoring)
                        .filter(
                          ([, eligibleFunder]) =>
                            eligibleFunder.fundingResponse === 'approval' &&
                            (eligibleFunder.isGrantedAmountKnown === 'false' ||
                              !eligibleFunder.isGrantedAmountKnown),
                        )
                        .map(([key, value]) => <ListItem key={key}>- {value.label}</ListItem>)}
                  </List>
                ) : (
                  <Text color="semiDarkGray.600">Aucun montant octroyable inconnu</Text>
                )}
              </Stack>

              <Stack spacing="1ch" mt="1.5rem">
                <Label as="p">Financeurs non sollicités :</Label>
                {formik.values.eligibleFundersMonitoring &&
                Object.values(formik.values.eligibleFundersMonitoring).some(
                  eligibleFunder => eligibleFunder.fundingResponse === 'not_requested',
                ) ? (
                  <List color="semiDarkGray.600">
                    {formik.values.eligibleFundersMonitoring &&
                      Object.entries(formik.values.eligibleFundersMonitoring)
                        .filter(
                          ([, eligibleFunder]) =>
                            eligibleFunder.fundingResponse === 'not_requested',
                        )
                        .map(([key, value]) => <ListItem key={key}>- {value.label}</ListItem>)}
                  </List>
                ) : (
                  <Text color="semiDarkGray.600">Aucun financeur non sollicité</Text>
                )}
              </Stack>
            </FormSection>

            <FormSection title="Fin d'instruction financière">
              <DateInput
                label="Date d'information de fin d'instruction financière au bénéficiaire"
                name="financialInvestigationEndDate"
                id="financialInvestigationEndDate"
                disabled={
                  formik.values.eligibleFundersMonitoring &&
                  Object.values(formik.values.eligibleFundersMonitoring).some(
                    eligibleFunder =>
                      !eligibleFunder.fundingResponse ||
                      (eligibleFunder.fundingResponse === 'approval' &&
                        (!eligibleFunder.isGrantedAmountKnown ||
                          (eligibleFunder.isGrantedAmountKnown === 'true' &&
                            !eligibleFunder.grantedAmount))),
                  )
                }
              />

              <Notice variant="info">
                <NoticeText variant="info">
                  Documents à envoyer à la personne à contacter :
                </NoticeText>
                <NoticeThinText mt="-1rem">
                  Si nécessaire, consulter les fiches-procédures ou l'administrateur
                </NoticeThinText>
              </Notice>
            </FormSection>

            <FormSection title="Reste à charge">
              <Stack spacing={5}>
                <Box>
                  <Label as="p">Reste à charge tout compris</Label>
                  <Box as="table">
                    <Box as="tbody">
                      <Box as="tr">
                        <Box as="td" textAlign="right" px={2}>
                          <Text d="flex" fontWeight={600} color="semiDarkGray.600">
                            <Text as="span" fontWeight="light" mr="8px">
                              +
                            </Text>
                            <Text as="span" fontWeight="light" mr="8px" ml="auto">
                              {recommendationsTotalPrice === null
                                ? 'XXX XXX'
                                : recommendationsTotalPrice}
                            </Text>
                            €
                          </Text>
                        </Box>
                        <Box as="td" fontSize="sm" pl={5} color="semiDarkGray.600">
                          Coût total des préconisations
                        </Box>
                      </Box>
                      <Box as="tr">
                        <Box as="td" textAlign="right" px={2}>
                          <Text d="flex" fontWeight={600} color="semiDarkGray.600">
                            <Text as="span" fontWeight="light" mr="8px">
                              +
                            </Text>
                            <Text as="span" fontWeight="light" mr="8px" ml="auto">
                              {amoPrice === null ? 'XXX XXX' : amoPrice}
                            </Text>
                            €
                          </Text>
                        </Box>
                        <Box as="td" fontSize="sm" pl={5} color="semiDarkGray.600">
                          Coût de l'AMO
                        </Box>
                      </Box>
                      <Box as="tr">
                        <Box as="td" textAlign="right" px={2} pb={2}>
                          <Text d="flex" fontWeight={600} color="semiDarkGray.600">
                            <Text as="span" fontWeight="light" mr="8px">
                              -
                            </Text>
                            <Text as="span" fontWeight="light" mr="8px" ml="auto">
                              {totalGrantedAmount === null ? 'XXX XXX' : totalGrantedAmount}
                            </Text>
                            €
                          </Text>
                        </Box>
                        <Box as="td" fontSize="sm" pl={5} pb={2} color="semiDarkGray.600">
                          Montant total octroyé
                        </Box>
                      </Box>
                      <Box as="tr">
                        <Box
                          as="td"
                          textAlign="right"
                          borderTopColor="coral.900"
                          borderTopWidth="1px"
                          px={2}
                          pt={2}
                        >
                          <Text fontWeight={600} color="semiDarkGray.600">
                            <Text as="span" color="semiDarkGray.900" mr="8px">
                              {totalToPayByRecipient === null ? 'XXX XXX' : totalToPayByRecipient}
                            </Text>
                            €
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </FormSection>

            <Box textAlign="center">
              <FormCTAButton
                disabled={
                  (needOwnerAutorisation === false && !formik.values.fundingPlanSentAt) ||
                  (needOwnerAutorisation === true &&
                    hasWorks &&
                    !formik.values.hasOwnerAuthorizedWorks) ||
                  !formik.values.financialInvestigationEndDate ||
                  (formik.values.eligibleFundersMonitoring &&
                    Object.values(formik.values.eligibleFundersMonitoring).some(
                      eligibleFunder =>
                        !eligibleFunder.fundingResponse ||
                        (eligibleFunder.fundingResponse === 'approval' &&
                          (!eligibleFunder.isGrantedAmountKnown ||
                            (eligibleFunder.isGrantedAmountKnown === 'true' &&
                              !eligibleFunder.grantedAmount))),
                    ))
                }
                onClick={() =>
                  formik
                    .submitForm()
                    .then(() => history.push(getFolderPath('bloc_h', initialValues.folderId)))
                }
              >
                Suivre la mise en oeuvre des préconisations
              </FormCTAButton>
            </Box>
          </Stack>

          <FormSubmitButton
            type="submit"
            disabled={formik.isSubmitting || pending}
            isLoading={formik.isSubmitting}
            loadingText="Enregistrer"
          >
            Enregistrer
          </FormSubmitButton>
        </TabForm>
      )}
    </Formik>
  );
};
