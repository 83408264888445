import { combineReducers } from 'redux';
import { bloc0 } from './bloc0/reducer';
import { blocFio } from './blocFio/reducer';
import { blocC } from './blocC/reducer';
import { blocA } from './blocA/reducer';
import { blocD } from './blocD/reducer';
import { blocE } from './blocE/reducer';
import { blocF } from './blocF/reducer';
import { blocG } from './blocG/reducer';
import { blocH } from './blocH/reducer';
import { blocI } from './blocI/reducer';

export const blocsReducer = combineReducers({
  bloc0,
  blocFio,
  blocC,
  blocA,
  blocD,
  blocE,
  blocF,
  blocG,
  blocH,
  blocI,
});
