const getQualifications = () =>
  // TODO : a backend endpoint shd be available to get these Magic values
  // apiV1.get(`/users/search_qualifications?search=${search}`).then(({ data }) => data);
  new Promise((resolve, reject) => {
    resolve(200);
  })
    .then(() => ['ergothérapeute interne', 'référent de cabinet', 'ergothérapeute de cabinet']) // RESOLVE
    .catch(() => []); // REJECT

export const qualificationsAPI = {
  getQualifications,
};
