import React from 'react';
import { Heading } from '@chakra-ui/core';

export const PageTitle = ({ children, ...props }) => (
  <Heading
    color="semiDarkGray.900"
    fontFamily="heading"
    fontWeight="bold"
    size="lg"
    textTransform="uppercase"
    {...props}
  >
    {children}
  </Heading>
);
