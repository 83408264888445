import { toastConstants } from './constants';

function success(message) {
  return { type: toastConstants.TOAST_SUCCESS, message };
}

function error(message) {
  return {
    type: toastConstants.TOAST_ERROR,
    message,
  };
}

function clear() {
  return {
    type: toastConstants.TOAST_CLEAR,
  };
}

export const toastActions = {
  success,
  error,
  clear,
};
