import React from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { Tabs, Tab, TabPanels, TabPanel, TabList } from '../../../../common/components/Tabs';
import { BlocGTab1 } from './BlocGTab1';
import { BlocGTab2 } from './BlocGTab2';
import { BlocGTab3 } from './BlocGTab3';
import { getFolderPath } from '../../../../common/routes';
import { PageError404 } from '../../../../errors/components';

export const BlocGTabs = ({ blocId, ...props }) => {
  const {
    match: {
      params: { folderId, tabIndex },
    },
  } = props;

  return (
    <Tabs folderId={folderId} tabIndex={tabIndex} blocId={blocId}>
      <TabList>
        <Tab>Préparation au financement</Tab>
        <Tab>Suivi des demandes de financement</Tab>
        <Tab>Synthèse</Tab>
      </TabList>

      <Switch>
        {tabIndex !== '2' ? (
          <Route
            path={getFolderPath(blocId, ':folderId', ':tabIndex')}
            exact
            render={routerProps => (
              <TabPanels>
                <TabPanel>
                  <BlocGTab1 {...props} />
                </TabPanel>
                <TabPanel>
                  <BlocGTab2 {...props} blocId={blocId} {...routerProps} />
                </TabPanel>
                <TabPanel>
                  <BlocGTab3 {...props} />
                </TabPanel>
              </TabPanels>
            )}
          />
        ) : (
          <Route
            path={getFolderPath(blocId, ':folderId', ':tabIndex')}
            exact
            render={routerProps => (
              <Redirect to={getFolderPath(blocId, folderId, `${tabIndex}/1`)} {...routerProps} />
            )}
          />
        )}

        <Route
          path={getFolderPath(blocId, ':folderId', ':tabIndex/:funderIndex')}
          render={routerProps => (
            <TabPanels>
              <TabPanel>
                <BlocGTab1 {...props} />
              </TabPanel>
              <TabPanel>
                <BlocGTab2 {...props} blocId={blocId} {...routerProps} />
              </TabPanel>
              <TabPanel>
                <BlocGTab3 {...props} />
              </TabPanel>
            </TabPanels>
          )}
        />

        <Route component={PageError404} />
      </Switch>
    </Tabs>
  );
};
