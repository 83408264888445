import React from 'react';
import { Box } from '@chakra-ui/core';
import Header from '../../Header';
import MainNav from '../../MainNav';

export const PrivateLayout = ({ children }) => {
  return (
    <Box minHeight="100vh" d="flex" flexDirection="column" bg="lightGray3">
      <Header />
      <Box d="flex" flexGrow="1" position="relative">
        <MainNav />
        <Box as="main" d="flex" flexDirection="column" flexGrow="1" ml="sidebar" minW="0">
          {children}
        </Box>
      </Box>
    </Box>
  );
};
